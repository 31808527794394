import customAxios from "lib/axios";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "../components/Pagination";
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from "react-bootstrap";
function ExamList() {
  const [t] = useTranslation(["exam"]);
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const [examList, setExamList] = React.useState([]);
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: "5 " + t("rows") });
  const allExamsCount = examList.length;
  const [examsPerPage, setExamsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const lastExamNumber = currentPage * examsPerPage;
  const firstExamIndex = lastExamNumber - examsPerPage;
  const limitedExams = examList.slice(
    firstExamIndex,
    lastExamNumber
  );
  const [loading, setLoading] = React.useState(true)
  const warningWithConfirmMessage = (id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirmDelete(id)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {t("modalWarnningMsgText")}
      </SweetAlert>
    );
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (id) => {
    customAxios.post("/exam/deleteExamInstance", {
      id: id
    })
      .then((res) => {
        if (res.data.msg == "success") {
          successAlert(t("submitRemoveSuccessAlertMsg.first") + t("submitRemoveSuccessAlertMsg.second"));
          setExamList(examList.filter((item) => item.id != id));
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
  }
  React.useEffect(() => {
    // submit form values
    customAxios.get("/exam/getExamList")
      .then((res) => {
        setExamList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        // Work with the err
      });
  }, [])
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("examList")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width overflow-auto">
                <div className="d-flex justify-content-end mr-4 mb-2">
                  <Pagination
                    itemsCount={allExamsCount}
                    itemsPerPage={examsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
                  <div style={{ width: 155 }}>
                    <Select
                      className="react-select primary ml-4"
                      classNamePrefix="react-select"
                      style={{ width: `100px` }}
                      name="rowCnt"
                      value={pageRowsCnt}
                      onChange={(optionSelected) => {
                        setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                        setExamsPerPage(optionSelected.value);
                      }}
                      options={[
                        { value: 5, label: "5 " + t("rows") },
                        { value: 10, label: "10 " + t("rows") },
                        { value: 15, label: "15 " + t("rows") },
                        { value: 20, label: "20 " + t("rows") },
                        { value: 25, label: "25 " + t("rows") },
                      ]}
                      placeholder={t("singleSelect")}
                    />
                  </div>
                </div>
                {!loading ? (
                  limitedExams.length > 0 ?
                    <Table>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                          <th className="text-left">{t("mainExamName")}</th>
                          <th className="text-center">{t("class")}</th>
                          <th className="text-center">{t("examDate")}</th>
                          <th className="text-center">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          limitedExams
                            .filter((item) => item.class_name.isActive)
                            .map((item, i) =>
                              <tr key={i}>
                                <td className="text-center" style={{ paddingLeft: '8px' }}>{firstExamIndex + i + 1}</td>
                                <td className="text-left">{item.exam_name.exam_name}</td>
                                <td className="text-center">{item.class_name.class_name}</td>
                                <td className="text-center">{item.exam_name.exam_datetime}</td>
                                <td className="td-actions text-center">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("btnEdit")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={(e) => {
                                        // history.push("/admin/create_exam");
                                        history.push("/admin/create_exam/" + item.exam_nameid + "/" + item.exam_classid);
                                      }}
                                      variant="success"
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("btnRemove")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={(e) => {
                                        onConfirmDelete(item.id)
                                      }}
                                      variant="danger"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )
                        }
                      </tbody>
                    </Table> :
                    <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noExamFound")}</div>)
                  :
                  (<div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExamList;
