import { createContext, useState } from 'react'
import i18n from "i18next";
export const commonContext = createContext({})
import image from '../assets/img/full-screen-image-3.jpg'



export const CommonContextProvider = (props) => {
    const [lang, setLang] = useState(i18n.language)
    const [name, setName] = useState('')
    const [profile, setProfile] = useState("")
    const [sidebarSwitch, setSidebarSwitch] = useState("")
    const [loading, setLoading] = useState(true)
    const [gradeLoading, setGradeLoading] = useState(true)
    const [userProfile, setUserProfile] = useState("")
    const [bgImage, setBgImage] = useState(image)

    return (
        <commonContext.Provider value={{ lang, setLang, name, setName, profile, setProfile, sidebarSwitch, setSidebarSwitch, loading, setLoading, gradeLoading, setGradeLoading, userProfile, setUserProfile, bgImage, setBgImage }}>
            {props.children}
        </commonContext.Provider >
    )
}