import customAxios from "lib/axios";
import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { setAll } from "../store/selectconstReducer";
import { setAll as setAllGrade } from '../store/gradeReducer'
import { authInfo, loginUser } from '../store/authReducer'
import { commonContext } from '../context/common'
// react-bootstrap components
// core components
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
// dinamically create dashboard routes
import routes from "routes.js";
import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";
function Admin() {
  const dispatch = useDispatch();
  const common = useContext(commonContext)
  const storeAuthInfo = useSelector(authInfo)
  const [sidebarImage, setSidebarImage] = React.useState(image3);
  const [sidebarBackground, setSidebarBackground] = React.useState("black");
  const [mainPanelWidths, setMainPanelWidths] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getProfile = async () => {
    const name = await customAxios.get("/common/get-profile")
    common.setName(name.data.name.name)
    if (name !== "") {
      const img = await customAxios.post("/common/getAvatarImg", { idcard_number: name.data.name.name })
      let base64img = img.data.base64data ? ("data:image/png;base64," + img.data.base64data) : null;
      common.setProfile(base64img)
    } else {
      common.setProfile("")
    }
    await customAxios.post("/user/getUser")
      .then(async (res) => {
        // dispatch(loginUser(res.data));
        if (res?.data?.email !== undefined) {
          const img = await customAxios.post("/common/getAvatarImg", { idcard_number: res?.data?.email })
          let base64img2 = img.data.base64data ? ("data:image/png;base64," + img.data.base64data) : null;
          common.setUserProfile(base64img2)
        } else {
          common.setUserProfile("")
        }
      })
  }
  const getGrade = async () => {
    await customAxios.get(`/grade/getAll/${common?.lang}`)
      .then((response) => {
        dispatch(setAllGrade(response.data))
        common.setGradeLoading(false)
      })
      .catch((err) => {
      })
  }
  React.useEffect(() => {
    // submit form values
    getProfile()
    customAxios.get(`/common/consts/${common?.lang}`)
      .then((res) => {
        dispatch(setAll(res.data));
        common.setLoading(false)
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
    getGrade()
  }, [common.lang])
  const handleSidebarButtonClick = (isCollapsed) => {
    setMainPanelWidths(isCollapsed)
  };
  return (
    <>
      <div className="wrapper" ref={wrapperRef}>
        <Sidebar
          onSidebarButtonClick={handleSidebarButtonClick}
          routes={routes}
          image={sidebarImage}
          background={sidebarBackground}
          uniName={common.name}
          uniProfile={common.profile}
          userProfile={common.userProfile}
        />
        <div className={`${!mainPanelWidths ? 'main-panel' : 'main-panel-width'}`}>
          <AdminNavbar userProfile={common.userProfile} />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
      <FixedPlugin
        setSidebarImageParent={(value) => setSidebarImage(value)}
        sidebarDefaultImage={sidebarImage}
        sidebarImages={[image1, image2, image3, image4]}
        backgroundColors={[
          "black",
          "azure",
          "green",
          "orange",
          "red",
          "purple",
        ]}
        backgroundColor={sidebarBackground}
        setSidebarBackgroundParent={(value) => setSidebarBackground(value)}
        wrapperRef={wrapperRef}
      />
    </>
  );
}
export default Admin;
