import customAxios from "lib/axios";
import React, { useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { selectConsts } from '../store/selectconstReducer';
import { resultGrade } from '../store/gradeReducer'
import * as XLSX from 'xlsx';
import { commonContext } from '../context/common'
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
  Spinner
} from "react-bootstrap";
function ResultView() {
  const [t] = useTranslation(["exam"]);
  const lang = React.useContext(commonContext)
  let componentRef = useRef();
  const ref = useRef()
  const { examname_id, classname_id } = useParams();
  const storeSelectConsts = useSelector(selectConsts);
  const storeReasultGrade = useSelector(resultGrade);
  const [examName, setExamName] = React.useState(examname_id ? storeSelectConsts.EXAM_NAMES.find((item) => item.value == examname_id) : storeSelectConsts.EXAM_NAMES[0]);
  //const [examName, setExamName] = React.useState(storeSelectConsts.EXAM_NAMES[0]);
  const [curClass, setCurClass] = React.useState(classname_id ? storeSelectConsts.CLASS_NAMES.find((item) => item.value == classname_id) : storeSelectConsts.CLASS_NAMES[0]);
  const [students, setStudents] = React.useState([]);
  const [examBooks, setExamBooks] = React.useState([]);
  const [topStudents, setTopStudents] = React.useState([])
  const [filteredStudents, setFilteredStudents] = React.useState([])
  const [grades, setGrades] = React.useState([])
  const [modal, setModal] = React.useState(false);
  const [selectedStudent, setSelectedStudent] = React.useState(null);
  const [examId, setExamId] = React.useState("");
  const [total, setTotal] = React.useState(0)
  const notificationAlertRef = React.useRef(null);
  const [alert, setAlert] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    getExamResultData()
    // submit form values
  }, [curClass, examName])

  React.useEffect(() => {
    if (lang.lang === 'en') {
      setGrades(storeReasultGrade.GRADE_NAMES)
    } else {
      setGrades(storeReasultGrade.GRADE_NAMESAR)
    }
  }, [lang?.lang, lang?.gradeLoading])

  const getExamResultData = () => {
    // customAxios.get(`/grade/getAll/${lang.lang}`).then((res) => {
    //   setGrades(res.data)
    // })
    customAxios.post("/exam/getExamSeatsAndBooksWithResult", {
      exam_nameid: examName?.value,
      exam_classid: curClass?.value
    }).then((res) => {
      if (res.data.msg == "EXAM_NOT_PREPARED") {
        setExamId("")
        setLoading(false);
        return;
      }
      setLoading(false);
      setExamId(res.data.examId);
      setExamBooks(res.data.examBooks);
      setStudents(res.data.students);
      setTopStudents(res.data.topStudents);
      setFilteredStudents(res.data.topStudents)
    }).catch((err) => {
      console.log(err)
      setLoading(false);
    });
  }
  React.useEffect(() => {
    const sum = examBooks?.reduce((accumulator, currentValue) => accumulator + currentValue.book.number, 0);
    setTotal(sum)
  }, [examBooks])
  // React.useEffect(() => {
  // })
  const handleGradeSelect = (optionSelected) => {
    const filteredStd = topStudents.filter(item => item?.class[0]?.isActive)
      .filter(item => {
        return item?.results.some(resultItem => resultItem?.result?.grade_name === optionSelected.label)
      })
    if (optionSelected.value !== '-1') {
      setFilteredStudents(filteredStd)
      setFlag(0)
    } else {
      setFilteredStudents(topStudents)
      setFlag(1)
    }
  }
  const data = [];
  let results = []
  for (let i = 0; i < topStudents?.length; i++) {
    for (let k = 0; k < topStudents[i].results.length; k++) {
      results.push(topStudents[i].results[k])
    }
    data.push({
      Rank: i + 1,
      Student_name: topStudents[i]?.student_name,
      FatherName: topStudents[i]?.father_name,
      SeatNumber: topStudents[i]?.seat_number,
      Class: topStudents[i]?.required_class,
      IDCARD_NUMBER: topStudents[i]?.idcard_number,
    })
    for (var index in results) {
      if (index == results.length - 2) break
      data[i][examBooks[index]?.book?.book_name] = results[index]?.result
    }
    data[i].total = results[results.length - 2]?.result
    data[i].grade = results[results.length - 1]?.result
    results = []
  }
  const exportToExcel = () => {
    /* Create a worksheet from the table data */
    const ws = XLSX.utils.json_to_sheet(data);
    /* Create a workbook with the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* Save the workbook as an Excel file */
    XLSX.writeFile(wb, 'table_data.xlsx');
  };
  const selectExportToExcel = () => {
    const filteredData = topStudents.map(item => {
      const newRow = {};
      if (selectedColumns.seatNumber) {
        newRow["seat_number"] = item?.seat_number;
      }
      if (selectedColumns.idcardNumber) {
        newRow["idcard_number"] = item?.idcard_number;
      }
      if (selectedColumns.studentName) {
        newRow["student_name"] = item?.student_name;
      }
      if (selectedColumns.fatherName) {
        newRow["father_name"] = item?.father_name;
      }
      if (selectedColumns.className) {
        newRow["className"] = item?.required_class;
      }
      examBooks?.map((book, index) => {
        if (selectedColumns[book?.book?.book_name]) {
          newRow[book?.book?.book_name] = item?.results[index]?.result || 0;
        }
      })
      if (selectedColumns.total) {
        newRow["total_number"] = item?.results?.find(ele => ele.book_id === 'total')?.result;
      }
      if (selectedColumns.grade) {
        newRow["grade"] = item?.results?.find(ele => ele.book_id === 'grade')?.result;
      }
      return newRow;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table_data.xlsx');
  };
  const [selectedColumns, setSelectedColumns] = React.useState({
    studentName: false,
    fatherName: false,
    className: false,
    seatNumber: false,
    idcardNumber: false,
    total: false,
    grade: false
  });

  let newGarde = grades.map(item => ({
    value: item.id,
    label: item.grade_name
  }));
  const newObject = {
    value: '-1',
    label: 'All'
  };
  newGarde = [newObject, ...newGarde];
  // const newArray1 = [newObject, ...newGarde];
  const [optiongrade, setOptiongrade] = React.useState(newGarde[0])
  const [flag, setFlag] = React.useState(1)
  const handleColumnSelection = (columnName, value) => {
    setSelectedColumns({
      ...selectedColumns,
      [columnName]: value,
    });
  };
  const handleResultChange = (e, i, bookItem) => {
    const updatedResults = [...selectedStudent.results];
    updatedResults[i].result = Number(e.target.value);
    const sumNumber = updatedResults.reduce((pre, cur) => {
      if (cur.book_id !== "total" && cur.book_id !== "grade") {
        return pre + (isNaN(cur.result) ? 0 : Number(cur.result));
      }
      return pre;
    }, 0);
    const totalIndex = updatedResults.findIndex((item) => item.book_id === "total");
    if (totalIndex !== -1) {
      updatedResults[totalIndex].result = sumNumber;
    }
    const sum = bookItem?.reduce((accumulator, currentValue) => accumulator + currentValue?.book?.number, 0);
    const gradeData = grade(sumNumber, sum);
    const gradeIndex = updatedResults.findIndex((item) => item.book_id === "grade");
    if (gradeIndex !== -1) {
      updatedResults[gradeIndex].result = gradeData;
    }
    setSelectedStudent({
      ...selectedStudent,
      results: updatedResults,
    });
  };
  const grade = (sumNumber, total) => {
    const num = (sumNumber / total) * 100;
    if (num >= 95) {
      return "A+"
    } else if (num >= 85) {
      return "A"
    } else if (num >= 75) {
      return "B+"
    } else if (num >= 65) {
      return "B"
    } else if (num >= 55) {
      return "C+"
    } else if (num >= 45) {
      return "C"
    } else if (num >= 35) {
      return "D"
    } else {
      return "F"
    }
  }
  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {t("resultSubmitSuccess")}
      </SweetAlert>
    );
  };
  const onSuccessOkay = () => {
    setAlert(null);
    setModal(false);
    getExamResultData()
  };
  const submit = () => {
    const requestBody = {
      exam_instanceId: examBooks[0]?.exam_instanceid,
      student_id: selectedStudent?.student_id,
      result: selectedStudent?.results
    };
    customAxios.post("/exam/updateExamResults", {
      data: requestBody
    }).then((res) => {
      successAlert();
    }).catch((err) => {
      notify(t("notifyConnectionProblem"), "tr", "danger")
    })
  }
  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setModal(true);
  };
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("viewExamResult")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="p1-1" md="4">
                    <Form.Group>
                      <label>{t("examName")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="examName"
                        value={examName}
                        onChange={(value) => {
                          setExamName(value)
                          setFlag(1)
                          setLoading(true)
                        }}
                        options={storeSelectConsts.EXAM_NAMES}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p1-1" md="4">
                    <Form.Group>
                      <label>{t("grade")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="curClass"
                        value={optiongrade}
                        onChange={(value) => {
                          setOptiongrade(value);
                          handleGradeSelect(value)
                        }}
                        // defaultValue={{ value: "1", label: "Class 1" }}
                        options={newGarde}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p1-1" md="4">
                    <Form.Group>
                      <label>{t("class")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="curClass"
                        value={curClass}
                        onChange={(value) => {
                          setCurClass(value)
                          setFlag(1)
                          setLoading(true)
                        }}
                        // defaultValue={{ value: "1", label: "Class 1" }}
                        options={storeSelectConsts.CLASS_NAMES.filter(cls => cls.isActive)}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                ) :
                  examId ?
                    <Row ref={(el) => (componentRef = el)} style={{ padding: '10px' }}>
                      {/* <div style={{ maxHeight: '530x', overflowY: 'auto', overflowX: 'auto' }}> */}
                      <Card className="stacked-form w-100">
                        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Card.Title as="h5">{curClass?.label}</Card.Title>
                          <Card.Title as="h5">{examName?.label}</Card.Title>
                        </Card.Header>
                        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Card.Title></Card.Title>
                          <button
                            variant="info"
                            className="btn btn-info no-print"
                            onClick={() => {
                              selectExportToExcel()
                            }}>
                            {t("exportToExcel")}
                          </button>
                        </Card.Header>
                        <Card.Body className="overflow-auto p-0">
                          <div className="printable-table">
                            {filteredStudents?.length > 0 && topStudents?.length > 0 ?
                              <Table>
                                <thead id="demo" style={{ width: '100%', overflowX: 'auto', overflowY: 'auto', position: "sticky", backgroundColor: "white", zIndex: "1", top: '0px' }}>
                                  <tr>
                                    <th className="text-center align-baseline" style={{ paddingLeft: '8px' }}>
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ opacity: '0', marginBottom: '1px', }}
                                          checked={selectedColumns.studentName}
                                          onChange={(e) => handleColumnSelection('studentName', e.target.checked)}
                                        />
                                        {t("rank")}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns.studentName}
                                          onChange={(e) => handleColumnSelection('studentName', e.target.checked)}
                                        />
                                        {t(`${'studentName'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['fatherName']}
                                          onChange={(e) => handleColumnSelection('fatherName', e.target.checked)}
                                        />
                                        {t(`${'fatherName'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['className']}
                                          onChange={(e) => handleColumnSelection('className', e.target.checked)}
                                        />
                                        {t(`${'className'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['seatNumber']}
                                          onChange={(e) => handleColumnSelection('seatNumber', e.target.checked)}
                                        />
                                        {t(`${'seatNumber'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['idcardNumber']}
                                          onChange={(e) => handleColumnSelection('idcardNumber', e.target.checked)}
                                        />
                                        {t(`${'idcardNumber'}`)}
                                      </div>
                                    </th>
                                    {examBooks.map((item, i) =>
                                      <th className="text-center align-baseline" style={{ minWidth: "90px", maxWidth: "90px" }} key={i}>
                                        <div className="align-items-start" style={{ "display": "grid", whiteSpace: 'break-spaces' }}>
                                          <input
                                            type="checkbox"
                                            className="mt-1 mr-1 no-print"
                                            style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                            checked={selectedColumns[`${item.book.book_name}`]}
                                            onChange={(e) => handleColumnSelection(`${item.book.book_name}`, e.target.checked)}
                                          />
                                          {item.book.book_name}
                                        </div>
                                      </th>
                                    )}
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['total']}
                                          onChange={(e) => handleColumnSelection('total', e.target.checked)}
                                        />
                                        {t(`${'total'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['percentage']}
                                          onChange={(e) => handleColumnSelection('percentage', e.target.checked)}
                                        />
                                        {t(`${'percentage'}`)}
                                      </div>
                                    </th>
                                    <th className="text-center align-baseline">
                                      <div className="align-items-start" style={{ "display": "grid" }}>
                                        <input
                                          type="checkbox"
                                          className="mt-1 mr-1 no-print"
                                          style={{ marginBottom: "1px", accentColor: '#17a2b8' }}
                                          checked={selectedColumns['grade']}
                                          onChange={(e) => handleColumnSelection('grade', e.target.checked)}
                                        />
                                        {t(`${'grade'}`)}
                                      </div>
                                    </th>
                                    <th style={{ textAlign: "center", display: "table-cell" }}>{t("Edit")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {flag ?
                                    (topStudents
                                      .filter(item => item.class[0]?.isActive)
                                      .map((item, index) =>
                                        <tr key={index}>
                                          <td className="text-center" style={{ paddingLeft: '8px' }} >{item.rank}</td>
                                          <td className="text-center">{item.student_name}</td>
                                          <td className="text-center">{item.father_name}</td>
                                          <td className="text-center">{item.class[0]?.className}</td>
                                          <td className="text-center">{item.seat_number}</td>
                                          <td className="text-center">{item.idcard_number}</td>
                                          {
                                            item.results.map((book, j1) => {
                                              if (book.book_id != "grade") {
                                                return (
                                                  <td className="text-center" key={j1}>
                                                    {book.result}
                                                  </td>
                                                )
                                              } else {
                                                return (
                                                  <td className="text-center" key={j1}>
                                                    {grades
                                                      .filter(grade => grade.id === book.result.id)
                                                      .map(grade => grade.grade_name)}
                                                  </td>
                                                )
                                              }
                                            })
                                          }
                                          <td className="text-center">
                                            <Button
                                              className="btn-link btn-xs"
                                              onClick={() => handleEditClick(item)}
                                              variant="success"
                                            >
                                              <i className="fas fa-edit"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      )) : (
                                      // topStudents
                                      // .filter(item => item.class[0]?.isActive)
                                      // .filter(item => {
                                      //   return item.results.some(resultItem => resultItem.result === optiongrade.label)
                                      // })
                                      filteredStudents
                                        .map((item, index1) =>
                                          <tr key={index1}>
                                            <td className="text-center">{item.rank}</td>
                                            <td className="text-center">{item.student_name}</td>
                                            <td className="text-center">{item.father_name}</td>
                                            <td className="text-center">{item.class[0]?.className}</td>
                                            <td className="text-center">{item.seat_number}</td>
                                            <td className="text-center">{item.idcard_number}</td>
                                            {
                                              item.results.map((book, index) => {
                                                if (book.book_id != "grade") {
                                                  return (
                                                    <td className="text-center" key={index}>
                                                      {book.result}
                                                    </td>
                                                  )
                                                } else {
                                                  return (
                                                    <td className="text-center" key={index}>
                                                      {grades
                                                        .filter(grade => grade.id === book.result.id)
                                                        .map(grade => grade.grade_name)
                                                      }
                                                    </td>
                                                  )
                                                }
                                              })
                                            }
                                            <td className="text-center">
                                              <Button
                                                className="btn-link btn-xs"
                                                onClick={() => handleEditClick(item)}
                                                variant="success"
                                              >
                                                <i className="fas fa-edit"></i>
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                    )
                                  }
                                </tbody>
                              </Table> :
                              <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noResultFound")}</div>
                            }
                          </div>
                        </Card.Body>
                      </Card>
                      {/* </div> */}
                    </Row>
                    :
                    <div ref={(el) => (componentRef = el)} className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("examNotResulted")}</div>
                }
              </Card.Body>
              {examId ?
                <Card.Footer className="d-flex justify-content-between">
                  <ReactToPrint
                    trigger={() =>
                      <Button className="btn-edit btn-fill" type="submit" variant="info">
                        <span className="btn-label">
                          <i className="fas fa-print"></i>
                        </span>
                        &nbsp;{t("btnPrint")}
                      </Button>
                    }
                    content={() => componentRef}
                  />
                  {/* <button onClick={() => {
                  handlePrint(null, () => componentRef.current);
                }}>print</button> */}
                  {/* <ReactToPrint content={() => componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => {
                      return (<button onClick={handlePrint}>Print this out!</button>)
                    }}
                  </PrintContextConsumer>
                </ReactToPrint> */}
                  <Button Button className="btn-edit btn-fill" type="submit" variant="info" onClick={exportToExcel}>
                    <span className="btn-label">
                      <i className="fas fa-print"></i>
                    </span>
                    &nbsp;{t("btnExcel")}
                  </Button>
                </Card.Footer> : ""}
            </Card>
          </Col>
        </Row >
      </Container >
      <Modal show={modal} onHide={() => setModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("editExamLabel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formStudentName">
              <Form.Label>{t("studentName")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter student name"
                defaultValue={selectedStudent ? selectedStudent.student_name : ''}
                onChange={(e) => selectedStudent.student_name = e.target.value}
              />
            </Form.Group>
            <Form.Group controlId="formFatherName">
              <Form.Label>{t("fatherName")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter father's name"
                defaultValue={selectedStudent ? selectedStudent.father_name : ''}
                onChange={(e) => selectedStudent.father_name = e.target.value}
              />
            </Form.Group>
            <Form.Group controlId="formClassName">
              <Form.Label>{t("className")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter classname"
                defaultValue={selectedStudent?.class[0]?.className}
                onChange={() => selectedStudent.required_class = e.target.value}
              />
            </Form.Group>
            <Form.Group controlId="formSeatNumber">
              <Form.Label>{t("seatNumber")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter Seat Number"
                defaultValue={selectedStudent ? selectedStudent.seat_number : ''}
                onChange={(e) => selectedStudent.seat_number = e.target.value}
              />
            </Form.Group>
            {examBooks?.map((item, i) =>
              <Form.Group controlId="formFatherName" key={item.bood_id}>
                <Form.Label>{item.book.book_name}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Marks"
                  defaultValue={selectedStudent ? selectedStudent.results[i]?.result : ''}
                  onChange={(e) => handleResultChange(e, i, examBooks)}
                />
              </Form.Group>
            )
            }
            <Form.Group controlId="formTotal">
              <Form.Label>{t("total")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter Total"
                value={selectedStudent ? selectedStudent.results[selectedStudent.results.length - 2].result : ''}
                onChange={(e) => selectedStudent.results[selectedStudent.results.length - 2].result = e.target.value}
              />
            </Form.Group>
            <Form.Group controlId="formGrade">
              <Form.Label>{t("gradeLabel")}: </Form.Label>
              <Form.Control
                type="text"
                disabled
                placeholder="Enter Grade"
                value={selectedStudent ? selectedStudent.results[selectedStudent.results.length - 1].result?.grade_name : ''}
                onChange={(e) => selectedStudent.results[selectedStudent.results.length - 1].result.grade_name = e.target.value}
              />
            </Form.Group>
            {/* Add other fields as needed */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" className="btn btn-danger" onClick={() => setModal(false)}>
            {t("btnClose")}
          </button>
          <button variant="primary" className="btn btn-primary" onClick={() => {
            submit();
          }}>
            {t("btnSave")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ResultView;
