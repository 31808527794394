import customAxios from "lib/axios";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { loginUser, logoutUser } from "./store/authReducer";
import store from './store/store';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/demo.css";
import "assets/scss/lbd/lbdp_rtl.scss";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "bootstrap/dist/css/bootstrap.min.css";

import AdminLayout from "layouts/Admin.js";
import UserLogin from "views/UserLogin.js";
import UserRegister from "views/UserRegister.js";
import setAuthToken from './lib/setAuthToken';
import "lib/i18n"; // Translation configuration.

import setPageDirection from "lib/setPageDirection";
import { useTranslation } from "react-i18next";
import { CommonContextProvider } from './context/common'

const App = () => {
  const [t, i18n] = useTranslation(["sidebar"]);

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  useEffect(() => {
    // check for token in LS
    customAxios.post("/user/getUser")
      .then((res) => {
        store.dispatch(loginUser(res.data));
      })

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch(logoutUser);
    });

    const curLangName = getCurrentLng();
    setPageDirection(curLangName);
  }, []);

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  return (
    <Provider store={store}>
      <CommonContextProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/userlogin" component={UserLogin} />
            <Route path="/userregister" component={UserRegister} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/userlogin" />
          </Switch>
        </BrowserRouter>
      </CommonContextProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

