import { useTranslation } from "react-i18next";
// react-bootstrap components
import {
  Container
} from "react-bootstrap";
function AdminFooter() {
  const [t] = useTranslation(["sidebar"]);
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            {/* <ul className="footer-menu">
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Home
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Company
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Portfolio
                </a>
              </li>
              <li>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Blog
                </a>
              </li>
            </ul> */}
            <p className="copyright text-center">
              {t("copyrightInfo")}
              {/* <a href="http://xxx.xxx.xxx"> </a> */}
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
