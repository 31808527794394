import customAxios from "lib/axios";
import setPageDirection from "lib/setPageDirection";
import { isRequired } from "lib/validation";
import React from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
import Select from "react-select";
import setAuthToken from "../lib/setAuthToken";
import { authInfo, loginUser } from '../store/authReducer';
// import bgVideo from "assets/img/course-video.mp4"

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";

const langVarName = [
  { value: "en", name: "English" },
  { value: "ar", name: "Arabic" },
];

function UserLogin() {
  const [t, i18n] = useTranslation(["auth"]);

  const history = useHistory();
  const dispatch = useDispatch();
  const notificationAlertRef = React.useRef(null);

  const [langName, setLangName] = React.useState({ value: "en", label: t("English") });

  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");

  // Validations
  const [userEmailState, setUserEmailState] = React.useState(true);
  const [userPasswordState, setUserPasswordState] = React.useState(true);

  const authInformation = useSelector(authInfo);

  const [cardClasses, setCardClasses] = React.useState("card-hidden");

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 100);
  });

  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const onSubmit = () => {
    // validation check
    let allValid = true;
    if (!userEmailState || !isRequired(userEmail)) {
      setUserEmailState(false);
      allValid = false;
    }
    if (!userPasswordState || !isRequired(userPassword)) {
      setUserPasswordState(false);
      allValid = false;
    }
    if (!allValid) {
      // notify("All input fields should be filled correctly", "tr", "warning");
      return;
    }
    customAxios.post("/user/login", {
      email: userEmail,
      password: userPassword,
    })
      .then((res) => {
        setAuthToken(res.data.token)
        dispatch(loginUser(res.data.user));
      })
      .catch((err) => {
        if (err.response?.status == 500)
          notify(err.response.data.message, "tr", "warning");
        else
          notify("Connection is not good. Please retry some later.", "tr", "warning");
      });
  }

  if (authInformation.isAuthenticated) {
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div
        className="full-page register-page section-image"
        data-color="black"
      // data-image={require("assets/img/full-screen-image-4.jpg")}
      >
        <div className="content d-flex align-items-center">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="8">
                    <div className="header-text">
                      <Card.Title as="h2">
                        {t("title")}
                      </Card.Title>
                      <Card.Subtitle as="h4">
                        {t("userLogin")}
                      </Card.Subtitle>
                      {/* <hr></hr> */}
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center">
                <Col className="mx-auto" lg="4" md="8">
                  {/* <Form className="form"> */}
                  <Card className={"card-login " + cardClasses}>
                    {/* <Card.Header>
                    <h3 className="header text-center">Login</h3>
                  </Card.Header> */}
                    <Card.Body>
                      <Card.Body className="text-left">
                        <Form.Group
                          className={
                            "has-label pl-3 pr-3 " +
                            (userEmailState ? "has-success" : "has-error")
                          }
                        >
                          <label>{t("emailAddress")}</label>
                          <Form.Control
                            placeholder={t("enterEmail")}
                            type="email"
                            value={userEmail}
                            onChange={(e) => {
                              setUserEmail(e.target.value);
                              if (isRequired(e.target.value)) {
                                setUserEmailState(true);
                              } else {
                                setUserEmailState(false);
                              }
                            }}
                          ></Form.Control>
                          {userEmailState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label pl-3 pr-3 " +
                            (userPasswordState ? "has-success" : "has-error")
                          }
                        >
                          <label>{t("password")}</label>
                          <Form.Control
                            placeholder={t("password")}
                            type="password"
                            value={userPassword}
                            onChange={(e) => {
                              setUserPassword(e.target.value);
                              if (isRequired(e.target.value)) {
                                setUserPasswordState(true);
                              } else {
                                setUserPasswordState(false);
                              }
                            }}
                          ></Form.Control>
                          {userPasswordState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                      </Card.Body>
                    </Card.Body>
                    <Card.Footer className="ml-auto mr-auto">
                      <Button className="btn-edit btn-wd mx-1" type="submit" variant="primary" onClick={(e) => { e.preventDefault(); onSubmit(); }}>
                        {t("login")}
                      </Button>
                      <Button className="btn-edit btn-wd mx-1" type="submit" variant="warning" onClick={(e) => history.push("/userregister")}>
                        {t("register")}
                      </Button>
                    </Card.Footer>
                  </Card>
                  {/* </Form> */}
                </Col>
              </Card.Body>
            </Card>
            <p className="copyright text-center text-white m-0">
              {t("copyrightInfo.first")}{new Date().getFullYear()}{" "}<a href="http://www.jamia.com">&nbsp;{t("copyrightInfo.second")}</a>
            </p>
          </Container>
          <Form.Group className="fixed-top mt-3 mr-5" style={{ width: `150px` }} >
            <Select
              className="react-select primary mx-2"
              classNamePrefix="react-select"
              name="rowCnt"
              value={langName}
              onChange={(optionSelected) => {
                i18n.changeLanguage(optionSelected.value);
                setLangName({ value: optionSelected.value, label: t(langVarName.find((i) => i.value == optionSelected.value).name) });
                setPageDirection(optionSelected.value);
              }}
              options={[
                { value: "en", label: t("English") },
                { value: "ar", label: t("Arabic") },
              ]}
            />
          </Form.Group>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage: "url(" + require("assets/img/full-screen-image-4.jpg") + ")"
          }}
        >
        </div>
      </div>
      {/* <video autoPlay muted loop id="bg-video">
          <source src={bgVideo} type="video/mp4" />
      </video> */}
    </>
  );
}

export default UserLogin;
