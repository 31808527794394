// validators
const emailValidation = (value) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  );
const urlValidation = (value) => {
  let returnValue = true;
  try {
    new URL(value);
  } catch (e) {
    returnValue = false;
  } finally {
    return returnValue;
  }
  return false;
};
const equalTo = (value1, value2) => value1 === value2;
const isRequired = (value) => value !== null && value !== "" && value;
const isNumber = (value) => !isNaN(value) && value !== "";
const minLength = (value, length) => value.length >= length;
const maxLength = (value, length) => value.length <= length && value !== "";
const range = (value, min, max) => min <= value && value <= max;
const minValue = (value, min) => min <= value;
const maxValue = (value, max) => max >= value;

module.exports = {
  emailValidation,
  urlValidation,
  equalTo,
  isRequired,
  isNumber,
  minLength,
  maxLength,
  range,
  minValue,
  maxValue,
}