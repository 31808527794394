import customAxios from "lib/axios";
import { isRequired } from "lib/validation";
import React, { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { selectConsts, useForceUpdate } from '../store/selectconstReducer';
import { resultGrade, addGradeName, removeGrade, changeGrade } from '../store/gradeReducer'
import { commonContext } from '../context/common'
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from "react-bootstrap";
function ResultGradeStructure() {
  const [t] = useTranslation(["exam"]);
  const lang = React.useContext(commonContext)
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const storeResultGrade = useSelector(resultGrade)
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [grades, setGrades] = React.useState([]);
  const [gradeId, setGradeId] = React.useState("");
  const [gradeName, setGradeName] = React.useState("");
  const [min, setMin] = React.useState("");
  const [max, setMax] = React.useState("");
  const [gradeNameState, setGradeNameState] = React.useState(true);
  const [maxState, setMaxState] = React.useState(true);
  const [minState, setMinState] = React.useState(true);
  const [isDelete, setIsDelete] = React.useState(false)
  const [modal, setModal] = React.useState(false);
  const [isReadonly, setIsReadonly] = React.useState(false)
  const sortedGradeEN = [...storeResultGrade.GRADE_NAMES].sort((a, b) => b.min - a.min);
  const sortedGradeAR = [...storeResultGrade.GRADE_NAMESAR].sort((a, b) => b.min - a.min);
  // React.useEffect(() => {
  //   // submit form values
  //   setLoading(true)
  //   customAxios.get(`/grade/getAll/${lang.lang}`)
  //     .then((res) => {
  //       if (lang.lang !== 'ar') {
  //         setGrades(res.data);
  //         setLoading(false)
  //       } else {
  //         const gradeNames = res?.data.map(grade => grade?.grade_name)
  //         translate(gradeNames, res?.data)
  //       }
  //     })
  //     .catch((err) => {
  //       // Work with the err
  //     });
  // }, [lang.lang])
  // const translate = async (prompt, grades) => {
  //   await customAxios.post(`/grade/translate/`, { gradeNames: prompt })
  //     .then((response) => {
  //       const gradesAr = grades.map((grade, index) => ({
  //         ...grade,
  //         grade_name: response?.data[index]
  //       }));
  //       setGrades(gradesAr)
  //       setLoading(false)
  //     })
  //     .catch(err => { })
  // }
  const warningWithConfirmMessage = (id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirmDelete(id)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {t("gradeRemoveConfirmMsg")}
      </SweetAlert>
    );
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setModal(!modal)
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (id) => {
    customAxios.post("/grade/deleteOne/", {
      id: id
    })
      .then((res) => {
        if (res.data.msg == "success") {
          successAlert(gradeName + t("submitRemoveSuccessAlertMsg.second"));
          // setGrades(grades.filter((item) => item.id != id));
          dispatch(removeGrade(id))
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
    setIsDelete(false)
    setGradeName("");
    setMin("");
    setMax("");
    setGradeNameState(true);
    setMinState(true);
    setMaxState(true);
    setGradeId("");
    setModal(!modal);
  }
  const onUpsert = () => {
    // validation check
    let allValid = true;
    if (!gradeNameState || !isRequired(gradeName)) {
      setGradeNameState(false);
      allValid = false;
    }
    if (!minState || !isRequired(min)) {
      setMinState(false);
      allValid = false;
    }
    if (!maxState || !isRequired(max)) {
      setMaxState(false);
      allValid = false;
    }
    if (!allValid) {
      notify("tr", t("validationNotify"));
      return;
    }
    if (gradeId) {
      customAxios.post("/grade/update", {
        id: gradeId,
        grade_name: gradeName,
        min: min,
        max: max,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(gradeName + t("submiUpdateSuccessAlertMsg.second"));
            // setGrades(grades.map((item) => {
            //   if (item.id == gradeId)
            //     return { ...item, grade_name: gradeName, min: min, max: max };
            //   return item;
            // }))
            dispatch(changeGrade({ id: gradeId, min, max }))
          }
        })
        .catch((err) => {
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    else {
      customAxios.post("/grade/create", {
        grade_name: gradeName,
        min: min,
        max: max,
      }).then((res) => {
        if (res?.data?.msg == "success") {
          dispatch(addGradeName(res?.data?.data))
          // setGrades([...grades, res.data.created]);
          successAlert(gradeName + t("submitAddSuccessAlertMsg.second"));
        }
      }).catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
    }
    setGradeName("");
    setMin("");
    setMax("");
    setGradeNameState(true);
    setMinState(true);
    setMaxState(true);
    setGradeId("");
    setModal(!modal);
  }
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("gradeStructure")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width">
                <Button
                  className="btn-wd btn-outline mt-4 mb-3"
                  type="button"
                  onClick={() => {
                    setModal(!modal)
                    setIsReadonly(false)
                    setIsDelete(false)
                  }
                  }
                  variant="success"
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  &nbsp;{t("addGrade")}
                </Button>
                {/* Mini Modal */}
                <Modal
                  className="modal-primary"
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => { setGradeId(""); setModal(!modal) }}
                  show={modal}
                >
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title className="text-center" as="h5">{t("addGrade")}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row className="mx-2">
                        <Col className="pl-1 pr-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (gradeNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("gradeName")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={gradeName}
                              type="text"
                              readOnly={isReadonly ? true : false}
                              onChange={(e) => {
                                setGradeName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGradeNameState(true);
                                } else {
                                  setGradeNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {gradeNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                        <Col className="pl-1 pr-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (maxState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("maxScore")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={max}
                              type="number"
                              onChange={(e) => {
                                setMax(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setMaxState(true);
                                } else {
                                  setMaxState(false);
                                }
                              }}
                            ></Form.Control>
                            {maxState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                        <Col className="pl-1 pr-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (minState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("minScore")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={min}
                              type="number"
                              onChange={(e) => {
                                setMin(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setMinState(true);
                                } else {
                                  setMinState(false);
                                }
                              }}
                            ></Form.Control>
                            {minState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className="modal-footer">
                    {
                      isDelete ?
                        (
                          <Button
                            className="btn-simple"
                            onClick={() => {
                              onConfirmDelete(gradeId)
                            }}
                            variant="link"
                          >
                            {t("btnRemove")}
                          </Button>) : (
                          <Button
                            className="btn-simple"
                            onClick={() => {
                              onUpsert();
                            }}
                            variant="link"
                          >
                            {t("btnSave")}
                          </Button>
                        )
                    }
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        setModal(!modal);
                        setGradeId("");
                        setGradeName("");
                        setMax("");
                        setMin("");
                        setGradeNameState(true);
                        setMaxState(true);
                        setMinState(true);
                      }}
                      variant="link"
                    >
                      {t("btnClose")}
                    </Button>
                  </div>
                </Modal>
                {/* End Modal */}
                <Card.Body className="overflow-auto">
                  {!lang.gradeLoading ?
                    storeResultGrade.GRADE_NAMES?.length > 0 || storeResultGrade.GRADE_NAMESAR?.length > 0 ?
                      <Table>
                        <thead>
                          <tr>
                            <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                            <th className="text-left">{t("gradeName")}</th>
                            <th className="text-center">{t("scoreRange")}</th>
                            <th className="text-center">{t("actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            lang.lang === 'en' ?
                              sortedGradeEN
                                .map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="text-center" style={{ paddingLeft: '8px' }}>{index + 1}</td>
                                      <td className="text-left">{item.grade_name}</td>
                                      <td className="text-center">{item.min} ~ {item.max}</td>
                                      <td className="td-actions text-center">
                                        <OverlayTrigger
                                          href="#pablo"
                                          overlay={
                                            <Tooltip id="tooltip-981231696">
                                              {t("btnEdit")}
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn-link btn-xs"
                                            onClick={(e) => {
                                              setGradeId(item.id);
                                              setGradeName(item.grade_name);
                                              setMin(item.min);
                                              setMax(item.max);
                                              setModal(!modal);
                                              setIsReadonly(true)
                                              setIsDelete(false)
                                            }}
                                            variant="success"
                                          >
                                            <i className="fas fa-edit"></i>
                                          </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          href="#pablo"
                                          overlay={
                                            <Tooltip id="tooltip-981231696">
                                              {t("btnRemove")}
                                            </Tooltip>
                                          }
                                        >
                                          <Button
                                            className="btn-link btn-xs"
                                            onClick={(e) => {
                                              setGradeId(item.id);
                                              setGradeName(item.grade_name);
                                              setMin(item.min);
                                              setMax(item.max);
                                              setModal(!modal);
                                              setIsDelete(true)
                                            }}
                                            variant="danger"
                                          >
                                            <i className="fas fa-trash"></i>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>)
                                }) :
                              sortedGradeAR
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{item.grade_name}</td>
                                    <td className="text-center">{item.min} ~ {item.max}</td>
                                    <td className="td-actions text-center">
                                      <OverlayTrigger
                                        href="#pablo"
                                        overlay={
                                          <Tooltip id="tooltip-981231696">
                                            {t("btnEdit")}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          className="btn-link btn-xs"
                                          onClick={(e) => {
                                            setGradeId(item.id);
                                            setGradeName(item.grade_name);
                                            setMin(item.min);
                                            setMax(item.max);
                                            setModal(!modal);
                                            setIsReadonly(true)
                                            setIsDelete(false)
                                          }}
                                          variant="success"
                                        >
                                          <i className="fas fa-edit"></i>
                                        </Button>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        href="#pablo"
                                        overlay={
                                          <Tooltip id="tooltip-981231696">
                                            {t("btnRemove")}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          className="btn-link btn-xs"
                                          onClick={(e) => {
                                            setGradeId(item.id);
                                            setGradeName(item.grade_name);
                                            setMin(item.min);
                                            setMax(item.max);
                                            setModal(!modal);
                                            setIsDelete(true)
                                          }}
                                          variant="danger"
                                        >
                                          <i className="fas fa-trash"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))
                          }
                        </tbody>
                      </Table> : <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noGradeFound")}</div>
                    :
                    <div className="d-flex align-items-center justify-content-center my-5">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  }
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ResultGradeStructure;
