// react component used to create charts
// react components used to create a SVG / Vector map
import { useTranslation } from "react-i18next";
import { VectorMap } from "react-jvectormap";
import customAxios from "lib/axios";
// react-bootstrap components
import {
  Card,
  Col,
  Container,
  Row,
  Table
} from "react-bootstrap";
import React, { useEffect, useRef } from "react";

function Dashboard() {
  const [t] = useTranslation(["dashboard"]);
  const [student, setStudent] = React.useState({ male: 0, female: 0 })
  const [employee, setEmployee] = React.useState({ male: 0, female: 0 })
  const [classes, setClasses] = React.useState({ local: 0, federal: 0 })
  const [inv, setInv] = React.useState({ students: 500, employees: 1000 })

  React.useEffect(() => {
    customAxios.get('/student/getStudentsByGender')
      .then((response) => {
        setStudent({ male: response?.data?.MaleStudents, female: response?.data?.FemaleStudents })
      })
    customAxios.get('/employee/getEmployeesByGender')
      .then((response) => {
        setEmployee({ male: response?.data?.MaleEmployees, female: response?.data?.FemaleEmployees })
      })
    customAxios.get('/class/getClassByType')
      .then((response) => {
        setClasses({ local: response?.data?.LocalClass, federal: response?.data?.FederalClass })
      })
  }, [])
  const totalStudents = student?.male + student?.female;
  const totalEmployees = employee?.male + employee?.female;
  const totalClasses = classes?.local + classes?.federal;
  const totalInv = inv?.students + inv?.employees;
  window.addEventListener('resize', function () {
    var boxes = document.querySelectorAll('.box');
    boxes.forEach(function (box) {
      box.style.height = 'auto';
    });
    var maxHeight = 0;
    boxes.forEach(function (box) {
      maxHeight = Math.max(maxHeight, box.offsetHeight);
    });
    boxes.forEach(function (box) {
      box.style.height = maxHeight + 'px';
    });
  });
  return (
    <>
      <Container fluid>
        <Row>
          <Col xl="3" sm="6">
            <Card className="card-stats box">
              <Card.Body style={{ height: "60%" }}>
                <Row className="mr-0 ml-0">
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-badge text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="8" className="dashboard-box">
                    <div className="numbers">
                      <p className="card-category">{t("students")}</p>
                      <Card.Title as="h4">{totalStudents}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: "40%" }}>
                <hr></hr>
                {/* <div className="stats"> */}
                {/* <i className="fas fa-male mr-1"></i> */}
                {/* {t("male")}: {student?.male} */}
                {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                {/* <span className="mx-3">{t("female")} : {student?.female}</span> */}
                {/* </div> */}
                <div className="d-flex flex-row align-items-center h-100 text-nowrap">
                  <div className="stats">
                    {/* <i className="fas fa-male mr-1"></i> */}
                    {t("male")}: {student?.male}
                    {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                  </div>
                  <div className="stats mx-3">{t("female")} : {student?.female}</div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats box">
              <Card.Body style={{ height: "60%" }}>
                <Row className="mr-0 ml-0">
                  <Col xs="4">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-circle-09 text-secondary"></i>
                    </div>
                  </Col>
                  <Col xs="8" className="dashboard-box">
                    <div className="numbers">
                      <p className="card-category">{t("employees")}</p>
                      <Card.Title as="h4">{totalEmployees}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: "40%" }}>
                <hr></hr>
                {/* <div className="stats"> */}
                {/* <i className="fas fa-male mr-1"></i> */}
                {/* {t("male")}: {employee?.male} */}
                {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                {/* <span className="mx-3">{t("female")} : {employee?.female}</span> */}
                {/* </div> */}
                <div className="d-flex flex-row align-items-center h-100 text-nowrap">
                  <div className="stats">
                    {/* <i className="fas fa-male mr-1"></i> */}
                    {t("male")}: {employee?.male}
                    {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                  </div>
                  <div className="stats mx-3">{t("female")} : {employee?.female}</div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats box">
              <Card.Body style={{ height: "60%" }}>
                <Row className="mr-0 ml-0">
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-grid-45 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7" className="dashboard-box">
                    <div className="numbers">
                      <p className="card-category">{t("classes")}</p>
                      <Card.Title as="h4">{totalClasses}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: "40%" }}>
                <hr></hr>
                {/* <div className="stats"> */}
                {/* <i className="fas fa-user mr-1"></i> */}
                {/* {t("federal")} : {classes?.federal} */}
                {/* <i className="fas fa-user mr-1"></i> */}
                {/* <span className="mx-3">{t("local")} : {classes?.local}</span> */}
                {/* </div> */}
                <div className="d-flex flex-row align-items-center h-100 text-nowrap">
                  <div className="stats">
                    {/* <i className="fas fa-male mr-1"></i> */}
                    {t("federal")}: {classes?.federal}
                    {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                  </div>
                  <div className="stats mx-3">{t("local")} : {classes?.local}</div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl="3" sm="6">
            <Card className="card-stats box">
              <Card.Body style={{ height: "60%" }}>
                <Row className="mr-0 ml-0">
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-settings-90 text-secondary"></i>
                    </div>
                  </Col>
                  <Col xs="7" className="dashboard-box">
                    <div className="numbers">
                      <p className="card-category">{t("inventories")}</p>
                      <Card.Title as="h4">{totalInv}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer style={{ height: "40%" }}>
                <hr></hr>
                {/* <div className="stats"> */}
                {/* <i className="fas fa-user mr-1"></i> */}
                {/* {t("students")} : 500 */}
                {/* <i className="fas fa-user mr-1"></i> */}
                {/* <span className="mx-3">{t("employees")} : 1000</span> */}
                {/* </div> */}
                <div className={`d-flex flex-row align-items-center h-100 text-nowrap`} >
                  <div className="stats">
                    {/* <i className="fas fa-male mr-1"></i> */}
                    {t("students")} : {inv.students}
                    {/* <i className="fas fa-female mx-3 mr-1"></i> */}
                  </div>
                  {/* {console.log(inv.students.toString().length, "students len")} */}
                  <div className="stats mx-3" >{t("employees")} : {inv.employees}</div>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="pl-3 pr-3">
              <Card.Header>
                <Card.Title as="h4">{t("states")}</Card.Title>
                <p className="card-category">{t("all")}</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>{t("category")}</th>
                          <th className="text-right">{t("totalNumber")}</th>
                          <th className="text-right">{t("percentage")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("residentStudents")}</td>
                          <td className="text-right">2.920</td>
                          <td className="text-right">72%</td>
                        </tr>
                        <tr>
                          <td>{t("nonResidentStudents")}</td>
                          <td className="text-right">1.300</td>
                          <td className="text-right">28%</td>
                        </tr>
                        <tr>
                          <td>{t("daresStudents")}</td>
                          <td className="text-right">760</td>
                          <td className="text-right">65%</td>
                        </tr>
                        <tr>
                          <td>{t("hafizStudents")}</td>
                          <td className="text-right">690</td>
                          <td className="text-right">45%</td>
                        </tr>
                        <tr>
                          <td>{t("maleStudents")}</td>
                          <td className="text-right">600</td>
                          <td className="text-right">80%</td>
                        </tr>
                        <tr>
                          <td>{t("femaleStudents")}</td>
                          <td className="text-right">550</td>
                          <td className="text-right">20%</td>
                        </tr>
                        <tr>
                          <td>{t("totalEmployees")}</td>
                          <td className="text-right">550</td>
                          <td className="text-right">100%</td>
                        </tr>
                        <tr>
                          <td>{t("totalTutors")}</td>
                          <td className="text-right">550</td>
                          <td className="text-right">100%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "300px"
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: {
                              AU: 760,
                              BR: 550,
                              CA: 120,
                              DE: 1300,
                              FR: 540,
                              GB: 690,
                              GE: 200,
                              IN: 200,
                              RO: 600,
                              RU: 300,
                              US: 2920
                            },
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial"
                          }
                        ]
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <Row>
        <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-user text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Male Students</p>
                      <Card.Title as="h4">1,345</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-user mr-1"></i>
                  69.8% of total students 
                </div>
              </Card.Footer>
            </Card>
          </Col> 
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-user-md text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Female students</p>
                      <Card.Title as="h4">1,345</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-user mr-1"></i>
                  30.2% of total students
                </div>
              </Card.Footer>
            </Card>
          </Col>  
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fas fa-user-edit text-warning"></i>                      
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total tutors</p>
                      <Card.Title as="h4">1,345</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-user mr-1"></i>
                  Male: 1000, Female: 345
                </div>
              </Card.Footer>
            </Card>
          </Col>   
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                     <i className="fas fa-user-secret text-secondary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total employees</p>
                      <Card.Title as="h4">1,345</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-user mr-1"></i>
                  Male: 1000, Female: 345
                </div>
              </Card.Footer>
            </Card>
          </Col>    
        </Row> */}
        {/* <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Univ Growth</Card.Title>
                <p className="card-category">8 years history</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  data={{
                    labels: [
                      "2015",
                      "2016",
                      "2017",
                      "2018",
                      "2019",
                      "2020",
                      "2021",
                      "2022",
                      "2023",
                      ""
                    ],
                    series: [
                      [2870, 3805, 4900, 4920, 5054, 5806, 6098, 6095, 6030],
                      [670, 1520, 1430, 2400, 2087, 3035, 4350, 4037, 4700],
                      [230, 1130, 670, 1008, 1900, 2039, 3070, 3008, 4300]
                    ]
                  }}
                  type="Line"
                  options={{
                    low: 0,
                    high: 8000,
                    showArea: false,
                    height: "245px",
                    axisX: {
                      showGrid: false
                    },
                    lineSmooth: true,
                    showLine: true,
                    showPoint: true,
                    fullWidth: true,
                    chartPadding: {
                      right: 50
                    }
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          }
                        }
                      }
                    ]
                  ]}
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-info"></i>
                  Students <i className="fas fa-circle mr-1 text-danger"></i>
                  Tutors <i className="fas fa-circle mr-1 text-warning"></i>
                  Employees
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default Dashboard;
