import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { authInfo } from '../../store/authReducer';
import { useHistory } from "react-router-dom";
import './Sidebar.css';
// react-bootstrap components
import {
  Collapse,
  Nav
} from "react-bootstrap";
function Sidebar({ routes, image, background, onSidebarButtonClick, uniName, uniProfile, userProfile }) {
  const [t] = useTranslation(["sidebar"]);
  const history = useHistory();
  const marqueeRef = useRef();
  // to check for active links and opened collapses
  let location = useLocation();
  const storeAuthInfo = useSelector(authInfo);
  // this is for the user collapse
  const [userCollapseState, setUserCollapseState] = React.useState(false);
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  const [collapsed, setCollapsed] = React.useState(false);
  const toggleSidebar = () => {
    const updatedState = { ...state };
    for (let key in updatedState) {
      updatedState[key] = false;
    }
    setTimeout(() => {
      if (!collapsed) {
        setState(updatedState);
      } else {
        setState(getCollapseStates(routes))
      }
    }, 100)
    setCollapsed(!collapsed);
  };
  const handleToggleButtonClick = (isMenuClick) => {
    if (typeof onSidebarButtonClick === "function") {
      onSidebarButtonClick(isMenuClick ? false : !collapsed);
    }
  };
  React.useEffect(() => {
    setState(getCollapseStates(routes));
  }, [collapsed]);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  React.useEffect(() => {
    const container = marqueeRef.current;
    if (container !== null) {
      const content = container.querySelector('.marquee-content');
      if (content.scrollWidth > container.clientWidth) {
        if (content.textContent.length > 30) {
          container.classList.add('dynamic-marquee-animation');
        } else {
          container.classList.add('marquee-animation');
        }
      } else {
        container.classList.remove('marquee-animation');
        container.classList.remove('dynamic-marquee-animation');
      }
    } else {
      return;
    }
  }, [uniName, collapsed]);
  const handleCollapse = (clickedState) => {
    const updatedState = { ...state };
    for (let key in updatedState) {
      if (key !== clickedState) {
        updatedState[key] = false;
      }
    }
    setState({ ...updatedState, [clickedState]: !state[clickedState] });
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.hide) return;
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? "collapsed" : ""}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                handleCollapse(prop.state);
                setCollapsed(false);
                handleToggleButtonClick(true)
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {t(prop.name)}
                <b className={`${!collapsed ? 'caret' : ''}`}></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{t(prop.name)}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{t(prop.name)}</span>
              </>
            )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  return (
    <>
      <div className={`sidebar ${collapsed ? 'collapsed' : 'expanded'}`} data-color={background} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            {!collapsed ? (
              <>
                <a className="simple-text logo-mini" style={{ textDecoration: 'none' }}>
                  <div className="logo-img">
                    <img
                      src={!uniProfile ? require('../../assets/img/default-uni') : uniProfile}
                      alt="university-logo"
                      style={{ borderRadius: '50%', top: '0px', left: '0px', height: '40px', width: '40px', position: 'absolute' }}
                    />
                  </div>
                </a>
                <a className="simple-text logo-normal uniName" ref={marqueeRef} style={{ maxWidth: '135px', textDecoration: 'none' }}>
                  <div className="marquee marquee-content">
                    {uniName}
                  </div>
                </a>
              </>
            ) : null}
            <div className="sidebar-toggle" onClick={() => {
              toggleSidebar();
              handleToggleButtonClick(false);
            }}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
          </div>
          <div className="user">
            <div className="photo" onClick={() => { history.push(`/admin/edit_user_profile/`) }}>
              {/* <img alt="..." src={require("assets/img/root-avatar.png")} /> */}
              <img
                src={!userProfile ? require('../../assets/img/default-avatar') : userProfile}
                alt="user-logo"
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className="info">
              <a
                className={userCollapseState ? "collapsed" : ""}
                data-toggle="collapse"
                onClick={(e) => {
                  e.preventDefault();
                  setUserCollapseState(!userCollapseState);
                  history.push(`/admin/edit_user_profile/`)
                }}
                aria-expanded={userCollapseState}
                style={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                
                <span className="text-large" title={storeAuthInfo?.user?.first_name + " " + storeAuthInfo?.user?.last_name} style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', minHeight: '20px', unicodeBidi: 'plaintext' }}>
                  {storeAuthInfo?.user?.first_name + " " + storeAuthInfo?.user?.last_name}
                  {/* <b className="caret"></b> */}
                </span>
              </a>
            </div>
          </div>
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url('" + image + "')"
          }}
        ></div>
      </div>
    </>
  );
}
let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};
Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: []
};
Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    "black",
    "azure",
    "green",
    "orange",
    "red",
    "purple"
  ]),
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string
        })
      })
    ])
  )
};
export default Sidebar;
