import customAxios from "lib/axios";
import React, { useRef, useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { selectConsts } from '../store/selectconstReducer';
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import Idcard1 from "assets/img/idcards/01.jfif";
import Idcard2 from "assets/img/idcards/02.jfif";
import Idcard3 from "assets/img/idcards/03.jfif";
import Idcard4 from "assets/img/idcards/04.jfif";
import Idcard5 from "assets/img/idcards/05.jfif";
import Idcard6 from "assets/img/idcards/06.jfif";
import Idcard7 from "assets/img/idcards/07.jfif";
import Idcard8 from "assets/img/idcards/08.jfif";
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from "react-bootstrap";
//import PrintableContent from "components/Printable";
import { commonContext } from '../context/common'
import * as XLSX from 'xlsx';

const PrintableContent = React.forwardRef(({ limitedStudents }, ref) => {
  const [t] = useTranslation(['students']);
  const storeSelectConsts = useSelector(selectConsts);
  const common = useContext(commonContext)
  return (
    <div style={{ display: 'none' }}>
      <Table ref={ref}>
        <thead>
          <tr>
            <th className='text-left'>#</th>
            <th>{t('name')}</th>
            <th className='text-center'>{t('genderLabel')}</th>
            <th className='text-center'>{t('admissionStatusLabel')}</th>
            <th className='text-center'>{t('currentStatusLabel')}</th>
            <th className='text-center'>{t('class')}</th>
            <th className='text-center'>{t('phoneNumber')}</th>
            <th className='text-center'>{t('idcardNumberLabel')}</th>
            <th className='text-center'>{t('cnicNumberLabel')}</th>
            <th className='text-center'>{t('admissionTypeLabel')}</th>
            <th className='text-center'>{t('boardTypeLabel')}</th>
          </tr>
        </thead>
        <tbody>
          {limitedStudents.map((student, index) => (
            <tr key={index}>
              <td className="text-left" style={{ paddingLeft: '8px' }}>{index + 1}</td>
              <td>{student.student_name}</td>
              <td className="text-center">
                {
                  common.lang === 'en' ?
                    storeSelectConsts.GENDER_NAMES.find((item) => item.value === student.gender_name.id)?.label
                    : storeSelectConsts.GENDER_NAMESAR.find((item) => item.value === student.gender_name.id)?.label
                }
              </td>
              <td className="text-center">
                {
                  common.lang === 'en' ?
                    storeSelectConsts.ADMISSION_STATUSES.find((item) => item.value === student.admission_statusname.id)?.label
                    :
                    storeSelectConsts.ADMISSION_STATUSESAR.find((item) => item.value === student.admission_statusname.id)?.label
                }
              </td>
              <td className="text-center">{
                common.lang === 'en' ?
                  storeSelectConsts.STUDENT_CURRENT_STATUSES.find(item => item.value === student.student_currentstatus.id)?.label
                  : storeSelectConsts.STUDENT_CURRENT_STATUSESAR.find(item => item.value === student.student_currentstatus.id)?.label
              }</td>
              <td className="text-center">{student.class_name.class_name}</td>
              {/* <td className="text-right">{student.class}</td> */}
              <td className="text-center">{student.phone}</td>
              <td className="text-center">{student.idcard_number}</td>
              <td className="text-center">{student.cnic_number}</td>
              <td className="text-center">{
                common.lang === 'en' ?
                  storeSelectConsts.STUDENT_ADMISSION_TYPES.find(item => item.value === student.student_admissiontype.id)?.label
                  :
                  storeSelectConsts.STUDENT_ADMISSION_TYPESAR.find(item => item.value === student.student_admissiontype.id)?.label
              }</td>
              <td className="text-center">{
                common.lang === 'en' ?
                  storeSelectConsts.BOARD_TYPES.find(item => item.value === student.board_typename.id)?.label
                  :
                  storeSelectConsts.BOARD_TYPESAR.find(item => item.value === student.board_typename.id)?.label
              }</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Render student details here based on the provided data */}
      {/* You can use the same structure as in your paginated component */}
    </div>
  );
});

function StudentsList() {
  const [t] = useTranslation(["students"]);
  const common = useContext(commonContext)
  const [loading, setLoading] = React.useState(true)
  let componentRef = useRef();
  const inputRef = useRef()
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const [studentName, setStudentName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [idcardNumber, setIdcardNumber] = React.useState("");
  const [cnicNumber, setCnicNumber] = React.useState("");
  const [requiredClass, setRequiredClass] = React.useState({ value: -1, label: "All" });
  const [admissionStatus, setAdmissionStatus] = React.useState({ value: "", label: "All" });
  const [boardType, setBoardType] = React.useState({ value: "", label: "All" });
  const [gender, setGender] = React.useState({ value: "", label: "All" });
  const [year, setYear] = React.useState({ value: "", label: "All" })
  const [modalExamResult, setModalExamResult] = React.useState(false);
  const [modalBooks, setModalBooks] = React.useState(false);
  const [modalInventory, setModalInventory] = React.useState(false);
  const [modalIDCard, setModalIDCard] = React.useState(false);
  const [students, setStudents] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [curModalStudentName, setCurModalStudentName] = React.useState("");
  const [studentExamResults, setStudentExamResults] = React.useState([]);
  const [studentBooks, setStudentBooks] = React.useState([]);
  const [studentInventories, setStudentInventories] = React.useState([]);
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: "5 " + t("rows") });
  const [pageRowsCntModal, setPageRowsCntModal] = React.useState({ value: 5, label: "5 " + t("rows") });
  const allStudentsCount = filteredList.length;
  const [studentsPerPage, setStudentsPerPage] = React.useState(5);
  const [studentsPerPageModal, setStudentsPerPageModal] = React.useState(5);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageModal, setCurrentPageModal] = React.useState(1)

  const [modalExpel, setModalExpel] = React.useState(false)
  const lastStudentNumber = currentPage * studentsPerPage;
  const firstStudentIndex = lastStudentNumber - studentsPerPage;

  const lastStudentNumberModal = currentPageModal * studentsPerPageModal;
  const firstStudentIndexModal = lastStudentNumberModal - studentsPerPageModal;

  const [reasonForExp, setReasonForExp] = React.useState([]);
  const [expStudentName, setExpStudentName] = React.useState("")
  const [expStudentIdCard, setExpStudentIdCard] = React.useState("");
  const [expStudentId, setExpStudentId] = React.useState("")
  const [resultBooks, setResultBooks] = React.useState("")
  const [selectedStudents, setSelectedStudents] = React.useState({});
  const [modalNextClass, setModalNextClasss] = React.useState(false)
  const [selectedClass, setSelectedClass] = React.useState("")
  const [expelledStudents, setExpelledStudents] = React.useState([]);
  const [expStudents, setExpStudents] = React.useState("")
  const [yearModal, setYearModal] = React.useState("")
  const [isReason, setIsReason] = React.useState(false);
  const [otherReason, setOtherReason] = React.useState("")

  const selectedStudentIds = Object.keys(selectedStudents).filter((studentId) => selectedStudents[studentId]);
  // Example: Perform an action with selected students
  const handleBulkAction = () => {
    const selectedStudentsData = limitedStudents.filter((student) => selectedStudents[student.id]);
    return selectedStudentsData
  };
  const toggleStudentSelection = (studentId) => {
    setSelectedStudents((prevSelected) => ({
      ...prevSelected,
      [studentId]: !prevSelected[studentId], // Toggle selection for the clicked student
    }));
  };
  const currentYear = new Date().getFullYear();
  const arr = Array.from({ length: 12 }, (_, index) => `${currentYear - index}-${currentYear - index + 1}`)
  const limitedStudents = filteredList.slice(
    firstStudentIndex,
    lastStudentNumber
  );
  const limitedExpelledStudents = expelledStudents.slice(
    firstStudentIndexModal,
    lastStudentNumberModal
  )
  const inputStyle = {
    ':: placeholder': {
      fontSize: '5px'
    }
  }
  const maxColumns = Math.max(
    ...studentExamResults.map((table) => table.results.length)
  );
  const tablesWithBlankObjects = studentExamResults.map((table) => {
    const numBlankObjects = maxColumns - table.results.length;
    const blankObjects = new Array(numBlankObjects).fill({
      book_name: "null",
      result: "-",
      number: "-",
    });
    return {
      ...table,
      results: [...table.results, ...blankObjects],
    };
  });

  //resultBooks.sort((a, b) => a.book_name.localeCompare(b.book_name));
  const newResult1 = studentExamResults.map((result) => {
    resultBooks.sort((a, b) => a.book_name.localeCompare(b.book_name));
    const otherBooks = resultBooks.filter(book => book.book_name !== 'grade' && book.book_name !== 'total');
    const gradeBook = otherBooks.find(book => book.book_name === 'grade');
    const totalBook = otherBooks.find(book => book.book_name === 'total');
    if (!gradeBook) {
      otherBooks.push({
        book_name: 'grade',
        result: '-',
        number: 0,
        deleted: false
      });
    }
    if (!totalBook) {
      otherBooks.push({
        book_name: 'total',
        result: "-",
        number: 0,
        deleted: false
      });
    }
    // Add other books first
    otherBooks.forEach((book) => {
      if (!result.results.some(resultBook => resultBook.book_name === book.book_name)) {
        result.results.push({
          book_name: book.book_name,
          result: "-",
          number: book.number,
          deleted: book.deleted
        });
      }
    });
    return result;
  })
  const newResult = studentExamResults.map((result) => {
    resultBooks.sort((a, b) => a.book_name.localeCompare(b.book_name));
    result.results.sort((a, b) => {
      const aIndex = resultBooks.findIndex(book => book.book_name === a.book_name);
      const bIndex = resultBooks.findIndex(book => book.book_name === b.book_name);
      return aIndex - bIndex;
    });
    const otherBooks = resultBooks.filter(book => book.book_name !== 'grade' && book.book_name !== 'total');
    const gradeBook = otherBooks.find(book => book.book_name === 'grade');
    const totalBook = otherBooks.find(book => book.book_name === 'total');
    if (!gradeBook) {
      otherBooks.push({
        book_name: 'grade',
        result: null,
        number: 0,
        deleted: false
      });
    }
    if (!totalBook) {
      otherBooks.push({
        book_name: 'total',
        result: null,
        number: 0,
        deleted: false
      });
    }
    // Add other books first
    otherBooks.forEach((book) => {
      if (!result.results.some(resultBook => resultBook.book_name === book.book_name)) {
        result.results.push({
          book_name: book.book_name,
          result: null,
          number: book.number,
          deleted: book.deleted
        });
      }
    });
    // Sort the "grade" and "total" entries to the end
    result.results.sort((a, b) => {
      if (a.book_name === 'grade') return 1;
      if (b.book_name === 'grade') return -1;
      if (a.book_name === 'total') return 1;
      if (b.book_name === 'total') return -1;
      return 0;
    });
    return result;
  });
  React.useEffect(() => {
    customAxios.get("/student/getAll")
      .then((res) => {
        setStudents(res.data.filter((student) => student.class_name.isActive));
        setFilteredList(res.data.filter((student) => student.class_name.isActive));
        setLoading(false)
      })
      .catch((err) => {
      });
    customAxios.get("/student/getExpelledStudents")
      .then((res) => {
        setExpelledStudents(res?.data)
        setExpStudents(res?.data)
      })
  }, []);
  React.useEffect(() => {
    onFilter();
  }, [studentName, phoneNumber, idcardNumber, cnicNumber, admissionStatus, requiredClass, gender, boardType, year]);

  const handleLangChange = () => {
    const row = document.getElementsByClassName('reverse')
    const cells = Array.from(row[0].getElementsByTagName('th')).reverse();
    while (row[0].firstChild) {
      row[0].removeChild(row[0].firstChild);
    }
    cells.forEach(function (cell) {
      row[0].appendChild(cell);
    });
  }
  const warningWithConfirmMessage = (id, registerationDate) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirmDelete(id, registerationDate)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Submit"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this student!
      </SweetAlert>
    );
  };
  const successAlert = (obj) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
      >
        {/* {t("msgRemoveSuccess.first")} {enrolmentNumber} {t("msgRemoveSuccess.second")} */}
        {
          obj.upgradeClass
            ? "Student upgraded to the next class"
            : `${obj.student} ${t("msgRemoveSuccess.second")}`
        }
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (id, registerationDate) => {
    // submit form values
    customAxios.post("/student/deleteOne/", {
      student_id: id,
      reason: reasonForExp.join(", ")
    }).then((res) => {
      if (res.data.msg == "success") {
        let enrolmentNumber = new Date(registerationDate).getFullYear().toString() + id;
        successAlert({ upgrade: false, student: expStudentName });
        setStudents(students.filter((item) => item.id != id));
        setFilteredList(filteredList.filter((item) => item.id != id));
        setModalExpel(!modalExpel)
        setReasonForExp([])
        setIsReason(false)
        setOtherReason("")
        customAxios.get("/student/getExpelledStudents")
          .then((res) => {
            setExpelledStudents(res?.data)
            setExpStudents(res?.data)
          })
      }
    }).catch((err) => {
      // Work with the err
      notify("tr", t("notifyConnectionProblem"));
    });
  }
  const onStudentExamResults = (studentName, studentId) => {
    setCurModalStudentName(studentName);
    customAxios.post("/exam/getStudentExamResults", { studentId })
      .then((res) => {
        setStudentExamResults(res.data.results);
        setResultBooks(res?.data?.books)
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
    setModalExamResult(!modalExamResult)
  }
  const onStudentBooks = (studentName, classId) => {
    setCurModalStudentName(studentName);
    customAxios.get("/class/getAllBooks")
      .then((res) => {
        setStudentBooks(res.data.filter((item) => item.class_id == classId))
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
    setModalBooks(!modalBooks)
  }
  const onStudentInventories = (studentName) => {
    setCurModalStudentName(studentName);
    setModalInventory(!modalInventory)
  }
  const onStudentIdCard = (studentName) => {
    setCurModalStudentName(studentName);
    setModalIDCard(!modalIDCard)
  }
  const onFilter = () => {
    setFilteredList(students.filter((item) =>
      item.student_name.includes(studentName) &&
      item.phone.includes(phoneNumber) &&
      item.idcard_number.includes(idcardNumber) &&
      item.cnic_number.includes(cnicNumber) &&
      item.admission_statusid.toString().includes(admissionStatus?.value ? admissionStatus?.value : "") &&
      //item.class.toString().includes(requiredClass?.value ? requiredClass?.value : "") &&
      (requiredClass?.value === -1 || item.class === (requiredClass?.value ? requiredClass?.value : "")) &&
      item.gender.toString().includes(gender?.value ? gender?.value : "") &&
      item.board_typeid.toString().includes(boardType?.value ? boardType?.value : "") &&
      item?.year?.includes(year.value)
    ));
  }
  const getRandImgUrl = (max) => {
    let rand = Math.random() * max;
    rand = Math.floor(rand);
    switch (rand) {
      case 0:
        return Idcard1;
      case 1:
        return Idcard2;
      case 2:
        return Idcard3;
      case 3:
        return Idcard4;
      case 4:
        return Idcard5;
      case 5:
        return Idcard6;
      case 6:
        return Idcard7;
      case 7:
        return Idcard8;
    }
    return Idcard1;
  }
  const data = [];
  for (let i = 0; i <= filteredList.length; i++) {
    data.push({
      Name: filteredList[i]?.student_name,
      Class: filteredList[i]?.class_name?.class_name,
      Gender: common?.lang === 'en' ?
        storeSelectConsts.GENDER_NAMES.find((item) => item.value == filteredList[i]?.gender)?.label
        : storeSelectConsts.GENDER_NAMESAR.find((item) => item.value == filteredList[i]?.gender)?.label,
      ID_Number: filteredList[i]?.idcard_number,
      Addmission_Status: common?.lang == 'en' ?
        storeSelectConsts.ADMISSION_STATUSES.find((item) => item.value == filteredList[i]?.admission_statusid
        )?.label : storeSelectConsts.ADMISSION_STATUSESAR.find((item) => item.value == filteredList[i]?.admission_statusid)?.label,
      Current_Status: common?.lang == 'en' ? storeSelectConsts.STUDENT_CURRENT_STATUSES.find((item) => item.value == filteredList[i]?.current_statusid)?.label
        : storeSelectConsts.STUDENT_CURRENT_STATUSESAR.find((item) => item.value == filteredList[i]?.current_statusid)?.label,
      Phone_Number: filteredList[i]?.phone,
      cnic_Number: filteredList[i]?.cnic_number,
      Admission_Type: common?.lang == 'en' ? storeSelectConsts.STUDENT_ADMISSION_TYPES.find((item) => item.value == filteredList[i]?.admission_typeid)?.label
        : storeSelectConsts.STUDENT_ADMISSION_TYPESAR.find((item) => item.value == filteredList[i]?.admission_typeid)?.label,
      Board_Type: common?.lang == 'en' ? storeSelectConsts.BOARD_TYPES.find((item) => item.value == filteredList[i]?.board_typeid)?.label
        : storeSelectConsts.BOARD_TYPES.find((item) => item.value == filteredList[i]?.board_typeid)?.label
    })
  }
  const handleUpgradeClass = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const result = handleBulkAction();
      await customAxios.post("/student/upgradeClass", { class_id: selectedClass?.value, ids: selectedStudentIds })
        .then(response => {
          successAlert({ upgradeClass: true, student: null })
          setModalNextClasss(!modalNextClass)
          setSelectedStudents({})
          customAxios.get("/student/getAll")
            .then((res) => {
              setStudents(res.data.filter((student) => student.class_name.isActive));
              setFilteredList(res.data.filter((student) => student.class_name.isActive));
              setRequiredClass({ value: -1, label: 'All' })
              setRequiredClass({ value: selectedClass?.value, label: selectedClass?.label })
              //setRequiredClass({value:})
              setLoading(false)
            })
            .catch((err) => {
            });
        })
    } catch (err) {
      console.log(err)
    }
  }
  const handleSelectReason = (e) => {
    if (e?.target?.innerText === 'Other') {
      setIsReason(true)
    } else {
      setReasonForExp((prevReasons) => [...prevReasons, e?.target?.innerText])
      setOtherReason("")
      setIsReason(false)
    }
  }
  const handleAddReason = (e) => {
    if (otherReason.trim() !== "") {
      setReasonForExp((prevReasons) => [...prevReasons, otherReason.trim()])
      setOtherReason("")
    }

  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      // Check if backspace key is pressed
      e.preventDefault(); // Prevent browser back navigation
      setReasonForExp((prevReasons) => prevReasons.slice(0, -1)); // Remove the last item
    }
  };
  React.useEffect(() => {
    // Focus the input field when isReason is true
    if (isReason && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isReason]);

  const exportToExcel = () => {
    /* Create a worksheet from the table data */
    const ws = XLSX.utils.json_to_sheet(data);
    /* Create a workbook with the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* Save the workbook as an Excel file */
    XLSX.writeFile(wb, 'table_data.xlsx');
  };
  React.useEffect(() => {
    if (common.lang === 'en') {
      setAdmissionStatus(storeSelectConsts.ADMISSION_STATUSES.find((item) => item?.value == admissionStatus?.value))
      setGender(storeSelectConsts.GENDER_NAMES.find((item) => item.value === gender?.value))
      setBoardType(storeSelectConsts.BOARD_TYPES.find((item) => item.value === boardType?.value))
    } else {
      setAdmissionStatus(storeSelectConsts.ADMISSION_STATUSESAR.find((item) => item?.value == admissionStatus?.value))
      setGender(storeSelectConsts.GENDER_NAMESAR.find((item) => item.value === gender?.value))
      setBoardType(storeSelectConsts.BOARD_TYPESAR.find((item) => item.value === boardType?.value))
    }
  }, [common?.lang])
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("studentList")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action="" className="form" method="">
                  <Card className="pl-3 pr-3">
                    <Card.Body >
                      <Row>
                        <Col className="pr-md-1 pl-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("studentNameLabel")}</label>
                            <Form.Control
                              style={inputStyle}
                              type="text"
                              value={studentName}
                              onChange={(e) => { setStudentName(e.target.value); }}
                              placeholder={t("searchStudentName")}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("phoneNumber")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t("searchStudentPhone")}
                              type="text"
                              value={phoneNumber}
                              onChange={(e) => { setPhoneNumber(e.target.value); }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("idcardNumber")}</label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder={t("searchStudentIdCard")}
                              type="text"
                              value={idcardNumber}
                              onChange={(e) => { setIdcardNumber(e.target.value); }}  ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("cnicNumberLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t("searchStudentCnic")}
                              type="text"
                              value={cnicNumber}
                              onChange={(e) => { setCnicNumber(e.target.value); }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("admissionStatusLabel")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="admissionStatus"
                              value={admissionStatus}
                              onChange={(optionSelected) => {
                                setAdmissionStatus({ value: optionSelected.value, label: optionSelected.label })
                              }}
                              // options={[...storeSelectConsts.ADMISSION_STATUSES, { value: "", label: "All" }]}
                              options={
                                common.lang === 'en' ? [{ value: "", label: "All" }, ...storeSelectConsts.ADMISSION_STATUSES].map(option => ({
                                  ...option,
                                  label: t(option.label), // Translate the label here
                                })) : [{ value: "", label: "All" }, ...storeSelectConsts.ADMISSION_STATUSESAR].map(option => ({
                                  ...option,
                                  label: t(option.label), // Translate the label here
                                }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("class")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="requiredClass"
                              value={requiredClass}
                              onChange={(optionSelected) => setRequiredClass({ value: optionSelected.value, label: optionSelected.label })}
                              options={[{ value: -1, label: "All" }, ...storeSelectConsts.CLASS_NAMES]}
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("genderLabel")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="gender"
                              value={gender}
                              onChange={(optionSelected) => setGender({ value: optionSelected.value, label: optionSelected.label })}
                              // options={[...storeSelectConsts.GENDER_NAMES, { value: "", label: "All" }]}
                              options={
                                common.lang === 'en' ? [{ value: "", label: "All" }, ...storeSelectConsts.GENDER_NAMES].map(option => ({
                                  ...option,
                                  label: t(option.label), // Translate the label here
                                })) :
                                  [{ value: "", label: "All" }, ...storeSelectConsts.GENDER_NAMESAR].map(option => ({
                                    ...option,
                                    label: t(option.label),
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("boardTypeLabel")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="boardType"
                              value={boardType}
                              onChange={(optionSelected) => setBoardType({ value: optionSelected.value, label: optionSelected.label })}
                              // options={[...storeSelectConsts.ADMISSION_STATUSES, { value: "", label: "All" }]}
                              options={
                                common.lang === 'en' ? [{ value: "", label: "All" }, ...storeSelectConsts.BOARD_TYPES].map(option => ({
                                  ...option,
                                  label: t(option.label), // Translate the label here
                                })) :
                                  [{ value: "", label: "All" }, ...storeSelectConsts.BOARD_TYPESAR].map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1 pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("yearLabel")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="year"
                              value={year}
                              onChange={(optionSelected) => {
                                setYear({ value: optionSelected?.value, label: optionSelected?.label })
                              }
                              }
                              // options={[...storeSelectConsts.ADMISSION_STATUSES, { value: "", label: "All" }]}
                              placeholder={t("singleSelectPlaceholder")}
                              options={[{ value: '', label: "All" }, ...arr.map((a) => ({ value: a, label: a }))]}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Card.Body>
              <div className="d-flex justify-content-start" style={{ opacity: selectedStudentIds?.length > 0 ? '1' : '0' }}>
                <Button
                  className="btn-wd btn-outline  mb-3"
                  type="button"
                  onClick={() => setModalNextClasss(!modalNextClass)}
                  variant="success"
                  disabled={selectedStudentIds?.length > 0 ? false : true}
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  &nbsp;{t("moveToNextClassLabel")}
                </Button>
              </div>
              <div className="d-flex justify-content-end">
                <Pagination
                  itemsCount={allStudentsCount}
                  itemsPerPage={studentsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                />
                <div style={{ width: 155 }}>
                  <Select
                    className="react-select primary ml-4"
                    classNamePrefix="react-select"
                    style={{ width: `100px` }}
                    name="rowCnt"
                    value={pageRowsCnt}
                    onChange={(optionSelected) => {
                      setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                      setStudentsPerPage(optionSelected.value);
                    }}
                    options={[
                      { value: 3, label: "3 " + t("rows") },
                      { value: 5, label: "5 " + t("rows") },
                      { value: 10, label: "10 " + t("rows") },
                      { value: 15, label: "15 " + t("rows") },
                      { value: 20, label: "20 " + t("rows") },
                      { value: 25, label: "25 " + t("rows") },
                    ]}
                    placeholder={t("singleSelectPlaceholder")}
                  />
                </div>
              </div>
              <Card.Body className="overflow-auto table-full-width" >
                {!loading && !common.loading ? (<>
                  {limitedStudents?.length > 0 ? (
                    <Table>
                      <thead>
                        <tr className="reverse">
                          <th className="text-left" style={{ paddingLeft: '8px' }}>#</th>
                          <th>{t("name")}</th>
                          <th>{t("fatherNameLabel")}</th>
                          <th className="text-center">{t("genderLabel")}</th>
                          <th className="text-center">{t("admissionStatusLabel")}</th>
                          <th className="text-center">{t("currentStatusLabel")}</th>
                          <th className="text-center">{t("class")}</th>
                          <th className="text-center">{t("phoneNumber")}</th>
                          <th className="text-center">{t("idcardNumberLabel")}</th>
                          <th className="text-center">{t("cnicNumberLabel")}</th>
                          <th className="text-center">{t("admissionTypeLabel")}</th>
                          <th className="text-center">{t("boardTypeLabel")}</th>
                          <th className="text-center print-hidden">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          limitedStudents
                            .map((student, index) => (
                              <tr key={index}>
                                <td className="text-left" style={{ paddingLeft: '8px' }}>{firstStudentIndex + index + 1}</td>
                                <td>{student.student_name}</td>
                                <td>{student.father_name}</td>
                                <td className="text-center">
                                  {
                                    common.lang === 'en' ?
                                      storeSelectConsts.GENDER_NAMES.find((item) => item.value === student.gender_name.id)?.label
                                      : storeSelectConsts.GENDER_NAMESAR.find((item) => item.value === student.gender_name.id)?.label
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    common.lang === 'en' ?
                                      storeSelectConsts.ADMISSION_STATUSES.find((item) => item.value === student.admission_statusname.id)?.label
                                      :
                                      storeSelectConsts.ADMISSION_STATUSESAR.find((item) => item.value === student.admission_statusname.id)?.label
                                  }
                                </td>
                                <td className="text-center">{
                                  common.lang === 'en' ?
                                    storeSelectConsts.STUDENT_CURRENT_STATUSES.find(item => item.value === student.student_currentstatus.id)?.label
                                    : storeSelectConsts.STUDENT_CURRENT_STATUSESAR.find(item => item.value === student.student_currentstatus.id)?.label
                                }</td>
                                <td className="text-center">{student.class_name.class_name}</td>
                                {/* <td className="text-right">{student.class}</td> */}
                                <td className="text-center">{student.phone}</td>
                                <td className="text-center">{student.idcard_number}</td>
                                <td className="text-center">{student.cnic_number}</td>
                                <td className="text-center">{
                                  common.lang === 'en' ?
                                    storeSelectConsts.STUDENT_ADMISSION_TYPES.find(item => item.value === student.student_admissiontype.id)?.label
                                    :
                                    storeSelectConsts.STUDENT_ADMISSION_TYPESAR.find(item => item.value === student.student_admissiontype.id)?.label
                                }</td>
                                <td className="text-center">{
                                  common.lang === 'en' ?
                                    storeSelectConsts.BOARD_TYPES.find(item => item.value === student.board_typename.id)?.label
                                    :
                                    storeSelectConsts.BOARD_TYPESAR.find(item => item.value === student.board_typename.id)?.label
                                }</td>
                                <td className="td-actions text-left">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("editProfile")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={(e) => history.push("/admin/add_edit_students/" + student.idcard_number)}
                                      variant="success"
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("examResults")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={() => onStudentExamResults(student.student_name, student.id)}
                                      variant="warning"
                                    >
                                      <i className="fas fa-star"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("inventory")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={() => onStudentInventories(student.student_name)}
                                      variant="success"
                                    >
                                      <i className="fas fa-certificate"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("idCard")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      //  onClick={() => onStudentIdCard(student.student_name)}
                                      onClick={(e) => history.push("/admin/student_cards/" + student.idcard_number)}
                                      variant="dark"
                                    >
                                      <i className="far fa-address-card"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    onClick={(e) => e.preventDefault()}
                                    overlay={
                                      <Tooltip id="tooltip-981231696">
                                        {t("btnExpel")}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-link btn-xs"
                                      onClick={() => {
                                        setExpStudentName(student.student_name)
                                        setExpStudentIdCard(student.idcard_number)
                                        setExpStudentId(student.id)
                                        setModalExpel(true)
                                      }}
                                      variant="danger"
                                    >
                                      <i className="fas fa-user-slash"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <input
                                    className="css-checkbox"
                                    defaultValue={true}
                                    type="checkbox"
                                    checked={selectedStudents[student.id]}
                                    // onChange={(e) => {
                                    //   // setIsDisactive((prevState) => ({ ...prevState, [item.label]: !prevState[item.label] }))
                                    //   onConfirmToggleActive(item.value, !item.isActive)
                                    // }}
                                    onChange={() => toggleStudentSelection(student.id)}
                                    style={{ margin: '0px 4px', top: '5px' }}
                                  ></input>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noStudentFound")}</div>
                  )}
                </>) : (
                  <div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between">
                <ReactToPrint
                  trigger={() =>
                    <Button className="btn-edit btn-fill" type="submit" variant="info">
                      <span className="btn-label">
                        <i className="fas fa-print"></i>
                      </span>
                      &nbsp;{t("btnPrint")}
                    </Button>
                  }
                  content={() => componentRef}
                />
                <Button Button className="btn-edit btn-fill" type="submit" variant="info" onClick={exportToExcel}>
                  <span className="btn-label">
                    <i className="fas fa-print"></i>
                  </span>
                  &nbsp;{t("btnExcel")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <PrintableContent limitedStudents={filteredList} ref={(el) => (componentRef = el)} hidden style={{ display: 'none' }} />
        {/* <div style={{ display: 'none' }}>
          <StudentCardPage limitedStudents={limitedStudents} ref={(el) => (componentRefIdCard = el)} hidden style={{ display: 'none' }} />
        </div> */}
        {/* Exam result Modal */}
        <Modal
          className="modal-primary"
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalExamResult(!modalExamResult) }}
          show={modalExamResult}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{curModalStudentName}{t("modalExamResultsTitle")}</Card.Title>
            </Card.Header>
            {studentExamResults.length != 0 ?
              <Card.Body className="overflow-auto">
                {studentExamResults.length > 0 && newResult.map((item, index) => (
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-center" style={{ width: "200px" }}>{t("modalExamName")}</th>
                        <th className="text-center" style={{ width: "100px" }}>{t("Date")}</th>
                        {/* {exam.results.map((book, index) =>
                        <th className="text-center" style={{ width: index < exam.results.length - 2 ? "auto" : "100px" }}>{book.book_name}</th>
                      )} */}
                        {/* {studentExamResults.length > 0 && studentExamResults[0].results.map((book, index) => ( */}
                        {item.results.map((book, index) => (
                          // <th key={index} className="text-center" style={{ width: index < studentExamResults[0].results.length - 2 ? "auto" : "100px" }}>
                          <th key={index} className="text-center" style={{ width: 'auto', opacity: book.result === '-' ? '0.5' : "" }}>
                            {book.book_name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {studentExamResults.map((item) => */}
                      <tr>
                        <td className="text-center">{item?.exam_name}</td>
                        <td className="text-center">{item?.exam_date}</td>
                        {item.results.map((book, index) =>
                          <th className="text-center" style={{ width: 'auto', opacity: book.result === '-' ? '0.5' : "" }}>{book.book_name ? book.book_name === 'grade' ? book.result.grade_name : book.result : ""}</th>
                        )}
                      </tr>
                      {/* )} */}
                    </tbody>
                  </Table>
                ))}
              </Card.Body>
              : <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("modalNoExamResult")}</div>
            }
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple mx-auto"
              onClick={() => { setModalExamResult(!modalExamResult) }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        {/* Books Modal */}
        <Modal
          className="modal-primary"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalBooks(!modalBooks) }}
          show={modalBooks}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{curModalStudentName}{t("modalBooks")}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">{t("modalBookName")}</th>
                  </tr>
                </thead>
                <tbody>
                  {studentBooks.map((book, index) =>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{book.book_name}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple mx-auto"
              onClick={() => { setModalBooks(!modalBooks) }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        {/* Inventory Modal */}
        <Modal
          className="modal-primary"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalInventory(!modalInventory) }}
          show={modalInventory}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{curModalStudentName}{t("modalInventoriesTitle")}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">{t("modalInventoryName")}</th>
                    <th className="text-right">{t("date")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td className="text-center">1</td>
                    <td className="text-center" style={{ whiteSpace: 'unset' }} >A way to speed up compilation of go code</td>
                    <td className="text-right">2023-02-05</td>
                  </tr>
                  <tr style={{ whiteSpace: 'unset' }}>
                    <td className="text-center">2</td>
                    <td className="text-center" style={{ whiteSpace: 'unset' }}>AI slot machine</td>
                    <td className="text-right">2023-02-05</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple mx-auto"
              onClick={() => { setModalInventory(!modalInventory) }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        {/* ID card Modal */}
        <Modal
          className="modal-primary"
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalIDCard(!modalIDCard) }}
          show={modalIDCard}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{curModalStudentName}{t("modalIdcardTitle")}</Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                alt="id card"
                src={getRandImgUrl(8)}
                // src={require("assets/img/idcards/04.jfif")}
                width={220}
                height={150}
              ></img>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple mx-auto"
              onClick={() => { setModalIDCard(!modalIDCard) }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        {/*  Expel Student card Modal */}
        <Modal
          className="modal-primary"
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalExpel(!modalExpel) }}
          show={modalExpel}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{t("expModalTitle")}</Card.Title>
            </Card.Header>
            <Card.Body className="mx-2">
              <Row>
                <Col className="pl-1 pr-1" md="6">
                  <Form.Group
                    className={
                      "has-label"
                    }
                  >
                    <label>{t("studentNameLabel")}</label>
                    <Form.Control
                      placeholder=""
                      value={expStudentName}
                      type="text"
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col className="pl-1 pr-1" md="6">
                  <Form.Group
                    className={
                      "has-label"
                    }
                  >
                    <label>{t("idcardNumber")}</label>
                    <Form.Control
                      placeholder=""
                      value={expStudentIdCard}
                      type="text"
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col className='pl-1 pr-1' md='12'>
                  <InputGroup className="mb-3 reason-dropdown">
                    <DropdownButton
                      variant="outline-secondary"
                      title={t("reasonLabel")}
                      id="input-group-dropdown-1"
                    >
                      <Dropdown.Item onClick={handleSelectReason} >{t("AbsenteeismLabel")}</Dropdown.Item>
                      <Dropdown.Item onClick={handleSelectReason} >{t("MoralcomplaintLabel")}</Dropdown.Item>
                      <Dropdown.Item onClick={handleSelectReason} >{t("ViolationofrulesLabel")}</Dropdown.Item>
                      <Dropdown.Item onClick={handleSelectReason} >{t("DisruptiveBehaviorLabel")}</Dropdown.Item>
                      <Dropdown.Item onClick={handleSelectReason} >Other</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control
                      aria-label="Text input with dropdown button"
                      value={reasonForExp?.map((item) => item)}
                      placeholder={t("reasonsPlaceholder")}
                      onKeyDown={handleKeyDown}
                      className="reason-container"
                    />
                    {
                      isReason ?
                        <Col md='4' className="d-flex pl-1 pr-1 other-reason-input" style={{ direction: common?.lang === 'en' ? 'ltr' : 'rtl' }} >
                          <Form.Control aria-label="Text input with dropdown button"
                            onChange={(e) => setOtherReason(e?.target?.value)}
                            value={otherReason}
                            style={{ color: 'black' }}
                            className="input-reason"
                            autoFocus={true}
                            ref={inputRef}
                          />
                          <Button className="btn-reason" onClick={handleAddReason}>+</Button>
                        </Col>
                        : ""
                    }
                  </InputGroup>
                </Col>
              </Row>
              <Row>
              </Row>
              <Row>
                <Col md='12' style={{ padding: '0px 8px' }}>
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title className="text-center" as="h5">{t("expleedStudents")}</Card.Title>
                    </Card.Header>
                    <Card.Body className="overflow-auto table-full-width" >
                      <Col className="pl-1 pr-1" md="3">
                        <Form.Group className="has-success">
                          <label>{t("yearLabel")}</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="yearModal"
                            value={yearModal}
                            onChange={(optionSelected) => {
                              setYearModal({ value: optionSelected?.value, label: optionSelected?.label })
                              setExpelledStudents(expStudents.filter((item) =>
                                item?.year?.includes(optionSelected?.value)
                              ));
                            }
                            }
                            // options={[...storeSelectConsts.ADMISSION_STATUSES, { value: "", label: "All" }]}
                            placeholder={t("singleSelectPlaceholder")}
                            options={[{ value: '', label: "All" }, ...arr.map((a) => ({ value: a, label: a }))]}
                          />
                        </Form.Group>
                      </Col>
                      <div className="d-flex justify-content-end">
                        <Pagination
                          itemsCount={expelledStudents?.length}
                          itemsPerPage={studentsPerPageModal}
                          currentPage={currentPageModal}
                          setCurrentPage={setCurrentPageModal}
                          alwaysShown={false}
                        />
                        <div style={{ width: 155 }}>
                          <Select
                            className="react-select primary ml-4"
                            classNamePrefix="react-select"
                            style={{ width: `100px` }}
                            name="rowCnt"
                            value={pageRowsCntModal}
                            onChange={(optionSelected) => {
                              setPageRowsCntModal({ value: optionSelected.value, label: optionSelected.label });
                              setStudentsPerPageModal(optionSelected.value);
                            }}
                            options={[
                              { value: 3, label: "3 " + t("rows") },
                              { value: 5, label: "5 " + t("rows") },
                              { value: 10, label: "10 " + t("rows") },
                              { value: 15, label: "15 " + t("rows") },
                              { value: 20, label: "20 " + t("rows") },
                              { value: 25, label: "25 " + t("rows") },
                            ]}
                            placeholder={t("singleSelectPlaceholder")}
                          />
                        </div>
                      </div>
                      {!loading && !common.loading ? (<>
                        {limitedExpelledStudents?.length > 0 ? (
                          <Table>
                            <thead>
                              <tr className="reverse">
                                <th className="text-left" style={{ paddingLeft: '8px' }}>#</th>
                                <th>{t("name")}</th>
                                <th>{t("fatherNameLabel")}</th>
                                <th className="text-center">{t("class")}</th>
                                <th className="text-center">{t("idcardNumberLabel")}</th>
                                <th className="text-center">{t("reasonForExplusionLabel")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                limitedExpelledStudents
                                  .map((student, index) => (
                                    <tr key={index}>
                                      <td className="text-left" style={{ paddingLeft: '8px' }}>{firstStudentIndex + index + 1}</td>
                                      <td>{student.student_name}</td>
                                      <td>{student.father_name}</td>
                                      <td className="text-center">{storeSelectConsts.CLASS_NAMES.find((item) => item.value === student?.required_class)?.label}</td>
                                      {/* <td className="text-right">{student.class}</td> */}
                                      <td className="text-center">{student.idcard_number}</td>
                                      <td className="text-center">{student?.reason_for_expulsion}</td>
                                    </tr>
                                  ))}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noStudentFound")}</div>
                        )}
                      </>) : (
                        <div className="d-flex align-items-center justify-content-center my-5">
                          <Spinner animation="border" variant="warning" />
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              onClick={() => {
                onConfirmDelete(expStudentId)
              }}
              variant="link"
            >
              {t("btnExpel")}
            </Button>
            <Button
              className="btn-simple"
              onClick={() => {
                setModalExpel(!modalExpel)
                setReasonForExp([])
                setIsReason(false)
                setOtherReason("")
              }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
        {/*  Next class modal */}
        <Modal
          className="modal-primary"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalExpel(!modalNextClass) }}
          show={modalNextClass}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{t("classModalTitle")}</Card.Title>
            </Card.Header>
            <Card.Body className="mx-2">
              <Row>
                <Col className="pl-md-1 pr-md-1" md="12">
                  <Form.Group className="has-success">
                    <label>{t("class")}</label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="selectedClass"
                      value={selectedClass}
                      onChange={(optionSelected) => setSelectedClass({ value: optionSelected.value, label: optionSelected.label })}
                      options={[{ value: -1, label: "All" }, ...storeSelectConsts.CLASS_NAMES]}
                      placeholder={t("singleSelectPlaceholder")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              onClick={handleUpgradeClass}
              variant="link"
            >
              {t("moveLabel")}
            </Button>
            <Button
              className="btn-simple"
              onClick={() => {
                setModalNextClasss(!modalNextClass)
              }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End of the modal */}
      </Container >
      {/* <Button onClick={async () => {
        let data = [];
        for (let i = 1; i <= 200; i++) {
          data.push({
            student_name: `student B${i}`,
            father_name: `student B${i} father`,
            required_class: 72,
            fathers_profession: 'gggg',
            birth_date: new Date(),
            dependency: 2,
            admission_type: 2,
            admission_status: 2,
            current_status: 2,
            board_type: 2,
            gender: 2,
            city: 'rrr',
            province: 'hghgh',
            country: 1,
            birth_place: 'cgvchgvhgv',
            school_achievement: 'gscdgg',
            prevexam_grade: "dgsdf",
            last_university: "sfdgf",
            last_univyear: "fvgvhg",
            temp_address: "jfghdgf",
            permanent_address: "jgdhgfhg",
            cnic_number: 412514325,
            firstcontact_number: 8978675645,
            secondcontact_number: 1234567890,
            guardians_name: "sdgf",
            guardians_profession: "scghhgghg",
            guardian_type: "hshch",
            guardian_curaddress: "hshhh",
            guardian_phone1: 1234567890,
            guardian_phone2: 12356789090,
            term_sign: 0,
            supervisor_sign: 0,
            eduheader_sign: 0,
            admin_sign: 0,
            registeration_date: new Date(),
            year: 2024,
            deleted: 0,
            idcard_number: 103582 + i
          })
        }
        const result = await customAxios.post("/student/bulk", { data: data })
        console.log(result, ">>> result from bulk")
      }}>bulk create</Button> */}
    </>
  );
}
export default StudentsList;
