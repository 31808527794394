import customAxios from "lib/axios";
import { isRequired } from "lib/validation";
import React, { useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addDepartmentName, changeDepartment, removeDepartment, selectConsts, toggleActiveDepartment, useForceUpdate, toggleActiveClassWithDpt } from '../store/selectconstReducer';
//react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from "react-bootstrap";
import { commonContext } from '../context/common'
function Department() {
  const [t] = useTranslation(["departments"]);
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [departmentId, setDepartmentId] = React.useState("");
  const [departmentName, setDepartmentName] = React.useState("");
  const [departmentNameState, setDepartmentNameState] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [isDisactive, setIsDisactive] = React.useState({})
  const common = useContext(commonContext)
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (curDepartmentId, curDepartmentName) => {
    customAxios.post("/department/deleteOne/", {
      curDepartment_id: curDepartmentId
    })
      .then((res) => {
        if (res.data.msg == "success") {
          // dispatch deleting event to the redux store
          dispatch(removeDepartment(curDepartmentId));
          successAlert(t("submitRemoveSuccessAlertMsg.first") + " " + curDepartmentName + " " + t("submitRemoveSuccessAlertMsg.second"));
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
  }
  const onConfirmToggleActive = (curDepartmentId, isActive) => {
    customAxios.post("/department/toggleActive/", {
      dpt: curDepartmentId, isActive: isActive
    })
      .then((res) => {
        if (res.data.msg == "success") {
          dispatch(toggleActiveDepartment({ curDepartmentId, isActive }))
          //dispatch(toggleActiveClassWithDpt({ curDepartmentId, isActive }))
          // successAlert(`Department ${isActive ? 'Activated' : 'disActivated'}`);
          // dispatch deleting event to the redux store
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
        console.log(err)
      });
  }
  const onUpsert = () => {
    // validation check
    let allValid = true;
    if (!departmentNameState || !isRequired(departmentName)) {
      setDepartmentNameState(false);
      allValid = false;
    }
    if (!allValid) {
      notify("tr", t("validationNotify"));
      return;
    }
    if (departmentId) {
      customAxios.post("/department/update", {
        id: departmentId,
        department_name: departmentName,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(t("submitUpdateSuccessAlertMsg.first") + " " + departmentName + " " + t("submitUpdateSuccessAlertMsg.second"));
            dispatch(changeDepartment({ value: departmentId, label: departmentName }));
            // forceUpdate();
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    else {
      customAxios.post("/department/create", {
        department_name: departmentName,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(t("submitAddSuccessAlertMsg.first") + " " + departmentName + " " + t("submitAddSuccessAlertMsg.second"));
            dispatch(addDepartmentName(res.data.created))
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    setDepartmentName("");
    setDepartmentNameState(true);
    setDepartmentId("");
    setModal(!modal);
  }
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("title")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width">
                <Button
                  className="btn-wd btn-outline mt-4 mb-3"
                  type="button"
                  onClick={() => setModal(!modal)}
                  variant="success"
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  &nbsp;{t("addDepartment")}
                </Button>
                {/* Mini Modal */}
                <Modal
                  className="modal-primary"
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => { setDepartmentId(""); setModal(!modal) }}
                  show={modal}
                >
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title className="text-center" as="h5">{t("addDepartment")}</Card.Title>
                    </Card.Header>
                    <Card.Body className="mx-2">
                      <Row>
                        <Col className="pl-1 pr-1" md="12">
                          <Form.Group
                            className={
                              "has-label " +
                              (departmentNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("departmentName")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={departmentName}
                              type="text"
                              onChange={(e) => {
                                setDepartmentName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setDepartmentNameState(true);
                                } else {
                                  setDepartmentNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {departmentNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        onUpsert();
                      }}
                      variant="link"
                    >
                      {t("btnSave")}
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        setModal(!modal);
                        setDepartmentId("");
                        setDepartmentName("");
                        setDepartmentNameState(true);
                      }}
                      variant="link"
                    >
                      {t("btnClose")}
                    </Button>
                  </div>
                </Modal>
                {/* End Modal */}
                <Card.Body className="overflow-auto">
                  {!common?.loding ?
                    storeSelectConsts?.DEPARTMENT_NAMES.length > 0 ? (
                      <Table>
                        <thead>
                          <tr>
                            <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                            <th>{t("departmentName")}</th>
                            <th className="text-center">{t("actions")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {storeSelectConsts.DEPARTMENT_NAMES.map((item, index) => (
                            <tr key={index}>
                              <td className={"text-center " + (!item.isActive ? 'disabled' : "")} style={{ paddingLeft: '8px' }}>{index + 1}</td>
                              <td className={!item.isActive ? 'disabled' : ""}>{item.label}</td>
                              <td className="td-actions text-center">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-981231696">
                                      {t("btnEdit")}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="btn-link btn-xs"
                                    onClick={(e) => {
                                      setDepartmentId(item.value);
                                      setDepartmentName(item.label);
                                      setModal(!modal);
                                    }}
                                    variant="success"
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-981231696">
                                      {t("btnRemove")}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="btn-link btn-xs"
                                    onClick={(e) => {
                                      onConfirmDelete(item.value, item.label)
                                    }}
                                    variant="danger"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </OverlayTrigger>
                                <input
                                  className="css-checkbox"
                                  defaultValue={true}
                                  type="checkbox"
                                  checked={item.isActive}
                                  onChange={(e) => {
                                    // setIsDisactive((prevState) => ({ ...prevState, [item.label]: !prevState[item.label] }))
                                    onConfirmToggleActive(item.value, !item.isActive)
                                  }}
                                  style={{ marginLeft: '4px', top: '5px' }}
                                ></input>
                                <span></span>
                              </td>
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center my-5">
                        <Spinner animation="border" variant="warning" />
                      </div>
                    ) : (
                      <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noDepartmentFound")}</div>
                    )
                  }
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  );
}
export default Department;
