
const setPageDirection = (language) => {
  const dir = language == "ar" ? "rtl" : "ltr"
  // document.documentElement.dir = dir;

  const _body = document.body;
  const _html = _body.parentNode;
  _body.dir = dir;
  _html.dir = dir;
  if (dir == "ltr") {
    _body.className = "off-canvas-sidebar"
  } else {
    _body.className = "off-canvas-sidebar rtl-active"
  }
};

export default setPageDirection;