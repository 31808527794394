import React, { useContext, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Row,
    Table,
    Button,
    Form,
} from "react-bootstrap";
import Select from "react-select";
import { commonContext } from '../context/common'
import customAxios from "../lib/axios";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { selectConsts } from '../store/selectconstReducer';
import Pagination from "../components/Pagination";
// export default EmployeeCardsPage
const EmployeeCardsPage = () => {
    const [t] = useTranslation(["employees"]);
    const common = useContext(commonContext)
    const storeSelectConsts = useSelector(selectConsts)
    const { id } = useParams();
    let componentRef = useRef();
    const [avatar, setAvatar] = React.useState("")
    const [empName, setEmpName] = React.useState("")
    const [empNumber, setEmpNumber] = React.useState("")
    const [fatherName, setFatherName] = React.useState("")
    const [designation, setDesignation] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [telePhone, setTelephone] = React.useState("")
    const [jobRole, setJobRole] = React.useState({ value: "", label: "All" });
    const [fatchedEmployees, setFatchedEmployees] = React.useState([])
    const [filteredList, setFilteredList] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState({ value: "", label: "All" })
    const [userId, setUserId] = React.useState('')
    const allEmployeesCount = id || userId ? 1 : filteredList.length;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: '5 rows' });
    const [employeesPerPage, setEmployeesPerPage] = React.useState(5);
    const lastEmployeeNumber = currentPage * employeesPerPage;
    const firstEmployeeIndex = lastEmployeeNumber - employeesPerPage;
    const limitedEmployees = filteredList.slice(firstEmployeeIndex, lastEmployeeNumber);
    const [parentHeight, setParentHeight] = React.useState('auto');
    const [loading, setLoading] = React.useState(true)
    const parentRef = useRef(null);
    const [scale, setScale] = React.useState(1);
    const parentRef2 = useRef(null);
    const [topPositionFront, setTopPositionFront] = React.useState(0);
    const [topPositionBack, setTopPositionBack] = React.useState(0);
    const cardRef = useRef(null);
    const cardRefBack = useRef(null);
    const [screenSize, setScreenSize] = useState(window.innerWidth)

    const fetchData = async () => {
        if (id || userId) {
            try {
                const response = await Promise.all([
                    customAxios.post('/employee/getOne/', { idcard_number: id || userId }),
                    customAxios.post('/common/getAvatarImg', { idcard_number: id || userId }),
                ]);
                const [res, img] = response;
                setAvatar(img?.data?.base64data)
                setEmpName(res?.data?.employee?.employee_name)
                setFatherName(res?.data?.employee?.father_name)
                setEmpNumber(res?.data?.employee?.idcard_number)
                setDesignation(res?.data?.employee?.employee_jobrole?.job_role)
                setAddress(res?.data?.employee?.permanent_address)
                setTelephone(res?.data?.employee?.contact_number)
            } catch (err) {
                console.log(err)
            }
        }
    }
    React.useEffect(() => {
        if (id || userId) {
            fetchData()
        }
    }, [id, userId])
    React.useEffect(() => {
        customAxios
            .get('/employee/getAllWithAvatar')
            .then((res) => {
                // const reversed = res.data.reverse();
                setFatchedEmployees(res?.data?.employees);
                setFilteredList(res?.data?.employees);
                //  setEmployeeJobTypes(res.data.jobTypes);
                setLoading(false)
            })
            .catch((err) => {
                // exception error handling
            });
    }, []);
    const onFilter = () => {
        setFilteredList(fatchedEmployees.filter((item) =>
            item.job_role.toString().includes(jobRole?.value ? jobRole?.value : '')
        ));
    }
    React.useEffect(() => {
        onFilter();
        // }, [employeeName, phoneNumber, idcardNumber, cnicNumber, admissionStatus, gender,]);
    }, [jobRole]);
    React.useEffect(() => {
        const calculateParentHeight = () => {
            const childCards = parentRef.current.querySelectorAll('.employee-card');
            let maxHeight = 0;
            childCards.forEach(card => {
                const cardHeight = card.getBoundingClientRect().height;
                maxHeight += cardHeight + 10
            });
            setParentHeight(`${maxHeight + 100}`)
            setScreenSize(window.innerWidth);
        };
        calculateParentHeight();
        window.addEventListener('resize', calculateParentHeight);
        return () => window.removeEventListener('resize', calculateParentHeight);
    });
    React.useEffect(() => {
        // Function to calculate and set the top position
        const calculateTopPosition = () => {
            if (cardRef.current) {
                const cardHeight = cardRef.current.getBoundingClientRect().height;
                setTopPositionFront(cardHeight + 10);
            }
            if (cardRefBack.current) {
                const cardHeight = cardRefBack.current.getBoundingClientRect().height;
                setTopPositionBack(2 * (cardHeight + 10));
                setScreenSize(window.innerWidth)
            }
        };
        window.addEventListener('resize', calculateTopPosition);
        calculateTopPosition();
        return () => window.removeEventListener('resize', calculateTopPosition);
    });
    return (
        <>
            <Container fluid>
                <Card className="stacked-form">
                    <Card.Header>
                        <Card.Title as="h4">{t("employeeIdCardLabel")}</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width" ref={parentRef2}>
                        <Row>
                            <Col className="pl-md-3 pr-md-1" md="6">
                                <Form.Group className="has-success">
                                    <label>{t("jobroleLabel")}</label>
                                    <Select
                                        className={"react-select primary " + (id ? "disabled" : "")}
                                        classNamePrefix='react-select'
                                        name='jobrole'
                                        value={jobRole}
                                        onChange={(optionSelected) =>
                                            setJobRole({
                                                value: optionSelected.value,
                                                label: optionSelected.label,
                                            })
                                        }
                                        options={[
                                            { value: '', label: 'All' },
                                            ...storeSelectConsts.JOB_ROLES,
                                        ]}
                                        placeholder={t('singleSelectPlaceholder')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="pr-md-3 pr-md-1" md="6">
                                <Form.Group className="has-success">
                                    <label>{t("employeesLabel")}</label>
                                    <Select
                                        className={"react-select primary " + (id ? "disabled" : "")}
                                        classNamePrefix="react-select"
                                        name="requiredClass"
                                        value={selectedEmployee}
                                        onChange={(optionSelected) => {
                                            if (optionSelected.label === 'All') {
                                                setSelectedEmployee(optionSelected)
                                                setUserId('')
                                            } else {
                                                setSelectedEmployee({ value: optionSelected.value, label: optionSelected.label })
                                                setUserId(optionSelected?.value)
                                            }
                                        }
                                        }
                                        options={[{ value: '-1', label: "All" }, ...filteredList.map((employee) => {
                                            return { value: employee?.idcard_number, label: employee?.employee_name }
                                        })]
                                        }
                                        placeholder={t("singleSelectPlaceholder")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end" style={{ marginBottom: '15px' }}>
                            <Pagination
                                itemsCount={allEmployeesCount}
                                itemsPerPage={employeesPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                alwaysShown={false}
                            />
                            <div style={{ width: 155 }}>
                                <Select
                                    className="react-select primary ml-4"
                                    classNamePrefix="react-select"
                                    style={{ width: `100px` }}
                                    name="rowCnt"
                                    value={pageRowsCnt}
                                    onChange={(optionSelected) => {
                                        setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                                        setEmployeesPerPage(optionSelected.value);
                                    }}
                                    options={[
                                        { value: 3, label: "3 " + t("rows") },
                                        { value: 5, label: "5 " + t("rows") },
                                        { value: 10, label: "10 " + t("rows") },
                                        { value: 15, label: "15 " + t("rows") },
                                        { value: 20, label: "20 " + t("rows") },
                                        { value: 25, label: "25 " + t("rows") },
                                    ]}
                                    placeholder={t("singleSelectPlaceholder")}
                                />
                            </div>
                        </div>
                        <Card className="stacked-form" style={{ padding: '10px 4px' }} ref={(el) => (componentRef = el)}>
                            <Card.Body className="table-full-width position-relative card-con" style={{ height: screenSize <= 570 ? `${parentHeight * 2}px` : `${parentHeight}px` }}
                                ref={parentRef}>
                                {limitedEmployees.length > 0 && !id && !userId ?
                                    limitedEmployees && limitedEmployees.map((employee, index) => (
                                        <Row style={{
                                            justifyContent: 'center',
                                            top: `${(topPositionFront * index)}px`,
                                        }}
                                            className='card-row'>
                                            <Col className='d-flex justify-content-center pr-1 pl-1'>
                                                <Card className='card-main box employee-card'
                                                    ref={cardRef}
                                                    style={{
                                                        top: `${(topPositionFront * index)}px`,
                                                        margin: 'auto',
                                                    }} >
                                                    <Card.Body style={{ paddingBottom: "0px", position: 'relative', zIndex: '1' }} >
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '10px',
                                                            right: '30px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                            <img src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')} className='uni-logo'></img>
                                                        </div>
                                                        <div className='px-3'>
                                                            <div className='d-flex align-items-center mt-2 card-emp-name'>
                                                                مرحلة جاميا فاروقوا كراتشي ||
                                                            </div>
                                                            <div className='card-uni-name mt-sm-2 card-uni-name' style={{ color: '#fff' }}  >
                                                                {common.name}
                                                            </div>
                                                            <div style={{ fontSize: '0.7rem', fontWeight: 'bold', marginTop: '2px' }} className='address'>Hub River Road,Kemari town,Karachi</div>
                                                            <Row className='justify-content-between'>
                                                                <div className='mt-3 col-8  student-detail '>
                                                                    <div>Name:<span className='ml-3'>{employee?.employee_name}</span></div>
                                                                    <div>F/Name:<span className='ml-3'>{employee?.father_name}</span></div>
                                                                    <div>Employee No:<span className='ml-3'>{employee?.idcard_number}</span></div>
                                                                    <div>Designation:<span className='ml-3'>{employee?.employee_jobrole?.job_role}</span></div>
                                                                </div>
                                                                <div className='d-flex align-items-center flex-column py-2 px-2 position-relative col-3 mr-2'>
                                                                    <span className='emp-card-title'>Employee card</span>
                                                                    <div style={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                                                                        <img src={employee?.avatar ? `data: image / png; base64, ${employee?.avatar} ` : require('../assets/img/default-avatar')} style={{ height: '100%', width: '100%', borderRadius: '8px' }}  ></img>                                        </div>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                        <h6 className='d-flex align-items-center justify-content-center valid-date'
                                                        >Valid date : 20/12/2025</h6>
                                                    </Card.Body>
                                                    <div className='student-background' style={{ zIndex: '0' }}>
                                                        <img
                                                            src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                            style={{ height: '50%', borderRadius: '50%' }}></img>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='d-flex justify-content-center pr-1 pl-1'>
                                                <Card className='card-main box employee-card-back'
                                                    ref={cardRefBack}
                                                    style={{
                                                        top: screenSize <= 570 ? `${(topPositionFront * (index + 1))}px` : `${(topPositionFront * index)}px`,
                                                        margin: 'auto',
                                                    }}  >
                                                    <Card.Body style={{ paddingBottom: "0px", zIndex: '1' }}>
                                                        <div className='mt-4  student-detail px-3 '>
                                                            <div className='stcard-back-font'>Res Address:<span className='ml-3' >{employee?.permanent_address}</span></div>
                                                            <div className='stcard-back-font'>TelePhone No:<span className='ml-3' >{employee?.phone}</span></div>
                                                            {/* <div className='stcard-back-font'>Reg No:<span className='ml-3'>njfhfu</span></div>
                                    <div className='stcard-back-font'>Serial No:<span className='ml-3'>njfhfu</span></div> */}
                                                        </div>
                                                        <div className='d-flex flex-row px-3 py-2 justify-content-between'>
                                                            <div style={{ fontWeight: '570' }} className='stcard-back-font' >In Case of Emergency Please Inform:</div>
                                                            <div className='stcard-back-font'>
                                                                <div>Muhammad Saleem </div>
                                                                <div>0333-3326195</div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row px-3  py-2 justify-content-between'>
                                                            <div style={{ fontWeight: '570', whiteSpace: 'nowrap' }} className='stcard-back-font' >National I.D Card Number:</div>
                                                            <div>
                                                                <table border="1" className='stcard-back-font'>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='note px-3 py-1 mt-3 '>
                                                            Note:This card is the property of Jamia Faruquee University Phase ||.
                                                            <div>if  found please return it to Jamia Faruquee University Phase ||.</div>
                                                            <div>Hub River,Kemari Town,Karachi,Pakistan.Tel:0333-3460114</div>
                                                        </div>
                                                    </Card.Body>
                                                    <div className='student-background' style={{ zIndex: '0' }} >
                                                        <img
                                                            src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                            style={{ height: '50%', borderRadius: '50%' }}></img>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )) :
                                    <Row style={{ justifyContent: 'center', top: `${(topPositionFront * 0)}px` }} className='card-row' >
                                        <Col className='d-flex justify-content-center pr-1 pl-1'>
                                            <Card className='card-main box employee-card'
                                                ref={cardRef}
                                                style={{
                                                    top: `${(topPositionFront * 0)}px`,
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card.Body style={{ paddingBottom: "0px", position: 'relative', zIndex: '1' }} >
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: '10px',
                                                        right: '30px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}>
                                                        <img src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')} className='uni-logo'></img>
                                                    </div>
                                                    <div className='px-sm-3 px-2'>
                                                        <div className='d-flex align-items-center mt-2 card-emp-name'>
                                                            مرحلة جاميا فاروقوا كراتشي ||
                                                        </div>
                                                        <div className='card-uni-name mt-sm-2 card-uni-name' style={{ color: '#fff' }}  >
                                                            {common.name}
                                                        </div>
                                                        <div style={{ fontSize: '0.7rem', fontWeight: 'bold', marginTop: '2px' }} className='address'>Hub River Road,Kemari town,Karachi</div>
                                                        <Row className='justify-content-between'>
                                                            <div className='mt-2 mt-sm-3 col-8  student-detail '>
                                                                <div>Name:<span className='ml-3'>{empName}</span></div>
                                                                <div>F/Name:<span className='ml-3'>{fatherName}</span></div>
                                                                <div>Employee No:<span className='ml-3'>{empNumber}</span></div>
                                                                <div>Designation:<span className='ml-3'>{designation}</span></div>
                                                            </div>
                                                            <div className='d-flex align-items-center flex-column py-2 px-2 position-relative col-3 mr-2'>
                                                                <span className='emp-card-title'>Employee card</span>
                                                                <div style={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                                                                    <img src={avatar ? `data: image / png; base64, ${avatar} ` : require('../assets/img/default-avatar')} style={{ height: '100%', width: '100%', borderRadius: '8px' }}  ></img>                                        </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <h6 className='d-flex align-items-center justify-content-center valid-date'
                                                    >Valid date : 20/12/2025</h6>
                                                </Card.Body>
                                                <div className='student-background' style={{ zIndex: '0' }}>
                                                    <img
                                                        src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                        style={{ height: '50%', borderRadius: '50%' }}></img>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='d-flex justify-content-center pr-1 pl-1'>
                                            <Card className='card-main box employee-card-back'
                                                ref={cardRefBack}
                                                style={{
                                                    top: screenSize <= 570 ? `${(topPositionFront * 1)}px` : `${(topPositionFront * 0)}px`,
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card.Body style={{ paddingBottom: "0px", zIndex: '1' }}>
                                                    <div className='mt-sm-4 mt-3 student-detail px-sm-3 px-2 py-sm-1'>
                                                        <div className='stcard-back-font'>Res Address:<span className='ml-3' >{address}</span></div>
                                                        <div className='stcard-back-font'>TelePhone No:<span className='ml-3' >{telePhone}</span></div>
                                                    </div>
                                                    <div className='d-flex flex-row px-sm-3 px-2 py-sm-2 justify-content-between'>
                                                        <div style={{ fontWeight: '570' }} className='stcard-back-font' >In Case of Emergency Please Inform:</div>
                                                        <div className='stcard-back-font'>
                                                            <div>Muhammad Saleem </div>
                                                            <div>0333-3326195</div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-row px-sm-3 px-2 py-2 justify-content-between'>
                                                        <div style={{ fontWeight: '570', whiteSpace: 'nowrap' }} className='stcard-back-font' >National I.D Card Number:</div>
                                                        <div>
                                                            <table border="1" className='stcard-back-font'>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='note px-sm-3 px-2 py-1 mt-sm-3 mt-2'>
                                                        Note:This card is the property of Jamia Faruquee University Phase ||.
                                                        <div>if  found please return it to Jamia Faruquee University Phase ||.</div>
                                                        <div>Hub River,Kemari Town,Karachi,Pakistan.Tel:0333-3460114</div>
                                                    </div>
                                                </Card.Body>
                                                <div className='student-background' style={{ zIndex: '0' }} >
                                                    <img
                                                        src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                        style={{ height: '50%', borderRadius: '50%' }}></img>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>

                                }
                                {/* <Col xl="6" sm="6">
                        <Card className='card-main box' style={{ width: "100%", height: '250px' }}>
                            <Card.Body>
                                jhgjfgh
                            </Card.Body>
                        </Card>
                    </Col> */}
                            </Card.Body>
                        </Card>
                        <div className='d-flex align-items-center justify-content-center'>
                            <ReactToPrint
                                trigger={() =>
                                    <Button className="btn-edit btn-fill" type="submit" variant="info">
                                        <span className="btn-label">
                                            <i className="fas fa-print"></i>
                                        </span>
                                        &nbsp;{t("btnPrint")}
                                    </Button>
                                }
                                content={() => componentRef}
                            />
                        </div>
                    </Card.Body >
                </Card >
            </Container >
        </>
    )
}
export default EmployeeCardsPage