import customAxios from "lib/axios";
import { isRequired } from "lib/validation";
import React, { useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { addClassName, changeClass, removeClass, selectConsts, toggleActiveClass, useForceUpdate } from '../store/selectconstReducer';
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from "react-bootstrap";
import { commonContext } from '../context/common'
function Class() {
  const [t] = useTranslation(["classes"]);
  const common = useContext(commonContext)
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [classId, setClassId] = React.useState("");
  const [className, setClassName] = React.useState("");
  const [departmentName, setDepartmentName] = React.useState("");
  const [classType, setClassType] = React.useState("");
  const [departmentNameState, setDepartmentNameState] = React.useState(true);
  const [classNameState, setClassNameState] = React.useState(true);
  const [classTypeState, setClassTypeState] = React.useState(true);
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: "5 " + t("rows") });
  const allClassesCount = storeSelectConsts.CLASS_NAMES.length;
  const [classesPerPage, setClassesPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const lastClassNumber = currentPage * classesPerPage;
  const firstClassIndex = lastClassNumber - classesPerPage;
  const limitedClasses = storeSelectConsts.CLASS_NAMES.slice(
    firstClassIndex,
    lastClassNumber
  );
  const [modal, setModal] = React.useState(false);
  const warningWithConfirmMessage = (curClassName) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirmDelete(curClassId)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {t("modalWarnningMsgText")}
      </SweetAlert>
    );
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const infoAlert = (msg) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={t("infoAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {t("infoAlertMsg")}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (curClassId, curClassName) => {
    customAxios.post("/class/deleteOne/", {
      class_id: curClassId
    })
      .then((res) => {
        if (res.data.msg == "success") {
          successAlert(t("submitRemoveSuccessAlertMsg.first") + " " + curClassName + " " + t("submitRemoveSuccessAlertMsg.second"));
          // dispatch deleting event to the redux store
          dispatch(removeClass(curClassId));
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
  }
  const onConfirmToggleActive = (curClassId, isActive) => {
    customAxios.post("/class/toggleActive/", {
      curClassId: curClassId, isActive: isActive
    }).then((res) => {
      if (res?.data?.dptActive === false) {
        infoAlert()
      } else {
        if (res.data.msg == "success") {
          dispatch(toggleActiveClass({ curClassId, isActive }))
        }
      }
    }).catch((err) => {
      // Work with the err
      notify("tr", t("notifyConnectionProblem"));
      console.log(err)
    });
  }
  const onUpsert = () => {
    // validation check
    let allValid = true;
    if (!classNameState || !isRequired(className)) {
      setClassNameState(false);
      allValid = false;
    }
    if (!departmentNameState || !isRequired(departmentName)) {
      setDepartmentNameState(false);
      allValid = false;
    }
    if (!classTypeState || !isRequired(classType)) {
      setClassTypeState(false);
      allValid = false;
    }
    if (!allValid) {
      notify("tr", t("validationNotify"));
      return;
    }
    if (classId) {
      customAxios.post("/class/update", {
        id: classId,
        class_name: className,
        department_id: departmentName.value,
        class_type: classType.value,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(t("submiUpdateSuccessAlertMsg.first") + " " + className + " " + t("submiUpdateSuccessAlertMsg.second"));
            dispatch(changeClass({ value: classId, label: className, department_id: departmentName.value, class_type: classType }));
            // forceUpdate();
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    else {
      customAxios.post("/class/create", {
        class_name: className,
        department_id: departmentName.value,
        class_type: classType.value,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(t("submitAddSuccessAlertMsg.first") + " " + className + " " + t("submitAddSuccessAlertMsg.second"));
            dispatch(addClassName(res?.data?.created))
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    setClassName("");
    setClassType("");
    setDepartmentName("");
    setClassNameState(true);
    setClassTypeState(true);
    setDepartmentNameState(true);
    setClassId("");
    setModal(!modal);
  }
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("title")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width">
                <Button
                  className="btn-wd btn-outline mb-3"
                  type="button"
                  onClick={() => setModal(!modal)}
                  variant="success"
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  &nbsp;{t("addClass")}
                </Button>
                <div className="d-flex justify-content-end align-center">
                  <Pagination
                    itemsCount={allClassesCount}
                    itemsPerPage={classesPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
                  <div style={{ width: 155 }}>
                    <Select
                      className="react-select primary ml-4"
                      classNamePrefix="react-select"
                      style={{ width: `100px` }}
                      name="rowCnt"
                      value={pageRowsCnt}
                      onChange={(optionSelected) => {
                        setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                        setClassesPerPage(optionSelected.value);
                      }}
                      options={[
                        { value: 5, label: "5 " + t("rows") },
                        { value: 10, label: "10 " + t("rows") },
                        { value: 15, label: "15 " + t("rows") },
                        { value: 20, label: "20 " + t("rows") },
                        { value: 25, label: "25 " + t("rows") },
                      ]}
                      placeholder={t("singleSelect")}
                    />
                  </div>
                </div>
                {/* Mini Modal */}
                <Modal
                  className="modal-primary"
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => { setClassId(""); setModal(!modal) }}
                  show={modal}
                >
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title className="text-center" as="h5">{t("addClass")}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (departmentNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("department")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="department"
                              value={departmentName}
                              onChange={(optionSelected) => {
                                setDepartmentName({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setDepartmentNameState(true);
                                } else {
                                  setDepartmentNameState(false);
                                }
                              }}
                              options={storeSelectConsts.DEPARTMENT_NAMES.filter((dpt) => dpt?.isActive === true)}
                              placeholder={t("singleSelect")}
                            />
                            {departmentNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                        <Col className="pl-1 pr-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (classNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("className")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={className}
                              type="text"
                              onChange={(e) => {
                                setClassName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setClassNameState(true);
                                } else {
                                  setClassNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {classNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="4">
                          <Form.Group
                            className={
                              "has-label " +
                              (classTypeState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("classType")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="classType"
                              value={classType}
                              onChange={(optionSelected) => {
                                setClassType({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setClassTypeState(true);
                                } else {
                                  setClassTypeState(false);
                                }
                              }}
                              options={[
                                { value: "Federal", label: "Federal" },
                                { value: "Local", label: "Local" },
                              ]}
                              placeholder={t("singleSelect")}
                            />
                            {classTypeState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        onUpsert();
                      }}
                      variant="link"
                    >
                      {t("btnSave")}
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        setModal(!modal);
                        setClassId("");
                        setClassName("");
                        setClassType("");
                        setClassNameState(true);
                        setClassTypeState(true);
                        setDepartmentName("");
                        setDepartmentNameState(true)
                      }}
                      variant="link"
                    >
                      {t("btnClose")}
                    </Button>
                  </div>
                </Modal>
                {/* End Modal */}
                <Card.Body className="overflow-auto">
                  {!common?.loading ? (<>
                    {limitedClasses?.length > 0 ? (<Table>
                      <thead>
                        <tr>
                          <th className="text-left" style={{ paddingLeft: '8px' }}>#</th>
                          <th>{t("className")}</th>
                          <th className="text-center">{t("department")}</th>
                          <th className="text-center">{t("classType")}</th>
                          <th className="text-right">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {limitedClasses?.map((item, index) => (
                          <tr key={index}>
                            <td className={"text-left " + (!item?.isActive ? 'disabled' : "")} style={{ paddingLeft: '8px' }}>{firstClassIndex + index + 1}</td>
                            <td className={!item?.isActive ? 'disabled' : ""}>
                              {item?.label ? item?.label : ""}
                            </td>
                            <td className={"text-center " + (!item?.isActive ? 'disabled' : "")} >{storeSelectConsts?.DEPARTMENT_NAMES.filter((i) => i?.value == item?.department_id)[0]?.label}</td>
                            <td className={"text-center " + (!item?.isActive ? 'disabled' : "")}>{item?.class_type}</td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-981231696">
                                    {t("btnEdit")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  onClick={(e) => {
                                    setClassId(item?.value);
                                    setClassName(item?.label);
                                    setClassType({ value: item?.class_type, label: item?.class_type });
                                    setDepartmentName({ value: item?.department_id, label: storeSelectConsts?.DEPARTMENT_NAMES.filter((i) => i?.value == item?.department_id)[0]?.label });
                                    setModal(!modal);
                                  }}
                                  variant="success"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-981231696">
                                    {t("btnRemove")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  onClick={(e) => {
                                    onConfirmDelete(item?.value, item?.label)
                                  }}
                                  variant="danger"
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </OverlayTrigger>
                              <input
                                className="css-checkbox"
                                defaultValue={true}
                                type="checkbox"
                                checked={item?.isActive}
                                onChange={(e) => {
                                  // setIsDisactive((prevState) => ({ ...prevState, [item.label]: !prevState[item.label] }))
                                  onConfirmToggleActive(item?.value, !item.isActive)
                                }}
                                style={{ marginLeft: '4px', top: '5px' }}
                              ></input>
                            </td>
                          </tr>
                        ))
                        }
                      </tbody>
                    </Table>) : (
                      <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noClassFound")}</div>
                    )}</>) : (
                    <div className="d-flex align-items-center justify-content-center my-5">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  )}
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Class;
