import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// Translations
import authAr from "translations/ar/auth.json";
import booksAr from "translations/ar/books.json";
import classesAr from "translations/ar/classes.json";
import dashboardAr from "translations/ar/dashboard.json";
import departmentsAr from "translations/ar/departments.json";
import employeesAr from "translations/ar/employee.json";
import examAr from "translations/ar/exam.json";
import sidebarAr from "translations/ar/sidebar.json";
import studentsAr from "translations/ar/students.json";
import authEn from "translations/en/auth.json";
import booksEn from "translations/en/books.json";
import classesEn from "translations/en/classes.json";
import dashboardEn from "translations/en/dashboard.json";
import departmentsEn from "translations/en/departments.json";
import employeesEn from "translations/en/employee.json";
import examEn from "translations/en/exam.json";
import sidebarEn from "translations/en/sidebar.json";
import studentsEn from "translations/en/students.json";

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    supportedLngs: ["en", "ar"],
    fallbackLng: "ar",
    resources: {
      ar: {
        dashboard: dashboardAr,
        sidebar: sidebarAr,
        students: studentsAr,
        employees: employeesAr,
        departments: departmentsAr,
        classes: classesAr,
        books: booksAr,
        exam: examAr,
        auth: authAr,
      },
      en: {
        dashboard: dashboardEn,
        sidebar: sidebarEn,
        students: studentsEn,
        employees: employeesEn,
        departments: departmentsEn,
        classes: classesEn,
        books: booksEn,
        exam: examEn,
        auth: authEn,
      },
    },
  });
