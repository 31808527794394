import { configureStore } from "@reduxjs/toolkit";
// Reducers
import setAuthToken from "../lib/setAuthToken";
import authReducer from "./authReducer";
import selectconstReducer from "./selectconstReducer";
import gradeReducer from "./gradeReducer";

const store = configureStore({
  reducer: {
    selectconstsReducer: selectconstReducer,
    authReducer: authReducer,
    gradeReducer: gradeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable non-serializable value in state error
    })
});

// set up a store subscription listener
// to store the users token in localStorage
// initialize current state from redux store for subscription comparison
// preventing undefined error
let currentState = store.getState();

store.subscribe(() => {
  // keep track of the previous and current state to compare changes
  let previousState = currentState;
  currentState = store.getState();
  // if the token changes set the value in localStorage and axios headers
  if (previousState.authReducer.token !== currentState.authReducer.token) {
    const token = currentState.authReducer.token;
    setAuthToken(token);
  }
});

export default store;
