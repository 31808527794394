import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
function PrintMaterial() {
  let componentRef = useRef();
  const [t] = useTranslation(["students"]);
  return (
    <>
      <Container fluid>
        <Row ref={(el) => (componentRef = el)}>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("addeditTitle")}</Card.Title>
                <Card.Title as="h6" className="mt-3">{t("idcardNumber")} : <br />{t("theYearOfJoining")} : </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action="" className="form" method="">
                  <Card>
                    {/* <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">Edit Profile</Card.Title>
                      </Card.Header>
                    </Card.Header> */}
                    <Card.Body className="pl-2 pr-2">
                      {/* <Row>
                      <div className="author mb-3">
                        <img
                          alt="..."
                          className="avatar"
                          src={require("assets/img/default-avatar.png")}
                        ></img>
                      </div>
                      </Row> */}
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("studentNameLabel")}</label>
                            <Form.Control
                              // defaultValue="Mike"
                              // placeholder="Abdullah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("fatherNameLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("requiredClassLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("fatherProfessionLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("dateOfBirthLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("dependencyLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("admissionTypeLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("admissionStatusLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("boardTypeLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("genderLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Karachi"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success" >
                            <label>{t("cityLabel")}</label>
                            <Form.Control
                              // placeholder="City name"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("provinceLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Province name"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("countryLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Karachi"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("placeOfBirthLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Karachi"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("schoolAchievementLabel")}</label>
                            <Form.Control
                              // placeholder="Metric"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("prevexamGradeLabel")}</label>
                            <Form.Control
                              // placeholder="330"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("lastUniversityLabel")}</label>
                            <Form.Control
                              // placeholder="Darul Uloom Karachi"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("lastUniversityYearLabel")}</label>
                            <Form.Control
                              // placeholder="2023"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("tempAddressLabel")}</label>
                            <Form.Control
                              // placeholder="Karachi"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("permanentAddressLabel")}</label>
                            <Form.Control
                              // placeholder="house no 655 shamshi colony shah faisal karachi sindh pakistan"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("idcardNumberLabel")}</label>
                            <Form.Control
                              // placeholder="42401-88647551"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("firstContactNumberLabel")}</label>
                            <Form.Control
                              // placeholder="03212678963"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("secondContactNumberLabel")}</label>
                            <Form.Control
                              // placeholder="03004936748"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardiansNameLabel")}</label>
                            <Form.Control
                              // placeholder=""
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardiansProfessionLabel")}</label>
                            <Form.Control
                              // placeholder=""
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardianTypeLabel")}</label>
                            <Form.Control
                              // placeholder=""
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardianCuraddressLabel")}</label>
                            <Form.Control
                              // placeholder="islamabad"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardianPhone1Label")}</label>
                            <Form.Control
                              // placeholder="0321-6365956"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("guardianPhone2Label")}</label>
                            <Form.Control
                              // placeholder="0300-5869574"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("termAndCondition")}</label>
                            <Form.Control
                              // placeholder="its was good"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("supervisor")}</label>
                            <Form.Control
                              // placeholder="0300-5869574"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("headOfEducation")}</label>
                            <Form.Control
                              // placeholder="its was good"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mt-n2 ml-0 mr-0">
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("administrator")}</label>
                            <Form.Control
                              // placeholder="Signature"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ReactToPrint
          trigger={() =>
            <Button className="btn-edit btn-fill" type="submit" variant="info">
              <span className="btn-label">
                <i className="fas fa-print"></i>
              </span>
              &nbsp;{t("Print")}
            </Button>
          }
          content={() => componentRef}
        />
      </Container>
    </>
  );
}

export default PrintMaterial;
