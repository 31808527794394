import React, { useRef, useContext } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { selectConsts, setAll } from '../store/selectconstReducer';
import { resultGrade } from '../store/gradeReducer'
import customAxios from "lib/axios";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { commonContext } from '../context/common'
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
    Table,
    Spinner
} from "react-bootstrap";
function PositionHolders() {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true)
    const [t] = useTranslation(["exam"]);
    const language = useContext(commonContext)
    let componentRef = useRef();
    const storeSelectConsts = useSelector(selectConsts);
    const storeResultGrade = useSelector(resultGrade)
    const { examname_id } = useParams();
    React.useEffect(() => {
        customAxios.get(`/common/consts/${language.lang}`)
            .then((res) => {
                dispatch(setAll(res.data));
            })
            .catch((err) => {
                // Work with the err
                // alert(err)
            });
    }, [language.lang])
    const [examName, setExamName] = React.useState(examname_id ? storeSelectConsts.EXAM_NAMES.find((item) => item.value == examname_id) : storeSelectConsts.EXAM_NAMES[0]);
    const [topStudents, setTopStudents] = React.useState([]);
    const [selectedColumns, setSelectedColumns] = React.useState({
        studentName: false,
        fatherName: false,
        className: false,
        seatNumber: false,
        idcardNumber: false,
    });
    React.useEffect(() => {
        // submit form values
        // setLoading(true)
        customAxios.post("/exam/getRankHolderList", {
            exam_nameid: examName ? examName?.value : storeSelectConsts.EXAM_NAMES[0]?.value,
        }).then((res) => {
            setTopStudents(res?.data?.topStudents);
            setSelectedColumns({
                studentName: false,
                fatherName: false,
                className: false,
                seatNumber: false,
                idcardNumber: false
            })
            setLoading(false)
        })
            .catch((err) => {
            });
    }, [storeSelectConsts, examName])
    const renderClassCard = (classData, classKey, t, selectedColumns) => {
        return (
            classData[0].classActive ?
                <Card className="stacked-form" key={classKey}>
                    <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Card.Title>{classData[0]?.className}</Card.Title>
                    </Card.Header>
                    <Card.Body className="overflow-auto table-full-width">
                        <Table ref={componentRef}>
                            <thead id="demo">
                                <tr>
                                    <th className="text-center" style={{ paddingLeft: '8px' }}>{t("rank")}</th>
                                    {Object.keys(selectedColumns)?.map((col, index2) => (
                                        <th key={index2} className="text-center">{t(`${col}`)}</th>
                                    ))}
                                    <th className="text-center">{t("total")}</th>
                                    <th className="text-center">{t("grade")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {classData?.map((stu, index1) => (
                                    <tr key={index1}>
                                        <td className="text-center" style={{ paddingLeft: '8px' }}>{stu.rank}</td>
                                        <td className="text-center">{stu.student_name}</td>
                                        <td className="text-center">{stu.father_name}</td>
                                        <td className="text-center">{stu.className}</td>
                                        <td className="text-center">{stu.seat_number}</td>
                                        <td className="text-center">{stu.idcard_number}</td>
                                        <td className="text-center">{stu.total}</td>
                                        <td className="text-center">{
                                            language.lang === 'en' ?
                                                // storeResultGrade.GRADE_NAMES.filter(grade => grade.id === stu.grade.id)
                                                //     .map(grade => grade.grade_name)
                                                stu.grade.grade_name
                                                :
                                                storeResultGrade.GRADE_NAMESAR.filter(grade => grade.id === stu.grade.id)
                                                    .map(grade => grade?.grade_name)
                                        }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card> : ""
        );
    };
    const renderAllClassCards = (topStudents, t, selectedColumns) => {
        return (
            <>
                {Object.keys(topStudents)?.map((classKey, index) => {
                    const classData = topStudents[classKey]
                    return renderClassCard(classData, classKey, t, selectedColumns);
                })}
            </>
        );
    };
    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <div ref={componentRef}>
                        <Card className="stacked-form">
                            <Row ref={(el) => (componentRef = el)} style={{ padding: '10px', display: 'contents' }}>
                                <Card.Header>
                                    <Card.Title as="h4">{t("positionHolders")}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="no-print">
                                        <Col className="p1-1" md="4">
                                            <Form.Group>
                                                <label>{t("examName")}</label>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="examName"
                                                    value={examName ? examName : storeSelectConsts.EXAM_NAMES[0]}
                                                    onChange={(value) => {
                                                        setExamName(value)
                                                    }}
                                                    options={storeSelectConsts?.EXAM_NAMES}
                                                    placeholder={t("singleSelect")}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="print">
                                        <label>{examName ? examName?.label : storeSelectConsts.EXAM_NAMES[0]?.label}</label>
                                    </Row>
                                    {console.log(loading, ">>>>", language?.gradeLoading, ">>>>eee")}
                                    {loading || language?.gradeLoading ?
                                        <div className="d-flex align-items-center justify-content-center my-5">
                                            <Spinner animation="border" variant="warning" />
                                        </div>
                                        :
                                        topStudents && Object.keys(topStudents)?.length !== 0 ? (
                                            renderAllClassCards(topStudents, t, selectedColumns)
                                        ) : (
                                            <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noPositionHoldersFound")}</div>
                                        )
                                    }
                                </Card.Body>
                            </Row>
                            {
                                topStudents && Object.keys(topStudents)?.length !== 0 ? (
                                    < Card.Footer className="d-flex justify-content-between">
                                        <ReactToPrint
                                            trigger={() => (
                                                <Button className="btn-edit btn-fill" type="submit" variant="info">
                                                    <span className="btn-label">
                                                        <i className="fas fa-print"></i>
                                                    </span>
                                                    &nbsp;{t("btnPrint")}
                                                </Button>
                                            )}
                                            content={() => componentRef}
                                        />
                                    </Card.Footer>
                                ) : null}
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container >
    );
}

export default PositionHolders;
