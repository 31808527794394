import customAxios from "lib/axios";
import { isRequired } from "lib/validation";
import React, { useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { addExamName, changeExamName, removeExamName, selectConsts } from '../store/selectconstReducer';
import { Spinner } from 'react-bootstrap';
import { commonContext } from '../context/common'

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from "react-bootstrap";

function ExamNameCreate() {
  const [t] = useTranslation(["exam"]);
  const common = useContext(commonContext)
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const dispatch = useDispatch();
  const [examNameId, setExamNameId] = React.useState("");
  const [examName, setExamName] = React.useState("");
  const [examDatetime, setExamDatetime] = React.useState("");
  const [examNameState, setExamNameState] = React.useState(true);
  const [examDatetimeState, setExamDatetimeState] = React.useState(true);
  const [modal, setModal] = React.useState(false);

  const warningWithConfirmMessage = (curExamId, curExamName) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirmDelete(curExamId, curExamName)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {t("modalWarnningMsgText")}
      </SweetAlert>
    );
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const onConfirmDelete = (curExamId, curExamName) => {
    customAxios.post("/exam/deleteExamName", {
      id: curExamId
    })
      .then((res) => {
        if (res.data.msg == "success") {
          successAlert(curExamName + t("submitRemoveSuccessAlertMsg.second"));
          dispatch(removeExamName(curExamId));
        }
      })
      .catch((err) => {
        // Work with the err
        notify("tr", t("notifyConnectionProblem"));
      });
  }

  const onUpsert = () => {
    // validation check
    let allValid = true;
    if (!examNameState || !isRequired(examName)) {
      setExamNameState(false);
      allValid = false;
    }
    if (!examDatetimeState || !isRequired(examDatetime)) {
      setExamDatetimeState(false);
      allValid = false;
    }

    if (!allValid) {
      notify("tr", t("validationNotify"));
      return;
    }

    if (examNameId) {
      customAxios.post("/exam/updateExamName", {
        id: examNameId,
        exam_name: examName,
        exam_datetime: examDatetime
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(examName + t("submiUpdateSuccessAlertMsg.second"));
            dispatch(changeExamName({ value: examNameId, label: examName, exam_datetime: examDatetime }));
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    else {
      customAxios.post("/exam/createExamName", {
        exam_name: examName,
        exam_datetime: examDatetime,
      })
        .then((res) => {
          if (res.data.msg == "success") {
            successAlert(examName + t("submitAddSuccessAlertMsg.second"));
            dispatch(addExamName({ value: res.data.created.id, label: res.data.created.exam_name, exam_datetime: res.data.created.exam_datetime }));
          }
        })
        .catch((err) => {
          // Work with the err
          notify("tr", t("notifyConnectionProblem"));
        });
    }
    setExamName("");
    setExamDatetime("");
    setExamNameState(true);
    setExamDatetimeState(true);
    setExamNameId("");
    setModal(!modal);
  }

  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("exams")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width">
                <Button
                  className="btn-wd btn-outline mt-4 mb-3"
                  type="button"
                  onClick={() => setModal(!modal)}
                  variant="success"
                >
                  <span className="btn-label">
                    <i className="fas fa-plus"></i>
                  </span>
                  &nbsp;{t("addExam")}
                </Button>
                {/* Mini Modal */}
                <Modal
                  className="modal-primary"
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={() => { setExamNameId(""); setModal(!modal) }}
                  show={modal}
                >
                  <Card className="stacked-form">
                    <Card.Header>
                      <Card.Title className="text-center" as="h5">{t("addExam")}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (examNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("examName")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder=""
                              value={examName}
                              type="text"
                              onChange={(e) => {
                                setExamName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setExamNameState(true);
                                } else {
                                  setExamNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {examNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (examDatetimeState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("date")}<span className="star">*</span></label>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: 'MM/DD/YYYY',
                              }}
                              timeFormat={false}
                              value={examDatetime}
                              onChange={(date) => {
                                setExamDatetime(date);
                                if (isRequired(date)) {
                                  setExamDatetimeState(true);
                                } else {
                                  setExamDatetimeState(false);
                                }
                              }}
                            ></ReactDatetime>
                            {examDatetimeState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className="modal-footer">
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        onUpsert();
                      }}
                      variant="link"
                    >
                      {t("btnSave")}
                    </Button>
                    <Button
                      className="btn-simple"
                      onClick={() => {
                        setModal(!modal);
                        setExamNameId("");
                        setExamName("");
                        setExamDatetime("");
                        setExamNameState(true);
                        setExamDatetimeState(true);
                      }}
                      variant="link"
                    >
                      {t("btnClose")}
                    </Button>
                  </div>
                </Modal>
                {/* End Modal */}
                <Card.Body className="overflow-auto">
                  {!common.loading ? (<> {storeSelectConsts.EXAM_NAMES.length > 0 ? (
                    <Table>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                          <th>{t("examName")}</th>
                          <th className="text-center">{t("date")}</th>
                          <th className="text-center">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          storeSelectConsts.EXAM_NAMES.map((item, index) =>
                            <tr key={index}>
                              <td className="text-center" style={{ paddingLeft: '8px' }}>{index + 1}</td>
                              <td>{item.label}</td>
                              <td className="text-center">{item.exam_datetime}</td>
                              <td className="td-actions text-center">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-981231696">
                                      {t("btnChange")}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="btn-link btn-xs"
                                    onClick={(e) => {
                                      setExamNameId(item.value);
                                      setExamName(item.label);
                                      setExamDatetime(item.exam_datetime);
                                      setModal(!modal);
                                    }}
                                    variant="success"
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-981231696">
                                      {t("btnRemove")}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="btn-link btn-xs"
                                    onClick={(e) => {
                                      onConfirmDelete(item.value, item.label)
                                    }}
                                    variant="danger"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  ) : (
                    <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noExamCreatedWarning")}</div>
                  )}</>) : (
                    <div className="d-flex align-items-center justify-content-center my-5">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  )}
                </Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  );
}

export default ExamNameCreate;
