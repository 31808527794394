import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Barcode from 'react-barcode';
// react plugin used to create datetimepicker
import SweetAlert from "react-bootstrap-sweetalert";
import ReactDatetime from "react-datetime";
import NotificationAlert from "react-notification-alert";
import { useSelector } from 'react-redux';
import ImgUpload from "../components/AvatarUpload";
import customAxios from "../lib/axios";
import { isRequired } from "../lib/validation";
import { selectConsts } from '../store/selectconstReducer';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Webcam from 'react-webcam';
import { commonContext } from '../context/common'
// react-bootstrap components
import { useRef } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";

function StudentsAddEdit() {
  const [t] = useTranslation(["students"]);
  const common = useContext(commonContext);
  const history = useHistory();
  const { id } = useParams();
  const storeSelectConsts = useSelector(selectConsts);
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const [studentType, setStudentType] = React.useState('')
  const [joinYear, setJoinYear] = React.useState(new Date().getFullYear());
  const [studentName, setStudentName] = React.useState("");
  const [fatherName, setFatherName] = React.useState("");
  const [requiredClass, setRequiredClass] = React.useState("");
  // const [requiredClass, setRequiredClass] = React.useState(storeSelectConsts.CLASS_NAMES[0]);
  const [fathersProfession, setFathersProfession] = React.useState("");
  const [birthDate, setBirthDate] = React.useState();
  const [dependency, setDependency] = React.useState();
  const [admissionType, setAdmissionType] = React.useState('');
  const defaultAdmissionStatus = common.lang === 'en' ? storeSelectConsts.ADMISSION_STATUSES[2] : storeSelectConsts.ADMISSION_STATUSESAR[2];
  const [admissionStatus, setAdmissionStatus] = React.useState(defaultAdmissionStatus);
  const [currentStatus, setCurrentStatus] = React.useState();
  const [boardType, setBoardType] = React.useState();
  const [gender, setGender] = React.useState();
  const [city, setCity] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [country, setCountry] = React.useState();
  const [birthPlace, setBirthPlace] = React.useState("");
  const [schoolAchievement, setSchoolAchievement] = React.useState("");
  const [prevexamGrade, setPrevexamGrade] = React.useState("");
  const [lastUniversity, setLastUniversity] = React.useState("");
  const [lastUniversityYear, setLastUniversityYear] = React.useState("");
  const [tempAddress, setTempAddress] = React.useState("");
  const [permanentAddress, setPermanentAddress] = React.useState("");
  const [idcardNumber, setIdcardNumber] = React.useState(id);
  const [cnicNumber, setCnicNumber] = React.useState("");
  const [firstContactNumber, setFirstContactNumber] = React.useState("");
  const [secondContactNumber, setSecondContactNumber] = React.useState("");
  const [guardiansName, setGuardiansName] = React.useState("");
  const [guardiansProfession, setGuardiansProfession] = React.useState("");
  const [guardianType, setGuardianType] = React.useState("");
  const [guardianCuraddress, setGuardianCuraddress] = React.useState("");
  const [guardianPhone1, setGuardianPhone1] = React.useState("");
  const [guardianPhone2, setGuardianPhone2] = React.useState("");
  const [termSign, setTermSign] = React.useState(false);
  const [supervisorSign, setSupervisorSign] = React.useState(false);
  const [eduheaderSign, setEduheaderSign] = React.useState(false);
  const [adminSign, setAdminSign] = React.useState(false);
  const [avatarFile, setAvatarFile] = React.useState(null);
  const [avatarPreviewBase64, setAvatarPreviewBase64] = React.useState(null);
  const [year, setYear] = React.useState({ label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`, value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}` })
  // Validations
  const [studentNameState, setStudentNameState] = React.useState(true);
  const [yearState, setYearState] = React.useState(true)
  const [fatherNameState, setFatherNameState] = React.useState(true);
  const [requiredClassState, setRequiredClassState] = React.useState(true);
  const [fathersProfessionState, setFathersProfessionState] = React.useState(true);
  const [birthDateState, setBirthDateState] = React.useState(true);
  const [dependencyState, setDependencyState] = React.useState(true);
  const [admissionTypeState, setAdmissionTypeState] = React.useState(true);
  const [admissionStatusState, setAdmissionStatusState] = React.useState(true);
  const [currentStatusState, setCurrentStatusState] = React.useState(true);
  const [boardTypeState, setBoardTypeState] = React.useState(true);
  const [genderState, setGenderState] = React.useState(true);
  const [cityState, setCityState] = React.useState(true);
  const [provinceState, setProvinceState] = React.useState(true);
  const [countryState, setCountryState] = React.useState(true);
  const [countryNameState, setCountryNameState] = React.useState(true);
  const [birthPlaceState, setBirthPlaceState] = React.useState(true);
  const [schoolAchievementState, setSchoolAchievementState] = React.useState(true);
  const [prevexamGradeState, setPrevexamGradeState] = React.useState(true);
  const [lastUniversityState, setLastUniversityState] = React.useState(true);
  const [lastUniversityYearState, setLastUniversityYearState] = React.useState(true);
  const [tempAddressState, setTempAddressState] = React.useState(true);
  const [permanentAddressState, setPermanentAddressState] = React.useState(true);
  const [idcardNumberState, setIdcardNumberState] = React.useState(true);
  const [cnicNumberState, setCnicNumberState] = React.useState(true);
  const [firstContactNumberState, setFirstContactNumberState] = React.useState(true);
  const [secondContactNumberState, setSecondContactNumberState] = React.useState(true);
  const [guardiansNameState, setGuardiansNameState] = React.useState(true);
  const [guardiansProfessionState, setGuardiansProfessionState] = React.useState(true);
  const [guardianTypeState, setGuardianTypeState] = React.useState(true);
  const [guardianCuraddressState, setGuardianCuraddressState] = React.useState(true);
  const [guardianPhone1State, setGuardianPhone1State] = React.useState(true);
  const [guardianPhone2State, setGuardianPhone2State] = React.useState(true);
  const [countryName, setCountryName] = React.useState("");
  const [isCountryName, setIsCountryName] = React.useState(false)
  const [isConditional, setIsConditional] = React.useState(false)
  const [studentNote, setStudentNote] = React.useState("");
  const [fatchedStudentsData, setFatchedStudentsData] = React.useState([])
  const [fatchedStudent, setFatchedStudent] = React.useState([])
  const [isMediumScreen, setIsMediumScreen] = React.useState(false);
  const [userId, setUserId] = React.useState(undefined);
  const currentYear = new Date().getFullYear();
  const arr = Array.from({ length: 12 }, (_, index) => `${currentYear - index}-${currentYear - index + 1}`)

  //handle arrow key events
  const handleKeyDown = (event) => {
    const focusableElements = document.querySelectorAll(
      'input, select, textarea, button, [tabindex]:not([tabindex="-1"],.react-select)'
    );
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      event.preventDefault(); // Prevent default arrow key behavior
      const activeElement = document.activeElement;
      const currentIndex = Array.from(focusableElements).indexOf(activeElement);
      if (currentIndex !== -1) {
        let nextIndex;
        if (event.key === 'ArrowRight') {
          nextIndex = (currentIndex + 1) % focusableElements.length; // Move to next element
        } else if (event.key === 'ArrowLeft') {
          nextIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length; // Move to previous element
        }
        let nextElement = focusableElements[nextIndex];
        while (nextElement && nextElement.getAttribute('type') === 'hidden') {
          // Skip hidden elements
          nextIndex = (nextIndex + 1) % focusableElements.length;
          nextElement = focusableElements[nextIndex];
        }
        while (nextElement && nextElement.getAttribute('disabled') === "") {
          nextIndex = (nextIndex + 1) % focusableElements.length;
          nextElement = focusableElements[nextIndex];
        }
        while (nextElement && nextElement.getAttribute('type') === 'file') {
          nextIndex = (nextIndex + 1) % focusableElements.length;
          nextElement = focusableElements[nextIndex];
        }
        // Attempt to focus on the next visible and focusable element
        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };
  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener('keydown', handleKeyDown);
    };
  });
  async function fetchData() {
    if (id || userId) {// id = idcardNumber
      try {
        const response = await Promise.all(
          [
            customAxios.post('/student/getOne', { idcard_number: id || userId }),
            customAxios.post('/common/getAvatarImg', { idcard_number: id || userId })
          ]
        )
        const [res, img] = response;
        setJoinYear(res?.data?.joinYear);
        setStudentName(res?.data?.student?.student_name);
        setFatherName(res?.data?.student?.father_name);
        setRequiredClass(storeSelectConsts?.CLASS_NAMES.find((cls) => cls.value === res.data.student.required_class));
        setFathersProfession(res?.data?.student?.fathers_profession);
        setBirthDate(new Date(res?.data?.student?.birth_date));

        common.lang === 'en' ? setDependency(storeSelectConsts?.DEPENDENCY_NAMES[res?.data?.student?.dependency - 1]) : setDependency(storeSelectConsts?.DEPENDENCY_NAMESAR[res?.data?.student?.dependency - 1])
        setAdmissionType(null);

        common.lang === 'en' ? setAdmissionType(storeSelectConsts?.STUDENT_ADMISSION_TYPES[1]) : setAdmissionType(storeSelectConsts?.STUDENT_ADMISSION_TYPESAR[1])

        common.lang === 'en' ? setAdmissionStatus(storeSelectConsts?.ADMISSION_STATUSES[res?.data?.student?.admission_status - 1]) : setAdmissionStatus(storeSelectConsts?.ADMISSION_STATUSESAR[res?.data?.student?.admission_status - 1])

        setYear({ value: res?.data?.student?.year, label: res?.data?.student?.year })

        common.lang === 'en' ? setCurrentStatus(storeSelectConsts?.STUDENT_CURRENT_STATUSES[res?.data?.student?.current_status - 1]) : setCurrentStatus(storeSelectConsts?.STUDENT_CURRENT_STATUSESAR[res?.data?.student?.current_status - 1])

        common.lang === 'en' ? setBoardType(storeSelectConsts?.BOARD_TYPES[res?.data?.student?.board_type - 1]) :
          setBoardType(storeSelectConsts?.BOARD_TYPESAR[res?.data?.student?.board_type - 1])

        common.lang === 'en' ? setGender(storeSelectConsts?.GENDER_NAMES[res?.data?.student?.gender - 1]) :
          setGender(storeSelectConsts?.GENDER_NAMESAR[res?.data?.student?.gender - 1]);
        setCity(res?.data?.student?.city);

        setProvince(res?.data?.student?.province);
        common.lang === 'en' ? setCountry(storeSelectConsts?.COUNTRY_NAMES[res?.data?.student?.country - 1]) :
          setCountry(storeSelectConsts?.COUNTRY_NAMESAR[res?.data?.student?.country - 1])
        setBirthPlace(res?.data?.student?.birth_place);
        setSchoolAchievement(res?.data?.student?.school_achievement);
        setPrevexamGrade(res?.data?.student?.prevexam_grade);
        setLastUniversity(res?.data?.student?.last_university);
        setLastUniversityYear(res?.data?.student?.last_univyear);
        setTempAddress(res?.data?.student?.temp_address);
        setPermanentAddress(res?.data?.student?.permanent_address);
        setIdcardNumber(res?.data?.student?.idcard_number);
        setCnicNumber(res?.data?.student?.cnic_number);
        setFirstContactNumber(res?.data?.student?.firstcontact_number);
        setSecondContactNumber(res?.data?.student?.secondcontact_number);
        setGuardiansName(res?.data?.student?.guardians_name);
        setGuardiansProfession(res?.data?.student?.guardians_profession);
        setGuardianType(res?.data?.student?.guardian_type);
        setGuardianCuraddress(res?.data?.student?.guardian_curaddress);
        setGuardianPhone1(res?.data?.student?.guardian_phone1);
        setGuardianPhone2(res?.data?.student?.guardian_phone2);
        setTermSign(res?.data?.student?.term_sign);
        setSupervisorSign(res?.data?.student?.supervisor_sign);
        setEduheaderSign(res?.data?.student?.eduheader_sign);
        setAdminSign(res?.data?.student?.admin_sign);
        setFatchedStudent({ label: res?.data?.student?.student_name })
        if (res?.data?.student?.country_name == null) {
          setCountry(storeSelectConsts?.COUNTRY_NAMES[storeSelectConsts.COUNTRY_NAMES.length - 1])
          setCountryName(res?.data?.student?.country)
          setIsCountryName(true)
        } else {
          common.lang === 'en' ?
            setCountry(
              storeSelectConsts.COUNTRY_NAMES.find(c => c?.value == res?.data?.student?.country)
            ) : setCountry(
              storeSelectConsts.COUNTRY_NAMESAR.find(c => c?.value == res?.data?.student?.country)
            );
          setCountryName("")
          setCountryNameState(true)
          setIsCountryName(false)
        }
        res?.data?.student?.student_note !== "" ? setIsConditional(true) : setIsConditional(false)
        setStudentNote(res?.data?.student?.student_note)
        let base64img = img.data.base64data ? ("data:image/png;base64," + img.data.base64data) : null;
        setAvatarPreviewBase64(base64img)
        fetchDataAndSetStudents(res?.data?.student?.year)
      } catch (err) {
        // exception error handling
        console.log(err)
      }
    } else if (id === undefined || userId === undefined) {
      customAxios.get("/common/genidnumber")
        .then((res) => {
          setIdcardNumber(res?.data?.idnumber);
          fetchDataAndSetStudents(year?.value)
        })
        .catch((err) => {
          // Work with the err
          // alert(err)
        });
    }
  }
  React.useEffect(() => {
    fetchData();
  }, [id, userId]);
  const fetchDataAndSetStudents = async (year) => {
    setFatchedStudentsData([])
    try {
      const studentData = await customAxios.get(`/student/getByYear/${year}`);
      studentData.data.length === 0 ? setUserId(undefined) : ""
      setFatchedStudentsData(studentData.data);
    } catch (error) {
    }
  };
  React.useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleResize = () => {
    setIsMediumScreen(window.innerWidth < 768);
  };
  const webcamRef = useRef(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const handleCaptureClick = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Update the state or perform other operations with the captured image
    // For example, you might set the captured image in the component's state
    setAvatarPreviewBase64(imageSrc);
    setAvatarFile(imageSrc);
    setIsCameraOn(false);
  };
  const startCamera = () => {
    setIsCameraOn(true);
  }
  //input value clear
  const clearAll = (isclear) => {
    setIsCountryName(false)
    setJoinYear("");
    setStudentName("");
    setFatherName("");
    setRequiredClass("");
    setFathersProfession("");
    setBirthDate("");
    setDependency("");
    setAdmissionType("");
    setAdmissionStatus("");
    setCurrentStatus("");
    setBoardType("");
    setGender("");
    setCity("");
    setProvince("");
    setCountry("");
    setCountryName('')
    setBirthPlace("");
    setSchoolAchievement("");
    setPrevexamGrade("");
    setLastUniversity("");
    setLastUniversityYear("");
    setTempAddress("");
    setPermanentAddress("");
    //isclear ? setIdcardNumber("") : setIdcardNumber(userId);
    //setIdcardNumber('');
    setCnicNumber("");
    setFirstContactNumber("");
    setSecondContactNumber("");
    setGuardiansName("");
    setGuardiansProfession("");
    setGuardianType("");
    setGuardianCuraddress("");
    setGuardianPhone1("");
    setGuardianPhone2("");
    setTermSign("");
    setSupervisorSign("");
    setEduheaderSign("");
    setAdminSign("");
    setAvatarPreviewBase64(null);
    setIsConditional(false)
    //setFatchedStudentsData([])
    setFatchedStudent([])
    isclear ? setYear({ label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`, value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}` }) : "";
    // validation init
    setStudentNameState(true);
    setYearState(true)
    setFatherNameState(true);
    setRequiredClassState(true);
    setFathersProfessionState(true);
    setBirthDateState(true);
    setDependencyState(true);
    setAdmissionTypeState(true);
    setAdmissionStatusState(true);
    setCurrentStatusState(true);
    setBoardTypeState(true);
    setGenderState(true);
    setCityState(true);
    setProvinceState(true);
    setCountryState(true);
    setCountryNameState(true);
    setBirthPlaceState(true);
    setSchoolAchievementState(true);
    setPrevexamGradeState(true);
    setLastUniversityState(true);
    setLastUniversityYearState(true);
    setTempAddressState(true);
    setPermanentAddressState(true);
    setIdcardNumberState(true);
    setCnicNumberState(true);
    setFirstContactNumberState(true);
    setSecondContactNumberState(true);
    setGuardiansNameState(true);
    setGuardiansProfessionState(true);
    setGuardianTypeState(true);
    setGuardianCuraddressState(true);
    setGuardianPhone1State(true);
    setGuardianPhone2State(true);
    setUserId(undefined)
    // history.push("/admin/add_edit_students")
    // !isclear ? customAxios.get("/common/genidnumber")
    //   .then((res) => {
    //     setIdcardNumber(res?.data?.idnumber);
    //   })
    //   .catch((err) => {
    //     // Work with the err
    //     // alert(err)
    //   }) : "";
    // if (isclear === true) {
    //   fetchDataAndSetStudents(`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`)
    // }
  }
  const claerAllState = () => {
    setIsConditional(false)
    setStudentNameState(true);
    setYearState(true)
    setFatherNameState(true);
    setRequiredClassState(true);
    setFathersProfessionState(true);
    setBirthDateState(true);
    setDependencyState(true);
    setAdmissionTypeState(true);
    setAdmissionStatusState(true);
    setCurrentStatusState(true);
    setBoardTypeState(true);
    setGenderState(true);
    setCityState(true);
    setProvinceState(true);
    setCountryState(true);
    setCountryNameState(true);
    setBirthPlaceState(true);
    setSchoolAchievementState(true);
    setPrevexamGradeState(true);
    setLastUniversityState(true);
    setLastUniversityYearState(true);
    setTempAddressState(true);
    setPermanentAddressState(true);
    setIdcardNumberState(true);
    setCnicNumberState(true);
    setFirstContactNumberState(true);
    setSecondContactNumberState(true);
    setGuardiansNameState(true);
    setGuardiansProfessionState(true);
    setGuardianTypeState(true);
    setGuardianCuraddressState(true);
    setGuardianPhone1State(true);
    setGuardianPhone2State(true);
  }
  const successAlert = (idcardNumber) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}>
        {id || userId ?
          t("studentUpdated") :
          `${t("submitSuccessAlertMsg.first")} ${idcardNumber} ${t("submitSuccessAlertMsg.second")}`
        }
      </SweetAlert >
    );
  };
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title='Error'
        onConfirm={() => hideAlert()}
        cancelBtnBsStyle='Cancel'
        showConfirm>
        {t('requiredFieldNotification')}
      </SweetAlert>,
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    history.push("/admin/add_edit_students")
    if (id === undefined) {
      customAxios.get("/common/genidnumber")
        .then((res) => {
          setIdcardNumber(res?.data?.idnumber);
          // fetchDataAndSetStudents(year?.value)
        })
        .catch((err) => {
          // Work with the err
          // alert(err)
        });
    }
    setAlert(null);
    clearAll(true);
  };
  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirm = async () => {
    let formData = new FormData();
    formData.append('image', avatarPreviewBase64);
    formData.append('name', idcardNumber);
    // if(admissionType === null) {
    //   notify("Select Admission Type", "tr", "danger");
    //   return;
    // }
    if (id !== undefined || userId !== undefined || admissionType?.value === 2) {
      if (
        studentName !== '' &&
        fatherName !== '' &&
        requiredClass?.value !== undefined &&
        birthDate !== undefined &&
        dependency?.value !== undefined &&
        gender?.value !== undefined &&
        admissionType?.value !== undefined &&
        admissionStatus?.value !== undefined &&
        currentStatus?.value !== undefined &&
        boardType?.value !== undefined &&
        country?.value !== undefined &&
        permanentAddress !== '' &&
        firstContactNumber !== '' &&
        (country.label !== 'Other' || countryName !== '')
      ) {
        try {
          const responses = await Promise.all([
            customAxios.post(`/student/update/:${idcardNumber}`, {
              student_name: studentName,
              father_name: fatherName,
              required_class: requiredClass?.value,
              fathers_profession: fathersProfession,
              birth_date: birthDate,
              dependency: dependency?.value,
              admission_type: admissionType?.value,
              admission_status: admissionStatus?.value,
              current_status: currentStatus?.value,
              board_type: boardType?.value,
              gender: gender?.value,
              city: city,
              province: province,
              country: isCountryName ? countryName : country?.value,
              birth_place: birthPlace,
              school_achievement: schoolAchievement,
              prevexam_grade: prevexamGrade,
              last_university: lastUniversity,
              last_univyear: lastUniversityYear,
              temp_address: tempAddress,
              permanent_address: permanentAddress,
              idcard_number: idcardNumber,
              cnic_number: cnicNumber,
              firstcontact_number: firstContactNumber,
              secondcontact_number: secondContactNumber,
              guardians_name: guardiansName,
              guardians_profession: guardiansProfession,
              guardian_type: guardianType,
              guardian_curaddress: guardianCuraddress,
              guardian_phone1: guardianPhone1,
              guardian_phone2: guardianPhone2,
              term_sign: termSign,
              supervisor_sign: supervisorSign,
              eduheader_sign: eduheaderSign,
              admin_sign: adminSign,
              year: year?.value,
              student_note: isConditional ? studentNote : ""
            }),
            avatarFile !== null && avatarPreviewBase64 !== null ?
              customAxios.post("/common/uploadimg", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }) : '',
          ]);
          const [res] = responses;
          successAlert(res.data.idcardNumber);
        } catch (err) {
          notify(t("notifyConnectionProblem"), "tr", "danger");
        }
      } else {
        studentName === "" ? setStudentNameState(false) : "";
        fatherName === "" ? setFatherNameState(false) : "";
        requiredClass?.value === undefined ? setRequiredClassState(false) : "";
        birthDate === undefined ? setBirthDateState(false) : "";
        dependency?.value === undefined ? setDependencyState(false) : "";
        admissionType?.value === undefined ? setAdmissionTypeState(false) : "";
        admissionStatus?.value === undefined ? setAdmissionStatusState(false) : "";
        currentStatus?.value === undefined ? setCurrentStatusState(false) : "";
        boardType?.value === undefined ? setBoardTypeState(false) : "";
        gender?.value === undefined ? setGenderState(false) : "";
        city === "" ? setCityState(false) : "";
        province === "" ? setProvinceState(false) : "";
        country === undefined ? setCountryState(false) : "";
        if (country?.label === "Other") {
          countryName === '' ? setCountryNameState(false) : "";
        }
        permanentAddress === "" ? setPermanentAddressState(false) : "";
        firstContactNumber === "" ? setFirstContactNumberState(false) : "";
        warningWithConfirmMessage();
      }
    } else if (id === undefined || userId === undefined || admissionType?.value === 1) {
      if (studentName !== '' &&
        fatherName !== '' &&
        requiredClass?.value !== undefined &&
        birthDate !== undefined &&
        dependency?.value !== undefined &&
        gender?.value !== undefined &&
        admissionType?.value !== undefined &&
        admissionStatus?.value !== undefined &&
        currentStatus?.value !== undefined &&
        boardType?.value !== undefined &&
        country?.value !== undefined &&
        permanentAddress !== '' &&
        cnicNumber !== '' &&
        firstContactNumber !== '' &&
        (country.label !== 'Other' || countryName !== '')
      ) {
        try {
          const responses = await Promise.all([
            customAxios.post("/student/create", {
              student_name: studentName,
              father_name: fatherName,
              required_class: requiredClass?.value,
              fathers_profession: fathersProfession,
              birth_date: birthDate,
              dependency: dependency?.value,
              admission_type: admissionType?.value ? admissionType.value : 1,
              admission_status: admissionStatus?.value,
              current_status: currentStatus?.value,
              board_type: boardType?.value,
              gender: gender?.value,
              city: city,
              province: province,
              country: isCountryName ? countryName : country?.value,
              birth_place: birthPlace,
              school_achievement: schoolAchievement,
              prevexam_grade: prevexamGrade,
              last_university: lastUniversity,
              last_univyear: lastUniversityYear,
              temp_address: tempAddress,
              permanent_address: permanentAddress,
              idcard_number: idcardNumber,
              cnic_number: cnicNumber,
              firstcontact_number: firstContactNumber,
              secondcontact_number: secondContactNumber,
              guardians_name: guardiansName,
              guardians_profession: guardiansProfession,
              guardian_type: guardianType,
              guardian_curaddress: guardianCuraddress,
              guardian_phone1: guardianPhone1,
              guardian_phone2: guardianPhone2,
              term_sign: termSign,
              supervisor_sign: supervisorSign,
              eduheader_sign: eduheaderSign,
              admin_sign: adminSign,
              student_note: isConditional ? studentNote : "",
              year: year?.value
            }),
            avatarFile !== null && avatarPreviewBase64 !== null ?
              customAxios.post("/common/uploadimg", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }) : '',
          ]);
          const [res] = responses
          if (res.data.msg === 'success') {
            successAlert(res.data.idcard_number);
          } else {
            notify(t("notifyConnectionProblem"), "tr", "danger");
          }
        }
        catch (err) {
          notify(t("notifyConnectionProblem"), "tr", "danger");
        }
      } else {
        studentName === "" ? setStudentNameState(false) : "";
        fatherName === "" ? setFatherNameState(false) : "";
        requiredClass?.value === undefined ? setRequiredClassState(false) : "";
        birthDate === undefined ? setBirthDateState(false) : "";
        dependency?.value === undefined ? setDependencyState(false) : "";
        admissionType?.value === undefined ? setAdmissionTypeState(false) : "";
        admissionStatus?.value === undefined ? setAdmissionStatusState(false) : "";
        currentStatus?.value === undefined ? setCurrentStatusState(false) : "";
        boardType?.value === undefined ? setBoardTypeState(false) : "";
        gender?.value === undefined ? setGenderState(false) : "";
        country === undefined ? setCountryState(false) : "";
        if (country?.label === "Other") {
          countryName === '' ? setCountryNameState(false) : "";
        }
        permanentAddress === "" ? setPermanentAddressState(false) : "";
        firstContactNumber === "" ? setFirstContactNumberState(false) : "";
        warningWithConfirmMessage();
      }
    }
  }
  const videoConstraints = {
    width: 150, // Adjust the width as needed
    height: 150, // Adjust the height as needed
    facingMode: 'user',
  };
  const clearArConstants = () => {
    if (common.lang === 'en') {
      setDependency(storeSelectConsts.DEPENDENCY_NAMES.find(d => d?.value === dependency?.value))
      setAdmissionType(storeSelectConsts.STUDENT_ADMISSION_TYPES.find(ad => ad?.value === admissionType?.value))
      setBoardType(storeSelectConsts.BOARD_TYPES.find(b => b?.value === boardType?.value))
      setAdmissionStatus(storeSelectConsts.ADMISSION_STATUSES.find(ad => ad?.value === admissionStatus?.value))
      setCurrentStatus(storeSelectConsts.STUDENT_CURRENT_STATUSES.find(c => c?.value === currentStatus?.value))
      setGender(storeSelectConsts.GENDER_NAMES.find((g => g?.value === gender?.value)))
      country?.label !== 'Other' ? setCountry(storeSelectConsts.COUNTRY_NAMES.find(c => c?.value === country?.value)) : ""
    } else {
      setDependency(storeSelectConsts.DEPENDENCY_NAMESAR.find(d => d?.value === dependency?.value))
      setAdmissionType(storeSelectConsts.STUDENT_ADMISSION_TYPESAR.find(ad => ad?.value === admissionType?.value))
      setBoardType(storeSelectConsts.BOARD_TYPESAR.find(b => b?.value === boardType?.value))
      setAdmissionStatus(storeSelectConsts.ADMISSION_STATUSESAR.find(ad => ad?.value === admissionStatus?.value))
      setCurrentStatus(storeSelectConsts.STUDENT_CURRENT_STATUSESAR.find(c => c?.value === currentStatus?.value))
      setGender(storeSelectConsts.GENDER_NAMESAR.find((g => g?.value === gender?.value)))
      country?.label !== 'Other' ? setCountry(storeSelectConsts.COUNTRY_NAMESAR.find(c => c?.value === country?.value)) : ""
    }
  }
  React.useEffect(() => {
    clearArConstants();
  }, [common?.lang])
  // React.useEffect(() => {
  //   console.log(idcardNumber, "when page leoad")
  //   console.log(id, ">>> id of students")
  //   console.log(userId, ">>>>")
  //   console.log("when id card set")
  // }, [idcardNumber])
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              {/* <Card.Header>
                <Card.Title as="h4">{t("addeditTitle")}</Card.Title>
                <Card.Title as="h6" className="mt-3">{t("idcardNumber")} : {idcardNumber ? idcardNumber : ""}<br />{t("theYearOfJoining")} : {joinYear}</Card.Title>
              </Card.Header> */}
              <Card.Body>
                <Form action="" className="form" method="">
                  <Card className="pl-3 pr-3">
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">{t("editProfile")}</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      <Row className="flex justify-content-center">
                        <Col className="p1-1" md="3">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("selectYearLabel")}<span className="star">*</span></label>
                            <Select
                              className={"react-select primary " + (id ? "disabled" : "")}
                              classNamePrefix="react-select"
                              name="year"
                              value={year}
                              onChange={async (optionSelected) => {
                                setYear({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setYearState(true);
                                } else {
                                  setYearState(false);
                                }
                                clearAll(false)
                                await fetchDataAndSetStudents(optionSelected.value)
                                history.push("/admin/add_edit_students")
                              }}
                              options={arr.map((a) => ({ value: a, label: a }))}
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="p1-1" md="3">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("selectStudentLabel")}</label>
                            <Select
                              id="fatchedStudent"
                              className={"react-select primary " + (id ? "disabled" : "")}
                              classNamePrefix="react-select"
                              name="fatchedStudent"
                              value={fatchedStudent}
                              onChange={(optionSelected) => {
                                setUserId(optionSelected.idcard_number)
                                setFatchedStudent({ label: optionSelected.student_name })
                                setIdcardNumber(optionSelected.idcard_number)
                                claerAllState();
                                // fetchData();
                                // history.push("/admin/add_edit_students")
                              }}
                              options={fatchedStudentsData.map(option => ({
                                ...option,
                                label: t(option.student_name),// Your translation function
                              }))}
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="p1-1" md="3">
                          <Form.Group
                            className={
                              "has-label " +
                              (admissionTypeState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("admissionTypeLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="admissionType"
                              value={admissionType}
                              onChange={(optionSelected) => {
                                setAdmissionType({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setAdmissionTypeState(true);
                                } else {
                                  setAdmissionTypeState(false);
                                }
                              }}
                              // options={storeSelectConsts.STUDENT_ADMISSION_TYPES}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.STUDENT_ADMISSION_TYPES.map(option => ({
                                    ...option,
                                    label: t(option.label),// Your translation function
                                    selected: t(option.label) === 'Pending' ? true : false
                                  }))
                                  :
                                  storeSelectConsts?.STUDENT_ADMISSION_TYPESAR.map(option => ({
                                    ...option,
                                    label: t(option.label),// Your translation function
                                    selected: t(option.label) === 'Pending' ? true : false
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {admissionTypeState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="flex justify-content-center">
                        {isCameraOn ? (
                          <div className="camera-capture">
                            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} onChangeCapture={setAvatarFile} />
                          </div>
                        ) : (
                          <div className="imageUpload">
                            <ImgUpload curPreviewImage={avatarPreviewBase64 || ''}
                              onChangePrivewImage={setAvatarPreviewBase64}
                              onChangeAvatarFile={setAvatarFile}
                              flag={false}
                            />
                          </div>
                        )}
                      </Row>
                      <Row className="justify-content-center">
                        {isCameraOn && (
                          <Button className="btn-edit btn-edit btn-fill mr-1 ml-1" type="button" variant="info" onClick={handleCaptureClick} style={{ backgroundColor: "#23CCEF" }}>
                            Capture
                          </Button>
                        )}
                        <Button className="btn-edit btn-edit btn-fill ml-1 mr-1" type="button" variant="info" style={{ backgroundColor: "#23CCEF" }} onClick={startCamera}>Start Camera</Button>
                      </Row>
                      <Row className="barcode">
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label" +
                              (idcardNumberState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("idcardNumberLabel")}<span className="star"></span></label>
                            <Form.Control
                              placeholder={t("idcardNumberPlaceholder")}
                              className="idcardNumber"
                              type="text"
                              value={idcardNumber}
                              disabled
                              onChange={(e) => {
                                setIdcardNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setIdcardNumberState(true);
                                } else {
                                  setIdcardNumberState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <label>{t("studentBarcodeLabel")}</label>
                          <div className="d-flex align-items-center justify-content-center pr-1 pl-1 barcode-container">
                            <Barcode value={`https://quran20.com/user/${idcardNumber}`} className=" has-success barcode" height={35} width={1} displayValue={false} textAlign={"center"} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (studentNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("studentNameLabel")}<span className="star">*</span></label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder={t("studentNamePlaceholder")}
                              type="text"
                              value={studentName}
                              onChange={(e) => {
                                setStudentName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setStudentNameState(true);
                                } else {
                                  setStudentNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {studentNameState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (fatherNameState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("fatherNameLabel")}<span className="star">*</span></label>
                            <Form.Control
                              // defaultValue="Andrew"
                              onChange={(e) => {
                                setFatherName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setFatherNameState(true);
                                } else {
                                  setFatherNameState(false);
                                }
                              }}
                              placeholder={t("fatherNamePlaceholder")}
                              value={fatherName}
                              type="text"
                            ></Form.Control>
                            {fatherNameState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (requiredClassState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("requiredClassLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="requiredClass"
                              value={requiredClass}
                              onChange={(optionSelected) => {
                                setRequiredClass({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setRequiredClassState(true);
                                } else {
                                  setRequiredClassState(false);
                                }
                              }}
                              // options={storeSelectConsts.CLASS_NAMES}
                              options={storeSelectConsts?.CLASS_NAMES.map(option => ({
                                ...option,
                                label: t(option.label), // Translate the label here
                              }))}
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {requiredClassState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("fatherProfessionLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              // placeholder="Moti Allah Jan"
                              onChange={(e) => {
                                setFathersProfession(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setFathersProfessionState(true);
                                } else {
                                  setFathersProfessionState(false);
                                }
                              }}
                              value={fathersProfession}
                              placeholder={t("fatherProfessionPlaceholder")}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (birthDateState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("dateOfBirthLabel")}<span className="star">*</span></label>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "MM/DD/YYYY",
                              }}
                              timeFormat={false}
                              value={birthDate}
                              onChange={(date) => {
                                setBirthDate(date);
                                if (isRequired(date)) {
                                  setBirthDateState(true);
                                } else {
                                  setBirthDateState(false);
                                }
                              }}
                            ></ReactDatetime>
                            {birthDateState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (dependencyState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("dependencyLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="dependency"
                              value={dependency}
                              onChange={(optionSelected) => {
                                setDependency({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setDependencyState(true);
                                } else {
                                  setDependencyState(false);
                                }
                              }}
                              // options={storeSelectConsts.DEPENDENCY_NAMES}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.DEPENDENCY_NAMES.map(option => ({
                                    ...option,
                                    label: t(option.label), // Your translation function
                                  })) :
                                  storeSelectConsts?.DEPENDENCY_NAMESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Your translation function
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {dependencyState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (admissionStatusState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("admissionStatusLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="admissionStatus"
                              value={admissionStatus}
                              defaultValue={common.lang === 'en' ? storeSelectConsts.ADMISSION_STATUSES[2] : storeSelectConsts.ADMISSION_STATUSESAR[2]}
                              onChange={(optionSelected) => {
                                setAdmissionStatus({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setAdmissionStatusState(true);
                                } else {
                                  setAdmissionStatusState(false);
                                }
                              }}
                              // options={storeSelectConsts.ADMISSION_STATUSES}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.ADMISSION_STATUSES.map(option => ({
                                    ...option,
                                    label: t(option.label),
                                    // Your translation function
                                  }))
                                  :
                                  storeSelectConsts?.ADMISSION_STATUSESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Your translation function
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {admissionStatusState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (currentStatusState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("currentStatusLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="currentStatus"
                              value={currentStatus}

                              onChange={(optionSelected) => {
                                setCurrentStatus({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setCurrentStatusState(true);
                                } else {
                                  setCurrentStatusState(false);
                                }
                              }}
                              // options={storeSelectConsts.STUDENT_CURRENT_STATUSES}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.STUDENT_CURRENT_STATUSES.map(option => ({
                                    ...option,
                                    label: t(option.label), // Your translation function
                                  }))
                                  :
                                  storeSelectConsts?.STUDENT_CURRENT_STATUSESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Your translation function
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {currentStatusState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (boardTypeState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("boardTypeLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="boardType"
                              value={boardType}
                              onChange={(optionSelected) => {
                                setBoardType({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setBoardTypeState(true);
                                } else {
                                  setBoardTypeState(false);
                                }
                              }}
                              // options={storeSelectConsts.BOARD_TYPES}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.BOARD_TYPES.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  })) :
                                  storeSelectConsts?.BOARD_TYPESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  }))

                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {boardTypeState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (genderState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("genderLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="gender"
                              value={gender}
                              onChange={(optionSelected) => {
                                setGender({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setGenderState(true);
                                } else {
                                  setGenderState(false);
                                }
                              }}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.GENDER_NAMES.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  })) :
                                  storeSelectConsts?.GENDER_NAMESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {genderState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("cityLabel")}</label>
                            <Form.Control
                              placeholder={t("cityPlaceholder")}
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setCityState(true);
                                } else {
                                  setCityState(false);
                                }
                              }}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("provinceLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t("provincePlaceholder")}
                              value={province}
                              onChange={(e) => {
                                setProvince(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setProvinceState(true);
                                } else {
                                  setProvinceState(false);
                                }
                              }}
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className='reverse-columns'>
                        <Col className="pr-md-1" md="6"
                          style={{
                            order: isMediumScreen ? 13 : 0,
                            // flexBasis: isMediumScreen ? 'auto' : 0
                          }}>
                          <Form.Group
                            className={
                              "has-label " +
                              (countryState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("countryLabel")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="country"
                              value={country}
                              onChange={(optionSelected) => {
                                if (optionSelected.label == 'Other') {
                                  setIsCountryName(true)
                                  setCountry({ value: optionSelected.value, label: optionSelected.label })
                                } else {
                                  setCountryNameState(true)
                                  setIsCountryName(false)
                                  setCountryName('')
                                  setCountry({ value: optionSelected.value, label: optionSelected.label });
                                }
                                if (isRequired(optionSelected.value)) {
                                  setCountryState(true);
                                } else {
                                  setCountryState(false);
                                }
                              }}
                              options={
                                common.lang === 'en' ?
                                  storeSelectConsts?.COUNTRY_NAMES.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  })) :
                                  storeSelectConsts?.COUNTRY_NAMESAR.map(option => ({
                                    ...option,
                                    label: t(option.label), // Translate the label here
                                  }))
                              }
                              placeholder={t("singleSelectPlaceholder")}
                            />
                            {countryState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("placeOfBirthLabel")}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t("placeOfBirthPlaceholder")}
                              type="text"
                              value={birthPlace}
                              onChange={(e) => {
                                setBirthPlace(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setBirthPlaceState(true);
                                } else {
                                  setBirthPlaceState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      {
                        isCountryName ?
                          <Row>
                            <Col className="pr-md-1" md="6">
                              <Form.Group className={
                                "mb-3 " +
                                (countryNameState ? "has-success" : "has-error")
                              } >
                                <Form.Control
                                  // defaultValue="Mike"
                                  type="text"
                                  value={countryName}
                                  onChange={(e) => {
                                    setCountryName(e.target.value);
                                    if (isRequired(e.target.value)) {
                                      setCountryNameState(true);
                                    } else {
                                      setCountryNameState(false);
                                    }
                                  }}
                                ></Form.Control>
                                {countryNameState ? null : (
                                  <label className='error'>{t('validationRequired')}</label>
                                )}
                              </Form.Group>
                            </Col>
                          </Row> : ""
                      }
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("schoolAchievementLabel")}</label>
                            <Form.Control
                              placeholder={t("schoolAchievementPlaceholder")}
                              type="text"

                              value={schoolAchievement}
                              onChange={(e) => {
                                setSchoolAchievement(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setSchoolAchievementState(true);
                                } else {
                                  setSchoolAchievementState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("prevexamGradeLabel")}</label>
                            <Form.Control
                              placeholder={t("prevexamGradePlaceholder")}
                              type="text"
                              value={prevexamGrade}
                              onChange={(e) => {
                                setPrevexamGrade(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setPrevexamGradeState(true);
                                } else {
                                  setPrevexamGradeState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ alignItems: "end" }}>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label title={t("lastUniversityLabel")}>{t("lastUniversityLabel")}</label>
                            <Form.Control
                              placeholder={t("lastUniversityPlaceholder")}
                              type="text"
                              value={lastUniversity}
                              onChange={(e) => {
                                setLastUniversity(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setLastUniversityState(true);
                                } else {
                                  setLastUniversityState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("lastUniversityYearLabel")}</label>
                            <Form.Control
                              placeholder={t("lastUniversityYearPlaceholder")}
                              type="text"
                              value={lastUniversityYear}
                              onChange={(e) => {
                                setLastUniversityYear(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setLastUniversityYearState(true);
                                } else {
                                  setLastUniversityYearState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("tempAddressLabel")}</label>
                            <Form.Control
                              placeholder={t("tempAddressPlaceholder")}
                              type="text"

                              value={tempAddress}
                              onChange={(e) => {
                                setTempAddress(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setTempAddressState(true);
                                } else {
                                  setTempAddressState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (permanentAddressState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("permanentAddressLabel")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder={t("permanentAddressPlaceholder")}
                              type="text"
                              value={permanentAddress}
                              onChange={(e) => {
                                setPermanentAddress(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setPermanentAddressState(true);
                                } else {
                                  setPermanentAddressState(false);
                                }
                              }}
                            ></Form.Control>
                            {permanentAddressState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (idcardNumberState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("idcardNumberLabel")}<span className="star"></span></label>
                            <Form.Control
                              placeholder={t("idcardNumberPlaceholder")}
                              type="text"
                              value={idcardNumber}
                              disabled
                              onChange={(e) => {
                                setIdcardNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setIdcardNumberState(true);
                                } else {
                                  setIdcardNumberState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("cnicNumberLabel")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder={t("cnicNumberPlaceholder")}
                              type="text"
                              value={cnicNumber}
                              onChange={(e) => {
                                setCnicNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setCnicNumberState(true);
                                } else {
                                  setCnicNumberState(false);
                                }
                              }}
                            ></Form.Control>
                            {cnicNumberState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className={
                              "has-label " +
                              (firstContactNumberState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("firstContactNumberLabel")}<span className="star">*</span></label>
                            <Form.Control
                              placeholder={t("firstContactNumberPlaceholder")}
                              type="text"

                              value={firstContactNumber}
                              onChange={(e) => {
                                setFirstContactNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setFirstContactNumberState(true);
                                } else {
                                  setFirstContactNumberState(false);
                                }
                              }}
                            ></Form.Control>
                            {firstContactNumberState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("secondContactNumberLabel")}</label>
                            <Form.Control
                              placeholder={t("secondContactNumberPlaceholder")}
                              type="text"

                              value={secondContactNumber}
                              onChange={(e) => {
                                setSecondContactNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setSecondContactNumberState(true);
                                } else {
                                  setSecondContactNumberState(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardiansNameLabel")}</label>
                            <Form.Control
                              placeholder={t("guardiansNamePlaceholder")}
                              type="text"

                              value={guardiansName}
                              onChange={(e) => {
                                setGuardiansName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardiansNameState(true);
                                } else {
                                  setGuardiansNameState(false);
                                }
                              }}
                            ></Form.Control>

                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardiansProfessionLabel")}</label>
                            <Form.Control
                              placeholder={t("guardiansProfessionPlaceholder")}
                              type="text"
                              value={guardiansProfession}
                              onChange={(e) => {
                                setGuardiansProfession(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardiansProfessionState(true);
                                } else {
                                  setGuardiansProfessionState(false);
                                }
                              }}
                            ></Form.Control>

                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardianTypeLabel")}</label>
                            <Form.Control
                              placeholder={t("guardianTypePlaceholder")}
                              type="text"
                              value={guardianType}
                              onChange={(e) => {
                                setGuardianType(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardianTypeState(true);
                                } else {
                                  setGuardianTypeState(false);
                                }
                              }}
                            ></Form.Control>

                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardianCuraddressLabel")}</label>
                            <Form.Control
                              placeholder={t("guardianCuraddressPlaceholder")}
                              type="text"
                              value={guardianCuraddress}
                              onChange={(e) => {
                                setGuardianCuraddress(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardianCuraddressState(true);
                                } else {
                                  setGuardianCuraddressState(false);
                                }
                              }}
                            ></Form.Control>

                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardianPhone1Label")}</label>
                            <Form.Control
                              placeholder={t("guardianPhone1Placeholder")}
                              type="text"

                              value={guardianPhone1}
                              onChange={(e) => {
                                setGuardianPhone1(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardianPhone1State(true);
                                } else {
                                  setGuardianPhone1State(false);
                                }
                              }}
                            ></Form.Control>

                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group
                            className="has-label has-success"
                          >
                            <label>{t("guardianPhone2Label")}</label>
                            <Form.Control
                              placeholder={t("guardianPhone2Placeholder")}
                              type="text"
                              value={guardianPhone2}
                              onChange={(e) => {
                                setGuardianPhone2(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setGuardianPhone2State(true);
                                } else {
                                  setGuardianPhone2State(false);
                                }
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  checked={isConditional ? true : false}
                                  type="radio"
                                  name="r1"
                                  onChange={(e) => {
                                    setIsConditional(true)
                                  }}
                                  style={{ accentColor: "#909090" }}
                                ></Form.Check.Input>
                                <label>{t("conditionalLabel")}</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                        <Col className="pr-md-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  checked={isConditional ? false : true}
                                  type="radio"
                                  name="r1"
                                  onChange={(e) => {
                                    setIsConditional(false)
                                  }}
                                  className="bg-dark text-dark"
                                  style={{ accentColor: "#909090" }}
                                ></Form.Check.Input>
                                <label>{t("unconditionalLabel")}l</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                      </Row>
                      {isConditional ?
                        <Row>
                          <Col className="pr-md-1" md="6">
                            <Form.Group className="mb-3 has-success has-label" controlId="exampleForm.ControlTextarea1">
                              <Form.Label>{t("typeLabel")}</Form.Label>
                              <Form.Control as="textarea" rows={3} value={studentNote} onChange={(e) => setStudentNote(e.target.value)} />
                            </Form.Group>
                          </Col>
                        </Row> : ""}
                      <Row className="mt-3">
                        <Col className="pr-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue={true}
                                  type="checkbox"
                                  checked={termSign ? true : false}
                                  onChange={(e) => {
                                    setTermSign(!termSign)
                                  }}
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                                <label>{t("termAndCondition")}</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue={true}
                                  type="checkbox"
                                  checked={supervisorSign ? true : false}
                                  onChange={(e) => {
                                    setSupervisorSign(!supervisorSign)
                                  }}
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                                <label>{t("supervisor")}</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue={true}
                                  type="checkbox"
                                  checked={eduheaderSign ? true : false}
                                  onChange={(e) => {
                                    setEduheaderSign(!eduheaderSign)
                                  }}
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                                <label>{t("headOfEducation")}</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                          <Form.Group className="pull-left">
                            <Form.Check>
                              <Form.Check.Label>
                                <Form.Check.Input
                                  defaultValue={true}
                                  type="checkbox"
                                  checked={adminSign ? true : false}
                                  onChange={(e) => {
                                    setAdminSign(!adminSign)
                                  }}
                                ></Form.Check.Input>
                                <span className="form-check-sign"></span>
                                <label>{t("administrator")}</label>
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button className="btn-edit btn-edit btn-fill" type="submit" variant="info" onClick={onConfirm}>
                  {t("btnSubmit")}
                </Button>
                <Button className="btn-edit btn-edit btn-fill mx-2" type="submit" variant="warning" onClick={(e) => {
                  clearAll(true)
                }
                }>
                  {t("btnClearAll")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default StudentsAddEdit;
