import customAxios from "lib/axios";
import React, { useContext, useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as XLSX from 'xlsx';
import { selectConsts } from '../store/selectconstReducer';
import { resultGrade } from '../store/gradeReducer'
import { commonContext } from '../context/common'
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner
} from "react-bootstrap";

function ResultEnter() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true)
  const common = useContext(commonContext)
  const [t] = useTranslation(["exam"]);
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const storeResultGrade = useSelector(resultGrade)
  const [examName, setExamName] = React.useState(storeSelectConsts.EXAM_NAMES[0]);
  const [curClass, setCurClass] = React.useState(storeSelectConsts.CLASS_NAMES[0]);
  const [students, setStudents] = React.useState([]);
  const [examBooks, setExamBooks] = React.useState([]);
  const [examInstanceId, setExamInstanceId] = React.useState("");
  const [total, setTotal] = React.useState(0)
  const [showScroll, setShowScroll] = React.useState(false);
  const [gradeName, setGradeNames] = React.useState([]);
  const ref = useRef([])
  const keyHandler = (e, targetElement) => {
    if (e.key === "ArrowDown" && targetElement) {
      targetElement.focus()
    }
    else if (e.key === "ArrowUp" && targetElement) {
      targetElement.focus()
    }
  }
  const checkScrollTop = () => {
    if ((!showScroll && window.pageYOffset > 400) || (!showScroll && window.pageYOffset)) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //   document.getElementById("btn-back-to-top").blur();
  // };
  React.useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [window.pageYOffset]);
  React.useEffect(() => {
    const ele = document.getElementById('con');
    if (ele) {
      ele.scrollTop = ele.scrollHeight;
    }
    const sum = examBooks?.reduce((accumulator, currentValue) => accumulator + currentValue.book.number, 0);
    setTotal(sum)
  }, [examBooks])
  React.useEffect(() => {
    // submit form values
    // customAxios.get(`/grade/getAll/${common.lang}`)
    //   .then((res) => {
    //     console.log(res.data, ">>>>")
    //   })
    //   .catch((err) => {
    //     // Work with the err
    //   });
    if (common.lang === 'en') {
      setGradeNames(storeResultGrade.GRADE_NAMES);
    } else {
      setGradeNames(storeResultGrade.GRADE_NAMESAR)
    }
  }, [common.lang])
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirm()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {t("resultEnterConfirm.first")}<br />{t("resultEnterConfirm.second")}
      </SweetAlert>
    );
  };
  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {t("resultSubmitSuccess")}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
    history.push("/admin/list_results");
  };
  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const totalNumber = (item) => {
    const sumNumber = item?.results?.reduce((pre, cur, index) => pre + Number(cur.result), 0)
    return sumNumber
  }
  const grade = (item) => {
    const num = (totalNumber(item) / total) * 100;
    if (num >= 95) {
      return "A+"
    } else if (num >= 85) {
      return "A"
    } else if (num >= 75) {
      return "B+"
    } else if (num >= 65) {
      return "B"
    } else if (num >= 55) {
      return "C+"
    } else if (num >= 45) {
      return "C"
    } else if (num >= 35) {
      return "D"
    } else {
      return "F"
    }
  }
  const getGradeString = (item) => {
    const num = (totalNumber(item) / total) * 100;
    // Iterate over the grade structure to find the appropriate grade
    for (const grade of gradeName) {
      if (num >= grade.min && num <= grade.max) {
        return grade.grade_name;
      }
    }
    // If the percentage doesn't match any grade, return "F" by default
    return "F";
  };
  React.useEffect(() => {
    setTimeout(() => {
      // submit form values
      customAxios.post("/exam/getExamSeatsAndBooks", {
        exam_nameid: examName !== undefined ? examName?.value : storeSelectConsts.EXAM_NAMES[0],
        exam_classid: curClass?.value
      })
        .then((res) => {
          setExamInstanceId(res.data.examId);
          setLoading(false)
          if (res.data.msg == "EXAM_NOT_PREPARED")
            return;
          setStudents(res?.data?.seatedStudents?.map((item, index) => {
            let book_ids = [];
            for (let key in res.data.examBooks) {
              book_ids.push({ book_id: res.data.examBooks[key].book_id, result: "", book_name: res.data.examBooks[key].book.book_name })
            }
            item['results'] = book_ids;
            item?.results?.forEach((resultItem, i) => {
              let foundResult = item.exam_results.find(
                (res) => res.book_id === resultItem.book_id
              );
              if (foundResult) {
                item.results[i].result = Number(foundResult?.result); // Update the 'result' field
              }
            });
            return {
              exam_instanceid: item.exam_instanceid,
              seat_number: item.seat_number,
              student_id: item.student_id,
              student_name: item.student.student_name,
              idcard_number: item.student.idcard_number,
              father_name: item.student.father_name,
              required_class: item.student.class_name.class_name,
              results: item?.results
            };
          }));
          setExamBooks(res.data.examBooks);
        })
        .catch((err) => {
          // Work with the err
        });
    }, 1000)
  }, [curClass, examName])
  const onSubmit = () => {
    warningWithConfirmMessage();
  }
  const onConfirm = () => {
    // submit form values
    customAxios.post("/exam/createExamResults", {
      data: students
    }).then((res) => {
      successAlert();
    }).catch((err) => {
      notify(t("notifyConnectionProblem"), "tr", "danger")
    })
  }
  const selectExportToExcel = () => {
    const filteredData = students.map(item => {
      const newRow = {};
      if (selectedColumns.seatNumber) {
        newRow["seat_number"] = item.seat_number;
      }
      if (selectedColumns.idcardNumber) {
        newRow["idcard_number"] = item.idcard_number;
      }
      if (selectedColumns.studentName) {
        newRow["student_name"] = item.student_name;
      }
      if (selectedColumns.fatherName) {
        newRow["father_name"] = item.father_name;
      }
      if (selectedColumns.className) {
        newRow["className"] = item.required_class;
      }
      examBooks?.map((book, index) => {
        if (selectedColumns[book?.book?.book_name]) {
          newRow[`${item.results[index].book_name}`] = item.results[index]?.result || 0;
        }
      })
      if (selectedColumns.totalNumber) {
        newRow["total_number"] = totalNumber(item);
      }
      if (selectedColumns.grade) {
        // newRow["grade"] = grade(item);
        newRow["grade"] = getGradeString(item);
      }
      if (selectedColumns.precent) {
        newRow["precent"] = ((totalNumber(item) / total) * 100).toFixed(2) + '%';
      }
      // Add more conditions for other selected columns
      return newRow;
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'table_data.xlsx');
  };
  const [selectedColumns, setSelectedColumns] = React.useState({
    seatNumber: false,
    idcardNumber: false,
    studentName: false,
    fatherName: false,
    className: false,
    totalNumber: false,
    grade: false,
    precent: false
  });
  const handleColumnSelection = (columnName, value) => {
    setSelectedColumns({
      ...selectedColumns,
      [columnName]: value,
    });
  };
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12" style={{ maxHeight: '530x', overflowY: 'auto', overflowX: 'auto' }}>
            <Card className="stacked-form">
              <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Card.Title as="h4">{t("enterExamResult")}</Card.Title>
                <button
                  variant="info"
                  className="btn btn-info"
                  onClick={() => {
                    selectExportToExcel()
                  }}>
                  {t("exportToExcel")}
                </button>
              </Card.Header>
              <Card.Body style={{ padding: "0px 15px" }}>
                <Row>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("examName")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="examName"
                        value={examName}
                        onChange={(value) => setExamName(value)}
                        defaultValue={{ value: "1", label: "Summer programming contest" }}
                        options={storeSelectConsts.EXAM_NAMES}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("class")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="curClass"
                        value={curClass}
                        onChange={(value) => setCurClass(value)}
                        defaultValue={{ value: "1", label: "Class 1" }}
                        options={storeSelectConsts.CLASS_NAMES.filter(cls => cls.isActive)}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Card className="stacked-form" style={{ padding: "0px 10px" }}>
                  <Card.Header>
                    {
                      !examInstanceId ?
                        <Card.Title as="h5" className="text-center text-warning pt-5 pb-5" style={{ fontSize: '16px', fontWeight: "400" }}>{t("examNotPrepared")}</Card.Title>
                        : ""
                    }
                  </Card.Header>
                  {
                    examInstanceId ?
                      <Card.Body className="overflow-auto p-0">
                        <div style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: '750px' }} >
                          {/* <Table style={{ transform: 'rotateX(180deg)' }}> */}
                          {!loading ? (<Table>
                            <thead style={{ width: '100%', overflowX: 'auto', overflowY: 'auto', position: "sticky", backgroundColor: "white", zIndex: "1", top: '0px' }}>
                              <tr>
                                <th className="text-center align-baseline" style={{ paddingLeft: '8px' }}>
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.seatNumber}
                                      onChange={(e) => handleColumnSelection('seatNumber', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("seatNumber")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.idcardNumber}
                                      onChange={(e) => handleColumnSelection('idcardNumber', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("idcardNumber")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.studentName}
                                      onChange={(e) => handleColumnSelection('studentName', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("studentName")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.fatherName}
                                      onChange={(e) => handleColumnSelection('fatherName', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("fatherName")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.className}
                                      onChange={(e) => handleColumnSelection('className', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("className")}
                                  </div>
                                </th>
                                {examBooks.map((item, i) =>
                                  <th className="text-center align-baseline" style={{ minWidth: "90px", maxWidth: "90px" }} key={i}>
                                    <div className="align-items-start" style={{ "display": "grid", whiteSpace: 'break-spaces' }}>
                                      <input
                                        type="checkbox"
                                        className="mt-1 mr-1"
                                        checked={selectedColumns[`${item.book.book_name}`]}
                                        onChange={(e) => handleColumnSelection(`${item.book.book_name}`, e.target.checked)}
                                        style={{ accentColor: '#17a2b8' }}
                                      />
                                      {item.book.book_name}
                                    </div>
                                  </th>
                                )}
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.totalNumber}
                                      onChange={(e) => handleColumnSelection('totalNumber', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("totalNumber")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.grade}
                                      onChange={(e) => handleColumnSelection('grade', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("grade")}
                                  </div>
                                </th>
                                <th className="text-center align-baseline">
                                  <div className="align-items-start" style={{ "display": "grid" }}>
                                    <input
                                      type="checkbox"
                                      className="mt-1 mr-1"
                                      checked={selectedColumns.precent}
                                      onChange={(e) => handleColumnSelection('precent', e.target.checked)}
                                      style={{ accentColor: '#17a2b8' }}
                                    />
                                    {t("precent")}
                                  </div>
                                </th>
                                {/* <th className="text-center"></th> */}
                              </tr>
                              <tr>
                                <th className="text-center" colSpan={5}>{t("number total")}</th>
                                {examBooks.map((item, i) => (
                                  <th className="text-center" key={i}>
                                    {item.book.number}
                                  </th>
                                ))}                                <th className="text-center"></th>
                                <th className="text-center"></th>
                                <th className="text-center"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {students.map((item, cur) =>
                                <tr key={cur}>
                                  <td className="text-center" style={{ paddingLeft: '8px' }}>{item.seat_number}</td>
                                  <td className="text-center">{item.idcard_number}</td>
                                  <td className="text-center">{item.student_name}</td>
                                  <td className="text-center">{item.father_name}</td>
                                  <td className="text-center">{item.required_class}</td>
                                  {item.results.map((i, index) =>
                                    <td className="text-center" key={index}>
                                      <Form.Control
                                        type="text"
                                        value={Number.isNaN(Number(i.result)) ? '' : Number(i.result)}
                                        className="text-center mx-auto"
                                        ref={(el) => (ref.current[(cur * item.results.length + index + 1)] = el)}
                                        onKeyDown={(e) => {
                                          if (e.key === "ArrowDown") {
                                            keyHandler(e, ref.current[cur === students.length - 1 ? 0 : (cur * item.results.length + index + 1) + item.results.length]);
                                          } else if (e.key === "ArrowUp") {
                                            keyHandler(e, ref.current[cur === students.length - 1 ? 0 : (cur * item.results.length + index + 1) - item.results.length]);
                                          }
                                        }}
                                        onChange={(e) => {
                                          setStudents(students.map((student, ii) => {
                                            if (cur == ii) {
                                              return {
                                                ...student,
                                                results: student.results.map((result_item, result_index) => {
                                                  if (index == result_index)
                                                    return {
                                                      ...result_item,
                                                      result: Number(e.target.value)
                                                    }
                                                  return result_item
                                                })
                                              }
                                            }
                                            return student;
                                          }
                                          ))
                                        }}
                                      ></Form.Control>
                                    </td>
                                  )}
                                  <td className="text-center">{totalNumber(item)}</td>
                                  {/* <td className="text-center">{grade(item)}</td> */}
                                  <td className="text-center">{getGradeString(item)}</td>
                                  <td className="text-center" style={{ "display": "table-cell" }}>{(((totalNumber(item) / total)) * 100)?.toFixed(2)}%</td>
                                </tr>
                              )}
                            </tbody>
                          </Table>) : (
                            <div className="d-flex align-items-center justify-content-center my-5">
                              <Spinner animation="border" variant="warning" />
                            </div>
                          )}

                        </div>
                      </Card.Body>
                      : ""
                  }
                </Card>
                {/* Scroll to Top Button */}
              </Card.Body>
              {
                examInstanceId ?
                  <Card.Footer>
                    <Button className="btn-edit btn-fill" type="submit" variant="info" onClick={onSubmit}>
                      {t("btnSubmit")}
                    </Button>
                  </Card.Footer>
                  : ""
              }
            </Card>
          </Col>
        </Row >
      </Container >
      {/* Your existing JSX here */}
      {/* <button
        onClick={scrollTop}
        className="btn btn-primary"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          opacity: showScroll ? "1" : "0",
          pointerEvents: showScroll ? "all" : "none",
        }}
      >
        <i className="fas fa-arrow-up"></i>
      </button> */}
    </>
  );
}

export default ResultEnter;
