import React, { useRef, useContext } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { selectConsts } from '../store/selectconstReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setAll } from "../store/selectconstReducer";
import customAxios from "lib/axios";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { commonContext } from '../context/common'
// react-bootstrap components
import {
    Button,
    Card,
    Form,
    Container,
    Table,
    Row,
    Col,
    Spinner
} from "react-bootstrap";
function TeacherGrading() {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true)
    const [t] = useTranslation(["exam"]);
    const language = useContext(commonContext)
    let componentRef = useRef();
    const storeSelectConsts = useSelector(selectConsts);
    const { examname_id } = useParams();
    React.useEffect(() => {
        customAxios.get(`/common/consts/${language.lang}`)
            .then((res) => {
                dispatch(setAll(res.data));
            })
            .catch((err) => {
                // Work with the err
                // alert(err)
            });
    }, [])
    const [examName, setExamName] = React.useState({ value: '', label: 'All' });
    const [employeeData, setEmployeeData] = React.useState([]);
    const [newExamArray, setNewExamArray] = React.useState([]);
    React.useEffect(() => {
        const newExamObject = {
            value: '',
            label: 'All',
            exam_datetime: ""
        };
        const newExamArray1 = [newExamObject, ...storeSelectConsts.EXAM_NAMES];
        setNewExamArray(newExamArray1);
        customAxios.get(`/exam/getTeacherGradingResult?exam_nameid=${examName?.value}`)
            .then((res) => {
                setEmployeeData(res?.data);
                setLoading(false)
            })
            .catch((err) => {
            });
    }, [examName, storeSelectConsts])
    const renderClassCard = (employeeData, t) => {
        return (
            <Card className="stacked-form">
                {/* <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}> */}
                {/* <Card.Title>{employeeData[0]?.className}</Card.Title> */}
                {/* </Card.Header> */}
                <Card.Body className="overflow-auto">
                    <Table ref={componentRef}>
                        <thead id="demo">
                            <tr>
                                <th className='text-left' style={{ paddingLeft: '8px' }}>{t("employeeName")}</th>
                                <th className="text-center">{t("fatherName")}</th>
                                <th className="text-center">{t("total")}</th>
                                <th className="text-center">{t("percentage")}</th>
                                <th className="text-center">{t("grade")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeData?.map((stu, index1) => (
                                <tr key={index1}>
                                    <td className="text-left" style={{ paddingLeft: '8px' }}>{stu.employee_name}</td>
                                    <td className="text-center">{stu.father_name}</td>
                                    <td className="text-center">{stu.totalResult}</td>
                                    <td className="text-center">{stu.percentage}%</td>
                                    <td className="text-center">{stu.grade.grade_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    };
    const renderAllClassCards = (employeeData, t) => {
        // const sortedEmployeeData = employeeData?.slice().sort((a, b) => a.grade.localeCompare(b.grade.grade_name));
        const sortedEmployeeData = employeeData?.slice().sort((a, b) => {
            // Convert percentage strings to numbers for comparison
            const percentageA = parseFloat(a.percentage);
            const percentageB = parseFloat(b.percentage);
            // Compare percentages
            return percentageB - percentageA;
        });
        return (
            renderClassCard(sortedEmployeeData, t)
        );
    };
    return (
        <Container fluid>
            <Row>
                <Col md="12">
                    <div ref={componentRef}>
                        <Card className="stacked-form">
                            <Row ref={(el) => (componentRef = el)} style={{ padding: '10px', display: 'contents' }}>
                                <Card.Header>
                                    <Card.Title as="h4">{t("teacherGrading")}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="no-print">
                                        <Col className="p1-1" md="4">
                                            <Form.Group>
                                                <label>{t("examName")}</label>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="examName"
                                                    value={examName}
                                                    onChange={(value) => setExamName(value)}
                                                    options={newExamArray}
                                                    placeholder={t("singleSelect")}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="print">
                                        <label>{examName ? examName?.label : storeSelectConsts.EXAM_NAMES[0]?.label}</label>
                                    </Row>
                                    {!loading ?
                                        employeeData && employeeData?.length !== 0 ? (
                                            renderAllClassCards(employeeData, t)
                                        ) : (
                                            <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noGradingResultFound")}</div>
                                        ) : <div className="d-flex align-items-center justify-content-center my-5">
                                            <Spinner animation="border" variant="warning" />
                                        </div>}
                                </Card.Body>
                            </Row>
                            {employeeData && employeeData?.length !== 0 ? (
                                < Card.Footer className="d-flex justify-content-between">
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button className="btn-edit btn-fill" type="submit" variant="info">
                                                <span className="btn-label">
                                                    <i className="fas fa-print"></i>
                                                </span>
                                                &nbsp;{t("btnPrint")}
                                            </Button>
                                        )}
                                        content={() => componentRef}
                                    />
                                </Card.Footer>
                            ) : null}
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container >
    );
}
export default TeacherGrading;
