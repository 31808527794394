import customAxios from "lib/axios";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { selectConsts } from '../store/selectconstReducer';
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner
} from "react-bootstrap";
function ExamCreate() {
  const [t] = useTranslation(["exam"]);
  const [loading, setLoading] = React.useState(true)
  const history = useHistory();
  const { exam_nameid, classname_id } = useParams();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const [examName, setExamName] = React.useState(exam_nameid ? storeSelectConsts.EXAM_NAMES.find((i) => i.value == exam_nameid) : storeSelectConsts.EXAM_NAMES[0]);
  const [curClass, setCurClass] = React.useState(classname_id ? storeSelectConsts.CLASS_NAMES.find((i) => i.value == classname_id) : storeSelectConsts.CLASS_NAMES[0]);
  const [examBooks, setExamBooks] = React.useState([]);

  React.useEffect(() => {
    // submit form values
    customAxios.post("/exam/getExamBooks", {
      exam_nameid: storeSelectConsts.EXAM_NAMES[0],
      class_id: curClass?.value
    })
      .then((res) => {
        // setExamBooks(res.data.map((item) => { return {book_id: item.id, book_name: item.book_name, book_cnt: item.number, marker: "", selected: false}; }));
        setExamBooks(res?.data?.data);
        setLoading(false)
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }, [examName, curClass])
  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const infoAlert = (msg) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
      </SweetAlert>
    );
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const onSubmit = () => {
    let booksData = examBooks?.filter((item) => item.selected == true);
    if (!booksData.length) {
      notify(t("notifyBookSelect"), "tr", "warning");
      return;
    }
    // submit form values
    customAxios.post("/exam/createExamInstance", {
      exam_nameid: examName.value,
      exam_classid: curClass.value,
      exam_books: booksData
    })
      .then((res) => {
        if (res?.data?.examResulted === true) {
          infoAlert(t("examResulted"));
        } else {
          successAlert(t("submitExamCreateSuccess"));
        }
        // setExamBooks(res.data.map((item) => { return {book_name: item.book_name, marker: "", selected: false}; }));

      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("createEditExam")}</Card.Title>
              </Card.Header>
              <Card.Body>
                {/* <Card.Body className="table-full-width"> */}
                {/* <Card.Body> */}
                <Row>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("examName")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="examName"
                        value={examName}
                        onChange={(value) => setExamName(value)}
                        defaultValue={storeSelectConsts.EXAM_NAMES[0]}
                        options={storeSelectConsts.EXAM_NAMES}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("class")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="curClass"
                        value={curClass}
                        onChange={(value) => setCurClass(value)}
                        defaultValue={storeSelectConsts.CLASS_NAMES[0]}
                        options={storeSelectConsts.CLASS_NAMES.filter(cls => cls.isActive)}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* </Card.Body> */}
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h5">{curClass?.label}</Card.Title>
                  </Card.Header>
                  <Card.Body className="overflow-auto">
                    {!loading ? (
                      examBooks.length > 0 ?
                        <Table>
                          <thead>
                            <tr>
                              <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                              <th>{t("bookName")}</th>
                              <th className="text-center">{t("number")}</th>
                              {/* <th className="text-center">Marks</th> */}
                              <th className="text-center">{t("select")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              examBooks?.map((book, index) =>
                                <tr key={index}>
                                  <td className="text-center" style={{ paddingLeft: '8px' }}>{index + 1}</td>
                                  <td>{book.book_name}</td>
                                  <td className="text-center">{book.book_cnt}</td>
                                  {/* <td className="text-right">                          
                                  <Form.Control
                                    placeholder="please insert the marker here"
                                    type="text"
                                    value={book.marker}
                                    onChange={(e) => {
                                      setExamBooks(examBooks.map((item, i) => {
                                        if (index == i)
                                          return {book_id: item.book_id, book_name: item.book_name, marker: e.target.value, selected: false};
                                        return item;
                                      }))
                                    }}
                                  ></Form.Control>
                              </td> */}
                                  <td className="td-actions text-center">
                                    <Form.Group className="pull-left">
                                      <Form.Check>
                                        <Form.Check.Label>
                                          <Form.Check.Input
                                            defaultValue={true}
                                            type="checkbox"
                                            checked={book?.selected ? true : false}
                                            onChange={(e) => {
                                              setExamBooks(examBooks?.map((item, i) => {
                                                if (index == i)
                                                  return { book_id: item?.book_id, book_name: item?.book_name, book_cnt: item?.book_cnt, marker: item?.marker, selected: e.target.checked };
                                                return item;
                                              }))
                                            }}
                                          ></Form.Check.Input>
                                          <span className="form-check-sign"></span>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </Form.Group>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table> :
                        <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noExamFound")}</div>
                    ) : (<div className="d-flex align-items-center justify-content-center my-5">
                      <Spinner animation="border" variant="warning" />
                    </div>)}

                  </Card.Body>
                </Card>
              </Card.Body>
              <Card.Footer>
                <Button className="btn-edit btn-fill" type="submit" variant="info" onClick={onSubmit}>
                  {t("btnSubmit")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ExamCreate;
