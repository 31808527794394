import React, { useContext, useRef } from 'react'
import { useParams } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Row,
    Button,
    Form,
} from "react-bootstrap";
import Select from "react-select";
import { commonContext } from '../context/common'
import customAxios from "../lib/axios";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { selectConsts } from '../store/selectconstReducer'
import Pagination from "../components/Pagination";
const StudentCardPage = () => {
    const [t] = useTranslation(["students"]);
    const { id } = useParams();
    let componentRef = useRef();
    const storeSelectConsts = useSelector(selectConsts)
    const [studentName, setStudentName] = React.useState('')
    const [fatherName, setFatherName] = React.useState("")
    const [dob, setDob] = React.useState("")
    const [avtar, setAvtar] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [regNumber, setRegNumber] = React.useState("")
    const [serialNumber, setSerialNumber] = React.useState("")
    const [className, setClassName] = React.useState("")
    const [requiredClass, setRequiredClass] = React.useState({ value: '', label: "All" });
    const [fatchedStudents, setFatchedStudents] = React.useState([]);
    const [selectedStudent, setSelectedStudent] = React.useState({ value: "", label: "All" })
    const [filteredList, setFilteredList] = React.useState([]);
    const [userId, setUserId] = React.useState("")
    const [studentsPerPage, setStudentsPerPage] = React.useState(5);
    const allStudentsCount = id || userId ? 1 : filteredList.length;
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: "5 " + t("rows") });
    const lastStudentNumber = currentPage * studentsPerPage;
    const firstStudentIndex = lastStudentNumber - studentsPerPage;
    const limitedStudents = filteredList.slice(
        firstStudentIndex,
        lastStudentNumber
    );
    const [topPositionFront, setTopPositionFront] = React.useState(0);
    const [topPositionBack, setTopPositionBack] = React.useState(0);
    const [parentHeight, setParentHeight] = React.useState('auto');
    const [screenSize, setScreenSize] = React.useState(window.innerWidth)
    const cardRef = useRef(null);
    const cardRefBack = useRef(null);
    const parentRef = useRef(null);

    const fetchData = async () => {
        if (id || userId) {
            try {
                const response = await Promise.all(
                    [
                        customAxios.post('/student/getOne', { idcard_number: id || userId }),
                        customAxios.post('/common/getAvatarImg', { idcard_number: id || userId })
                    ]
                )
                const [res, img] = response;
                setStudentName(res?.data?.student?.student_name)
                setFatherName(res?.data?.student?.father_name)
                setDob(new Date(res?.data?.student?.birth_date).toLocaleDateString())
                setAvtar(img?.data?.base64data)
                setAddress(res?.data?.student?.permanent_address)
                setRegNumber(res?.data?.student?.idcard_number)
                setClassName(res?.data?.student?.class_name?.class_name)
                setSerialNumber("00000")
            } catch (err) {
                console.log(err)
            }
        }
    }
    const common = useContext(commonContext)
    React.useEffect(() => {
        if (id || userId) {
            fetchData()
        }
    }, [id, userId])
    const onFilter = () => {
        setFilteredList(fatchedStudents.filter((item) =>
            item.class.toString().includes(requiredClass?.value ? requiredClass?.value : "")
        ));
    }
    React.useEffect(() => {
        // localStorage.token && setAuthToken(localStorage.token)
        customAxios.get("/student/getAllWithAvatar")
            .then(async (res) => {
                setFatchedStudents(res.data.filter((student) => student.class_name.isActive));
                setFilteredList(res.data.filter((student) => student.class_name.isActive));
            })
            .catch((err) => {
            });
    }, []);
    React.useEffect(() => {
        onFilter();
    }, [requiredClass]);
    React.useEffect(() => {
        const calculateParentHeight = () => {
            const childCards = parentRef.current.querySelectorAll('.student-card');
            let maxHeight = 0;
            childCards.forEach(card => {
                const cardHeight = card.getBoundingClientRect().height;
                maxHeight += cardHeight + 10
            });
            setParentHeight(`${maxHeight + 100}`)
            setScreenSize(window.innerWidth);
        };
        calculateParentHeight();
        window.addEventListener('resize', calculateParentHeight);
        return () => window.removeEventListener('resize', calculateParentHeight);
    });
    React.useEffect(() => {
        const calculateTopPosition = () => {
            if (cardRef.current) {
                const cardHeight = cardRef.current.getBoundingClientRect().height;
                setTopPositionFront(cardHeight + 10);
            }
            if (cardRefBack.current) {
                const cardHeight = cardRefBack.current.getBoundingClientRect().height;
                setTopPositionBack(2 * (cardHeight + 10));
                setScreenSize(window.innerWidth)
            }
        };
        window.addEventListener('resize', calculateTopPosition);
        calculateTopPosition();
        return () => window.removeEventListener('resize', calculateTopPosition);
    });
    return (
        <>
            <Container fluid>
                <Card className="stacked-form">
                    <Card.Header>
                        <Card.Title as="h4">{t("studentIdCardLabel")}</Card.Title>
                    </Card.Header>
                    <Card.Body className="table-full-width">
                        <Row>
                            <Col className="pl-md-3  pr-md-1" md="6">
                                <Form.Group className="has-success">
                                    <label>{t("class")}</label>
                                    <Select
                                        className={"react-select primary " + (id ? "disabled" : "")}
                                        classNamePrefix="react-select"
                                        name="requiredClass"
                                        value={requiredClass}
                                        onChange={(optionSelected) => {
                                            setRequiredClass({ value: optionSelected.value, label: optionSelected.label })
                                            setUserId("")
                                            setSelectedStudent("")
                                        }}
                                        options={[{ value: "", label: "All" }, ...storeSelectConsts.CLASS_NAMES]}
                                        placeholder={t("singleSelectPlaceholder")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="pr-md-3  pr-md-1" md="6">
                                <Form.Group className="has-success">
                                    <label>{t("studentsLabel")}</label>
                                    <Select
                                        className={"react-select primary " + (id ? "disabled" : "")}
                                        classNamePrefix="react-select"
                                        name="requiredClass"
                                        value={selectedStudent}
                                        onChange={(optionSelected) => {
                                            if (optionSelected.label === 'All') {
                                                setSelectedStudent(optionSelected)
                                                setUserId('')
                                            } else {
                                                setSelectedStudent({ value: optionSelected.value, label: optionSelected.label })
                                                setUserId(optionSelected?.value)
                                            }
                                        }}
                                        options={[{ value: '-1', label: "All" }, ...filteredList.map((student) => {
                                            return { value: student?.idcard_number, label: student?.student_name }
                                        })]
                                        }
                                        placeholder={t("singleSelectPlaceholder")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-end" style={{ marginBottom: '15px' }}>
                            <Pagination
                                itemsCount={allStudentsCount}
                                itemsPerPage={studentsPerPage}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                alwaysShown={false}
                            />
                            <div style={{ width: 155 }}>
                                <Select
                                    className="react-select primary ml-4"
                                    classNamePrefix="react-select"
                                    style={{ width: `100px` }}
                                    name="rowCnt"
                                    value={pageRowsCnt}
                                    onChange={(optionSelected) => {
                                        setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                                        setStudentsPerPage(optionSelected.value);
                                    }}
                                    options={[
                                        { value: 3, label: "3 " + t("rows") },
                                        { value: 5, label: "5 " + t("rows") },
                                        { value: 10, label: "10 " + t("rows") },
                                        { value: 15, label: "15 " + t("rows") },
                                        { value: 20, label: "20 " + t("rows") },
                                        { value: 25, label: "25 " + t("rows") },
                                    ]}
                                    placeholder={t("singleSelectPlaceholder")}
                                />
                            </div>
                        </div>
                        <Card className="stacked-form" style={{ padding: '10px 4px', marginTop: '10px' }} ref={(el) => (componentRef = el)}>
                            <Card.Body className="table-full-width card-con"
                                ref={parentRef}
                                style={{ height: screenSize <= 570 ? `${parentHeight * 2}px` : `${parentHeight}px` }}>
                                {limitedStudents.length > 0 && !id && !userId ?
                                    limitedStudents && limitedStudents.map((student, index) => (
                                        <Row style={{ justifyContent: 'center', top: `${(topPositionFront * index)}px`, }} className='card-row' >
                                            <Col className='d-flex justify-content-center pr-1 pl-1' >
                                                <Card className='card-main box student-card'
                                                    ref={cardRef}
                                                    style={{
                                                        top: index !== 0 ? `${topPositionFront * index}px` : `0px`,
                                                        margin: 'auto',
                                                    }}>
                                                    <Card.Body style={{ paddingBottom: "0px", position: 'relative', zIndex: '1' }} >
                                                        <div style={{
                                                            position: 'absolute',
                                                            height: '25%',
                                                            width: '12%',
                                                            backgroundColor: 'white',
                                                            top: '0',
                                                            right: '30px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            borderRadius: '0px 0px 8px 8px',
                                                            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                                        }}>
                                                            <img src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')} className='uni-logo'></img>
                                                        </div>
                                                        <div className='d-flex align-items-center  card-st-name'>
                                                            مرحلة جاميا فاروقوا كراتشي ||
                                                        </div>
                                                        <div className='d-flex justify-content-between mt-3 st-details'>
                                                            <div className='px-1 py-3 ml-4 position-relative col-8 '>
                                                                <div className='card-uni-name' >
                                                                    {common.name}
                                                                </div>
                                                                <div style={{ backgroundColor: 'black', color: 'white', marginLeft: '5px', fontSize: '0.7rem', letterSpacing: '1px', padding: '0px 2px' }} className="address" >Hub River Road,Kemari town,Karachi</div>
                                                                <div className='mt-3 student-detail'>
                                                                    <div>Name:<span className='ml-3'>{student?.student_name}</span></div>
                                                                    <div>F/Name:<span className='ml-3'>{student?.father_name}</span></div>
                                                                    <div>Date of birth:<span className='ml-3'>
                                                                        {(new Date(student?.birth_date).toLocaleDateString())}</span></div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-center flex-column  py-3 px-2  position-relative mr-3 avatar-con col-3'>
                                                                <span className='title'>Student ID card</span>
                                                                <div style={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                                                                    <img src={student?.avatar ? `data:image/png;base64,${student?.avatar}` : require('../assets/img/default-avatar')} style={{ height: '100%', width: '100%', borderRadius: '8px' }}  ></img>                                        </div>
                                                            </div>
                                                        </div>
                                                        <h6 className='d-flex align-items-center justify-content-center valid-date'
                                                        >Valid date:20/12/2025</h6>
                                                    </Card.Body>
                                                    <div className='student-background' style={{ zIndex: '0' }}>
                                                        <img
                                                            src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                            style={{ height: '50%', borderRadius: '50%' }}></img>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='d-flex justify-content-center pl-1 pr-1'>
                                                <Card className='card-main box student-card-back'
                                                    ref={cardRefBack}
                                                    style={{
                                                        top: screenSize <= 570 ? `${(topPositionFront * (index + 1))}px` : `${(topPositionFront * index)}px`,
                                                        margin: 'auto',
                                                    }}>
                                                    <Card.Body style={{ paddingBottom: "0px", zIndex: '1' }} >
                                                        <div className='student-detail px-3 py-1'>
                                                            <div className='stcard-back-font'>Res Address:<span className='ml-3'>{student?.permanent_address}</span></div>
                                                            <div className='stcard-back-font'>Class:<span className='ml-3' >{student?.class_name?.class_name}</span></div>
                                                            <div className='stcard-back-font'>Reg No:<span className='ml-3'>{student?.idcard_number}</span></div>
                                                            <div className='stcard-back-font'>Serial No:<span className='ml-3'>{'00000'}</span></div>
                                                        </div>
                                                        <div className='d-flex flex-row px-3 py-1 justify-content-between'>
                                                            <div style={{ fontWeight: '570' }} className='stcard-back-font' >In Case of Emergency Please Inform:</div>
                                                            <div className='stcard-back-font'>
                                                                <div>Taj husain</div>
                                                                <div>8889-999-990-78</div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex flex-row px-3 justify-content-between'>
                                                            <div style={{ fontWeight: '570', whiteSpace: 'nowrap' }} className='stcard-back-font' >National I.D Card Number:</div>
                                                            <div>
                                                                <table border="1" className='stcard-back-font'>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                    <td>1</td>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className='note px-3 py-1 mt-3'>
                                                            Note:This card is the property of Jamia Faruquee University Phase ||.
                                                            <div>if  found please return it to Jamia Faruquee University Phase ||.</div>
                                                            <div>Hub River,Kemari Town,Karachi,Pakistan.Tel:0333-3460114</div>
                                                        </div>
                                                    </Card.Body>
                                                    <div className='student-background' style={{ zIndex: '0' }}>
                                                        <img
                                                            src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                            style={{ height: '50%', borderRadius: '50%' }}></img>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ))
                                    :
                                    <Row className='card-row' style={{ justifyContent: 'center' }}>
                                        <Col className='d-flex justify-content-center pr-1 pl-1' >
                                            <Card
                                                className='card-main box student-card'
                                                ref={cardRef}
                                                style={{
                                                    top: `${topPositionFront * 0}px`,
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Card.Body
                                                    style={{ paddingBottom: "0px", position: 'relative', zIndex: '1' }} >
                                                    <div style={{
                                                        position: 'absolute',
                                                        height: '25%',
                                                        width: '12%',
                                                        backgroundColor: 'white',
                                                        top: '0',
                                                        right: '30px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: '0px 0px 8px 8px',
                                                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                                                    }}>
                                                        <img src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')} className='uni-logo'></img>
                                                    </div>
                                                    <div className='d-flex align-items-center  card-st-name'>
                                                        مرحلة جاميا فاروقوا كراتشي ||
                                                    </div>

                                                    <div className='d-flex justify-content-between mt-3'>
                                                        <div className=' px-1 py-3 ml-4 position-relative col-8'>
                                                            <div className='card-uni-name' >
                                                                {common.name}
                                                            </div>
                                                            <div style={{ backgroundColor: 'black', color: 'white', marginLeft: '5px', fontSize: '0.7rem', letterSpacing: '1px', padding: '0px 2px' }} className="address" >Hub River Road,Kemari town,Karachi</div>
                                                            <div className='mt-3  student-detail'>
                                                                <div>Name:<span className='ml-3'>{studentName}</span></div>
                                                                <div>F/Name:<span className='ml-3'>{fatherName}</span></div>
                                                                <div>Date of birth:<span className='ml-3'>{dob}</span></div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center flex-column  py-3 px-2  position-relative mr-3 avatar-con col-3'>
                                                            <span className='title'>Student ID card</span>
                                                            <div style={{ height: '100%', width: '100%', borderRadius: '5px' }}>
                                                                <img src={avtar ? `data:image/png;base64,${avtar}` : require('../assets/img/default-avatar')} style={{ height: '100%', width: '100%', borderRadius: '8px' }}  ></img>                                        </div>
                                                        </div>
                                                    </div>
                                                    <h6 className='d-flex align-items-center justify-content-center valid-date'
                                                    >Valid date:20/12/2025</h6>
                                                </Card.Body>
                                                <div className='student-background' style={{ zIndex: '0' }}>
                                                    <img
                                                        src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                        style={{ height: '50%', borderRadius: '50%' }}></img>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='d-flex justify-content-center pl-1 pr-1' style={{ padding: '2px' }}>
                                            <Card className='card-main box student-card-back'
                                                ref={cardRefBack}
                                                style={{
                                                    top: screenSize <= 570 ? `${(topPositionFront * 1)}px` : `${(topPositionFront * 0)}px`,
                                                    margin: 'auto',
                                                }}>
                                                <Card.Body style={{ paddingBottom: "0px", zIndex: '1' }} >
                                                    <div className=' student-detail px-3 py-1'>
                                                        <div className='stcard-back-font'>Res Address:<span className='ml-3'>{address}</span></div>
                                                        <div className='stcard-back-font'>Class:<span className='ml-3' >{className}</span></div>
                                                        <div className='stcard-back-font'>Reg No:<span className='ml-3'>{regNumber}</span></div>
                                                        <div className='stcard-back-font'>Serial No:<span className='ml-3'>{serialNumber}</span></div>
                                                    </div>
                                                    <div className='d-flex flex-row px-3 py-1 justify-content-between'>
                                                        <div style={{ fontWeight: '570' }} className='stcard-back-font' >In Case of Emergency Please Inform:</div>
                                                        <div className='stcard-back-font'>
                                                            <div>Taj husain</div>
                                                            <div>8889-999-990-78</div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-row px-3 px-2 justify-content-between'>
                                                        <div style={{ fontWeight: '570', whiteSpace: 'nowrap' }} className='stcard-back-font' >National I.D Card Number:</div>
                                                        <div>
                                                            <table border="1" className='stcard-back-font'>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                                <td>1</td>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='note px-3 py-1 mt-3'>
                                                        Note:This card is the property of Jamia Faruquee University Phase ||.
                                                        <div>if  found please return it to Jamia Faruquee University Phase ||.</div>
                                                        <div>Hub River,Kemari Town,Karachi,Pakistan.Tel:0333-3460114</div>
                                                    </div>
                                                </Card.Body>
                                                <div className='student-background' style={{ zIndex: '0' }}>
                                                    <img
                                                        src={common.profile ? common.profile : require('../assets/img/jamia-logo-removebg-preview.png')}
                                                        style={{ height: '50%', borderRadius: '50%' }}></img>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                        </Card>
                        {/* <Col xl="6" sm="6">
                        <Card className='card-main box' style={{ width: "100%", height: '250px' }}>
                            <Card.Body>
                                jhgjfgh
                            </Card.Body>
                        </Card>
                    </Col> */}
                        <div className='d-flex align-items-center justify-content-center'>
                            <ReactToPrint
                                trigger={() =>
                                    <Button className="btn-edit btn-fill" type="submit" variant="info">
                                        <span className="btn-label">
                                            <i className="fas fa-print"></i>
                                        </span>
                                        &nbsp;{t("btnPrint")}
                                    </Button>
                                }
                                content={() => componentRef}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
};
export default StudentCardPage