import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'authReducer',
  initialState: {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    user: null
  },
  reducers: {
    loginUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logoutUser: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
})

// Action creators are generated for each case reducer function
export const authInfo = (state) => state.authReducer;
export const { loginUser, setUser, logoutUser } = authSlice.actions

export default authSlice.reducer
