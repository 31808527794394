// react-bootstrap components
import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import customAxios from "../lib/axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from "react-notification-alert";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { authInfo, loginUser } from '../store/authReducer';
import { useDispatch, useSelector } from 'react-redux';
import ImgUpload from "../components/AvatarUpload";
import defaultUni from '../assets/img/default-uni';
import { isRequired } from '../lib/validation'
import { commonContext } from '../context/common'
function UserPage() {
  const [t] = useTranslation(['auth']);
  const dispatch = useDispatch();
  const [firstNameState, setFirstNameState] = React.useState(true)
  const [lastNameState, setLastNameState] = React.useState(true)
  const [emailState, setEmailState] = React.useState(true)
  const [avatarFile, setAvatarFile] = React.useState(null);
  const [userName, setUserName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [address, setAddress] = React.useState("")
  const [city, setCity] = React.useState("")
  const [country, setCountry] = React.useState("")
  const [psCode, setPsCode] = React.useState("")
  const [aboutme, setaboutme] = React.useState("")
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const [userId, setUserId] = React.useState(null)
  const storeAuthInfo = useSelector(authInfo);
  const profile = React.useContext(commonContext)
  const [avatarPreviewBase64, setAvatarPreviewBase64] = React.useState();
  const [defaultPreview, setDefaultPreview] = React.useState();
  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  }
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title='Error'
        onConfirm={() => hideAlert()}
        cancelBtnBsStyle='Cancel'
        showConfirm>
        {t("requiredField")}
      </SweetAlert>,
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
    //clearAll();
  };
  const clearAll = () => {
    setFirstName(storeAuthInfo?.user?.first_name)
    setLastName(storeAuthInfo?.user?.last_name)
    setUserName(storeAuthInfo?.user?.username)
    setEmail(storeAuthInfo?.user?.email)
    setAddress(storeAuthInfo?.user?.address)
    setCity(storeAuthInfo?.user?.city)
    setCountry(storeAuthInfo?.user?.country)
    setPsCode(storeAuthInfo?.user?.postal_code)
    setaboutme(storeAuthInfo?.user?.about_me)
    // setAvatarPreviewBase64(profile?.userProfile)
  }
  const handleChange = (image) => {
    if (image !== '') {
      setAvatarPreviewBase64(image)
    } else {
      setAvatarPreviewBase64(defaultPreview)
    }
  }
  const successAlert = (idcardNumber) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('submitSuccessAlertTitle')}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle='info'
        confirmBtnText={t('btnOkay')}>
        {t("profileUpdated")}
      </SweetAlert>,
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (firstName === "" || lastName === "" || email === "") {
        warningWithConfirmMessage();
      } else {
        avatarFile !== null && avatarPreviewBase64 !== null ?
          await customAxios.post("/common/uploadimg", { 'image': avatarPreviewBase64, 'name': email }, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }) : ''
        await customAxios.post(`/user/update/${userId}`, {
          first_name: firstName,
          last_name: lastName,
          email: email,
          username: userName,
          address: address,
          postal_code: psCode,
          city: city,
          country: country,
          about_me: aboutme,
        }).then((res) => {
          if (res?.data?.msg == "success") {
            successAlert()
            setDefaultPreview(avatarPreviewBase64)
          }
        }).catch((err) => {
          notify(t("notifyConnectionProblem"), "tr", "danger");
          console.log(err)
        })
        successAlert()
        dispatch(loginUser({
          first_name: firstName,
          last_name: lastName,
          email: email,
          username: userName,
          address: address,
          postal_code: psCode,
          city: city,
          country: country,
          about_me: aboutme,
          profile: avatarPreviewBase64
        }))
        profile.setUserProfile(avatarPreviewBase64)
      }
    } catch (err) {
      notify(t("notifyConnectionProblem"), "tr", "danger");
      console.log(err)
    }
  }
  const getUser = async () => {
    await customAxios.post("/user/getUser")
      .then(async (res) => {
        setUserId(res?.data?.id)
        setFirstName(res?.data?.first_name)
        setLastName(res?.data?.last_name)
        setUserName(res?.data?.username)
        setEmail(res?.data?.email)
        setAddress(res?.data?.address)
        setCity(res?.data?.city)
        setCountry(res?.data?.country)
        setPsCode(res?.data?.postal_code)
        setaboutme(res?.data?.about_me)
        if (res?.data?.email !== undefined) {
          const img = await customAxios.post("/common/getAvatarImg", { idcard_number: res?.data?.email })
          let base64img2 = img.data.base64data ? ("data:image/png;base64," + img.data.base64data) : null;
          setAvatarPreviewBase64(base64img2)
          setDefaultPreview(base64img2)
          setAvatarFile("file")
        } else {
          setAvatarPreviewBase64("")
          setAvatarFile("")
          setDefaultPreview('')
        }
      })
  }
  React.useEffect(() => {
    getUser()
  }, [])
  return (
    <>
      {alert}
      <div div className="rna-container" >
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <div className="section-image" data-image="../../assets/img/bg5.jpg">
          {/* you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " */}
          <Container>
            <Row className="user-profile-con">
              <Col md="8" className="col-md-6">
                <Form action="" className="form" method="Post">
                  <Card className="pl-3 pr-3">
                    <Card.Header>
                      <Card.Header>
                        <Card.Title as="h4">{t("editProfileLabel")}</Card.Title>
                      </Card.Header>
                    </Card.Header>
                    <Card.Body>
                      {/* <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group>
                            <label>Company (disabled)</label>
                            <Form.Control
                              defaultValue="Creative Code Inc."
                              disabled
                              placeholder="Company"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group className="has-success">
                            <label>{t("userNameLabel")}</label>
                            <Form.Control
                              defaultValue="michael23"
                              placeholder="Add Uaser Name"
                              type="text"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className={
                            "has-label " +
                            (emailState ? "has-success" : "has-error")
                          }>
                            <label htmlFor="exampleInputEmail1">
                              {t("emailLabel")}
                            </label>
                            <Form.Control
                              placeholder="Email"
                              type="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                if (isRequired(e.target.value)) {
                                  setEmailState(true);
                                } else {
                                  setEmailState(false);
                                }
                              }}
                            ></Form.Control>
                            {emailState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="6">
                          <Form.Group className={
                            "has-label " +
                            (firstNameState ? "has-success" : "has-error")
                          }>
                            <label>{t("firstNameLabel")}</label>
                            <Form.Control
                              defaultValue="Mike"
                              placeholder="First Name"
                              type="text"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value)
                                if (isRequired(e.target.value)) {
                                  setFirstNameState(true);
                                } else {
                                  setFirstNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {firstNameState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="6">
                          <Form.Group className={
                            "has-label " +
                            (lastNameState ? "has-success" : "has-error")
                          }>
                            <label>{t("lastNameLabel")}</label>
                            <Form.Control
                              defaultValue="Andrew"
                              placeholder="Last Name"
                              type="text"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value)
                                if (isRequired(e.target.value)) {
                                  setLastNameState(true);
                                } else {
                                  setLastNameState(false);
                                }
                              }}
                            ></Form.Control>
                            {lastNameState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group className="has-success">
                            <label>{t("addressLabel")}</label>
                            <Form.Control
                              defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                              placeholder="Add Home Address"
                              type="text"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("cityLabel")}</label>
                            <Form.Control
                              defaultValue="Mike"
                              placeholder="Add City"
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="px-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("countryLabel")}</label>
                            <Form.Control
                              defaultValue="Andrew"
                              placeholder="Add Country"
                              type="text"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-md-1" md="4">
                          <Form.Group className="has-success">
                            <label>{t("postalCodeLabel")}</label>
                            <Form.Control
                              placeholder="Add ZIP Code"
                              type="number"
                              value={psCode}
                              onChange={(e) => setPsCode(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group className="has-success">
                            <label>{t("aboutMeLabel")}</label>
                            <Form.Control
                              cols="80"
                              defaultValue="Mike"
                              placeholder="Write something about yourself"
                              rows="4"
                              value={aboutme}
                              onChange={(e) => setaboutme(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="info"
                        onClick={handleSubmit}
                      >
                        {t("btnUpdateProfile")}
                      </Button>
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
              <Col md="4">
                <Card className="card-user padding-zero ">
                  <Card.Header>
                    <div className="card-image">
                      <img
                        alt="..."
                        //src={require("assets/img/full-screen-image-3.jpg")}
                        src={profile.bgImage}
                      ></img>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="author">
                      {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                      {/* <img
                        alt="..."
                        className="avatar border-gray"
                        src={require("assets/img/root-avatar.png")}
                      ></img> */}
                      <div className="imageUpload">
                        <ImgUpload curPreviewImage={avatarPreviewBase64 || ''}
                          //  onChangePrivewImage={setAvatarPreviewBase64}
                          onChangePrivewImage={handleChange}
                          onChangeAvatarFile={setAvatarFile}
                          flag={true}
                        />
                      </div>
                      <Card.Title as="h5" style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', minHeight: '80%' }}>{firstName} {lastName}</Card.Title>
                      {/* </a> */}
                      <p className="card-description" >{userName}</p>
                    </div>
                    <p className="card-description text-center pr-2 pl-2">
                      {aboutme}
                    </p>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    {/* <div className="button-container text-center">
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-twitter"></i>
                      </Button>
                      <Button
                        className="btn-simple btn-icon"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        variant="link"
                      >
                        <i className="fab fa-google-plus-square"></i>
                      </Button>
                    </div> */}
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default UserPage;
