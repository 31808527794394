import PropTypes, { element } from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { commonContext } from '../../context/common'

// react-bootstrap components
import {
    Badge,
    Dropdown,
    Form
} from "react-bootstrap";
function FixedPlugin({
    setSidebarImageParent,
    setSidebarBackgroundParent,
    sidebarDefaultImage,
    sidebarImages,
    backgroundColors,
    backgroundColor,
    wrapperRef
}) {
    const [t] = useTranslation(["sidebar"]);
    const [imageSwitch, setImageSwitch] = React.useState(true);
    //const [sidebarSwitch, setSidebarSwitch] = React.useState(false);
    const [navbarSwitch, setNavbarSwitch] = React.useState(false);
    const [sidebarImage, setSidebarImage] = React.useState(sidebarDefaultImage);
    const [isDragging, setIsDragging] = React.useState(false);
    const [position, setPosition] = React.useState({ x: 0, y: 200 });
    const [dragTimeout, setDragTimeout] = React.useState(null);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isOverButton, setIsOverButton] = React.useState(false);
    const pluginRef = React.useRef(null);
    const dragStartPos = React.useRef({ x: 0, y: 0 });
    const common = useContext(commonContext)

    const handleMouseEnter = () => {
        setIsOverButton(true);
    };
    const handleMouseLeave = () => {
        setIsOverButton(false);
    };
    const handleDragStart = (e) => {
        if (isOverButton) {
            setIsDragging(true)
        } else {
            setIsDragging(false)
        }
        dragStartPos.current = {
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        };
    };
    const handleDragMove = (e) => {
        if (!isDragging || !isOverButton || isMenuOpen) return;
        if (isDragging && isOverButton) {
            const offsetX = e.clientX - dragStartPos.current.x;
            let offsetY = e.clientY - dragStartPos.current.y;
            offsetY = Math.max(45, Math.min(offsetY, window.innerHeight - 80));
            setPosition({ x: offsetX, y: offsetY });
        }
    };
    const handleDragEnd = () => {
        setIsDragging(false);
    };
    React.useEffect(() => {
        document.addEventListener("mousemove", handleDragMove);
        document.addEventListener("mouseup", handleDragEnd);
        return () => {
            document.removeEventListener("mousemove", handleDragMove);
            document.removeEventListener("mouseup", handleDragEnd);
        };
    }, [isDragging])
    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            setIsDragging(false)
        }
    };
    return (
        <>
            <div
                ref={pluginRef}
                onMouseDown={handleDragStart}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="fixed-plugin"
                style={{ position: 'fixed', left: common.lang === 'ar' ? 0 : null, top: position.y, cursor: 'pointer' }}
            >
                {/* <div className='fixed-plugin'> */}
                <Dropdown className="show-dropdown" show={isMenuOpen} onClose={() => setIsMenuOpen(false)} >
                    <Dropdown.Toggle onClick={handleToggleMenu}>
                        <i className="fas fa-cogs fa-2x"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={position.y >= window.innerHeight * 0.5 ? 'modified-top' : 'custom-top'}>
                        <li className="header-title">{t("sidebarStyle")}</li>
                        <li className="adjustments-line d-flex justify-content-between align-items-center">
                            <p className="pt-0">{t("backgroundImage")}</p>
                            <Form.Check
                                type="switch"
                                id="custom-switch-1-image"
                                checked={imageSwitch}
                                onChange={() => {
                                    imageSwitch
                                        ? setSidebarImageParent("")
                                        : setSidebarImageParent(sidebarImage);
                                    setImageSwitch(!imageSwitch);
                                }}
                            />
                        </li>
                        <li className="adjustments-line d-flex justify-content-between align-items-center">
                            <p className="pt-0">{t("sidebarMini")}</p>
                            <Form.Check
                                type="switch"
                                id="custom-switch-2-sidebarSwitch"
                                checked={common.sidebarSwitch}
                                onChange={() => {
                                    const element = document.getElementsByClassName("wrapper")
                                    if (element) {
                                        element[0].classList.contains("sidebar-mini")
                                            ? common?.setSidebarSwitch(false)
                                            : common?.setSidebarSwitch(true);
                                        element[0].classList.toggle("sidebar-mini")
                                    }
                                }}
                            />
                        </li>
                        <li className="adjustments-line d-flex justify-content-between align-items-center">
                            <p className="pt-0">{t("fixedNavbar")}</p>
                            <Form.Check
                                type="switch"
                                id="custom-switch-3-navbar"
                                checked={navbarSwitch}
                                onChange={() => {
                                    setNavbarSwitch(!navbarSwitch);
                                    document.querySelector(".navbar")?.classList.toggle("fixed-top");
                                    document.querySelector(".content")?.classList.toggle("mtc-5");
                                }}
                            />
                        </li>
                        <li className="adjustments-line d-flex justify-content-between align-items-center">
                            <p className="pt-0">{t("filters")}</p>
                            <div>
                                {backgroundColors.map((prop, key) => (
                                    <Badge
                                        key={key}
                                        variant={prop}
                                        onClick={() => setSidebarBackgroundParent(prop)}
                                        className={prop === backgroundColor ? "active" : ""}
                                    ></Badge>
                                ))}
                            </div>
                        </li>
                        <li className="header-title">{t("sidebarImages")}</li>
                        {sidebarImages.map((prop, key) => (
                            <li
                                key={key}
                                className={prop === sidebarDefaultImage ? "active" : ""}
                            >
                                <a
                                    className="img-holder"
                                    onClick={
                                        () => {
                                            setSidebarImageParent(prop)
                                            common.setBgImage(prop)
                                        }
                                    }
                                >
                                    <img alt="..." src={prop}></img>
                                </a>
                            </li>
                        ))}
                        {/* <li className="button-container">
              <div>
                <Button
                  block
                  href="http://www.creative-tim.com/product/light-bootstrap-dashboard-react?ref=lbpr-fp"
                  target="_blank"
                  variant="info"
                >
                  Get free demo!
                </Button>
              </div>
            </li>
            <li className="button-container">
              <div>
                <Button
                  block
                  className="btn-fill"
                  href="http://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react?ref=lbpr-fp"
                  target="_blank"
                  variant="warning"
                >
                  Buy now!
                </Button>
              </div>
            </li>
            <li className="button-container">
              <div>
                <Button
                  block
                  href="https://demos.creative-tim.com/light-bootstrap-dashboard-pro-react/#/documentation/tutorial?ref=lbpr-fp"
                  target="_blank"
                  variant="danger"
                >
                  Documentation
                </Button>
              </div>
            </li> */}
                        <li className="header-title" id="sharrreTitle">
                            {t("copyrightInfo")}
                        </li>
                        {/* <li className="button-container d-flex justify-content-center">
              <Button className="mr-2" id="twitter" variant="twitter">
                <i className="fab fa-twitter"></i>
              </Button>
              <Button id="facebook" variant="facebook">
                <i className="fab fa-facebook-square"></i>
              </Button>
            </li> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}
FixedPlugin.defaultProps = {
    setSidebarImageParent: () => { },
    setSidebarBackgroundParent: () => { },
    sidebarDefaultImage: "",
    sidebarImages: [],
    backgroundColors: [],
};
FixedPlugin.propTypes = {
    setSidebarImageParent: PropTypes.func,
    setSidebarBackgroundParent: PropTypes.func,
    sidebarDefaultImage: PropTypes.string,
    sidebarImages: PropTypes.arrayOf(PropTypes.string),
    // these are colors that can be passed to the Badge component
    backgroundColors: PropTypes.arrayOf(PropTypes.string),
};
export default FixedPlugin;
