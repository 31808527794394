import Book from "views/Book.js";
import Class from "views/Class.js";
import Dashboard from "views/Dashboard.js";
import Department from "views/Department.js";
import EditProfile from "views/EditProfile";
import EmployeesAddEdit from "views/EmployeesAddEdit.js";
import EmployeesList from "views/EmployeesList.js";
import ExamCreate from "views/ExamCreate.js";
import ExamList from "views/ExamList.js";
import ExamNameCreate from "views/ExamNameCreate.js";
import ExamSeatGeneration from "views/ExamSeatGeneration.js";
import GradeResult from "views/GradeResults.js";
import PercentageReport from "views/PercentageReport.js";
import PositionHolders from "views/PositionHolders.js";
import PrintMaterial from "views/PrintMaterial.js";
import ResultEnter from "views/ResultEnter.js";
import ResultGradeStructure from "views/ResultGradeStructure.js";
import ResultList from "views/ResultList.js";
import ResultView from "views/ResultView.js";
import StudentsAddEdit from "views/StudentsAddEdit.js";
import StudentsList from "views/StudentsList.js";
import TeacherGrading from "views/TeacherGrading";
import UserPage from "views/UserProfile";
import EmployeeCardsPage from "views/EmployeeCards";
import StudentCardPage from "views/StudentCard"
var routes = [
  // {
  //   path: "/userlogin",
  //   layout: "/admin",
  //   name: "User Login",
  //   mini: "UP",
  //   component: UserLogin
  // },
  // {
  //   path: "/userregister",
  //   layout: "/admin",
  //   name: "User Register",
  //   mini: "UP",
  //   component: UserRegister
  // },
  // {
  //   path: "/userprofile",
  //   layout: "/admin",
  //   name: "User Profile",
  //   mini: "UP",
  //   component: UserProfile
  // },
  // {
  //   path: "/lockscreen",
  //   layout: "/admin",
  //   name: "Lock Screen",
  //   mini: "",
  //   component: LockScreen
  // },23434
  {
    path: "/dashboard",
    layout: "/admin",
    name: "dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard
  },
  {
    collapse: true,
    path: "/components",
    name: "students",
    state: "openStudents",
    icon: "nc-icon nc-single-02",
    views: [
      {
        path: "/add_edit_students",
        layout: "/admin",
        name: "addEditStudents",
        // mini: "A/E",
        exact: true,
        component: StudentsAddEdit
      },
      {
        path: "/add_edit_students/:id",
        layout: "/admin",
        name: "addEditStudents",
        // mini: "A/E",
        component: StudentsAddEdit,
        hide: true
      },
      {
        path: "/list_students",
        layout: "/admin",
        name: "listStudents",
        // mini: "LS",
        component: StudentsList
      },
    ]
  },
  {
    collapse: true,
    path: "/employees",
    name: "employees",
    state: "openEmployees",
    icon: "nc-icon nc-circle-09",
    views: [
      {
        path: "/add_edit_employee",
        layout: "/admin",
        name: "addEditEmployee",
        // mini: "A/E",
        exact: true,
        component: EmployeesAddEdit
      },
      {
        path: "/add_edit_employee/:id",
        layout: "/admin",
        name: "addEditEmployee",
        // mini: "A/E",
        component: EmployeesAddEdit,
        hide: true
      },
      {
        path: "/list_employee",
        layout: "/admin",
        name: "listEmployee",
        // mini: "LS",
        component: EmployeesList
      },
    ]
  },
  {
    path: "/departments",
    layout: "/admin",
    name: "departments",
    icon: "nc-icon nc-grid-45",
    component: Department
  },
  {
    path: "/class",
    layout: "/admin",
    name: "class",
    icon: "nc-icon nc-grid-45",
    component: Class
  },
  {
    path: "/book",
    layout: "/admin",
    name: "book",
    icon: "nc-icon nc-single-copy-04",
    component: Book
  },
  {
    collapse: true,
    path: "/exams",
    name: "exams",
    state: "openExams",
    icon: "nc-icon nc-watch-time",
    views: [
      {
        path: "/create_main_exam_name",
        layout: "/admin",
        name: "createExamName",
        // mini: "EN",
        component: ExamNameCreate
      },
      {
        path: "/create_exam",
        layout: "/admin",
        name: "createEditExam",
        // mini: "CE",
        exact: true,
        component: ExamCreate
      },
      {
        path: "/create_exam/:exam_nameid/:classname_id",
        layout: "/admin",
        name: "createEditExam",
        // mini: "CE",
        component: ExamCreate,
        hide: true
      },
      {
        path: "/list_exam",
        layout: "/admin",
        name: "listExam",
        // mini: "LE",
        component: ExamList
      },
      {
        path: "/gen_seat_numbers",
        layout: "/admin",
        name: "genExamSeat",
        // mini: "SN",
        component: ExamSeatGeneration
      },
    ]
  },
  {
    collapse: true,
    path: "/results",
    name: "results",
    state: "openResults",
    icon: "nc-icon nc-chart-bar-32",
    views: [
      {
        path: "/enter_exam_results",
        layout: "/admin",
        name: "enterResults",
        // mini: "ER",
        component: ResultEnter
      },
      {
        path: "/list_results",
        layout: "/admin",
        name: "listExamRsults",
        // mini: "LR",
        component: ResultList
      },
      {
        path: "/view_results",
        layout: "/admin",
        name: "viewExamRsults",
        // mini: "VR",
        exact: true,
        component: ResultView
      },
      {
        path: "/view_results/:examname_id/:classname_id",
        layout: "/admin",
        name: "viewExamRsults",
        // mini: "VR",
        component: ResultView,
        hide: true
      },
      {
        path: "/percentage_report",
        layout: "/admin",
        name: "percentageReport",
        // icon: "nc-icon nc-grid-45",
        component: PercentageReport
      },
      {
        path: "/position_holders",
        layout: "/admin",
        name: "viewPositionHolders",
        // mini: "PR",
        exact: true,
        component: PositionHolders
      },
      {
        path: "/teacher_grading",
        layout: "/admin",
        name: "teacherGrading",
        // mini: "PR",
        exact: true,
        component: TeacherGrading
      },
      {
        path: "/grade_structures",
        layout: "/admin",
        name: "gradeStructures",
        // mini: "GS",
        component: ResultGradeStructure
      },
      {
        path: "/grade_result",
        layout: "/admin",
        name: 'gradeResult',
        // mini: 'GR',
        component: GradeResult
      },
    ]
  },
  {
    collapse: true,
    path: "/inventory",
    name: "inventory",
    state: "openInventory",
    icon: "nc-icon nc-settings-90",
    views: [
      {
        path: "/add_edit_inventory",
        layout: "/admin",
        name: "addEditInventory",
        // mini: "A/E",
        component: Dashboard
      },
      {
        path: "/list_inventory",
        layout: "/admin",
        name: "listInventory",
        // mini: "LI",
        component: Dashboard
      },
    ]
  },
  {
    collapse: true,
    path: "/cards",
    name: "cards",
    state: "openCards",
    icon: "nc-icon nc-badge",
    views: [
      {
        path: "/student_cards",
        layout: "/admin",
        name: "StudentCards",
        exact: true,
        // mini: "LC",
        component: StudentCardPage
      },
      {
        path: "/student_cards/:id",
        layout: "/admin",
        name: "StudentCards",
        hide: true,
        // mini: "LC",
        component: StudentCardPage
      },
      {
        path: "/employee_cards",
        layout: "/admin",
        name: "EmployeeCards",
        exact: true,
        // mini: "LC",
        component: EmployeeCardsPage
      },
      {
        path: "/employee_cards/:id",
        layout: "/admin",
        name: "EmployeeCards",
        hide: true,
        // mini: "LC",
        component: EmployeeCardsPage
      },
    ]
  },
  {
    path: "/print_empty_student_add_edit",
    layout: "/admin",
    name: "printMaterial",
    icon: "nc-icon nc-paper-2",
    component: PrintMaterial
  },
  {
    path: '/edit_profile',
    layout: "/admin",
    name: "editUniProfile",
    icon: "nc-icon nc-paper-2",
    hide: true,
    component: EditProfile
  },
  {
    path: '/edit_user_profile',
    layout: '/admin',
    name: 'editUserProfile',
    hide: true,
    component: UserPage
  }
];
export default routes;
