import React, { useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { selectConsts } from '../store/selectconstReducer';

import customAxios from "lib/axios";
import { useTranslation } from "react-i18next";

// react-bootstrap components
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner
} from "react-bootstrap";
function PercentageReport() {
  const dispatch = useDispatch();
  const [t] = useTranslation(["exam"]);
  let componentRef = useRef();
  const storeSelectConsts = useSelector(selectConsts);
  React.useEffect(() => {
    customAxios.get("/common/consts")
      .then((res) => {
        dispatch(setAll(res.data));
      })
      .catch((err) => {
      });
  }, [])
  const [employees, setEmployees] = React.useState([]);
  const [empName, setEmpName] = React.useState({ value: '', label: 'All' });
  const [examName, setExamName] = React.useState({ value: '', label: 'All' });
  const [curClass, setCurClass] = React.useState({ value: '', label: 'All' });
  const [topStudents, setTopStudents] = React.useState([]);
  const [newArray, setNewArray] = React.useState([]);
  const [newExamArray, setNewExamArray] = React.useState([]);
  const [loading, setLoading] = React.useState(true)


  React.useEffect(() => {
    customAxios.get("/employee/getAll")
      .then((res) => {
        const employees = res?.data?.employees.filter(employee => employee?.employee_currentstatus?.employee_curstatusname === "Working" && employee?.employee_jobrole?.job_role === 'Teacher'
        );
        const employeeData = {
          id: '',
          employee_name: 'All'
        };
        const data = [employeeData, ...employees]
        setEmployees(data);
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }, []);

  const employeesOptions = employees.map((employee) => ({
    label: employee.employee_name,
    value: employee.id
  }));

  React.useEffect(() => {
    const newObject = {
      value: '',
      label: 'All',
      class_type: "Local",
      department_id: 3
    };
    const newExamObject = {
      value: '',
      label: 'All',
      exam_datetime: ""
    };
    const newArray1 = [newObject, ...storeSelectConsts.CLASS_NAMES];
    const newExamArray1 = [newExamObject, ...storeSelectConsts.EXAM_NAMES];
    setNewExamArray(newExamArray1);
    setNewArray(newArray1)
    customAxios.get(`/exam/getPercentageReport?examId=${examName?.value}&classId=${curClass?.value}`)
      .then((res) => {
        setTopStudents(res.data);
        setLoading(false)
      })
      .catch((err) => {
      });
  }, [storeSelectConsts, curClass, examName]);
  const renderClassCard = (classData, t) => {
    const filteredData = empName && empName.value !== ''
      ? classData.filter(stu => stu.employee_id === empName.value)
      : classData;
    // const filteredData = classData;
    const bookNumber = filteredData?.reduce((total, stu) => {
      return total + (stu.number ? parseFloat(stu.number) : 0);
    }, 0);
    const totalGrade = filteredData?.reduce((total, stu) => {
      return total + (stu.exam_total_grade ? parseFloat(stu.exam_total_grade) : 0);
    }, 0);
    const totalPercentage = (totalGrade / bookNumber) * 100;

    return (
      <Card className="stacked-form">
        {/* <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card.Title>{filteredData[0]?.class_names[0]?.class_name}</Card.Title>
        </Card.Header> */}
        <Card.Body className="overflow-auto table-full-width table-container">
          {filteredData?.length > 0 ? <Table ref={componentRef}>
            <thead id="demo">
              <tr>
                <th className="text-left" style={{ paddingLeft: '8px' }}>{t("className")}</th>
                <th className="text-center">{t("bookName")}</th>
                <th className="text-left">{t("examName")}</th>
                <th className="text-center">{t("examTotalGrade")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((stu, index1) => {
                let count = 0;
                return (
                  <tr key={index1}>
                    <td className="text-left" style={{ paddingLeft: '8px' }}>{stu.class_names[0]?.class_name ? stu.class_names[0]?.class_name : '-'}</td>
                    <td className="text-center">{stu.book_name ? stu.book_name : '-'}</td>
                    <td className="text-left">
                      {stu.exam_books.some((book) => book?.exam_instance?.length > 0) ? (
                        stu.exam_books.map((book, bookIndex) => {
                          if (book?.exam_instance?.length !== 0) {
                            count++
                            return (<p key={bookIndex} style={{ margin: '0px' }}>
                              <b>{count}.</b>
                              {book?.exam_instance?.length > 0
                                ? book?.exam_instance[0]?.exam_name?.exam_name
                                : null
                              }
                              {bookIndex < stu.exam_books.length - 1 && book?.exam_instance?.length > 0 && examName === null ? ', ' : ''}
                            </p>
                            )
                          } else {
                            return ""
                          }
                        })
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-center" style={{ display: 'table-cell' }}>{stu.exam_total_grade ? stu.exam_total_grade : '-'}</td>
                  </tr>)
              })}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-center" colSpan={3}><strong>{t("totalPercentage")}</strong></td>
                <td className="text-center"><strong>{totalPercentage.toFixed(2)}</strong></td>
              </tr>
            </tfoot>
          </Table> : <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noPercentageReportFound")}</div>}
        </Card.Body>
      </Card>
    );
  };
  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <div ref={componentRef}>
            <Card className="stacked-form">
              <Row ref={(el) => (componentRef = el)} style={{ padding: '10px', display: 'contents' }}>
                <Card.Header>
                  <Card.Title as="h4">{t("percentageReport")}</Card.Title>
                </Card.Header>
                <Card.Body className="table-full-width">
                  <Row className="no-print">
                    <Col className="p1-1" md="4">
                      <Form.Group>
                        <label>{t("className")}</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="className"
                          value={curClass ? curClass : null}
                          onChange={(value) => {
                            setCurClass(value)
                          }}
                          options={newArray.filter((ele) => ele.isActive)}
                          placeholder={t("singleSelect")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="p1-1" md="4">
                      <Form.Group>
                        <label>{t("examName")}</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="examName"
                          value={examName ? examName : null}
                          onChange={(value) => {
                            setExamName(value);
                          }}
                          options={newExamArray}
                          placeholder={t("singleSelect")}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="p1-1" md="4">
                      <Form.Group>
                        <label>{t("teacherName")}</label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="employeeName"
                          value={empName ? empName : null}
                          onChange={(value) => {
                            setEmpName(value);
                          }}
                          options={employeesOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          placeholder={t("singleSelect")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="print">
                    <label>{examName ? examName?.label : storeSelectConsts.EXAM_NAMES[0]?.label}</label>
                  </Row>
                  {!loading ?
                    (topStudents && topStudents?.length !== 0 ?
                      renderClassCard(topStudents, t)
                      :
                      <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noPercentageReportFound")}</div>)
                    : (
                      <div className="d-flex align-items-center justify-content-center my-5">
                        <Spinner animation="border" variant="warning" />
                      </div>
                    )}
                </Card.Body>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </Container >
  );
}

export default PercentageReport;