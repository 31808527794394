import React from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
// react plugin used to create datetimepicker
import ImgUpload from 'components/AvatarUpload';
import customAxios from 'lib/axios';
import { isRequired } from 'lib/validation';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReactDatetime from 'react-datetime';
import NotificationAlert from 'react-notification-alert';
import { useSelector, useDispatch } from 'react-redux';
import { selectConsts, addJobRole } from '../store/selectconstReducer';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import Webcam from 'react-webcam';
import { useHistory } from "react-router-dom";
import { commonContext } from '../context/common'
// react-bootstrap components
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
function EmployeesAddEdit() {
    const [t] = useTranslation(['employees']);
    const common = React.useContext(commonContext)
    const history = useHistory();
    const { id } = useParams();
    const storeSelectConsts = useSelector(selectConsts);
    const dispatch = useDispatch()
    const [alert, setAlert] = React.useState(null);
    const notificationAlertRef = React.useRef(null);
    const [joinYear, setJoinYear] = React.useState(new Date().getFullYear());
    const [employeeName, setEmployeeName] = React.useState('');
    const [fatherName, setFatherName] = React.useState('');
    const [qualification, setQualification] = React.useState('');
    // const [fathersProfession, setFathersProfession] = React.useState('');
    const [birthDate, setBirthDate] = React.useState('');
    //  const [dependency, setDependency] = React.useState(storeSelectConsts.DEPENDENCY_NAMES[0]);
    const [jobTypes, setJobTypes] = React.useState(''); //storeSelectConsts.EMPLOYEE_JOBTYPES[0]);
    //const [admissionStatus, setAdmissionStatus] = React.useState(
    //     storeSelectConsts.ADMISSION_STATUSES[0],
    // );
    const [currentStatus, setCurrentStatus] = React.useState();
    const [jobrole, setJobrole] = React.useState();
    const [gender, setGender] = React.useState();
    const [city, setCity] = React.useState('');
    const [province, setProvince] = React.useState('');
    const [country, setCountry] = React.useState();
    const [birthPlace, setBirthPlace] = React.useState('');
    const [prevexamGrade, setPrevexamGrade] = React.useState('');
    const [tempAddress, setTempAddress] = React.useState('');
    const [permanentAddress, setPermanentAddress] = React.useState('');
    const [idcardNumber, setIdcardNumber] = React.useState(id);
    const [cnicNumber, setCnicNumber] = React.useState('');
    const [contactNumber, setContactNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [referenceName, setReferenceNam] = React.useState('');
    const [referenceContact, setReferenceContact] = React.useState('');
    const [termSign, setTermSign] = React.useState(false);
    const [supervisorSign, setSupervisorSign] = React.useState(false);
    const [eduheaderSign, setEduheaderSign] = React.useState(false);
    const [adminSign, setAdminSign] = React.useState(false);
    const [avatarFile, setAvatarFile] = React.useState(null);
    const [avatarPreviewBase64, setAvatarPreviewBase64] = React.useState(null);
    // Validations
    const [employeeNameState, setEmployeeNameState] = React.useState(true);
    const [fatherNameState, setFatherNameState] = React.useState(true);
    const [qualificationState, setQualificationState] = React.useState(true);
    const [birthDateState, setBirthDateState] = React.useState(true);
    const [jobTypesState, setJobTypesState] = React.useState(true);
    const [currentStatusState, setCurrentStatusState] = React.useState(true);
    const [jobRoleState, setJobRoleState] = React.useState(true);
    const [genderState, setGenderState] = React.useState(true);
    const [cityState, setCityState] = React.useState(true);
    const [provinceState, setProvinceState] = React.useState(true);
    const [countryState, setCountryState] = React.useState(true);
    const [countryNameState, setCountryNameState] = React.useState(true);
    const [birthPlaceState, setBirthPlaceState] = React.useState(true);
    const [prevexamGradeState, setPrevexamGradeState] = React.useState(true);
    const [tempAddressState, setTempAddressState] = React.useState(true);
    const [permanentAddressState, setPermanentAddressState] = React.useState(true);
    const [idcardNumberState, setIdcardNumberState] = React.useState(true);
    const [cnicNumberState, setCnicNumberState] = React.useState(true);
    const [contactNumberState, setContactNumberState] = React.useState(true);
    const [emailState, setEmailState] = React.useState(true);
    const [referenceNameState, setReferenceNamState] = React.useState(true);
    const [referenceContactState, setReferenceContactState] = React.useState(true);
    const [isExperienced, setIsExperienced] = React.useState(false);
    const [counter, setCounter] = React.useState(1);
    const [deleted, setDeleted] = React.useState([]);
    const [isMediumScreen, setIsMediumScreen] = React.useState(false);
    const [jobinfo, setJobinfo] = React.useState([
        {
            id: 0,
            name: `job0`,
            job_title: '',
            ctc: '',
            start_date: '',
            end_date: '',
            reasonfor_leave: '',
        },
    ]);
    const initialJobInfoState = {
        id: 0,
        jobTitleState: true,
        ctcState: true,
        startDateState: true,
        reasonForLeaveState: true,
    };
    const [jobinfoState, setJobInfoState] = React.useState(Array(jobinfo.length).fill(initialJobInfoState));
    // const [jobinfoState, setJobInfoState] = React.useState(jobinfo.map(() => true))
    const [isCountryName, setIsCountryName] = React.useState(false);
    const [isJobRoleName, setIsJobRoleName] = React.useState(false)
    const [jobRoleName, setJobRoleName] = React.useState('')
    const [jobRoleNameState, setJobRoleNameState] = React.useState(true)
    const [countryName, setCountryName] = React.useState('');
    const [formSubmitted, setFormSubmitted] = React.useState(false);

    //handle arrow key events
    const handleKeyDown = (event) => {
        const focusableElements = document.querySelectorAll(
            'input, select, textarea, button, [tabindex]:not([tabindex="-1"],.react-select)'
        );
        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
            event.preventDefault(); // Prevent default arrow key behavior
            const activeElement = document.activeElement;
            const currentIndex = Array.from(focusableElements).indexOf(activeElement);
            if (currentIndex !== -1) {
                let nextIndex;
                if (event.key === 'ArrowRight') {
                    nextIndex = (currentIndex + 1) % focusableElements.length; // Move to next element
                } else if (event.key === 'ArrowLeft') {
                    nextIndex = (currentIndex - 1 + focusableElements.length) % focusableElements.length; // Move to previous element
                }
                let nextElement = focusableElements[nextIndex];
                while (nextElement && nextElement.getAttribute('type') === 'hidden') {
                    // Skip hidden elements
                    nextIndex = (nextIndex + 1) % focusableElements.length;
                    nextElement = focusableElements[nextIndex];
                }
                while (nextElement && nextElement.getAttribute('disabled') === "") {
                    nextIndex = (nextIndex + 1) % focusableElements.length;
                    nextElement = focusableElements[nextIndex];
                }
                while (nextElement && nextElement.getAttribute('type') === 'file') {
                    nextIndex = (nextIndex + 1) % focusableElements.length;
                    nextElement = focusableElements[nextIndex];
                }
                // Attempt to focus on the next visible and focusable element
                if (nextElement) {
                    nextElement.focus();
                }
            }
        }
    };
    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            // Clean up event listener on component unmount
            document.removeEventListener('keydown', handleKeyDown);
        };
    });
    React.useEffect(() => {
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleResize = () => {
        setIsMediumScreen(window.innerWidth <= 768);
    };
    React.useEffect(() => {
        async function fetchData() {
            if (id) {
                try {
                    const response = await Promise.all([
                        customAxios.post('/employee/getOne/', { idcard_number: id }),
                        customAxios.post('/common/getAvatarImg', { idcard_number: id }),
                    ]);
                    const [res, img] = response;
                    if (res.data.jobs.length !== 0) {
                        setIsExperienced(true);
                        setJobinfo(res.data.jobs);
                        setJobInfoState(res.data.jobs.map(jobItem => ({
                            id: jobItem.id,
                            jobTitleState: jobItem.job_title ? true : false,
                            ctcState: jobItem.ctc ? true : false,
                            startDateState: jobItem.start_date ? true : false,
                            reasonForLeaveState: jobItem.reasonfor_leave ? true : false
                        })))
                    } else {
                        setIsExperienced(false);
                    }
                    setJoinYear(res?.data?.joinYear);
                    setEmployeeName(res?.data?.employee?.employee_name);
                    setFatherName(res?.data?.employee?.father_name);
                    setQualification(res?.data?.employee?.qualification);
                    // setFathersProfession(res.data.employee.fathers_profession);
                    setBirthDate(new Date(res?.data?.employee?.birth_date));
                    //setDependency(storeSelectConsts.DEPENDENCY_NAMES[res.data.employee.dependency - 1]);
                    common.lang === 'en' ? setJobTypes(
                        storeSelectConsts?.EMPLOYEE_JOBTYPES.filter(
                            (item) => res?.data?.jobTypes?.filter((i) => i.jobtypename_id == item.value).length != 0,
                        ),
                    ) :
                        setJobTypes(
                            storeSelectConsts?.EMPLOYEE_JOBTYPESAR.filter(
                                (item) => res?.data?.jobTypes?.filter((i) => i.jobtypename_id == item.value).length != 0,
                            ));
                    //  setAdmissionStatus(
                    //      storeSelectConsts.ADMISSION_STATUSES[res.data.employee.admission_status - 1],
                    // );
                    common.lang === 'en' ? setCurrentStatus(
                        storeSelectConsts?.EMPLOYEE_CURRENT_STATUSES[res?.data?.employee?.current_status - 1],
                    ) : setCurrentStatus(
                        storeSelectConsts?.EMPLOYEE_CURRENT_STATUSESAR[res?.data?.employee?.current_status - 1],
                    );
                    setJobrole({ value: res?.data?.employee?.employee_jobrole?.id, label: res?.data?.employee?.employee_jobrole?.job_role })
                    common.lang === 'en' ? setGender(storeSelectConsts?.GENDER_NAMES[res?.data?.employee?.gender - 1]) : setGender(storeSelectConsts?.GENDER_NAMESAR[res?.data?.employee?.gender - 1]);
                    setCity(res?.data?.employee?.city);
                    setProvince(res?.data?.employee?.province);
                    //setCountry(storeSelectConsts?.COUNTRY_NAMES[res?.data?.employee?.country - 1]);
                    setBirthPlace(res?.data?.employee?.birth_place);
                    setPrevexamGrade(res?.data?.employee?.prevexam_grade);
                    setTempAddress(res?.data?.employee?.temp_address);
                    setPermanentAddress(res?.data?.employee?.permanent_address);
                    setIdcardNumber(res?.data?.employee?.idcard_number);
                    setCnicNumber(res?.data?.employee?.cnic_number);
                    setContactNumber(res?.data?.employee?.contact_number);
                    setEmail(res?.data?.employee?.email);
                    setReferenceNam(res?.data?.employee?.reference_name);
                    setReferenceContact(res?.data?.employee?.reference_contact);
                    setTermSign(res?.data?.employee?.term_sign);
                    setSupervisorSign(res?.data?.employee?.supervisor_sign);
                    setEduheaderSign(res?.data?.employee?.eduheader_sign);
                    setAdminSign(res?.data?.employee?.admin_sign);
                    let base64img = img?.data?.base64data
                        ? ('data:image/png;base64,' + img?.data?.base64data)
                        : null;
                    setAvatarPreviewBase64(base64img);
                    if (res?.data?.employee?.country_name == null) {
                        setCountry({ value: storeSelectConsts?.COUNTRY_NAMES[storeSelectConsts?.COUNTRY_NAMES.length - 1], label: 'Other' })
                        setCountryName(res?.data?.employee?.country)
                        setIsCountryName(true)
                    } else {
                        common.lang === 'en' ? setCountry(storeSelectConsts.COUNTRY_NAMES.find(item => res?.data?.employee?.country)) : setCountry(storeSelectConsts.COUNTRY_NAMESAR.find(item => res?.data?.employee?.country))
                        setIsCountryName(false);
                        setIsCountryName(false);
                    }
                } catch (err) {
                    // exception error handling
                }
            } else {
                customAxios
                    .get('/common/genidnumber')
                    .then((res) => {
                        setIdcardNumber(res?.data?.idnumber);
                    })
                    .catch((err) => {
                        // Work with the err
                        // alert(err)
                    });
            }
        }
        fetchData();
    }, [id]);
    const handleJobs = () => {
        if (jobinfo.length < 10) {
            setCounter((prevCounter) => prevCounter + 1);
            const newItem = {
                id: counter,
                name: `job${counter}`,
                job_title: '',
                ctc: '',
                start_date: '',
                end_date: '',
                reasonfor_leave: '',
            };
            const newItemState = {
                id: counter,
                jobTitleState: true,
                ctcState: true,
                startDateState: true,
                reasonForLeaveState: true,
            };
            setJobinfo((prevArray) => [...prevArray, newItem]);
            setJobInfoState((prevArray) => [...prevArray, newItemState])
        } else {
            notify('You cannot add more than 10 jobs.', 'tr', 'danger');
        }
    };
    const handleDelete = (itemId) => {
        if (jobinfo.length === 0) {
            setIsExperienced(false);
        }
        const updatedItems = jobinfo.filter((item) => item.id !== itemId);
        const updatedItemsState = jobinfoState.filter((item) => item.id !== itemId)
        if (id) {
            setJobinfo(updatedItems);
            setDeleted([...deleted, itemId]);
            setJobInfoState(updatedItemsState)
        }
        setJobinfo(updatedItems);
        setJobInfoState(updatedItemsState)
        setCounter((prevCounter) => prevCounter - 1);
    };
    const webcamRef = useRef(null);
    const [isCameraOn, setIsCameraOn] = React.useState(false);
    const handleCaptureClick = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        // Update the state or perform other operations with the captured image
        // For example, you might set the captured image in the component's state
        setAvatarPreviewBase64(imageSrc);
        setAvatarFile(imageSrc);
        setIsCameraOn(false);
    };
    const startCamera = () => {
        setIsCameraOn(true);
    };
    const clearAll = () => {
        setJoinYear('');
        setEmployeeName('');
        setFatherName('');
        setQualification('');
        setBirthDate('');
        setJobTypes('');
        setCurrentStatus({ value: '', label: '' });
        setGender('');
        setCity('');
        setProvince('');
        setCountry({ value: '1', label: 'Pakistan' });
        setBirthPlace('');
        setPrevexamGrade('');
        setTempAddress('');
        setPermanentAddress('');
        // setIdcardNumber('');
        setCnicNumber('');
        setContactNumber('');
        setEmail('');
        setReferenceNam('');
        setReferenceContact('');
        setTermSign('');
        setSupervisorSign('');
        setEduheaderSign('');
        setAdminSign('');
        setAvatarPreviewBase64(null);
        setJobrole('');
        // validation init
        setEmployeeNameState(true);
        setFatherNameState(true);
        setQualificationState(true);
        setBirthDateState(true);
        setJobTypesState(true);
        setCurrentStatusState(true);
        setGenderState(true);
        setCityState(true);
        setProvinceState(true);
        setCountryState(true);
        setCountryNameState(true);
        setBirthPlaceState(true);
        setPrevexamGradeState(true);
        setTempAddressState(true);
        setPermanentAddressState(true);
        setIdcardNumberState(true);
        setCnicNumberState(true);
        setContactNumberState(true);
        setEmailState(true);
        setReferenceNamState(true);
        setReferenceContactState(true);
        setIsCountryName(false);
        setJobRoleState(true);
        setIsExperienced(false);
        setIsJobRoleName(false);
        setJobRoleName('');
        setCountryName('')
        setJobrole('');
        setJobinfo([
            {
                id: 0,
                name: `job0`,
                job_title: '',
                ctc: '',
                start_date: '',
                end_date: '',
                reasonfor_leave: '',
            },
        ]);

    };
    const warningWithConfirmMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Error'
                onConfirm={() => hideAlert()}
                cancelBtnBsStyle='Cancel'
                showConfirm>
                {t('requiredFieldNotification')}
            </SweetAlert>,
        );
    };
    const successAlert = (idcardNumber) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title={t('submitSuccessAlertTitle')}
                onConfirm={() => onSuccessOkay()}
                onCancel={() => onSuccessOkay()}
                confirmBtnBsStyle='info'
                confirmBtnText={t('btnOkay')}>
                {id ?
                    t("employeeUpdated") :
                    `${t("submitSuccessAlertMsg.first")} ${idcardNumber} ${t("submitSuccessAlertMsg.second")}`
                }
            </SweetAlert>,
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };
    const onSuccessOkay = () => {
        history.push("/admin/add_edit_employee")
        if (id === undefined) {
            customAxios.get("/common/genidnumber")
                .then((res) => {
                    setIdcardNumber(res?.data?.idnumber);
                    fetchDataAndSetStudents(year?.value)
                })
                .catch((err) => {
                    // Work with the err
                    // alert(err)
                });
        }
        setAlert(null);
        clearAll();
    };
    const notify = (msg, place, color) => {
        var options = {};
        options = {
            place: place,
            type: color,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            icon: 'nc-icon nc-bell-55',
            autoDismiss: 5,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const onConfirm = async () => {
        let formData = new FormData();
        formData.append('image', avatarPreviewBase64);
        formData.append('name', idcardNumber);
        if (id) {
            if (
                employeeName !== '' &&
                fatherName !== '' &&
                birthDate !== undefined &&
                currentStatus?.value !== undefined &&
                jobTypes?.length !== (undefined || 0) &&
                jobrole?.value !== undefined &&
                gender?.value !== undefined &&
                country?.value !== undefined &&
                permanentAddress !== '' &&
                contactNumber !== '' &&
                email !== '' &&
                (country.label !== 'Other' || countryName !== '') &&
                (jobrole.label !== 'Other' || jobRoleName !== '') &&
                (isExperienced !== true || jobinfo.every(job => job.job_title !== '' && job.start_date !== ''))
            ) {
                try {
                    const responses = await Promise.all([
                        customAxios.post('/employee/update', {
                            employee_name: employeeName,
                            father_name: fatherName,
                            qualification: qualification,
                            birth_date: birthDate,
                            job_types: jobTypes,
                            current_status: currentStatus?.value,
                            job_role: isJobRoleName ? jobRoleName : jobrole?.value,
                            isJobRoleName: isJobRoleName,
                            gender: gender?.value,
                            city: city,
                            province: province,
                            country: isCountryName ? countryName : country?.value,
                            birth_place: birthPlace,
                            prevexam_grade: prevexamGrade,
                            temp_address: tempAddress,
                            permanent_address: permanentAddress,
                            idcard_number: idcardNumber,
                            cnic_number: cnicNumber,
                            contact_number: contactNumber,
                            email: email,
                            reference_name: referenceName,
                            reference_contact: referenceContact,
                            term_sign: termSign,
                            supervisor_sign: supervisorSign,
                            eduheader_sign: eduheaderSign,
                            admin_sign: adminSign,
                            deletedJob: deleted,
                            jobs: isExperienced ? jobinfo.map((item) => {
                                const newItem = { ...item };
                                ['name'].forEach((field) => delete newItem[field]);
                                return newItem;
                            }) : []
                        }),
                        avatarFile !== null || avatarPreviewBase64 !== null
                            ? customAxios.post('/common/uploadimg', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            })
                            : '',
                    ]);
                    const [res] = responses;
                    if (jobRoleName) {
                        dispatch(addJobRole({ id: res?.data?.jobRoleId, job_role: jobRoleName }))
                    }
                    if (res.data.msg === 'success') {
                        successAlert(idcardNumber);
                    } else {
                        notify(t('notifyConnectionProblem'), 'tr', 'danger');
                    }
                    // successAlert(res.idcardNumber);
                } catch (err) {
                    // Work with the err
                    notify(t('notifyConnectionProblem'), 'tr', 'danger');
                }
            } else {
                employeeName === '' ? setEmployeeNameState(false) : "";
                fatherName === '' ? setFatherNameState(false) : "";
                birthDate === undefined ? setBirthDateState(false) : "";
                currentStatus?.value === undefined ? setCurrentStatusState(false) : "";
                jobrole?.value === undefined ? setJobRoleState(false) : "";
                gender?.value === undefined ? setGenderState(false) : "";
                if (country?.label === "Other") {
                    countryName === '' ? setCountryNameState(false) : "";
                }
                if (jobrole?.label === 'Other') {
                    jobRoleName === '' ? setJobRoleNameState(false) : ""
                }
                country?.value === undefined ? setCountryState(false) : "";
                permanentAddress === '' ? setPermanentAddressState(false) : "";
                contactNumber === '' ? setContactNumberState(false) : "";
                email === '' ? setEmailState(false) : "";
                jobTypes?.length === (undefined || 0) ? setJobTypesState(false) : "";
                if (isExperienced) {
                    setJobInfoState(jobinfoState.map((jobState, index) => {
                        const updatedFields = {};
                        updatedFields.jobTitleState = jobinfo[index].job_title !== '';
                        updatedFields.startDateState = jobinfo[index].start_date !== '';
                        return {
                            ...jobState,
                            ...updatedFields,
                        };
                    }))
                }
                warningWithConfirmMessage();
            }
            return;
        }
        if (
            employeeName !== '' &&
            fatherName !== '' &&
            jobTypes?.length !== undefined &&
            birthDate !== undefined &&
            currentStatus?.value !== undefined &&
            gender?.value !== undefined &&
            country?.value !== undefined &&
            permanentAddress !== '' &&
            contactNumber !== '' &&
            email !== '' &&
            jobrole?.value !== undefined &&
            (jobrole.label !== 'Other' || jobRoleName !== '') &&
            (country.label !== 'Other' || countryName !== '') &&
            (isExperienced !== true || jobinfo.every(job => job.job_title !== '' && job.start_date !== ''))
        ) {
            try {
                const responses = await Promise.all([
                    customAxios.post('/employee/create', {
                        employee_name: employeeName,
                        father_name: fatherName,
                        qualification: qualification,
                        birth_date: birthDate,
                        job_types: jobTypes,
                        current_status: currentStatus?.value,
                        job_role: isJobRoleName ? jobRoleName : jobrole?.value,
                        isJobRoleName: isJobRoleName,
                        gender: gender?.value,
                        city: city,
                        province: province,
                        country: isCountryName ? countryName : country?.value,
                        birth_place: birthPlace,
                        prevexam_grade: prevexamGrade,
                        temp_address: tempAddress,
                        permanent_address: permanentAddress,
                        idcard_number: idcardNumber,
                        cnic_number: cnicNumber,
                        contact_number: contactNumber,
                        email: email,
                        reference_name: referenceName,
                        reference_contact: referenceContact,
                        term_sign: termSign,
                        supervisor_sign: supervisorSign,
                        eduheader_sign: eduheaderSign,
                        admin_sign: adminSign,
                        jobs: isExperienced ? jobinfo.map((item) => {
                            const newItem = { ...item };
                            ['id', 'name'].forEach((field) => delete newItem[field]);
                            return newItem;
                        }) : ""
                    }),
                    avatarFile !== null || avatarPreviewBase64 !== null
                        ? customAxios.post('/common/uploadimg', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                        : '',
                ]);
                const [res] = responses;
                if (jobRoleName) {
                    dispatch(addJobRole({ id: res?.data?.jobRoleId, job_role: jobRoleName }))
                }
                if (res.data.msg === 'success') {
                    successAlert(res.data.enrolmentNumber);
                } else {
                    notify(t('notifyConnectionProblem'), 'tr', 'danger');
                }
            } catch (err) {
                // Work with the err
                notify(t('notifyConnectionProblem'), 'tr', 'danger');
            }
        } else {
            employeeName === '' ? setEmployeeNameState(false) : "";
            fatherName === '' ? setFatherNameState(false) : "";
            birthDate === undefined || '' ? setBirthDateState(false) : "";
            currentStatus?.value === undefined ? setCurrentStatusState(false) : "";
            jobrole?.length === undefined ? setJobRoleState(false) : "";
            gender?.value === undefined ? setGenderState(false) : "";
            country?.value === undefined ? setCountryState(false) : "";
            if (country?.label === "Other") {
                countryName === '' ? setCountryNameState(false) : "";
            }
            if (jobrole?.label === 'Other') {
                jobRoleName === '' ? setJobRoleNameState(false) : "";
            }
            permanentAddress === '' ? setPermanentAddressState(false) : "";
            contactNumber === '' ? setContactNumberState(false) : "";
            email === '' ? setEmailState(false) : "";
            jobTypes?.value === undefined ? setJobTypesState(false) : "";
            if (isExperienced) {
                setJobInfoState(jobinfoState.map((jobState, index) => {
                    const updatedFields = {};
                    updatedFields.jobTitleState = jobinfo[index].job_title !== '';
                    updatedFields.startDateState = jobinfo[index].start_date !== '';
                    return {
                        ...jobState,
                        ...updatedFields,
                    };
                }))
            }
            warningWithConfirmMessage();
        }
    };
    const videoConstraints = {
        width: 150, // Adjust the width as needed
        height: 150, // Adjust the height as needed
        facingMode: 'user',
    };
    const clearArConstants = () => {
        if (common.lang === 'en') {
            try {
                setJobTypes(storeSelectConsts.EMPLOYEE_JOBTYPES.filter((type) => {
                    return jobTypes.find((j) => j.value === type.value)
                }))
                setCurrentStatus(storeSelectConsts.EMPLOYEE_CURRENT_STATUSES.find(c => c?.value === currentStatus?.value))
                setGender(storeSelectConsts.GENDER_NAMES.find((g => g?.value === gender?.value)))
                country?.label !== 'Other' ? setCountry(storeSelectConsts.COUNTRY_NAMES.find(c => c?.value === country?.value)) : ""
            } catch (err) {

            }
        } else {
            try {
                setJobTypes(storeSelectConsts.EMPLOYEE_JOBTYPESAR.filter((type) => {
                    return jobTypes.find((j) => j.value === type.value)
                }))
                setCurrentStatus(storeSelectConsts.EMPLOYEE_CURRENT_STATUSESAR.find(c => c?.value === currentStatus?.value))
                setGender(storeSelectConsts.GENDER_NAMESAR.find((g => g?.value === gender?.value)))
                country?.label !== 'Other' ? setCountry(storeSelectConsts.COUNTRY_NAMESAR.find(c => c?.value === country?.value)) : ""
            } catch (err) {

            }
        }
    }
    React.useEffect(() => {
        clearArConstants()
    }, [common?.lang])
    return (
        <>
            {alert}
            <div className='rna-container'>
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Container fluid>
                <Row>
                    <Col md='12'>
                        <Card className='stacked-form'>
                            <Card.Header>
                                <Card.Title as='h4'>{t('addeditTitle')}</Card.Title>
                                <Card.Title as='h6' className='mt-3'>
                                    {t('idcardNumber')} : {idcardNumber ? idcardNumber : ''}
                                    <br />
                                    {t('theYearOfJoining')} : {joinYear}
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form action='' className='form' method=''>
                                    <Card className='pl-3 pr-3'>
                                        <Card.Header>
                                            <Card.Header>
                                                <Card.Title as='h4'>{t('editProfile')}</Card.Title>
                                            </Card.Header>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row className='flex justify-content-center'>
                                                <Col className='p1-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (jobTypesState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label htmlFor='jobTypes'>
                                                            {t('jobTypeLabel')}
                                                            <span className='star'>*</span>
                                                        </label>
                                                        <Select
                                                            style={{
                                                                boredr: '1px solid blue'
                                                            }}
                                                            name='jobTypes'
                                                            value={jobTypes}
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            onChange={(value) => {
                                                                setJobTypes(value);
                                                                if (isRequired(value?.length === undefined ? null : value)) {
                                                                    setJobTypesState(true);
                                                                } else {
                                                                    setJobTypesState(false);
                                                                }
                                                            }}
                                                            // options={storeSelectConsts.EMPLOYEE_JOBTYPES}
                                                            options={
                                                                common.lang === 'en' ?
                                                                    storeSelectConsts?.EMPLOYEE_JOBTYPES.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    })) :
                                                                    storeSelectConsts?.EMPLOYEE_JOBTYPESAR.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    }))
                                                            }
                                                            placeholder={t('multiSelectPlaceholder')}
                                                        />
                                                        {jobTypesState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row className='flex justify-content-center'>
                                                {isCameraOn ? (
                                                    <div className='camera-capture'>
                                                        <Webcam
                                                            audio={false}
                                                            ref={webcamRef}
                                                            screenshotFormat='image/jpeg'
                                                            videoConstraints={videoConstraints}
                                                            onChangeCapture={setAvatarFile}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className='imageUpload'>
                                                        <ImgUpload
                                                            curPreviewImage={avatarPreviewBase64 || ''}
                                                            onChangePrivewImage={setAvatarPreviewBase64}
                                                            onChangeAvatarFile={setAvatarFile}
                                                            flag={false}
                                                        />
                                                    </div>
                                                )}
                                            </Row>
                                            <Row className='justify-content-center'>
                                                {isCameraOn && (
                                                    <Button
                                                        className='btn-edit btn-edit btn-fill mr-1 ml-1'
                                                        type='button'
                                                        variant='info'
                                                        onClick={handleCaptureClick}
                                                        style={{ backgroundColor: '#23CCEF' }}>
                                                        Capture
                                                    </Button>
                                                )}
                                                <Button
                                                    className='btn-edit btn-edit btn-fill ml-1 mr-1'
                                                    type='button'
                                                    variant='info'
                                                    style={{ backgroundColor: '#23CCEF' }}
                                                    onClick={startCamera}>
                                                    Start Camera
                                                </Button>
                                            </Row>
                                            <Row className="barcode">
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (idcardNumberState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>
                                                            {t('idcardNumberLabel')}
                                                            <span className='star'></span>
                                                        </label>
                                                        <Form.Control
                                                            placeholder={t('idcardNumberPlaceholder')}
                                                            className="idcardNumber"
                                                            type='text'
                                                            value={idcardNumber}
                                                            disabled
                                                            onChange={(e) => {
                                                                setIdcardNumber(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setIdcardNumberState(true);
                                                                } else {
                                                                    setIdcardNumberState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {idcardNumberState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <label>{t("employeeBarcodeLabel")}</label>
                                                    <div className=" pr-1 d-flex align-items-center justify-content-center barcode-container">
                                                        <Barcode
                                                            value={`https://quran20.com/employee/${idcardNumber}`}
                                                            className=' has-success'
                                                            height={35}
                                                            width={1}
                                                            displayValue={false}
                                                            textAlign={'center'}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (employeeNameState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label htmlFor='studentName'>
                                                            {t('employeeNameLabel')}
                                                            <span className='star'>*</span>
                                                        </label>
                                                        <Form.Control
                                                            // defaultValue="Mike"
                                                            placeholder={t('employeeNamePlaceholder')}
                                                            type='text'
                                                            value={employeeName}
                                                            onChange={(e) => {
                                                                setEmployeeName(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setEmployeeNameState(true);
                                                                } else {
                                                                    setEmployeeNameState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {employeeNameState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (fatherNameState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>
                                                            {t('fatherNameLabel')}
                                                            <span className='star'>*</span>
                                                        </label>
                                                        <Form.Control
                                                            // defaultValue="Andrew"
                                                            onChange={(e) => {
                                                                setFatherName(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setFatherNameState(true);
                                                                } else {
                                                                    setFatherNameState(false);
                                                                }
                                                            }}
                                                            placeholder={t('fatherNamePlaceholder')}
                                                            value={fatherName}
                                                            type='text'></Form.Control>
                                                        {fatherNameState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (contactNumberState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>{t('contactNumberLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Form.Control
                                                            placeholder={t('firstContactNumberPlaceholder')}
                                                            type='text'
                                                            value={contactNumber}
                                                            onChange={(e) => {
                                                                setContactNumber(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setContactNumberState(true);
                                                                } else {
                                                                    setContactNumberState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {contactNumberState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className={'has-label ' + (emailState ? 'has-success' : 'has-error')}>
                                                        <label>{t('emailLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Form.Control
                                                            placeholder={t('emailPlaceholder')}
                                                            type='email'
                                                            value={email}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setEmailState(true);
                                                                } else {
                                                                    setEmailState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {emailState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={'has-label ' + (genderState ? 'has-success' : 'has-error')}>
                                                        <label>{t('genderLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Select
                                                            className='react-select primary'
                                                            classNamePrefix='react-select'
                                                            name='gender'
                                                            value={gender}
                                                            onChange={(optionSelected) => {
                                                                setGender({
                                                                    value: optionSelected.value,
                                                                    label: optionSelected.label,
                                                                });
                                                                if (isRequired(optionSelected.value)) {
                                                                    setGenderState(true);
                                                                } else {
                                                                    setGenderState(false);
                                                                }
                                                            }}
                                                            // options={storeSelectConsts.GENDER_NAMES}
                                                            options={
                                                                common?.lang === 'en' ?
                                                                    storeSelectConsts?.GENDER_NAMES.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    })) : storeSelectConsts?.GENDER_NAMESAR.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    }))
                                                            }
                                                            placeholder={t('singleSelectPlaceholder')}
                                                        />
                                                        {genderState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (birthDateState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>{t('dateOfBirthLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <ReactDatetime
                                                            inputProps={{
                                                                className: 'form-control',
                                                                placeholder: 'MM/DD/YYYY',
                                                            }}
                                                            timeFormat={false}
                                                            value={birthDate}
                                                            onChange={(date) => {
                                                                setBirthDate(date);
                                                                if (isRequired(date)) {
                                                                    setBirthDateState(true);
                                                                } else {
                                                                    setBirthDateState(false);
                                                                }
                                                            }}></ReactDatetime>
                                                        {birthDateState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'
                                                    >
                                                        <label>{t('placeOfBirthLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('placeOfBirthPlaceholder')}
                                                            type='text'
                                                            value={birthPlace}
                                                            onChange={(e) => {
                                                                setBirthPlace(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setBirthPlaceState(true);
                                                                } else {
                                                                    setBirthPlaceState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('cityLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('cityPlaceholder')}
                                                            value={city}
                                                            onChange={(e) => {
                                                                setCity(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setCityState(true);
                                                                } else {
                                                                    setCityState(false);
                                                                }
                                                            }}
                                                            type='text'></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6' style={{
                                                    order: isMediumScreen ? 13 : 0,
                                                    flexBasis: 'auto'
                                                }}>
                                                    <Form.Group
                                                        className={'has-label ' + (countryState ? 'has-success' : 'has-error')}>
                                                        <label>{t('countryLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Select
                                                            className='react-select primary'
                                                            classNamePrefix='react-select'
                                                            name='country'
                                                            value={country}
                                                            onChange={(optionSelected) => {
                                                                if (optionSelected.label == 'Other') {
                                                                    setIsCountryName(true);
                                                                    setCountry({ value: optionSelected.value, label: optionSelected.label });
                                                                } else {
                                                                    setIsCountryName(false);
                                                                    setCountry({
                                                                        value: optionSelected.value,
                                                                        label: optionSelected.label,
                                                                    });
                                                                }
                                                                if (isRequired(optionSelected.value)) {
                                                                    setCountryState(true);
                                                                } else {
                                                                    setCountryState(false);
                                                                }
                                                            }}
                                                            // options={storeSelectConsts.COUNTRY_NAMES}
                                                            options={
                                                                common?.lang === 'en' ?
                                                                    storeSelectConsts?.COUNTRY_NAMES.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    })) :
                                                                    storeSelectConsts?.COUNTRY_NAMESAR.map((option) => ({
                                                                        ...option,
                                                                        label: t(option.label), // Your translation function
                                                                    }))
                                                            }
                                                            placeholder={t('singleSelectPlaceholder')}
                                                        />
                                                        {countryState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('provinceLabel')}</label>
                                                        <Form.Control
                                                            // defaultValue="Andrew"
                                                            placeholder={t('provincePlaceholder')}
                                                            value={province}
                                                            onChange={(e) => {
                                                                setProvince(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setProvinceState(true);
                                                                } else {
                                                                    setProvinceState(false);
                                                                }
                                                            }}
                                                            type='text'></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {isCountryName ? (
                                                <Row>
                                                    <Col className='pr-md-1' md='6'>
                                                        <Form.Group className={'mb-3 ' + (countryNameState ? 'has-success' : 'has-error')}>
                                                            <Form.Control
                                                                // defaultValue="Mike"
                                                                type='text'
                                                                value={countryName}
                                                                onChange={(e) => {
                                                                    setCountryName(e.target.value);
                                                                    if (isRequired(e.target.value)) {
                                                                        setCountryNameState(true);
                                                                    } else {
                                                                        setCountryNameState(false);
                                                                    }
                                                                }}></Form.Control>
                                                            {countryNameState ? null : (
                                                                <label htmlFor='validation' className='error'>
                                                                    {t('validationRequired')}
                                                                </label>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('tempAddressLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('tempAddressPlaceholder')}
                                                            type='text'
                                                            value={tempAddress}
                                                            onChange={(e) => {
                                                                setTempAddress(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setTempAddressState(true);
                                                                } else {
                                                                    setTempAddressState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (permanentAddressState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>{t('permanentAddressLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Form.Control
                                                            placeholder={t('permanentAddressPlaceholder')}
                                                            type='text'
                                                            value={permanentAddress}
                                                            onChange={(e) => {
                                                                setPermanentAddress(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setPermanentAddressState(true);
                                                                } else {
                                                                    setPermanentAddressState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {permanentAddressState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('qualificationLabel')}</label>
                                                        <Form.Control
                                                            // defaultValue="Mike"
                                                            placeholder={t('qualificationPlaceholder')}
                                                            type='text'
                                                            value={qualification}
                                                            onChange={(e) => {
                                                                setQualification(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setQualificationState(true);
                                                                } else {
                                                                    setQualificationState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('prevexamGradeLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('prevexamGradePlaceholder')}
                                                            type='text'
                                                            value={prevexamGrade}
                                                            onChange={(e) => {
                                                                setPrevexamGrade(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setPrevexamGradeState(true);
                                                                } else {
                                                                    setPrevexamGradeState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={'has-label ' + (jobRoleState ? 'has-success' : 'has-error')}>
                                                        <label>{t('jobroleLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Select
                                                            className='react-select primary'
                                                            classNamePrefix='react-select'
                                                            name='jobrole'
                                                            value={jobrole}
                                                            onChange={(optionSelected) => {
                                                                if (optionSelected.label === "Other") {
                                                                    setIsJobRoleName(true)
                                                                    setJobrole({ value: optionSelected.value, label: optionSelected.label })
                                                                } else {
                                                                    setIsJobRoleName(false)
                                                                    setJobrole({
                                                                        value: optionSelected.value,
                                                                        label: optionSelected.label,
                                                                    });
                                                                }
                                                                if (isRequired(optionSelected.value)) {
                                                                    setJobRoleState(true);
                                                                } else {
                                                                    setJobRoleState(false);
                                                                }
                                                            }}
                                                            // options={storeSelectConsts.EMPLOYEE_CURRENT_STATUSES}
                                                            options={storeSelectConsts?.JOB_ROLES.map((option) => ({
                                                                ...option,
                                                                label: t(option.label),// Your translation function
                                                            }))}
                                                            placeholder={t('singleSelectPlaceholder')}
                                                        />
                                                        {jobRoleState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (currentStatusState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>{t('currentStatusLabel')}</label>
                                                        <span className='star'>*</span>
                                                        <Select
                                                            className='react-select primary'
                                                            classNamePrefix='react-select'
                                                            name='currentStatus'
                                                            value={currentStatus}
                                                            onChange={(optionSelected) => {
                                                                setCurrentStatus({
                                                                    value: optionSelected.value,
                                                                    label: optionSelected.label,
                                                                });
                                                                if (isRequired(optionSelected.value)) {
                                                                    setCurrentStatusState(true);
                                                                } else {
                                                                    setCurrentStatusState(false);
                                                                }
                                                            }}
                                                            // options={storeSelectConsts.EMPLOYEE_CURRENT_STATUSES}
                                                            options={
                                                                common?.lang === 'en' ?
                                                                    storeSelectConsts?.EMPLOYEE_CURRENT_STATUSES.map(
                                                                        (option) => ({
                                                                            ...option,
                                                                            label: t(option.label), // Your translation function
                                                                        }))
                                                                    : storeSelectConsts?.EMPLOYEE_CURRENT_STATUSESAR.map(
                                                                        (option) => ({
                                                                            ...option,
                                                                            label: t(option.label), // Your translation function
                                                                        }))
                                                            }
                                                            placeholder={t('singleSelectPlaceholder')}
                                                        />
                                                        {currentStatusState ? null : (
                                                            <label htmlFor='validation' className='error'>
                                                                {t('validationRequired')}
                                                            </label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {isJobRoleName ? (
                                                <Row>
                                                    <Col className='pr-md-1' md='6'>
                                                        <Form.Group className={'mb-3 ' + (jobRoleNameState ? 'has-success' : 'has-error')}>
                                                            <Form.Control
                                                                // defaultValue="Mike"
                                                                type='text'
                                                                value={jobRoleName}
                                                                onChange={(e) => {
                                                                    setJobRoleName(e.target.value);
                                                                    if (isRequired(e.target.value)) {
                                                                        setJobRoleNameState(true);
                                                                    } else {
                                                                        setJobRoleNameState(false);
                                                                    }
                                                                }}></Form.Control>
                                                            {jobRoleNameState ? null : (
                                                                <label htmlFor='validation' className='error'>
                                                                    {t('validationRequired')}
                                                                </label>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                            <Row>
                                                <Col className='pr-md-1' md='3'>
                                                    <Form.Check.Label style={{ whiteSpace: "nowrap" }}>{t("jobExperienceLabel")} </Form.Check.Label>
                                                </Col>
                                                <Col className='pr-md-1' md='2'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    checked={isExperienced ? true : false}
                                                                    type='radio'
                                                                    name='r1'
                                                                    onChange={(e) => {
                                                                        setIsExperienced(true);
                                                                    }}
                                                                    style={{ accentColor: "#909090" }}
                                                                ></Form.Check.Input>
                                                                <label>{t("yesLabel")}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='2'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    checked={isExperienced ? false : true}
                                                                    type='radio'
                                                                    name='r1'
                                                                    onChange={(e) => {
                                                                        setIsExperienced(false);
                                                                        setJobinfo([
                                                                            {
                                                                                id: 0,
                                                                                name: `job0`,
                                                                                job_title: '',
                                                                                ctc: '',
                                                                                start_date: '',
                                                                                end_date: '',
                                                                                reasonfor_leave: '',
                                                                            },
                                                                        ]);
                                                                    }}
                                                                    style={{ accentColor: "#909090" }}
                                                                ></Form.Check.Input>
                                                                <label>{t("noLabel")}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {isExperienced ? (
                                                <div className='fieldSetCon'>
                                                    <Row>
                                                        <h5
                                                            style={{
                                                                color: '#52514e',
                                                                borderBottom: '1px solid #52514e',
                                                                display: 'inline-block',
                                                                marginLeft: '0.9rem',
                                                                marginRight: '0.9rem'
                                                            }}>
                                                            {t("jobDetailsLabel")}
                                                        </h5>
                                                        <div className='ml-auto'>
                                                            <Button
                                                                onClick={handleJobs}
                                                                type='button'
                                                                variant='info'
                                                                style={{
                                                                    display: 'block',
                                                                    marginRight: '16px',
                                                                    marginLeft: '16px',
                                                                    color: "#17a2b8",
                                                                    backgroundColor: "#fff",
                                                                    borderColor: "#17a2b8",
                                                                    fontSize: 'large'
                                                                }}>
                                                                <b>+</b>
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                    {jobinfo.map((i, index) => (
                                                        <fieldset className='fieldSet'>
                                                            <Row>
                                                                <p
                                                                    style={{
                                                                        margin: '2px 12px',
                                                                        color: '#9A9A9A',
                                                                    }}>
                                                                    {t("jobLabel")} {index + 1}
                                                                </p>
                                                                <div className='ml-auto'>
                                                                    <Button
                                                                        style={{
                                                                            color: '#dc3545',
                                                                            backgroundColor: '#fff',
                                                                            borderColor: '#fff',
                                                                        }}
                                                                        onClick={() => handleDelete(i.id)}
                                                                        variant='danger '
                                                                        disabled={i.id === 0 ? true : false}>
                                                                        <i className='fas fa-trash'></i>
                                                                    </Button>
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <Col md='6'>
                                                                    <Form.Group
                                                                        className={
                                                                            'has-label ' +
                                                                            (jobinfoState[index]?.jobTitleState ? 'has-success' : 'has-error')
                                                                        }
                                                                    // className={'has-label  has-success'}
                                                                    >
                                                                        <label>{t("jobTitleLabel")} </label>
                                                                        <span className='star'>*</span>
                                                                        <Form.Control
                                                                            // defaultValue="Mike"
                                                                            placeholder={t("jobTitlePlaceholder")}
                                                                            type='text'
                                                                            value={i.job_title}
                                                                            onChange={(e) => {
                                                                                setJobinfo((prevJobInfo) =>
                                                                                    prevJobInfo.map((job) =>
                                                                                        job.id === i.id
                                                                                            ? { ...job, job_title: e.target.value }
                                                                                            : job,
                                                                                    ),
                                                                                );
                                                                                if (isRequired(e.target.value)) {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, jobTitleState: true } : job
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, jobTitleState: false } : job
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                        ></Form.Control>
                                                                        {jobinfoState[index]?.jobTitleState ? null : (
                                                                            <label htmlFor='validation' className='error'>
                                                                                {t('validationRequired')}
                                                                            </label>
                                                                        )}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md='6'>
                                                                    <Form.Group
                                                                        className='has-label has-success'
                                                                    >
                                                                        <label>{t("ctcLabel")}</label>
                                                                        <Form.Control
                                                                            // defaultValue="Mike"
                                                                            placeholder={t("ctcPlaceholder")}
                                                                            type='text'
                                                                            value={i.ctc}
                                                                            onChange={(e) => {
                                                                                setJobinfo((prevJobInfo) =>
                                                                                    prevJobInfo.map((job) =>
                                                                                        job.id === i.id ? { ...job, ctc: e.target.value } : job,
                                                                                    ),
                                                                                );
                                                                                if (isRequired(e.target.value)) {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, ctcState: true } : job
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, ctcState: false } : job
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md='6'>
                                                                    <Form.Group
                                                                        className={
                                                                            'has-label ' + (jobinfoState[index]?.startDateState ? 'has-success' : 'has-error')
                                                                        }
                                                                    >
                                                                        <label>{t("startDateLabel")}</label>
                                                                        <span className='star'>*</span>
                                                                        <ReactDatetime
                                                                            inputProps={{
                                                                                className: 'form-control',
                                                                                placeholder: 'MM/DD/YYYY',
                                                                            }}
                                                                            timeFormat={false}
                                                                            value={i.start_date}
                                                                            onChange={(date) => {
                                                                                setJobinfo((prevJobInfo) =>
                                                                                    prevJobInfo.map((job) =>
                                                                                        job.id === i.id ? { ...job, start_date: date } : job,
                                                                                    ),
                                                                                );
                                                                                if (isRequired(date)) {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, startDateState: true } : job
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, startDateState: false } : job
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}></ReactDatetime>
                                                                        {jobinfoState[index]?.startDateState ? null : (
                                                                            <label htmlFor='validation' className='error'>
                                                                                {t('validationRequired')}
                                                                            </label>
                                                                        )}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md='6'>
                                                                    <Form.Group
                                                                        // className={
                                                                        //     'has-label ' + (birthDateState ? 'has-success' : 'has-error')
                                                                        // }
                                                                        className={'has-label has-success'}
                                                                    >
                                                                        {/* <label>{t("endDateLabel")}</label> */}
                                                                        <label>{t("endDateLabel")} </label>
                                                                        <ReactDatetime
                                                                            inputProps={{
                                                                                className: 'form-control',
                                                                                placeholder: 'MM/DD/YYYY',
                                                                            }}
                                                                            timeFormat={false}
                                                                            value={i.end_date}
                                                                            onChange={(date) => {
                                                                                setJobinfo((prevJobInfo) =>
                                                                                    prevJobInfo.map((job) =>
                                                                                        job.id === i.id ? { ...job, end_date: date } : job,
                                                                                    ),
                                                                                );
                                                                                // if (isRequired(date)) {
                                                                                //     setBirthDateState(true);
                                                                                // } else {
                                                                                //     setBirthDateState(false);
                                                                                // }
                                                                            }}></ReactDatetime>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className='pr-md-3' md='6'>
                                                                    <Form.Group
                                                                        className='has-label has-success'
                                                                        controlId='exampleForm.ControlTextarea1'>
                                                                        <Form.Label>{t("reasonLabel")}</Form.Label>
                                                                        <Form.Control
                                                                            as='textarea'
                                                                            rows={3}
                                                                            value={i.reasonfor_leave}
                                                                            onChange={(e) => {
                                                                                setJobinfo((prevJobInfo) =>
                                                                                    prevJobInfo.map((job) =>
                                                                                        job.id === i.id
                                                                                            ? { ...job, reasonfor_leave: e.target.value }
                                                                                            : job,
                                                                                    ),
                                                                                );
                                                                                if (isRequired(e.target.value)) {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, reasonForLeaveState: true } : job
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    setJobInfoState((prevJobInfoState) =>
                                                                                        prevJobInfoState.map((job, idx) =>
                                                                                            idx === index ? { ...job, reasonForLeaveState: false } : job
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </fieldset>
                                                    ))}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className={
                                                            'has-label ' + (idcardNumberState ? 'has-success' : 'has-error')
                                                        }>
                                                        <label>
                                                            {t('idcardNumberLabel')}
                                                            <span className='star'></span>
                                                        </label>
                                                        <Form.Control
                                                            placeholder={t('idcardNumberPlaceholder')}
                                                            type='text'
                                                            value={idcardNumber}
                                                            disabled
                                                            onChange={(e) => {
                                                                setIdcardNumber(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setIdcardNumberState(true);
                                                                } else {
                                                                    setIdcardNumberState(false);
                                                                }
                                                            }}></Form.Control>
                                                        {idcardNumberState ? null : (
                                                            <label className='error'>{t('validationRequired')}</label>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('cnicNumberLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('cnicNumberPlaceholder')}
                                                            type='text'
                                                            value={cnicNumber}
                                                            onChange={(e) => {
                                                                setCnicNumber(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setCnicNumberState(true);
                                                                } else {
                                                                    setCnicNumberState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='pr-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('referenceNameLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('referenceNamePlaceholder')}
                                                            type='text'
                                                            value={referenceName}
                                                            onChange={(e) => {
                                                                setReferenceNam(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setReferenceNamState(true);
                                                                } else {
                                                                    setReferenceNamState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='6'>
                                                    <Form.Group
                                                        className='has-label has-success'>
                                                        <label>{t('referencePhoneLabel')}</label>
                                                        <Form.Control
                                                            placeholder={t('referencePhonePlaceholder')}
                                                            type='text'
                                                            value={referenceContact}
                                                            onChange={(e) => {
                                                                setReferenceContact(e.target.value);
                                                                if (isRequired(e.target.value)) {
                                                                    setReferenceContactState(true);
                                                                } else {
                                                                    setReferenceContactState(false);
                                                                }
                                                            }}></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3'>
                                                <Col className='pr-md-1' md='3'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    defaultValue={true}
                                                                    type='checkbox'
                                                                    checked={termSign ? true : false}
                                                                    onChange={(e) => {
                                                                        setTermSign(!termSign);
                                                                    }}></Form.Check.Input>
                                                                <span className='form-check-sign'></span>
                                                                <label>{t('termAndCondition')}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='3'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    defaultValue={true}
                                                                    type='checkbox'
                                                                    checked={supervisorSign ? true : false}
                                                                    onChange={(e) => {
                                                                        setSupervisorSign(!supervisorSign);
                                                                    }}></Form.Check.Input>
                                                                <span className='form-check-sign'></span>
                                                                <label>{t('supervisor')}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pr-md-1' md='3'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    defaultValue={true}
                                                                    type='checkbox'
                                                                    checked={eduheaderSign ? true : false}
                                                                    onChange={(e) => {
                                                                        setEduheaderSign(!eduheaderSign);
                                                                    }}></Form.Check.Input>
                                                                <span className='form-check-sign'></span>
                                                                <label>{t('headOfEducation')}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='pl-md-1' md='3'>
                                                    <Form.Group className='pull-left'>
                                                        <Form.Check>
                                                            <Form.Check.Label>
                                                                <Form.Check.Input
                                                                    defaultValue={true}
                                                                    type='checkbox'
                                                                    checked={adminSign ? true : false}
                                                                    onChange={(e) => {
                                                                        setAdminSign(!adminSign);
                                                                    }}></Form.Check.Input>
                                                                <span className='form-check-sign'></span>
                                                                <label>{t('administrator')}</label>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className='clearfix'></div>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                <Button
                                    className='btn-edit btn-fill'
                                    type='submit'
                                    variant='info'
                                    onClick={onConfirm}>
                                    {t('btnSubmit')}
                                </Button>
                                <Button
                                    className='btn-edit btn-fill mx-2'
                                    type='submit'
                                    variant='warning'
                                    onClick={(e) => clearAll()}>
                                    {t('btnClearAll')}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default EmployeesAddEdit;
