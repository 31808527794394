import { createSlice } from '@reduxjs/toolkit';
import { useState } from 'react';

export const selectconstsSlice = createSlice({
  name: 'selectconstsReducer',
  initialState: {
    STUDENT_ADMISSION_TYPES: [],
    EMPLOYEE_JOBTYPES: [],
    ADMISSION_STATUSES: [],
    STUDENT_CURRENT_STATUSES: [],
    EMPLOYEE_CURRENT_STATUSES: [],
    BOARD_TYPES: [],
    DEPARTMENT_NAMES: [],
    CLASS_NAMES: [],
    COUNTRY_NAMES: [],
    DEPENDENCY_NAMES: [],
    GENDER_NAMES: [],
    EXAM_NAMES: [],
    JOB_ROLES: [],

    STUDENT_ADMISSION_TYPESAR: [],
    ADMISSION_STATUSESAR: [],
    STUDENT_CURRENT_STATUSESAR: [],
    BOARD_TYPESAR: [],
    COUNTRY_NAMESAR: [],
    DEPENDENCY_NAMESAR: [],
    GENDER_NAMESAR: [],
    EMPLOYEE_JOBTYPESAR: [],
    EMPLOYEE_CURRENT_STATUSESAR: [],
  },
  reducers: {
    setAll: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.STUDENT_ADMISSION_TYPES = action.payload.STUDENT_ADMISSION_TYPES;
      state.EMPLOYEE_JOBTYPES = action.payload.EMPLOYEE_JOBTYPES;
      state.ADMISSION_STATUSES = action.payload.ADMISSION_STATUSES;
      state.STUDENT_CURRENT_STATUSES = action.payload.STUDENT_CURRENT_STATUSES;
      state.EMPLOYEE_CURRENT_STATUSES = action.payload.EMPLOYEE_CURRENT_STATUSES;
      state.BOARD_TYPES = action.payload.BOARD_TYPES;
      state.DEPARTMENT_NAMES = action.payload.DEPARTMENT_NAMES;
      state.CLASS_NAMES = action.payload.CLASS_NAMES;
      state.COUNTRY_NAMES = action.payload.COUNTRY_NAMES;
      state.DEPENDENCY_NAMES = action.payload.DEPENDENCY_NAMES;
      state.GENDER_NAMES = action.payload.GENDER_NAMES;
      state.EXAM_NAMES = action.payload.EXAM_NAMES;
      state.JOB_ROLES = action.payload.JOB_ROLES;

      state.STUDENT_ADMISSION_TYPESAR = action.payload.STUDENT_ADMISSION_TYPESAR;
      state.STUDENT_CURRENT_STATUSESAR = action.payload.STUDENT_CURRENT_STATUSESAR;
      state.ADMISSION_STATUSESAR = action.payload.ADMISSION_STATUSESAR;
      state.BOARD_TYPESAR = action.payload.BOARD_TYPESAR;
      state.COUNTRY_NAMESAR = action.payload.COUNTRY_NAMESAR;
      state.DEPENDENCY_NAMESAR = action.payload.DEPENDENCY_NAMESAR;
      state.GENDER_NAMESAR = action.payload.GENDER_NAMESAR;
      state.EMPLOYEE_CURRENT_STATUSESAR = action.payload.EMPLOYEE_CURRENT_STATUSESAR;
      state.EMPLOYEE_JOBTYPESAR = action.payload.EMPLOYEE_JOBTYPESAR;
    },
    addClassName: (state, action) => {
      state.CLASS_NAMES = [{ value: action.payload.id, label: action.payload.class_name, department_id: action.payload.department_id, class_type: action.payload.class_type, isActive: action.payload.isActive }, ...state.CLASS_NAMES];
    },
    removeClass: (state, action) => {
      state.CLASS_NAMES = state.CLASS_NAMES.filter((item) => item.value != action.payload);
    },
    changeClass: (state, action) => {
      state.CLASS_NAMES = state.CLASS_NAMES.map(classname => {
        if (classname.value == action.payload.value) {
          return { ...classname, label: action.payload.label, department_id: action.payload.department_id, class_type: action.payload.class_type.value };
        }
        return classname;
      });
    },
    addJobRole: (state, action) => {
      state.JOB_ROLES = [{ value: action.payload.id, label: action.payload.job_role }, ...state.JOB_ROLES];
    },
    addDepartmentName: (state, action) => {
      state.DEPARTMENT_NAMES = [{ value: action.payload.id, label: action.payload.department_name, isActive: action.payload.isActive }, ...state.DEPARTMENT_NAMES];
    },
    removeDepartment: (state, action) => {
      state.DEPARTMENT_NAMES = state.DEPARTMENT_NAMES.filter((item) => item.value != action.payload);
    },
    changeDepartment: (state, action) => {
      state.DEPARTMENT_NAMES = state.DEPARTMENT_NAMES.map(departmentname => {
        if (departmentname.value == action.payload.value) {
          return { ...departmentname, label: action.payload.label };
        }
        return departmentname;
      });
    },
    addExamName: (state, action) => {
      state.EXAM_NAMES = [...state.EXAM_NAMES, { value: action.payload.value, label: action.payload.label, exam_datetime: action.payload.exam_datetime }];
    },
    removeExamName: (state, action) => {
      state.EXAM_NAMES = state.EXAM_NAMES.filter((item) => item.value != action.payload);
    },
    changeExamName: (state, action) => {
      state.EXAM_NAMES = state.EXAM_NAMES.map(examname => {
        if (examname.value == action.payload.value) {
          return { ...examname, label: action.payload.label, exam_datetime: action.payload.exam_datetime };
        }
        return examname;
      });
    },
    toggleActiveDepartment: (state, action) => {
      state.DEPARTMENT_NAMES = state.DEPARTMENT_NAMES.map(department => {
        if (department.value === action.payload.curDepartmentId) {
          return { ...department, isActive: action.payload.isActive };
        }
        return department;
      })
      state.CLASS_NAMES = state.CLASS_NAMES.map((cls) => {
        if (cls.department_id === action.payload.curDepartmentId) {
          return { ...cls, isActive: action.payload.isActive };
        }
        return cls;
      })
    },
   
    toggleActiveClass: (state, action) => {
      state.CLASS_NAMES = state.CLASS_NAMES.map(c => {
        if (c.value === action.payload.curClassId) {
          return { ...c, isActive: action.payload.isActive };
        }
        return c;
      })
    },
  },
})
//create your forceUpdate hook
export const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // A function that increment 👆🏻 the previous state like here 
  // is better than directly setting `setValue(value + 1)`
}
// Action creators are generated for each case reducer function
export const selectConsts = (state) => state.selectconstsReducer;
export const { setAll, addClassName, removeClass, changeClass, addDepartmentName, removeDepartment, changeDepartment, addExamName, removeExamName, changeExamName, toggleActiveDepartment, toggleActiveClass, addJobRole, toggleActiveClassWithDpt } = selectconstsSlice.actions
export default selectconstsSlice.reducer
