import customAxios from "lib/axios";
import React, { useRef } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useSelector } from 'react-redux';
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { selectConsts } from '../store/selectconstReducer';
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table
} from "react-bootstrap";
function ExamSeatGeneration() {
  const [t] = useTranslation(["exam"]);
  let componentRef = useRef();
  const [flag, setFlag] = React.useState(1)
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const [examName, setExamName] = React.useState(storeSelectConsts.EXAM_NAMES[0]);
  const [curDepartment, setCurDepartment] = React.useState(storeSelectConsts.DEPARTMENT_NAMES[0]);
  const [curClass, setCurClass] = React.useState([]);
  const [students, setStudents] = React.useState([]);
  const [filteredStudents, setFilteredStudents] = React.useState(students)
  const [examPrepared, setExamPrepared] = React.useState(false);
  const [examResulted, setExamResulted] = React.useState(false)
  const [examDate, setExamDate] = React.useState("");
  const [filteredClasses, setFilteredClasses] = React.useState(storeSelectConsts.CLASS_NAMES)
  React.useEffect(() => {
    // submit form values
    customAxios.post("/exam/getExamStudents", {
      exam_nameid: examName?.value, ///current value 30
      exam_departmentid: curDepartment?.value, //current value 1
      exam_classid: curClass?.value
    })
      .then((res) => {
        // setStudents(res.data);
        if (res?.data?.examResulted) {
          infoAlert(t("examResulted"))
          res?.data?.examAlreadyPrepared ? setFlag(false) : setFlag(true);
          setExamPrepared(res?.data?.examPrepared);
          setExamResulted(res?.data?.examResulted);
          if (!res?.data?.examPrepared)
            return
        } else {
          res?.data?.examAlreadyPrepared ? setFlag(false) : setFlag(true);
          setExamPrepared(res?.data?.examPrepared);
          setExamResulted(res?.data?.examResulted);
          if (!res?.data?.examPrepared)
            return
          setStudents(res?.data?.students.map((item, index) => { return { seat_number: index + 1, student_id: item.student_id, student_name: item.student_name, idcard_number: item.idcard_number, class_id: item.class_name.id, class_name: item.class_name.class_name, father_name: item.father_name }; }));
          setFilteredStudents(res?.data?.students.map((item, index) => { return { seat_number: index + 1, student_id: item.student_id, student_name: item.student_name, idcard_number: item.idcard_number, class_id: item.class_name.id, class_name: item.class_name.class_name, father_name: item.father_name }; }));
          setExamDate(res?.data?.examDate);
        }
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }, [curDepartment, examName, curClass])
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("modalWarnningMsgTitle")}
        onConfirm={() => onConfirm()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={t("btnSubmit")}
        cancelBtnText={t("btnCancel")}
        showCancel
      >
        {flag ? (
          <span>{t("alertRegenerate")}</span>
        ) : (
          <span>{t("alertUpdate")}</span>
        )}
      </SweetAlert>
    );
  };
  const infoAlert = (msg) => {
    setAlert(
      <SweetAlert
        info
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onConfirm = () => {
    // submit form values
    let data = students.map((item) => { return { exam_nameid: examName?.value, class_id: item.class_id, seat_number: item.seat_number, student_id: item.student_id } })
    customAxios.post("/exam/createExamSeats", {
      data: data,
      exam_departmentid: curDepartment?.value,
      exam_nameid: examName?.value,
      exam_classid: curClass?.value
    }).then((res) => {
      successAlert("Seat numbers are submited!")
      // setExamBooks(res.data.map((item) => { return {book_name: item.book_name, marker: "", selected: false}; }));
    })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }
  const onSubmit = () => {
    warningWithConfirmMessage()
  }
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={t("submitSuccessAlertTitle")}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle="info"
        confirmBtnText={t("btnOkay")}
      >
        {msg}
      </SweetAlert>
    );
  };
  const handleExamSelect = async (value) => {
    await customAxios.get(`/exam/getExamInstance/${value?.value}`)
      .then((response) => {
        const examClassIds = response?.data?.data?.map(item => item.exam_classid);
        const filteredClasses = storeSelectConsts.CLASS_NAMES.filter(cls => examClassIds.includes(cls.value) && cls.isActive);
        if (filteredClasses.length > 0) {
          setFilteredClasses(filteredClasses);
          setCurClass(filteredClasses[0])
        } else {
          setFilteredClasses([])
          setCurClass("")
        }
      })
  }
  const onSuccessOkay = () => {
    setAlert(null);
    setFlag(0)
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: "danger",
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  React.useEffect(() => {
    handleExamSelect(examName)
  }, [])
  return (
    <>
      {alert}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("seatNumberGeneration")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("examName")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="examName"
                        value={examName}
                        onChange={async (value) => {
                          setFilteredStudents(students)
                          setExamName(value)
                          handleExamSelect(value)
                        }}
                        // defaultValue={storeSelectConsts.EXAM_NAMES[0]}
                        options={storeSelectConsts.EXAM_NAMES}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("department")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="department"
                        value={curDepartment}
                        onChange={(value) => {
                          setFilteredStudents(students)
                          setCurDepartment(value)
                        }}
                        defaultValue={storeSelectConsts.DEPARTMENT_NAMES[0]}
                        options={storeSelectConsts.DEPARTMENT_NAMES.filter((dpt) => dpt.isActive)}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("class")}</label>
                      <Select
                        className={"react-select primary" + (filteredClasses.length === 0 ? ' disabled' : '')}
                        classNamePrefix="react-select"
                        name="department"
                        value={curClass}
                        onChange={(value) => {
                          setCurClass(value)
                          setFilteredStudents(students.filter(student => student.class_id === value.value))
                        }}
                        // options={[...new Map(students.map(student => [student.class_id, student])).values()].map(item => ({ value: item.class_id, label: item.class_name })).concat([{ value: "", label: "All" }])}
                        options={filteredClasses.filter((cls) => cls.isActive)}
                        placeholder={filteredClasses.length !== 0 ? t("singleSelect") : t("noExamCreated")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Card className="stacked-form">
                  <Card.Header>
                    <Card.Title as="h5">{t("seatNumbers")}
                      {/* <Button className="btn-wd btn-round btn-outline ml-3" size="sm" type="submit" variant="info">
                        Generate
                      </Button> */}
                      {
                        examPrepared ?
                          <Button
                            className="btn-round btn-wd btn-outline ml-4"
                            style={{ color: "#86cb16" }}
                            type="button"
                            size="sm"
                            disabled={flag ? false : true}
                            onClick={(e) => {
                              let randomized = students.sort(() => Math.random() - 0.5);
                              randomized = randomized.map((item, index) => { return { seat_number: index + 1, student_id: item.student_id, class_id: item.class_id, class_name: item.class_name, student_name: item.student_name, idcard_number: item.idcard_number }; })
                              setStudents(randomized);
                            }} // ramdomization
                          >
                            <span className="btn-label">
                              <i className="fas fa-sync"></i>
                            </span>
                            &nbsp;{t("btnGenerate")}
                          </Button>
                          :
                          !examResulted ?
                            <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center" style={{ fontSize: '16px', fontWeight: "400" }}>{t("examNotPrepared")}</div> :
                            <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center" style={{ fontSize: '16px', fontWeight: "400" }}>{t("examResulted")}</div>
                      }
                    </Card.Title>
                  </Card.Header>
                  <div ref={(el) => (componentRef = el)} className="mr-0 ml-0">
                    {
                      examPrepared ?
                        <Card.Body className="overflow-auto" style={{ maxHeight: '100vh' }}>
                          <Table>
                            <thead style={{ position: 'sticky', top: '-15px', backgroundColor: '#fff', zIndex: '1' }}>
                              <tr>
                                <th className="text-center" style={{ paddingLeft: '8px' }}>{t("seatNumber")}</th>
                                <th className="text-left">{t("studentName")}</th>
                                {!flag ?
                                  (<th className="text-center">{t("fatherName")}</th>)
                                  : ""}
                                <th className="text-center">{t("class")}</th>
                                <th className="text-center">{t("idcardNumber")}</th>
                                <th className="text-center">{t("examDate")}</th>
                                <th className="text-center">{t("examName")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                filteredStudents?.length === students.length || filteredStudents?.length === 0 ? (students.map((item, index) =>
                                  <tr key={index}>
                                    <td className="text-center" style={{ paddingLeft: '8px' }}>{!flag ? item.seat_number : index + 1}
                                    </td>
                                    <td className="text-left">{item.student_name}</td>
                                    {!flag ?
                                      <td className="text-center">{item.father_name}</td> : ""
                                    }
                                    <td className="text-center">{item.class_name}</td>
                                    <td className="text-center">{item.idcard_number}</td>
                                    <td className="text-center">{examDate}</td>
                                    <td className="text-center">{examName.label}</td>
                                  </tr>
                                )) : (filteredStudents.map((item, index) =>
                                  <tr key={index}>
                                    <td className="text-center">{!flag ? item.seat_number : index + 1}</td>
                                    <td className="text-center">{item.student_name}</td>
                                    {!flag ?
                                      <td className="text-center">{item.father_name}</td> : ""
                                    }
                                    <td className="text-center">{item.class_name}</td>
                                    <td className="text-center">{item.idcard_number}</td>
                                    <td className="text-center">{examDate}</td>
                                    <td className="text-center">{examName.label}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Card.Body>
                        : ""
                    }
                  </div>
                </Card>
              </Card.Body>
              {
                examPrepared ?
                  <Card.Footer className="d-flex justify-content-between">
                    <ReactToPrint
                      trigger={() =>
                        <Button className="btn-edit btn-fill" type="submit" variant="info">
                          <span className="btn-label">
                            <i className="fas fa-print"></i>
                          </span>
                          &nbsp;{t("btnPrint")}
                        </Button>
                      }
                      content={() => componentRef}
                    />
                    <Button className="btn-edit btn-fill" type="submit" variant="info" onClick={onSubmit}>
                      {t("btnSubmit")}
                    </Button>
                  </Card.Footer>
                  : ""
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default ExamSeatGeneration;
