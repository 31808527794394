import customAxios from "lib/axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Pagination from "../components/Pagination";
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
  Spinner
} from "react-bootstrap";
function ResultList() {
  const [t] = useTranslation(["exam"]);
  const [loading, setLoading] = React.useState(true)
  const history = useHistory();
  const [examList, setExamList] = React.useState([]);
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: "5 " + t("rows") });
  const allExamsCount = examList.length;
  const [examsPerPage, setExamsPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const lastExamNumber = currentPage * examsPerPage;
  const firstExamIndex = lastExamNumber - examsPerPage;
  const limitedExams = examList.slice(
    firstExamIndex,
    lastExamNumber
  );
  React.useEffect(() => {
    // submit form values
    customAxios.get("/exam/getResultedExamList")
      .then((res) => {
        setExamList(res.data);
        setLoading(false)
      })
      .catch((err) => {
        // Work with the err
      });
  }, [])
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form">
              <Card.Header>
                <Card.Title as="h4">{t("resultList")}</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width overflow-auto">
                <div className="d-flex justify-content-end mb-2">
                  <Pagination
                    itemsCount={allExamsCount}
                    itemsPerPage={examsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
                  <div style={{ width: 155 }}>
                    <Select
                      className="react-select primary ml-4"
                      classNamePrefix="react-select"
                      style={{ width: `100px` }}
                      name="rowCnt"
                      value={pageRowsCnt}
                      onChange={(optionSelected) => {
                        setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                        setExamsPerPage(optionSelected.value);
                      }}
                      options={[
                        { value: 5, label: "5 " + t("rows") },
                        { value: 10, label: "10 " + t("rows") },
                        { value: 15, label: "15 " + t("rows") },
                        { value: 20, label: "20 " + t("rows") },
                        { value: 25, label: "25 " + t("rows") },
                      ]}
                      placeholder={t("singleSelect")}
                    />
                  </div>
                </div>
                {!loading ? (
                  limitedExams?.length > 0 ?
                    <Table>
                      <thead>
                        <tr>
                          <th className="text-center" style={{ paddingLeft: '8px' }}>#</th>
                          <th className="text-center">{t("mainExamName")}</th>
                          <th className="text-center">{t("class")}</th>
                          <th className="text-center">{t("examDate")}</th>
                          <th className="text-center">{t("actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          limitedExams
                            .filter(item => item.class_name.isActive)
                            .map((item, i) =>
                              <tr key={i}>
                                <td className="text-center" style={{ paddingLeft: '8px' }}>{firstExamIndex + i + 1}</td>
                                <td className="text-center">{item.exam_name.exam_name}</td>
                                <td className="text-center">{item.class_name.class_name}</td>
                                <td className="text-center">{item.exam_name.exam_datetime}</td>
                                <td className="td-actions text-center">
                                  <Button className="btn-edit btn-outline" size="sm" variant="success" onClick={
                                    (e) => history.push("/admin/view_results/" + item.exam_nameid + "/" + item.exam_classid)
                                  }>
                                    {t("view")}
                                  </Button>
                                </td>
                              </tr>
                            )
                        }
                      </tbody>
                    </Table> :
                    <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noExamFound")}</div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResultList;
