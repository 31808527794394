import React, { useEffect, useRef } from 'react'
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from "react-bootstrap";
import customAxios from "../lib/axios";
import ImgUpload from "../components/AvatarUpload";
import { isRequired } from "../lib/validation";
import { useTranslation } from 'react-i18next';
import NotificationAlert from "react-notification-alert";
import SweetAlert from 'react-bootstrap-sweetalert';
import defaultUni from '../assets/img/default-uni';
import { commonContext } from '../context/common'

function EditProfile() {
    const [avatarFile, setAvatarFile] = React.useState(null);
    const [alert, setAlert] = React.useState(null);
    const [name, setName] = React.useState("");
    const [nameState, setNameState] = React.useState(true)
    const [avatarPreviewBase64, setAvatarPreviewBase64] = React.useState("");
    const notificationAlertRef = React.useRef(null);
    const profile = React.useContext(commonContext)
    const [t] = useTranslation(['employees']);

    const notify = (msg, place, color) => {
        var options = {};
        options = {
            place: place,
            type: color,
            message: (
                <div>
                    <div>
                        <b>{msg}</b>
                    </div>
                </div>
            ),
            icon: "nc-icon nc-bell-55",
            autoDismiss: 5,
        };
        notificationAlertRef.current.notificationAlert(options);
    };
    const warningWithConfirmMessage = () => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Error'
                onConfirm={() => hideAlert()}
                cancelBtnBsStyle='Cancel'
                showConfirm>
                Name is required
            </SweetAlert>,
        );
    };
    const hideAlert = () => {
        setAlert(null);
    };
    const onSuccessOkay = () => {
        setAlert(null);
        // clearAll();
    };
    const clearAll = () => {
        setName("")
        setAvatarFile(null)
        setAlert(null)
        setNameState(true)
        //setAvatarPreviewBase64(defaultUni)
    }
    const successAlert = (idcardNumber) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title={t('submitSuccessAlertTitle')}
                onConfirm={() => onSuccessOkay()}
                onCancel={() => onSuccessOkay()}
                confirmBtnBsStyle='info'
                confirmBtnText={t('btnOkay')}>
                Profile Updated
            </SweetAlert>,
        );
    };
    const onConfirm = (e) => {
        e.preventDefault()
        try {
            if (!name) {
                warningWithConfirmMessage();
            }
            else {
                avatarFile !== null && avatarPreviewBase64 !== null ?
                    customAxios.post("/common/uploadimg", { 'image': avatarPreviewBase64, 'name': name }, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }) : ''
                customAxios.post("/common/edit-profile", { name: name })
                successAlert(name)
                profile.setName(name)
                profile.setProfile(avatarPreviewBase64)
            }

        } catch (err) {
            notify(t("notifyConnectionProblem"), "tr", "danger");
            console.log(err)
        }
    }
    const getProfile = async () => {
        const name = await customAxios.get("/common/get-profile")
        setName(name?.data?.name?.name)
        if (name !== "") {
            const img = await customAxios.post("/common/getAvatarImg", { idcard_number: name?.data?.name?.name })
            let base64img = img?.data?.base64data ? ("data:image/png;base64," + img?.data?.base64data) : null;
            setAvatarPreviewBase64(base64img)
            setAvatarFile("file")
        } else {
            setAvatarPreviewBase64("")
            setAvatarFile("")
        }
    }
    React.useEffect(() => {
        getProfile()
    }, [])
    return (
        <>
            {alert}
            <div div className="rna-container" >
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <Card className="stacked-form">
                <Form action='' className='form' method=''>
                    <Card.Body>
                        <Row className="flex justify-content-center">
                            <div className="imageUpload">
                                <ImgUpload curPreviewImage={avatarPreviewBase64 || ''}
                                    onChangePrivewImage={setAvatarPreviewBase64}
                                    onChangeAvatarFile={setAvatarFile}
                                    flag={false}
                                />
                            </div>
                        </Row>
                        <Row className="flex justify-content-center">
                            <Form.Group
                                className={
                                    "has-label " +
                                    (nameState ? "has-success" : "has-error")
                                }
                            >
                                <label>{t("universityNameLabel")}</label>
                                <Form.Control
                                    placeholder=""
                                    type="text"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                        if (isRequired(e.target.value)) {
                                            setNameState(true)
                                        } else {
                                            setNameState(false)
                                        }
                                    }}
                                ></Form.Control>
                                {nameState ? null : (
                                    <label className='error'>{t('validationRequired')}</label>
                                )}
                            </Form.Group>
                        </Row>
                    </Card.Body>
                    <Card.Footer className='d-flex align-items-center justify-content-center my-2'>
                        <Button className="btn-edit btn-edit btn-fill" type="submit" variant="info" onClick={onConfirm}>
                            {t("btnSubmit")}
                        </Button>
                    </Card.Footer>
                </Form>
            </Card>
        </>
    )
}

export default EditProfile