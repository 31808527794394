import customAxios from "lib/axios";
import React, { useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Select from "react-select";
import { selectConsts } from '../store/selectconstReducer';
import { commonContext } from '../context/common'
import { resultGrade } from '../store/gradeReducer'
// react-bootstrap components
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner
} from "react-bootstrap";
function ResultView() {
  const [t] = useTranslation(["exam"]);
  const common = useContext(commonContext)
  let componentRef = useRef();
  const ref = useRef()
  const storeSelectConsts = useSelector(selectConsts);
  const storeGradeResult = useSelector(resultGrade)
  const [examName, setExamName] = React.useState({ value: '', label: 'All' });
  const [curClass, setCurClass] = React.useState({ value: '', label: 'All' });
  const [newArray, setNewArray] = React.useState([]);
  const [newExamArray, setNewExamArray] = React.useState([]);
  const [gradeResult, setGradeResult] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    const newObject = {
      value: '',
      label: 'All',
      class_type: "Local",
      department_id: 3
    };
    const newExamObject = {
      value: '',
      label: 'All',
      exam_datetime: ""
    };
    const newArray1 = [newObject, ...storeSelectConsts.CLASS_NAMES];
    const newExamArray1 = [newExamObject, ...storeSelectConsts.EXAM_NAMES];
    setNewExamArray(newExamArray1);
    setNewArray(newArray1)
    customAxios.get(`/exam/getGradingResult?exam_classid=${curClass?.value}&exam_nameid=${examName?.value}`)
      .then((res) => {
        setGradeResult(res.data.gradeResult);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      });
  }, [curClass, examName, storeSelectConsts])
  window.addEventListener('resize', function () {
    var boxes = document.querySelectorAll('.result-box');
    var maxWidth = 0;
    // Loop through each box to find the maximum width
    boxes.forEach(function (box) {
      // Reset width to auto to calculate natural width
      box.style.width = 'auto';
      // Find the maximum width
      maxWidth = Math.max(maxWidth, box.offsetWidth);
    });
    // Set the same width for all boxes
    boxes.forEach(function (box) {
      box.style.width = maxWidth + 'px';
    });
  });
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="stacked-form result-grade">
              <Card.Header>
                <Card.Title as="h4">{t("GradeResult")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("examName")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="examName"
                        value={examName}
                        onChange={(value) => setExamName(value)}
                        options={newExamArray}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                  <Col className="p1-1" md="6">
                    <Form.Group>
                      <label>{t("class")}</label>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="curClass"
                        value={curClass}
                        onChange={(value) => setCurClass(value)}
                        options={[{ value: '', label: 'All' }, ...newArray.filter((ele) => ele.isActive)]}
                        placeholder={t("singleSelect")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {!common.gradeLoading && !loading ?
                  <row ref={(el) => (componentRef = el)}>
                    {gradeResult && gradeResult.length !== 0 ? (
                      gradeResult
                        .filter((ele) => ele.classActive)
                        .map((item, i) => {
                          return (
                            <Card className="stacked-form card" key={i}>
                              <Card.Header>
                                <Card.Title as="h5">{item?.className}</Card.Title>
                              </Card.Header>
                              <Card.Body className="d-flex overflow-auto scroll-container">
                                {Object.entries(item)?.map((ele, j) => {
                                  return ele[0] === 'className' || ele[0] === 'classId' ? null : (
                                    ele[0] === 'classActive' ?
                                      <div
                                        className="div-style result-box"
                                        key={j}
                                      >
                                        <span>
                                          {/* <b>{ele[0] === 'totalStudents' ? 'All' : ele[0]}: </b> */}
                                          <b>{ele[0] === 'totalStudents' ? 'All' : ele[0]}: </b>
                                          {ele[1].count}
                                        </span>
                                      </div> :
                                      <div
                                        className="div-style result-box"
                                        key={j}
                                      >
                                        <span>
                                          {/* <b>{ele[0] === 'totalStudents' ? 'All' : ele[0]}: </b> */}
                                          <b>{ele[0] === 'totalStudents' ? 'All' : common.lang === 'en' ? storeGradeResult.GRADE_NAMES.map((item => {
                                            if (item.id === ele[1].id) {
                                              return item.grade_name
                                            }
                                          })) : storeGradeResult.GRADE_NAMESAR.map((item => {
                                            if (item.id === ele[1].id) {
                                              return item.grade_name
                                            }
                                          }))}: </b>
                                          {ele[1].count}
                                        </span>
                                      </div>
                                  );
                                })}
                              </Card.Body>
                            </Card>
                          );
                        })
                    ) : (
                      <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">
                        {t("noGradingResultFound")}
                      </div>
                    )}
                  </row> :
                  <div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  );
}
export default ResultView;