import axios from "axios";
import axiosRetry from "axios-retry";

const customAxios = axios.create({
  baseURL: "/api",  // on real server
  // baseURL: "http://localhost:8001/api",  //process.env.REACT_APP_API_URL
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosRetry(customAxios, { retries: 3, retryDelay: () => 500 });

export default customAxios;
