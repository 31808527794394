import customAxios from "lib/axios";
import setPageDirection from "lib/setPageDirection";
import { isRequired } from "lib/validation";
import React from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import Select from "react-select";

// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Media,
  Row
} from "react-bootstrap";

const langVarName = [
  { value: "en", name: "English" },
  { value: "ar", name: "Arabic" },
];

function UserRegister() {
  const [t, i18n] = useTranslation(["auth"]);

  const history = useHistory();

  const notificationAlertRef = React.useRef(null);

  const [langName, setLangName] = React.useState({ value: "en", label: t("English") });

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [curPosition, setCurPosition] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  // Validations
  const [firstNameState, setFirstNameState] = React.useState(true);
  const [lastNameState, setLastNameState] = React.useState(true);
  const [curPositionState, setCurPositionState] = React.useState(true);
  const [emailState, setEmailState] = React.useState(true);
  const [passwordState, setPasswordState] = React.useState(true);
  const [passwordConfirmState, setPasswordConfirmState] = React.useState(true);
  const [passwordConfirmEqualState, setPasswordConfirmEqualState] = React.useState(true);

  const notify = (msg, place, color) => {
    var options = {};
    options = {
      place: place,
      type: color,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      icon: "nc-icon nc-bell-55",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const onSubmit = () => {
    // validation check
    let allValid = true;
    if (!firstNameState || !isRequired(firstName)) {
      setFirstNameState(false);
      allValid = false;
    }
    if (!lastNameState || !isRequired(lastName)) {
      setLastNameState(false);
      allValid = false;
    }
    if (!curPositionState || !isRequired(curPosition)) {
      setCurPositionState(false);
      allValid = false;
    }
    if (!emailState || !isRequired(email)) {
      setEmailState(false);
      allValid = false;
    }
    if (!passwordState || !isRequired(password)) {
      setPasswordState(false);
      allValid = false;
    }
    if (!passwordConfirmState || !isRequired(passwordConfirm)) {
      setPasswordConfirmState(false);
      allValid = false;
    }
    if (!passwordConfirmEqualState || password != passwordConfirm) {
      setPasswordConfirmEqualState(false);
      allValid = false;
    }

    if (!allValid) {
      // notify("All input fields should be filled correctly", "tr", "warning");
      return;
    }

    customAxios.post("/user/register", {
      first_name: firstName,
      last_name: lastName,
      position: curPosition,
      email: email,
      password: password,
    })
      .then((res) => {
        notify("Registeration success.", "tr", "success");
        clearAll();
        // history.push("/userlogin");
        return;
      })
      .catch((err) => {
        notify("Registeration failed. Please try again.", "tr", "warning");
        // notify("Connection is not good. Please retry some later.", "tr", "warning");
      });
  }

  const clearAll = () => {
    setFirstName("");
    setLastName("");
    setCurPosition("");
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
  }

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div
        className="full-page register-page section-image"
        data-color="black"
        data-image={require("assets/img/bg5.jpg")}
      >
        <div className="content d-flex align-items-center">
          <Container>
            <Card className="card-register card-plain text-center">
              <Card.Header>
                <Row className="justify-content-center">
                  <Col md="8">
                    <div className="header-text">
                      <Card.Title as="h2">
                        {t("title")}
                      </Card.Title>
                      <Card.Subtitle as="h4">
                        {t("userRegisteration")}
                      </Card.Subtitle>
                      <hr></hr>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row className="justify-content-center">
                  <Col className="mt-1" md="7" lg="5">
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-circle-09"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>{t("userName")}</h4>
                        <p>
                          {t("userNameDesc.first")}<br />
                          {t("userNameDesc.second")}
                        </p>
                      </Media.Body>
                    </Media>
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-preferences-circle-rotate"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>{t("positionAndEmail")}</h4>
                        <p>
                          {t("positionAndEmailDesc.first")}<br />
                          {t("positionAndEmailDesc.second")}
                        </p>
                      </Media.Body>
                    </Media>
                    <Media>
                      <div className="media-left">
                        <div className="icon">
                          <i className="nc-icon nc-planet"></i>
                        </div>
                      </div>
                      <Media.Body>
                        <h4>{t("createPassword")}</h4>
                        <p>
                          {t("createPasswordDesc.first")}<br />
                          {t("createPasswordDesc.second")}
                        </p>
                      </Media.Body>
                    </Media>
                  </Col>
                  <Col className="" md="5" lg="4">
                    {/* <Form action="#" method="#"> */}
                    <Card className="card-plain">
                      <div className="card-body">
                        <Form.Group
                          className={
                            "has-label " +
                            (firstNameState ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("yourFirstName")}
                            type="text"
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              if (isRequired(e.target.value)) {
                                setFirstNameState(true);
                              } else {
                                setFirstNameState(false);
                              }
                            }}
                          ></Form.Control>
                          {firstNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label " +
                            (lastNameState ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("yourLastName")}
                            type="text"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              if (isRequired(e.target.value)) {
                                setLastNameState(true);
                              } else {
                                setLastNameState(false);
                              }
                            }}
                          ></Form.Control>
                          {lastNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label " +
                            (curPositionState ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("currentPosition")}
                            type="text"
                            value={curPosition}
                            onChange={(e) => {
                              setCurPosition(e.target.value);
                              if (isRequired(e.target.value)) {
                                setCurPositionState(true);
                              } else {
                                setCurPositionState(false);
                              }
                            }}
                          ></Form.Control>
                          {curPositionState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label " +
                            (emailState ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("enterEmail")}
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (isRequired(e.target.value)) {
                                setEmailState(true);
                              } else {
                                setEmailState(false);
                              }
                            }}
                          ></Form.Control>
                          {emailState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label " +
                            (password ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("password")}
                            type="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if (isRequired(e.target.value)) {
                                setPasswordState(true);
                              } else {
                                setPasswordState(false);
                              }
                            }}
                          ></Form.Control>
                          {passwordState ? null : (<label className="error">{t("validationRequired")}</label>)}
                        </Form.Group>
                        <Form.Group
                          className={
                            "has-label " +
                            (passwordConfirm & passwordConfirmEqualState ? "has-success" : "has-error")
                          }
                        >
                          <Form.Control
                            placeholder={t("passwordConfirm")}
                            type="password"
                            value={passwordConfirm}
                            onChange={(e) => {
                              setPasswordConfirm(e.target.value);
                              setPasswordConfirmEqualState(true);
                              if (isRequired(e.target.value)) {
                                setPasswordConfirmState(true);
                              } else {
                                setPasswordConfirmState(false);
                              }
                            }}
                          ></Form.Control>
                          {passwordConfirmState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          {passwordConfirmEqualState ? null : (<label className="error">{t("passwordConfirmValidation")}</label>)}
                        </Form.Group>
                      </div>
                      <div className="card-footer text-center">
                        <Button
                          className="btn-fill btn-wd mx-1"
                          type="submit"
                          variant="primary"
                          onClick={onSubmit}
                        >
                          {t("createAccount")}
                        </Button>
                        <Button
                          className="btn-fill btn-wd mx-1"
                          type="submit"
                          variant="warning"
                          onClick={(e) => history.push("/userlogin")}
                        >
                          {t("login")}
                        </Button>
                      </div>
                    </Card>
                    {/* </Form> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          <Form.Group className="fixed-top mt-3 mr-5" style={{ width: `150px` }} >
            <Select
              className="react-select primary mx-2"
              classNamePrefix="react-select"
              name="rowCnt"
              value={langName}
              onChange={(optionSelected) => {
                i18n.changeLanguage(optionSelected.value);
                setLangName({ value: optionSelected.value, label: t(langVarName.find((i) => i.value == optionSelected.value).name) });
                setPageDirection(optionSelected.value);
              }}
              options={[
                { value: "en", label: t("English") },
                { value: "ar", label: t("Arabic") },
              ]}
            />
          </Form.Group>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage: "url(" + require("assets/img/bg5.jpg") + ")"
          }}
        ></div>
      </div>
    </>
  );
}

export default UserRegister;
