import { createSlice } from '@reduxjs/toolkit';
import { useState } from 'react';

export const gradeSlice = createSlice({
    name: 'gradeReducer',
    initialState: {
        GRADE_NAMES: [],
        GRADE_NAMESAR: [],
    },
    reducers: {
        setAll: (state, action) => {
            state.GRADE_NAMES = action.payload.GRADE_NAMES;
            state.GRADE_NAMESAR = action.payload.GRADE_NAMESAR;
        },
        addGradeName: (state, action) => {
            state.GRADE_NAMES = [action.payload.GRADE_NAME, ...state.GRADE_NAMES];
            state.GRADE_NAMESAR = [action.payload.GRADE_NAMEAR, ...state.GRADE_NAMESAR];
        },
        removeGrade: (state, action) => {
            state.GRADE_NAMES = state.GRADE_NAMES.filter((item) => item.id != action.payload);
            state.GRADE_NAMESAR = state.GRADE_NAMESAR.filter((item) => item.id != action.payload);
        },
        changeGrade: (state, action) => {
            state.GRADE_NAMES = state.GRADE_NAMES.map(gradeName => {
                if (gradeName.id == action.payload.id) {
                    return { ...gradeName, min: action.payload.min, max: action.payload.max };
                }
                return gradeName;
            });
        },
    }
})
//create your forceUpdate hook
export const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // A function that increment 👆🏻 the previous state like here 
    // is better than directly setting `setValue(value + 1)`
}

export const resultGrade = (state) => state.gradeReducer;
export const { setAll, addGradeName, removeGrade, changeGrade } = gradeSlice.actions
export default gradeSlice.reducer
