import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import defaultAvatar from "../../assets/img/default-avatar";
import { useTranslation } from 'react-i18next';
function ImgUpload({ curPreviewImage, onChangePrivewImage, onChangeAvatarFile, flag }) {
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1.2);
  const [flag1, setFlag1] = useState(false)
  const [t] = useTranslation(['auth']);
  const photoUpload = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      onChangePrivewImage(reader.result); // this should be runned before onChangeAvatarFile to keep file information to send to the server
      onChangeAvatarFile(file);
    }
    reader.readAsDataURL(file);
    flag ? setFlag1(true) : setFlag1(false)
  }
  const handleSave = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const dataURL = canvas.toDataURL(); // This is the resized image data
      onChangePrivewImage(dataURL);
      setScale(1.2)
      setFlag1(false)
    }
  }
  const handleCancle = () => {
    setFlag1(false)
    onChangePrivewImage('')
  }
  const handleZoomChange = (e) => {
    setScale(parseFloat(e.target.value));
  }
  return (
    <>
      {flag1 ? (
        <div className='position-relative'>
          <div>
            <AvatarEditor
              ref={setEditor}
              image={curPreviewImage || defaultAvatar}
              width={116}
              height={116}
              border={4}
              color={[0, 0, 0, 0.7]} // RGBA
              scale={scale}
              borderRadius={125}
              style={{ borderRadius: '50%' }}
            />
          </div>
          <input type="range" min="1" max="3" step="0.1" value={scale} onChange={handleZoomChange} style={{ accentColor: "#6c757d" }} /><br></br>
          <button className="btn btn-warning ml-1 mr-1" onClick={handleSave}>{t("btnSave")}</button>
          <button className="btn btn-danger ml-1 mr-1" onClick={handleCancle}>{t("btnCancel")}</button>
          {/* </>
        <> */}
          {/* <label htmlFor="photo-upload" className="mb-3">
          <img for="photo-upload" className="avatar" src={!curPreviewImage ? defaultAvatar : curPreviewImage} title="Change Avatar" />
          <input id="photo-upload" accept="image/jpeg, image/png" type="file" onChange={photoUpload} />
        </label> */}
        </div>
      )
        : (
          <>
            <label htmlFor="photo-upload" className="mb-3">
              <img htmlFor="photo-upload" className="avatar" src={!curPreviewImage ? defaultAvatar : curPreviewImage} title="Change Avatar" />
              <input id="photo-upload" accept="image/jpeg, image/png" type="file" onChange={photoUpload} />
            </label>
          </>
        )
      }
    </>
  )
}
export default ImgUpload;