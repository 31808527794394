import setAuthToken from "lib/setAuthToken";
import setPageDirection from "lib/setPageDirection";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { logoutUser } from "store/authReducer";
import { commonContext } from "../../context/common"
import { authInfo } from '../../store/authReducer';
import { useSelector } from 'react-redux';
// react-bootstrap components
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar
} from "react-bootstrap";
const langVarName = [
  { value: "en", name: "English" },
  { value: "ar", name: "Arabic" },
];
function AdminNavbar({ userProfile }) {
  const [t, i18n] = useTranslation(["sidebar"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [langName, setLangName] = React.useState({ value: "en", label: t("English") });
  const common = React.useContext(commonContext)
  const storeAuthInfo = useSelector(authInfo);
  const onLogout = () => {
    dispatch(logoutUser());
    setAuthToken(null);
    history.push("/userlogin");
  }
  return (
    <>
      <Navbar expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-fill btn-round btn-icon d-none d-lg-block bg-dark border-dark"
                variant="dark"
                onClick={() => {
                  const element = document.getElementsByClassName("wrapper");
                  if (element) {
                    element[0].classList.contains("sidebar-mini")
                      ? common?.setSidebarSwitch(false)
                      : common?.setSidebarSwitch(true);
                    element[0].classList.toggle("sidebar-mini")
                  }
                }}
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
              <Button
                className="btn-fill btn-round btn-icon d-block d-lg-none bg-dark border-dark"
                variant="dark"
                onClick={() =>
                  document.documentElement.classList.toggle("nav-open")
                }
              >
                <i className="fas fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i className="fas fa-bars visible-on-sidebar-mini"></i>
              </Button>
            </div>
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              {t("administrator")}
            </Navbar.Brand>
          </div>
          <div className="user d-flex flex-row align-items-center button-profile d-lg-none">
            <div className="photo"
              onClick={() => { history.push(`/admin/edit_user_profile/`) }}
              style={{ height: '40px', width: '40px', margin: '0px 5px' }}
            >
              <img
                // src={!userProfile ? require('../../assets/img/default-uni') : userProfile}
                src={!userProfile ? require('../../assets/img/default-avatar') : userProfile}
                alt=""
                style={{ cursor: 'pointer', height: '100%', width: '100%', borderRadius: '50%' }}
              />
            </div>
            <button
              className="navbar-toggler navbar-toggler-right border-0"
              type="button"
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
              <span className="navbar-toggler-bar burger-lines"></span>
            </button>
          </div>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav className="navbar" style={{ border: 'none' }}>
              <div className="user d-flex flex-row align-items-center nav-profile">
                <div className="photo"
                  onClick={() => { history.push(`/admin/edit_user_profile/`) }}
                  style={{ height: '40px', width: '40px', margin: '0px 10px' }}
                >
                  <img
                    src={!userProfile ? require('../../assets/img/default-avatar') : userProfile}
                    alt=""
                    style={{ cursor: 'pointer', height: '100%', width: '100%', borderRadius: '50%' }}
                  />
                </div>
                <div className="info" onClick={() => { history.push(`/admin/edit_user_profile/`) }} style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <a
                    data-toggle="collapse"
                    aria-expanded={true}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    <span className="text-large"
                      style={{ overflow: "hidden", whiteSpace: 'nowrap', textOverflow: 'ellipsis', minHeight: '20px', color: '#495057' }}
                      title={storeAuthInfo?.user?.first_name + " " + storeAuthInfo?.user?.last_name}
                    >
                      {storeAuthInfo?.user?.first_name + " " + storeAuthInfo?.user?.last_name}
                    </span>
                  </a>
                </div>
              </div>
              <div className="d-flex flex-row">
                <Form.Group className="my-auto" style={{ width: `150px` }} >
                  <Select
                    className="react-select primary mx-2"
                    classNamePrefix="react-select"
                    name="rowCnt"
                    value={langName}
                    onChange={(optionSelected) => {
                      i18n.changeLanguage(optionSelected.value);
                      setLangName({ value: optionSelected.value, label: t(langVarName.find((i) => i.value == optionSelected.value).name) });
                      setPageDirection(optionSelected.value);
                      common.setLang(optionSelected.value)
                    }}
                    options={[
                      { value: "en", label: t("English") },
                      { value: "ar", label: t("Arabic") },
                    ]}
                  />
                </Form.Group>
                <Dropdown as={Nav.Item} >
                  <Dropdown.Toggle
                    as={Nav.Link}
                    id="dropdown-41471887333"
                    variant="default"
                    className="pl-1 pr-1"
                  >
                    <i className="nc-icon nc-bullet-list-67"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    alignRight
                    aria-labelledby="navbarDropdownMenuLink"
                    className="mt-1"
                  >
                    <Dropdown.Item
                      className="text-danger"
                      onClick={(e) => { e.preventDefault(); onLogout(); }}
                    >
                      <i className="nc-icon nc-button-power"></i>
                      {t("logout")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="text-info"
                      onClick={(e) => history.push("/admin/edit_profile")}
                    >
                      <i className="nc-icon nc-bank"></i>
                      {t("editProfileLabel")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default AdminNavbar;


