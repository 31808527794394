import customAxios from 'lib/axios';
import { isRequired } from 'lib/validation';
import React, { useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import NotificationAlert from 'react-notification-alert';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import { selectConsts } from '../store/selectconstReducer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from 'react-bootstrap';
import { commonContext } from '../context/common'
const DraggedRow = ({ bookIndex, bookItem, snapshot, provided, classIndex }) => {
  const draggedItemStyle = {
    backgroundColor: snapshot.isDragging ? '#edf7dcde' : '',
    borderTop: 'none',
    opacity: snapshot.isDragging ? 0.7 : 1,
    ...provided.draggableProps.style,
    zIndex: "0",
    overflowX: 'hidden',
    textAlign: 'center',
    display: "flex",
    flxeDiraction: "row",
    alignItems: "center",
    justifyContent: 'center',
    overflowX: 'hidden',
    left: window.innerWidth >= 992 ? undefined : '62px',
    ...(window.innerWidth <= 525 && { width: `calc(${window.innerWidth}px - 125px)` }),
  };
  return (
    <tr
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      draggable
      style={draggedItemStyle}
    >
      <td id="dragged" style={{ paddingLeft: '8px', color: '#2b2b2b', textAlign: 'center', borderTop: 'none', position: 'absolute' }} colSpan={4}>{bookItem.book_name}</td>
    </tr>
  );
};
function Book() {
  const [t] = useTranslation(['books']);
  const common = useContext(commonContext)
  const [loading, setLoading] = React.useState(true)
  const storeSelectConsts = useSelector(selectConsts);
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const [bookId, setBookId] = React.useState('');
  const [bookName, setBookName] = React.useState('');
  const [bookNumber, setBookNumber] = React.useState('');
  const [classOption, setClassOption] = React.useState({ value: '', label: 'All' });
  const [employeename, setEmployeename] = React.useState('');
  const [bookNameState, setBookNameState] = React.useState(true);
  const [bookNumberState, setBookNumberState] = React.useState(true);
  const [classOptionState, setClassOptionState] = React.useState(true);
  const [employeeNameState, setEmployeeNameState] = React.useState(true);
  const [books, setBooks] = React.useState([]);
  const [flags, setFlags] = React.useState(1);
  const [modal, setModal] = React.useState(false);
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 2, label: '2 ' + t('rows') });
  const allClassesCount = flags ? storeSelectConsts.CLASS_NAMES.length : 1;
  const [classesPerPage, setClassesPerPage] = React.useState(2);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [employee, setEmployee] = React.useState([]);
  const lastClassNumber = currentPage * classesPerPage;
  const firstClassIndex = lastClassNumber - classesPerPage;
  const limitedClasses = storeSelectConsts.CLASS_NAMES
    .filter((cls) => cls?.isActive)
    .slice(firstClassIndex, lastClassNumber);
  const [characters, updateCharacters] = React.useState(books);
  const [draggedIndex, setDraggedIndex] = React.useState(null);
  const [draggedItem, setDraggedItem] = React.useState(null);
  const array = [];
  const oneClass = storeSelectConsts.CLASS_NAMES.find((item) => {
    if (item.label === classOption.label) {
      return item;
    }
  });
  array.push(oneClass);
  React.useEffect(() => {
    getBooks();
  }, []);
  async function handleOnDragEnd(result, index) {
    try {
      if (!result.destination) return;
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      // Clone the characters array to avoid modifying the state directly
      const updatedCharacters = [...characters];
      // Find the dragged book in the filtered array
      const classBooks = updatedCharacters.filter((item) => item.class_id === index);
      const draggedBook = classBooks[sourceIndex];
      if (!draggedBook) {
        return;
      }
      // Remove the dragged book from its original position
      updatedCharacters.splice(
        updatedCharacters.findIndex((item) => item.id === draggedBook.id), 1
      );
      // Determine the index in the filtered array for the destination
      const updatedIndex = destinationIndex < classBooks.length ? destinationIndex : classBooks.length;
      // Insert the dragged book at its new position in the filtered array
      updatedCharacters.splice(
        updatedCharacters.findIndex((item) => item.class_id === index) + updatedIndex, 0, draggedBook
      );
      updateCharacters(updatedCharacters);
      try {
        const response = await customAxios.post('/class/updateOrder', {
          newOrder: updatedCharacters.map((item, index) => ({ id: item.id, order: index })),
        });
      } catch (error) {
        console.error('Error updating order:', error);
      }
    } catch (err) {
      console.log(Error, "Error")
    }
  }
  function getBooks() {
    customAxios
      .get('/class/getAllBooks')
      .then((res) => {
        setBooks(res.data);
        setLoading(false)
      })
      .catch((err) => {
        // Work with the err
        // alert(err)
      });
  }
  React.useEffect(() => {
    customAxios.get('/employee/getAll').then((res) => {
      setEmployee(res?.data?.employees.filter((emp) => emp?.job_role === 1 || emp?.employee_jobrole?.job_role
        === "Teacher"));
      setLoading(false)
    });
  }, []);
  React.useEffect(() => {
    updateCharacters(books);
  }, [books]);
  const warningWithConfirmMessage = (curBookId, curBookName) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('modalWarnningMsgTitle')}
        onConfirm={() => onConfirmDelete(curBookId, curBookName)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle='info'
        cancelBtnBsStyle='danger'
        confirmBtnText={t('btnSubmit')}
        cancelBtnText={t('btnCancel')}
        showCancel>
        {t('modalWarnningMsgText')}
      </SweetAlert>,
    );
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('submitSuccessAlertTitle')}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle='info'
        confirmBtnText={t('btnOkay')}>
        {msg}
      </SweetAlert>,
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: 'danger',
      icon: 'nc-icon nc-bell-55',
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (curBookId, curBookName) => {
    customAxios
      .post('/class/deleteOneBook/', {
        id: curBookId,
      })
      .then((res) => {
        if (res.data.msg == 'success') {
          successAlert(
            t('submitRemoveSuccessAlertMsg.first') +
            curBookName +
            t('submitRemoveSuccessAlertMsg.second'),
          );
          // dispatch deleting event to the redux store
          setBooks(books.filter((item) => item.id != curBookId));
        }
      })
      .catch((err) => {
        // Work with the err
        notify('tr', t('notifyConnectionProblem'));
      });
  };
  const onUpsert = () => {
    // validation check
    let allValid = true;
    if (!bookNameState || !isRequired(bookName)) {
      setBookNameState(false);
      allValid = false;
    }
    if (!bookNumberState || !isRequired(bookNumber)) {
      setBookNumberState(false);
      allValid = false;
    }
    if (!classOptionState || !isRequired(classOption.value)) {
      setClassOptionState(false);
      allValid = false;
    }
    if (!allValid) {
      notify('tr', t('validationNotify'));
      return;
    }
    if (bookId) {
      customAxios
        .post('/class/updateBook', {
          id: bookId,
          book_name: bookName,
          number: bookNumber,
          employee_id: employeename.value,
          class_id: classOption.value,
        })
        .then((res) => {
          if (res.data.msg == 'success') {
            successAlert(
              t('submiUpdateSuccessAlertMsg.first') +
              bookName +
              t('submiUpdateSuccessAlertMsg.second'),
            );
            // getBooks()
            setBooks(
              books.map((item) => {
                if (item.id == bookId)
                  return {
                    ...item,
                    class_id: classOption.value,
                    book_name: bookName,
                    number: bookNumber,
                    employee_id: employeename.value,
                    employees: [{ employee_name: employeename.label }],
                  };
                return item;
              }),
            );
          }
        })
        .catch((err) => {
          // Work with the err
          notify('tr', t('notifyConnectionProblem'));
        });
    } else {
      customAxios
        .post('/class/createBook', {
          class_id: classOption.value,
          book_name: bookName,
          number: bookNumber,
          employee_id: employeename.value,
        })
        .then((res) => {
          if (res.data.msg == 'success') {
            successAlert(
              t('submitAddSuccessAlertMsg.first') + bookName + t('submitAddSuccessAlertMsg.second'),
            );
            res.data.created['employees'] = [{ employee_name: employeename.label }];
            setBooks([res.data.created, ...books]);
            // getBooks()
          }
        })
        .catch((err) => {
          'err', err;
          // Work with the err
          notify('tr', t('notifyConnectionProblem'));
        });
    }
    setBookId('');
    setBookName('');
    setBookNumber('');
    setClassOption({ value: classOption.value, label: classOption.label });
    setBookNameState(true);
    setBookNumberState(true);
    setClassOptionState(true);
    setModal(!modal);
  };
  let employeeArray = [];
  employee.forEach((emp) => {
    employeeArray.push({
      label: emp.employee_name,
      value: emp.id,
    });
  });
  return (
    <>
      {alert}
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md='12'>
            <Card className='stacked-form'>
              <Card.Header>
                <Card.Title as='h4'>{t('title')}</Card.Title>
              </Card.Header>
              <Card.Body className='table-full-width'>
                <Row>
                  <Col>
                    <Button
                      className='btn-wd btn-outline'
                      type='button'
                      onClick={() => setModal(!modal)}
                      variant='success'>
                      <span className='btn-label'>
                        <i className='fas fa-plus'></i>
                      </span>
                      &nbsp;{t('addBook')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    {/* <div style={{ width: 300, marginTop: '-15px' }} className='col-md-3'> */}
                    <label>{t('sortByClass')}</label>
                    <Select
                      className='react-select primary'
                      classNamePrefix='react-select'
                      name='className'
                      value={classOption}
                      onChange={(optionSelected) => {
                        if (optionSelected.value === '' && optionSelected.label == 'All') {
                          setFlags(1)
                          setClassOption({
                            value: optionSelected.value,
                            label: optionSelected.label,
                          });
                        } else {
                          setClassOption({
                            value: optionSelected.value,
                            label: optionSelected.label,
                          });
                          setFlags(0);
                        }
                      }}
                      placeholder={t('singleSelect')}
                      options={[{ value: "", label: "All" }, ...storeSelectConsts.CLASS_NAMES.filter(cls => cls.isActive)]}
                    />
                    {/* </div> */}
                  </Col>
                </Row>
                <div className='d-flex justify-content-end align-center mt-3 mb-2'>
                  <Pagination
                    itemsCount={allClassesCount}
                    itemsPerPage={classesPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={false}
                  />
                  <div style={{ width: 155 }}>
                    <Select
                      className='react-select primary ml-4'
                      classNamePrefix='react-select'
                      style={{ width: `100px` }}
                      name='rowCnt'
                      value={pageRowsCnt}
                      onChange={(optionSelected) => {
                        setPageRowsCnt({
                          value: optionSelected.value,
                          label: optionSelected.label,
                        });
                        setClassesPerPage(optionSelected.value);
                        setFlags(1);
                      }}
                      options={[
                        { value: 2, label: '2 ' + t('rows') },
                        { value: 3, label: '3 ' + t('rows') },
                        { value: 5, label: '5 ' + t('rows') },
                        { value: 10, label: '10 ' + t('rows') },
                        { value: 15, label: '15 ' + t('rows') },
                        { value: 20, label: '20 ' + t('rows') },
                        { value: 25, label: '25 ' + t('rows') },
                      ]}
                      placeholder={t('singleSelect')}
                    />
                  </div>
                </div>
                {/* Mini Modal */}
                <Modal
                  className='modal-primary'
                  size='lg'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                  onHide={() => {
                    setModal(!modal);
                  }}
                  show={modal}>
                  <Card className='stacked-form'>
                    <Card.Header className='text-center'>
                      <Card.Title as='h4'>{`Add book - ${bookName}(${bookNumber})`}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className='pr-1' md='4'>
                          <Form.Group
                            className={
                              'has-label ' + (bookNameState ? 'has-success' : 'has-error')
                            }>
                            <label>
                              {t('bookName')}
                              <span className='star'>*</span>
                            </label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder=''
                              value={bookName}
                              type='text'
                              onChange={(e) => {
                                setBookName(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setBookNameState(true);
                                } else {
                                  setBookNameState(false);
                                }
                              }}></Form.Control>
                            {bookNameState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className='pr-1' md='2'>
                          <Form.Group
                            className={
                              'has-label ' + (bookNumberState ? 'has-success' : 'has-error')
                            }>
                            <label>
                              {t('number')}
                              <span className='star'>*</span>
                            </label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder=''
                              value={bookNumber}
                              type='number'
                              onChange={(e) => {
                                setBookNumber(e.target.value);
                                if (isRequired(e.target.value)) {
                                  setBookNumberState(true);
                                } else {
                                  setBookNumberState(false);
                                }
                              }}></Form.Control>
                            {bookNumberState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className='pl-1' md='3'>
                          <Form.Group
                            className={
                              'has-label ' + (classOptionState ? 'has-success' : 'has-error')
                            }>
                            <label>
                              {t('class')}
                              <span className='star'>*</span>
                            </label>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='className'
                              value={classOption}
                              onChange={(optionSelected) => {
                                setClassOption({
                                  value: optionSelected.value,
                                  label: optionSelected.label,
                                });
                                if (isRequired(optionSelected.value)) {
                                  setClassOptionState(true);
                                } else {
                                  setClassOptionState(false);
                                }
                              }}
                              options={storeSelectConsts.CLASS_NAMES.filter(cls => cls.isActive)}
                              placeholder={t('singleSelect')}
                            />
                            {classOptionState ? null : (
                              <label className='error'>{t('validationRequired')}</label>
                            )}
                          </Form.Group>
                        </Col>
                        <Col className="pl-3" md="3">
                          <Form.Group
                            className={
                              "has-label " +
                              (classOptionState ? "has-success" : "has-error")
                            }
                          >
                            <label>{t("employee")}<span className="star">*</span></label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="employee name"
                              value={employeename}
                              onChange={(optionSelected) => {
                                setEmployeename({ value: optionSelected.value, label: optionSelected.label });
                                if (isRequired(optionSelected.value)) {
                                  setEmployeeNameState(true);
                                } else {
                                  setEmployeeNameState(false);
                                }
                              }}
                              options={employeeArray}
                              placeholder={t("singleSelect")}
                            />
                            {employeeNameState ? null : (<label className="error">{t("validationRequired")}</label>)}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <div className='modal-footer'>
                    <Button
                      className='btn-simple'
                      onClick={() => {
                        onUpsert();
                      }}
                      variant='link'>
                      {t('btnSave')}
                    </Button>
                    <Button
                      className='btn-simple'
                      onClick={() => {
                        setModal(!modal);
                        setBookId('');
                        setBookName('');
                        setBookNumber('');
                        //setClassOption('')
                        setBookNameState(true);
                        setBookNumberState(true);
                        //setClassOptionState(true);
                      }}
                      variant='link'>
                      {t('btnClose')}
                    </Button>
                  </div>
                </Modal>
                {/* End Modal */}
                {flags ?
                  !common.loading ? (
                    limitedClasses
                      .map((item, index) => (
                        // characters.filter((i) => i.class_id == item.value).length > 0 && (
                        <Card className='stacked-form' key={index}>
                          <Card.Header>
                            <Card.Title as='h5'>{item?.label}</Card.Title>
                          </Card.Header>
                          <Card.Body className='overflow-auto' >
                            {!loading ? (
                              characters.filter((i) => i.class_id == item.value).length > 0 ?
                                <Table>
                                  <thead>
                                    <tr>
                                      <th className='text-center' style={{ paddingLeft: '8px' }}>#</th>
                                      <th style={{ width: '60%' }}>{t('bookName')}</th>
                                      <th className='text-center'>{t('number')}</th>
                                      <th className='text-center'>{t('actions')}</th>
                                    </tr>
                                  </thead>
                                  <DragDropContext onDragEnd={(result) => handleOnDragEnd(result, item.value)}>
                                    <Droppable droppableId="characters">
                                      {(provided) => (
                                        <tbody {...provided.droppableProps} ref={provided.innerRef} >
                                          {characters
                                            .filter((i) => i.class_id == item.value)
                                            .map((bookItem, bookIndex) => {
                                              return (
                                                <Draggable key={bookIndex} draggableId={bookIndex.toString()} index={bookIndex}>
                                                  {(provided, snapshot) => {
                                                    return snapshot.isDragging ? (
                                                      <DraggedRow
                                                        bookIndex={bookIndex}
                                                        bookItem={bookItem}
                                                        snapshot={snapshot}
                                                        provided={provided}
                                                        classIndex={index}
                                                      />
                                                    ) : (
                                                      <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        draggable
                                                      >
                                                        <td className='text-center' style={{ paddingLeft: '8px' }}>{bookIndex + 1}</td>
                                                        <td>{bookItem.book_name}</td>
                                                        <td className='text-center'>{bookItem.number}</td>
                                                        <td className='td-actions text-center'>
                                                          <OverlayTrigger
                                                            overlay={
                                                              <Tooltip id='tooltip-981231696'>{t('btnEdit')}</Tooltip>
                                                            }
                                                          >
                                                            <Button
                                                              className='btn-link btn-xs'
                                                              variant='success'
                                                              onClick={(e) => {
                                                                setBookId(bookItem.id);
                                                                setBookName(bookItem.book_name);
                                                                setBookNumber(bookItem.number);
                                                                setClassOption({
                                                                  value: bookItem.class_id,
                                                                  label: storeSelectConsts.CLASS_NAMES.find(
                                                                    (c) => c.value == bookItem.class_id,
                                                                  )?.label,
                                                                });
                                                                setModal(!modal);
                                                              }}>
                                                              <i className='fas fa-edit'></i>
                                                            </Button>
                                                          </OverlayTrigger>
                                                          <OverlayTrigger
                                                            overlay={<Tooltip id='tooltip-981231696'>{t("btnRemove")}</Tooltip>}>
                                                            <Button
                                                              className='btn-link btn-xs'
                                                              onClick={(e) => {
                                                                onConfirmDelete(bookItem.id, bookItem.book_name);
                                                              }}
                                                              variant='danger'>
                                                              <i className='fas fa-trash'></i>
                                                            </Button>
                                                          </OverlayTrigger>
                                                        </td>
                                                      </tr>
                                                    )
                                                  }}
                                                </Draggable>
                                              )
                                            })}
                                          {provided.placeholder}
                                        </tbody>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </Table>
                                :
                                <div className="pr-5 pl-5 pt-2 pb-3 text-warning text-center">{t("noBookFound")}</div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center my-5">
                                <Spinner animation="border" variant="warning" />
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                        // )
                      ))
                  ) : (<div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>)
                  : !common.loading ? (array
                    .filter((cls) => cls.isActive)
                    .map((item, index) => (
                      <Card className='stacked-form' key={index}>
                        <Card.Header>
                          <Card.Title as='h5'>{item?.label}</Card.Title>
                        </Card.Header>
                        <Card.Body className='overflow-auto'>
                          {/* characters.filter((i) => i.class_id == item.value).length > 0 */}
                          {!loading ? (<>{characters.filter((i) => i.class_id == item.value).length > 0 ? (
                            <Table>
                              <thead>
                                <tr>
                                  <th className='text-center' style={{ paddingLeft: '8px' }}>#</th>
                                  <th style={{ width: '60%' }}>{t('bookName')}</th>
                                  <th className='text-center'>{t('number')}</th>
                                  <th className='text-center'>{t('actions')}</th>
                                </tr>
                              </thead>
                              <DragDropContext onDragEnd={(result) => handleOnDragEnd(result, item.value)}>
                                <Droppable droppableId="characters">
                                  {(provided) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                      {characters
                                        .filter((i) => i.class_id == item?.value)
                                        .map((bookItem, bookIndex) => {
                                          return (
                                            <Draggable key={bookIndex} draggableId={bookIndex.toString()} index={bookIndex}>{(provided, snapshot) => {
                                              return snapshot.isDragging ? (
                                                <DraggedRow
                                                  bookIndex={bookIndex}
                                                  bookItem={bookItem}
                                                  snapshot={snapshot}
                                                  provided={provided}
                                                />
                                              ) : (
                                                <tr
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  draggable
                                                >
                                                  <td className='text-center' style={{ paddingLeft: '8px' }}>{bookIndex + 1}</td>
                                                  <td>{bookItem.book_name}</td>
                                                  <td className='text-center'>{bookItem.number}</td>
                                                  <td className='td-actions text-center'>
                                                    <OverlayTrigger
                                                      overlay={
                                                        <Tooltip id='tooltip-981231696'>{t('btnEdit')}</Tooltip>
                                                      }
                                                    >
                                                      <Button
                                                        className='btn-link btn-xs'
                                                        variant='success'
                                                        onClick={(e) => {
                                                          setBookId(bookItem.id);
                                                          setBookName(bookItem.book_name);
                                                          setBookNumber(bookItem.number);
                                                          setClassOption({
                                                            value: bookItem.class_id,
                                                            label: storeSelectConsts.CLASS_NAMES.find(
                                                              (c) => c.value == bookItem.class_id,
                                                            )?.label,
                                                          });
                                                          setModal(!modal);
                                                        }}>
                                                        <i className='fas fa-edit'></i>
                                                      </Button>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                      overlay={<Tooltip id='tooltip-981231696'>{t("btnRemove")}</Tooltip>}>
                                                      <Button
                                                        className='btn-link btn-xs'
                                                        onClick={(e) => {
                                                          onConfirmDelete(bookItem.id, bookItem.book_name);
                                                        }}
                                                        variant='danger'>
                                                        <i className='fas fa-trash'></i>
                                                      </Button>
                                                    </OverlayTrigger>
                                                  </td>
                                                </tr>
                                              )
                                            }}
                                            </Draggable>
                                          )
                                        })}
                                      {provided.placeholder}
                                    </tbody>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Table>
                          ) : (
                            <div className="pr-5 pl-5 pb-5 text-warning text-center">{t("noBookFound")}</div>
                          )}</>) : (
                            <div className="d-flex align-items-center justify-content-center my-5">
                              <Spinner animation="border" variant="warning" />
                            </div>
                          )}

                        </Card.Body>
                      </Card>
                    ))) : (<div className="d-flex align-items-center justify-content-center my-5">
                      <Spinner animation="border" variant="warning" />
                    </div>)}
              </Card.Body>
            </Card>
          </Col>
        </Row >
      </Container >
    </>
  );
}

export default Book;
