import customAxios from 'lib/axios';
import React, { useRef, useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import { selectConsts } from '../store/selectconstReducer';
import ReactToPrint from "react-to-print";
import { useTranslation } from 'react-i18next';
import { commonContext } from '../context/common';
import * as XLSX from 'xlsx';
// react-bootstrap components
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Spinner
} from 'react-bootstrap';

import Idcard1 from 'assets/img/idcards/01.jfif';
import Idcard2 from 'assets/img/idcards/02.jfif';
import Idcard3 from 'assets/img/idcards/03.jfif';
import Idcard4 from 'assets/img/idcards/04.jfif';
import Idcard5 from 'assets/img/idcards/05.jfif';
import Idcard6 from 'assets/img/idcards/06.jfif';
import Idcard7 from 'assets/img/idcards/07.jfif';
import Idcard8 from 'assets/img/idcards/08.jfif';

//printable content 
const PrintableContent = React.forwardRef(({ limitedEmployees, employeeJobTypes }, ref) => {
  const [t] = useTranslation(['employees']);
  const storeSelectConsts = useSelector(selectConsts);
  const common = useContext(commonContext)
  return (
    <div style={{ display: 'none' }}>
      <Table ref={ref}>
        <thead>
          <tr>
            <th className='text-center' style={{ paddingLeft: '8px' }}>#</th>
            <th>{t('name')}</th>
            <th className='text-left'>{t('genderLabel')}</th>
            <th className='text-center'>{t('jobroleLabel')}</th>
            <th className='text-center'>{t('currentStatusLabel')}</th>
            <th className='text-center'>{t('phoneNumber')}</th>
            <th className='text-center'>{t('idcardNumberLabel')}</th>
            <th className='text-center'>{t('cnicNumberLabel')}</th>
            <th className='text-center'>{t('jobTypeLabel')}</th>
          </tr>
        </thead>
        <tbody>
          {limitedEmployees.map((employee, index) => (
            <tr key={index}>
              <td className='text-left' style={{ paddingLeft: '8px' }}>{index + 1}</td>
              <td>{employee.employee_name}</td>
              <td className='text-center'>
                {
                  common?.lang === 'en' ?
                    storeSelectConsts?.GENDER_NAMES.find(item => item.value === employee?.gender_name?.id)?.label
                    :
                    storeSelectConsts?.GENDER_NAMESAR.find(item => item.value === employee?.gender_name?.id)?.label
                }
              </td>
              {/* //<td className="text-right">{employee.employee_jobrole.admission_statusname}</td> */}
              <td className='text-center'>{storeSelectConsts.JOB_ROLES.find(item => item.value === employee.employee_jobrole.id)?.label}</td>
              <td className='text-center'>
                {
                  common.lang === 'en' ?
                    storeSelectConsts.EMPLOYEE_CURRENT_STATUSES.find(item => item.value === employee.employee_currentstatus.id)?.label
                    :
                    storeSelectConsts.EMPLOYEE_CURRENT_STATUSESAR.find(item => item.value === employee.employee_currentstatus.id)?.label
                }
              </td>
              <td className='text-center'>{employee.phone}</td>
              <td className='text-center'>{employee.idcard_number}</td>
              <td className='text-center'>{employee.cnic_number}</td>
              {/* <td className="text-right">1st half, 2nd half, 3rd half, 4th half </td> */}
              <td className='text-center'>
                {employeeJobTypes
                  .filter((i) => i.employee_id == employee.id)
                  // .map((item) => <span>dsfds</span>) }
                  // .map((item, index1) => <span key={index1}>{index == 0 ? "" : ", "}{item.jobtypename_id}</span>)}
                  .map((item, index1) => {
                    return (
                      <span key={index1}>
                        {index1 == 0 ? '' : ', '}
                        {
                          common?.lang === 'en' ?
                            item?.jobtypename_id === 1 ? `${storeSelectConsts?.EMPLOYEE_JOBTYPES[0]?.label} ` : `${storeSelectConsts?.EMPLOYEE_JOBTYPES[1]?.label}`
                            :
                            item?.jobtypename_id === 1 ? `${storeSelectConsts?.EMPLOYEE_JOBTYPESAR[0]?.label} ` : `${storeSelectConsts?.EMPLOYEE_JOBTYPESAR[1]?.label}`
                        }
                      </span>
                    );
                  })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Render student details here based on the provided data */}
      {/* You can use the same structure as in your paginated component */}
    </div>
  );
});

function EmployeesList() {
  const [t] = useTranslation(['employees']);
  const common = useContext(commonContext)
  let componentRef = useRef();
  const [loading, setLoading] = React.useState(true)
  const history = useHistory();
  const [alert, setAlert] = React.useState(null);
  const notificationAlertRef = React.useRef(null);
  const storeSelectConsts = useSelector(selectConsts);
  const [employeeName, setEmployeeName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [idcardNumber, setIdcardNumber] = React.useState('');
  const [cnicNumber, setCnicNumber] = React.useState('');
  //const [admissionStatus, setAdmissionStatus] = React.useState("");
  const [jobRole, setJobRole] = React.useState({ value: -1, label: "All" });
  const [gender, setGender] = React.useState({ value: "", label: 'All' });
  const [modalIDCard, setModalIDCard] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [employeeJobTypes, setEmployeeJobTypes] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [curModalEmployeeName, setCurModalEmployeeName] = React.useState('');
  const [pageRowsCnt, setPageRowsCnt] = React.useState({ value: 5, label: '5 rows' });
  const [modalTermination, setModalTermination] = React.useState(false)
  const allEmployeesCount = filteredList.length;
  const [employeesPerPage, setEmployeesPerPage] = React.useState(5);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [termEmployee, setTermEmployee] = React.useState("")
  const [termSEmpIdCard, setTermEmpIdCard] = React.useState("");
  const [termEmpId, setTermEmpId] = React.useState("")
  const [reasonForTerm, setReasonForTerm] = React.useState("");
  const lastEmployeeNumber = currentPage * employeesPerPage;
  const firstEmployeeIndex = lastEmployeeNumber - employeesPerPage;
  const limitedEmployees = filteredList.slice(firstEmployeeIndex, lastEmployeeNumber);
  React.useEffect(() => {
    customAxios
      .get('/employee/getAll')
      .then((res) => {
        // const reversed = res.data.reverse();
        setEmployees(res.data.employees);
        setFilteredList(res.data.employees);
        setEmployeeJobTypes(res.data.jobTypes);
        setLoading(false)
      })
      .catch((err) => {
        // exception error handling
      });
  }, []);
  React.useEffect(() => {
    onFilter();
    // }, [employeeName, phoneNumber, idcardNumber, cnicNumber, admissionStatus, gender,]);
  }, [employeeName, phoneNumber, idcardNumber, cnicNumber, jobRole, gender]);
  const getRandImgUrl = (max) => {
    let rand = Math.random() * max;
    rand = Math.floor(rand);
    switch (rand) {
      case 0:
        return Idcard1;
      case 1:
        return Idcard2;
      case 2:
        return Idcard3;
      case 3:
        return Idcard4;
      case 4:
        return Idcard5;
      case 5:
        return Idcard6;
      case 6:
        return Idcard7;
      case 7:
        return Idcard8;
    }
    return Idcard1;
  };
  // const warningWithConfirmMessage = (id, registerationDate) => {
  //   setAlert(
  //     <SweetAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => onConfirmDelete(id, registerationDate)}
  //       onCancel={() => hideAlert()}
  //       confirmBtnBsStyle="info"
  //       cancelBtnBsStyle="danger"
  //       confirmBtnText="Submit"
  //       cancelBtnText="Cancel"
  //       showCancel
  //     >
  //       You will not be able to recover this employee!
  //     </SweetAlert>
  //   );
  // };
  const successAlert = (employee) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title={t('submitSuccessAlertTitle')}
        onConfirm={() => onSuccessOkay()}
        onCancel={() => onSuccessOkay()}
        confirmBtnBsStyle='info'
        confirmBtnText={t('btnOkay')}>
        {t('msgRemoveSuccess.first')} {employee} {t('msgRemoveSuccess.second')}
      </SweetAlert>,
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };
  const onSuccessOkay = () => {
    setAlert(null);
  };
  const notify = (place, msg) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{msg}</b>
          </div>
        </div>
      ),
      type: 'danger',
      icon: 'nc-icon nc-bell-55',
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const onConfirmDelete = (id, registerationDate) => {
    // submit form values
    customAxios
      .post('/employee/deleteOne/', {
        employee_id: id,
        reasonForTerm: reasonForTerm
      })
      .then((res) => {
        if (res.data.msg == 'success') {
          successAlert(termEmployee);
          setEmployees(employees.filter((item) => item.id != id));
          setFilteredList(filteredList.filter((item) => item.id != id));
          setReasonForTerm('')
          setTermEmpId('')
          setTermEmpIdCard('')
          setTermEmployee('')
          setModalTermination(!modalTermination)
        }
      })
      .catch((err) => {
        // Work with the err
        notify('tr', t('notifyConnectionProblem'));
      });
  };
  const onEmployeeIdCard = (employeeName) => {
    setCurModalEmployeeName(employeeName);
    setModalIDCard(!modalIDCard);
  };
  const handlePrint = (employeeName) => {
    setCurModalEmployeeName(employeeName)
  }
  const onFilter = () => {
    setFilteredList(
      employees.filter(
        (item) =>
          item.employee_name.includes(employeeName) &&
          item.phone.includes(phoneNumber) &&
          item.idcard_number.includes(idcardNumber) &&
          item.cnic_number.includes(cnicNumber) &&
          // item.cnic.includes(cnicNumber))
          //item.admission_statusid.toString().includes(admissionStatus?.value ? admissionStatus?.value : "") &&
          (jobRole?.value === -1 || item.job_role === (jobRole?.value ? jobRole?.value : '')) &&
          item.gender.toString().includes(gender?.value ? gender?.value : ''),
      ),
    );
  };
  const data = [];
  for (let i = 0; i <= filteredList.length; i++) {
    const appointmentTypeLabels = employeeJobTypes
      .filter((item) => item.employee_id === filteredList[i]?.id)
      .map((item) => {
        return common?.lang === 'en' ?
          (item?.jobtypename_id === 1 ? storeSelectConsts?.EMPLOYEE_JOBTYPES[0]?.label : storeSelectConsts?.EMPLOYEE_JOBTYPES[1]?.label)
          : (item?.jobtypename_id === 1 ? storeSelectConsts?.EMPLOYEE_JOBTYPESAR[0]?.label : storeSelectConsts?.EMPLOYEE_JOBTYPESAR[1]?.label);
      });
    const appointmentType = appointmentTypeLabels.join(', ');
    data.push({
      Name: filteredList[i]?.employee_name,
      Gender: common?.lang === 'en' ?
        storeSelectConsts.GENDER_NAMES.find((item) => item.value == filteredList[i]?.gender)?.label
        : storeSelectConsts.GENDER_NAMESAR.find((item) => item.value == filteredList[i]?.gender)?.label,
      Job_Role: storeSelectConsts.JOB_ROLES.find((item) => item.value == filteredList[i]?.job_role
      )?.label,
      Current_status: common?.lang === 'en' ? storeSelectConsts.EMPLOYEE_CURRENT_STATUSES.find((item) => item.value == filteredList[i]?.employee_currentstatus?.id)?.label : storeSelectConsts.EMPLOYEE_CURRENT_STATUSESAR.find((item) => item.value === filteredList[i]?.employee_currentstatus?.id)?.label,
      ID_Number: filteredList[i]?.idcard_number,
      CNIC_Number: filteredList[i]?.cnic_number,
      Appointment_Type: appointmentType
    })
  }
  const exportToExcel = () => {
    /* Create a worksheet from the table data */
    const ws = XLSX.utils.json_to_sheet(data);
    /* Create a workbook with the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* Save the workbook as an Excel file */
    XLSX.writeFile(wb, 'table_data.xlsx');
  };
  React.useEffect(() => {
    if (common?.lang === 'en') {
      setGender(storeSelectConsts.GENDER_NAMES.find((item) => item?.value === gender?.value))
    } else {
      setGender(storeSelectConsts.GENDER_NAMESAR.find((item) => item?.value === gender?.value))
    }
  }, [common.lang])
  return (
    <>
      {alert}
      <div className='rna-container'>
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row>
          <Col md='12'>
            <Card className='stacked-form'>
              <Card.Header>
                <Card.Title as='h4'>{t('employeeList')}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form action='' className='form' method=''>
                  <Card className='pl-3 pr-3'>
                    <Card.Body>
                      <Row>
                        <Col className='pr-md-1' md='3'>
                          <Form.Group className="has-success">
                            <label>{t('employeeNameLabel')}</label>
                            <Form.Control
                              type='text'
                              value={employeeName}
                              onChange={(e) => {
                                setEmployeeName(e.target.value);
                              }}
                              placeholder={t('searchEmployeeName')}></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className='pl-md-1 pr-md-1' md='3'>
                          <Form.Group className="has-success">
                            <label>{t('phoneNumber')}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t('searchEmployeePhone')}
                              type='text'
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className='pl-md-1 pr-md-1' md='3'>
                          <Form.Group className="has-success">
                            <label>{t('idcardNumber')}</label>
                            <Form.Control
                              // defaultValue="Mike"
                              placeholder={t('searchEmployeeIdCard')}
                              type='text'
                              value={idcardNumber}
                              onChange={(e) => {
                                setIdcardNumber(e.target.value);
                              }}></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className='pl-md-1' md='3'>
                          <Form.Group className="has-success">
                            <label>{t('cnicNumberLabel')}</label>
                            <Form.Control
                              // defaultValue="Andrew"
                              placeholder={t('searchEmployeeCnic')}
                              type='text'
                              value={cnicNumber}
                              onChange={(e) => {
                                setCnicNumber(e.target.value);
                              }}></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>{t("admissionStatusLabel")}</label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="admissionStatus"
                              value={admissionStatus}
                              onChange={(optionSelected) => setAdmissionStatus({ value: optionSelected.value, label: optionSelected.label })}
                              options={[...storeSelectConsts.ADMISSION_STATUSES, { value: "", label: "All" }]}
                              placeholder={t("singleSelectPlaceholder")}
                            />
                          </Form.Group>
                        </Col> */}
                        <Col className='pr-md-1' md='6'>
                          <Form.Group className="has-success">
                            <label>{t('jobroleLabel')}</label>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='jobrole'
                              value={jobRole}
                              onChange={(optionSelected) =>
                                setJobRole({
                                  value: optionSelected.value,
                                  label: optionSelected.label,
                                })
                              }
                              options={[
                                { value: -1, label: 'All' },
                                ...storeSelectConsts.JOB_ROLES.filter((item) => item.value !== -1),
                              ]}
                              placeholder={t('singleSelectPlaceholder')}
                            />
                          </Form.Group>
                        </Col>
                        <Col className='pl-md-1' md='6'>
                          <Form.Group className="has-success">
                            <label>{t('genderLabel')}</label>
                            <Select
                              className='react-select primary'
                              classNamePrefix='react-select'
                              name='gender'
                              value={gender}
                              onChange={(optionSelected) =>
                                setGender({
                                  value: optionSelected.value,
                                  label: optionSelected.label,
                                })
                              }
                              options={
                                common.lang === 'en' ?
                                  [{ value: '', label: 'All' },
                                  ...storeSelectConsts.GENDER_NAMES
                                  ] :
                                  [
                                    { value: '', label: 'All' },
                                    ...storeSelectConsts.GENDER_NAMESAR
                                  ]
                              }
                              placeholder={t('singleSelectPlaceholder')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className='clearfix'></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Card.Body>
              <div className='d-flex justify-content-end mr-md-2'>
                <Pagination
                  itemsCount={allEmployeesCount}
                  itemsPerPage={employeesPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  alwaysShown={false}
                />
                <div style={{ width: 155 }}>
                  <Select
                    className='react-select primary ml-4'
                    classNamePrefix='react-select'
                    style={{ width: `100px` }}
                    name='rowCnt'
                    value={pageRowsCnt}
                    onChange={(optionSelected) => {
                      setPageRowsCnt({ value: optionSelected.value, label: optionSelected.label });
                      setEmployeesPerPage(optionSelected.value);
                    }}
                    options={[
                      { value: 3, label: '3 rows' },
                      { value: 5, label: '5 rows' },
                      { value: 10, label: '10 rows' },
                      { value: 15, label: '15 rows' },
                      { value: 20, label: '20 rows' },
                      { value: 25, label: '25 rows' },
                    ]}
                    placeholder={t('singleSelectPlaceholder')}
                  />
                </div>
              </div>
              <Card.Body className='table-full-width overflow-auto' ref={(el) => (componentRef = el)}>
                {!loading && !common?.loading ? (<>
                  {limitedEmployees?.length > 0 ? (
                    <Table>
                      <thead>
                        <tr>
                          <th className='text-center' style={{ paddingLeft: '8px' }}>#</th>
                          <th>{t('name')}</th>
                          <th className='text-left'>{t('genderLabel')}</th>
                          <th className='text-center'>{t('jobroleLabel')}</th>
                          <th className='text-center'>{t('currentStatusLabel')}</th>
                          <th className='text-center'>{t('phoneNumber')}</th>
                          <th className='text-center'>{t('idcardNumberLabel')}</th>
                          <th className='text-center'>{t('cnicNumberLabel')}</th>
                          <th className='text-center'>{t('jobTypeLabel')}</th>
                          <th className='text-left'>{t('actions')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {limitedEmployees.map((employee, index) => (
                          <tr key={index}>
                            <td className='text-left' style={{ paddingLeft: '8px' }}>{firstEmployeeIndex + index + 1}</td>
                            <td>{employee.employee_name}</td>
                            <td className='text-center'>
                              {
                                common?.lang === 'en' ?
                                  storeSelectConsts?.GENDER_NAMES.find(item => item.value === employee?.gender_name?.id)?.label
                                  :
                                  storeSelectConsts?.GENDER_NAMESAR.find(item => item.value === employee?.gender_name?.id)?.label
                              }
                            </td>
                            {/* //<td className="text-right">{employee.employee_jobrole.admission_statusname}</td> */}
                            <td className='text-center'>{storeSelectConsts.JOB_ROLES.find(item => item.value === employee.employee_jobrole.id)?.label}</td>
                            <td className='text-center'>
                              {
                                common.lang === 'en' ?
                                  storeSelectConsts.EMPLOYEE_CURRENT_STATUSES.find(item => item.value === employee.employee_currentstatus.id)?.label
                                  :
                                  storeSelectConsts.EMPLOYEE_CURRENT_STATUSESAR.find(item => item.value === employee.employee_currentstatus.id)?.label
                              }
                            </td>
                            <td className='text-center'>{employee.phone}</td>
                            <td className='text-center'>{employee.idcard_number}</td>
                            <td className='text-center'>{employee.cnic_number}</td>
                            {/* <td className="text-right">1st half, 2nd half, 3rd half, 4th half </td> */}
                            <td className='text-center'>
                              {employeeJobTypes
                                .filter((i) => i.employee_id == employee.id)
                                // .map((item) => <span>dsfds</span>) }
                                // .map((item, index1) => <span key={index1}>{index == 0 ? "" : ", "}{item.jobtypename_id}</span>)}
                                .map((item, index1) => {
                                  return (
                                    <span key={index1}>
                                      {index1 == 0 ? '' : ', '}
                                      {
                                        common?.lang === 'en' ?
                                          item?.jobtypename_id === 1 ? `${storeSelectConsts?.EMPLOYEE_JOBTYPES[0]?.label} ` : `${storeSelectConsts?.EMPLOYEE_JOBTYPES[1]?.label}`
                                          :
                                          item?.jobtypename_id === 1 ? `${storeSelectConsts?.EMPLOYEE_JOBTYPESAR[0]?.label} ` : `${storeSelectConsts?.EMPLOYEE_JOBTYPESAR[1]?.label}`
                                      }
                                    </span>
                                  );
                                })}
                            </td>
                            <td className='td-actions text-left'>
                              <OverlayTrigger
                                overlay={<Tooltip id='tooltip-981231696'>{t('editProfile')}</Tooltip>}>
                                <Button
                                  className='btn-link btn-xs'
                                  onClick={(e) =>
                                    history.push('/admin/add_edit_employee/' + employee.idcard_number)
                                  }
                                  variant='success'>
                                  <i className='fas fa-edit'></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                href='#pablo'
                                onClick={(e) => e.preventDefault()}
                                overlay={<Tooltip id='tooltip-981231696'>{t('idCard')}</Tooltip>}>
                                <Button
                                  className='btn-link btn-xs'
                                  // onClick={() => onEmployeeIdCard(employee.employee_name)}
                                  onClick={(e) =>
                                    history.push('/admin/employee_cards/' + employee.idcard_number)
                                  }
                                  variant='dark'>
                                  <i className='far fa-address-card'></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                  <Tooltip id="tooltip-981231696">
                                    {t("btnTerminate")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className="btn-link btn-xs"
                                  onClick={() => {
                                    setTermEmployee(employee?.employee_name)
                                    setTermEmpIdCard(employee?.idcard_number)
                                    setTermEmpId(employee?.id)
                                    setModalTermination(true)
                                  }}
                                  variant="danger"
                                >
                                  <i className="fas fa-user-slash"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>) :
                    (
                      <div className="pr-5 pl-5 pt-5 pb-5 text-warning text-center">{t("noEmployeeFound")}</div>
                    )
                  }
                </>) : (
                  <div className="d-flex align-items-center justify-content-center my-5">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between">
                <ReactToPrint
                  trigger={() =>
                    <Button className="btn-edit btn-fill" type="submit" variant="info">
                      <span className="btn-label">
                        <i className="fas fa-print"></i>
                      </span>
                      &nbsp;{t("btnPrint")}
                    </Button>
                  }
                  content={() => componentRef}
                />
                <PrintableContent limitedEmployees={filteredList} employeeJobTypes={employeeJobTypes} ref={(el) => (componentRef = el)} hidden style={{ display: 'none' }} />
                <Button Button className="btn-edit btn-fill" type="submit" variant="info" onClick={exportToExcel}>
                  <span className="btn-label">
                    <i className="fas fa-print"></i>
                  </span>
                  &nbsp;{t("btnExcel")}
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        {/* ID card Modal */}
        <Modal
          className='modal-primary'
          size='sm'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={() => {
            setModalIDCard(!modalIDCard);
          }}
          show={modalIDCard}>
          <Card className='stacked-form'>
            <Card.Header>
              <Card.Title className='text-center' as='h5'>
                {curModalEmployeeName}
                {t('modalIdcardTitle')}
              </Card.Title>
            </Card.Header>
            <Card.Body className='text-center' ref={(el) => (componentRef = el)}>
              <img alt='id card' src={getRandImgUrl(8)} width={220} height={150}></img>
            </Card.Body>
          </Card>
          <div className='modal-footer'>
            <Button
              className='btn-simple mx-auto'
              onClick={() => {
                setModalIDCard(!modalIDCard);
              }}
              variant='link'>
              {t('btnClose')}
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button className='btn-link btn-xs' href='#pablo' variant='info'>
                  <i className='fas fa-print'></i>
                </Button>
              )}
              content={() => componentRef}
            />
          </div>
        </Modal>
        {/* End Modal */}
        {/* employee termination modal */}
        <Modal
          className="modal-primary"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => { setModalTermination(!modalTermination) }}
          show={modalTermination}
        >
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title className="text-center" as="h5">{t("termModalTitle")}</Card.Title>
            </Card.Header>
            <Card.Body className="mx-2">
              <Row>
                <Col className="pl-1 pr-1" md="6">
                  <Form.Group
                    className={
                      "has-label"
                    }
                  >
                    <label>{t("name")}</label>
                    <Form.Control
                      placeholder=""
                      value={termEmployee}
                      type="text"
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col className="pl-1 pr-1" md="6">
                  <Form.Group
                    className={
                      "has-label"
                    }
                  >
                    <label>{t("idCard")}</label>
                    <Form.Control
                      placeholder=""
                      value={termSEmpIdCard}
                      type="text"
                      disabled
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="pl-1 pr-1" md="12">
                  <Form.Group className="mb-3 has-success has-label" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>{t("TermReasonLabel")}</Form.Label>
                    <Form.Control as="textarea" rows={3} value={reasonForTerm} onChange={(e) => setReasonForTerm(e.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <div className="modal-footer">
            <Button
              className="btn-simple"
              onClick={() => {
                onConfirmDelete(termEmpId)
              }}
              variant="link"
            >
              {t("btnTerminate")}
            </Button>
            <Button
              className="btn-simple"
              onClick={() => {
                setModalTermination(!modalTermination)
                setReasonForTerm('')
                setTermEmpId('')
                setTermEmpIdCard('')
                setTermEmployee('')
              }}
              variant="link"
            >
              {t("btnClose")}
            </Button>
          </div>
        </Modal>
        {/* End Modal */}
      </Container>
    </>
  );
}
export default EmployeesList;

